import {keyframes} from '@emotion/react';
import styled from '@emotion/styled';
import {ReactComponent as OriginalSpinner} from './spinner.svg';

interface Props {
  size: number;
}

const rotate = keyframes`
  from { transform: rotate(0); }
  to { transform: rotate(360deg); }
`;

const Spinner = styled(OriginalSpinner)`
  width: ${({size}: Props) => size}px;
  height: ${({size}: Props) => size}px;
  animation: ${rotate} 2s linear infinite;
`;

export default Spinner;
