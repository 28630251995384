import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {Table, Tab} from 'semantic-ui-react';

const SMALLER_COLUMN_WIDTH = 4;
const SMALL_COLUMN_WIDTH = 8;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
`;

export const StyledPharmacyInformationContainer = styled.div`
  color: #8695a9;
  flex-basis: 65%;

  > div {
    margin-top: 25px;
    margin-left: 199px;
  }
`;

export const StyledPatientInformationContainer = styled.div`
  position: relative;
  flex-basis: 35%;
`;

export const StyledSheetName = styled.h2`
  font-family: BuenosAires, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
  color: #0b2852;
  font-feature-settings: 'liga' off;
`;

// The margin top aligns this component to StyledPharmacyInformationContainer > div
export const StyledPatientAddressContainer = styled.div`
  margin-top: 51px !important;
`;

export const StyledTableWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 450px;
`;

export const StyledGridContainer = styled.div<{small?: boolean}>`
  display: inline-grid;
  width: ${(props) => (props.small ? '10%' : '70%')};
  grid-template-areas:
    'label1 content1'
    'label2 content2'
    'label3 content3';
  grid-template-columns: 50% 50%;

  label {
    opacity: 0.6;
    text-transform: uppercase;
    font-family: Gilroy, sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.03em;
    color: #0b2852;
    font-feature-settings: 'liga' off;
  }
`;

export const StyledStatusWrapper = styled.div<{small?: boolean}>`
  width: ${(props) => (props.small ? 'auto' : '220px')};
`;

export const StyledStatusContainer = styled.div`
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

const sharedStickyTableHeaderStyles = css`
  thead tr > th {
    top: 0 !important;
    background-color: #dde5ed !important;
  }
`;

// The 1px height is simply set so the table rows are as small a possible
// The last (empty) row will take the remaining space
const sharedTBodyHeight = css`
  tbody {
    tr {
      height: 1px;

      &:last-of-type {
        height: auto;
      }
    }
  }
`;

const sharedStickyTableFooterStyles = css`
  tfoot {
    position: sticky;
    bottom: 0;
    background-color: white;

    tr:first-of-type {
      background-color: #f5f7fa;

      td {
        border-top: 1px solid #dde5ed !important;
      }
    }

    td {
      z-index: 2;
      border-bottom: 0;
    }
  }
`;

export const StyledInvoiceTable = styled(Table)`
  width: 100%;
  height: 100%;
  border: 0 !important;
  border-collapse: collapse;

  ${sharedTBodyHeight}

  th,
  td {
    padding: 20px 30px !important;
    border-bottom: 1px solid #dde5ed;
    vertical-align: middle !important;
  }

  ${sharedStickyTableHeaderStyles}
  ${sharedStickyTableFooterStyles}
`;

export const StyledSummaryTable = styled(Table)`
  width: 100%;
  height: 100%;
  border: 0 !important;
  border-collapse: separate;

  ${sharedTBodyHeight}

  th,
  td {
    width: 50%;
    padding: 20px 30px !important;

    :last-of-type {
      text-align: end !important;
    }
  }

  h2,
  h3 {
    margin: 0 !important;
  }

  ${sharedStickyTableHeaderStyles}
  ${sharedStickyTableFooterStyles}
`;

const sharedCheckboxOverrideStyles = css`
  > div {
    min-height: auto;
    margin: 0;

    label {
      width: fit-content;
      margin: 0 auto;
    }

    input {
      margin-right: 0;
    }
  }
`;

export const StyledTh = styled(Table.HeaderCell, {
  shouldForwardProp(propName) {
    return !['allBold', 'smaller', 'small', 'checkbox', 'money'].includes(propName.toString());
  },
})`
  font-family: BuenosAires, sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: #0b2852 !important;
  font-feature-settings: 'liga' off !important;

  ${(props) => {
    if (!props.allBold) {
      return `> div:not(:first-of-type) {
                opacity: 0.6;
              }`;
    } else return null;
  }}

  ${(props) =>
    props.smaller
      ? `width: ${SMALLER_COLUMN_WIDTH}%;`
      : props.small
      ? `width: ${SMALL_COLUMN_WIDTH}%;`
      : null}
  ${(props) => (props.checkbox ? sharedCheckboxOverrideStyles : null)}
  ${(props) => (props.money ? 'text-align: right !important;' : null)};
`;

export const StyledTd = styled(Table.Cell, {
  shouldForwardProp(propName) {
    return !['money', 'checkbox'].includes(propName.toString());
  },
})`
  position: relative;

  > strong + strong {
    display: block;
  }

  ${(props) => (props.money ? 'text-align: right !important;' : null)};
  ${(props) => (props.checkbox ? sharedCheckboxOverrideStyles : null)}

  h3 {
    margin-bottom: 0 !important;
  }
`;

export const StyledMoney = styled.div<{light?: boolean}>`
  text-align: right;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: #0b2852;
  font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;
  ${(props) => (props.light ? 'opacity: 0.6;' : null)}
`;

export const StyledCode = styled(StyledMoney)`
  text-align: left;
`;

export const StyledTdEmpty = styled(Table.Cell)`
  padding: 16px 0;
  text-align: center;

  h3 {
    margin-bottom: 0 !important;
  }
`;

export const StyledTotal = styled.h3`
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  color: #0b2852;
`;

export const StyledInvoiceDate = styled.div`
  opacity: 0.6;
  font-family: BuenosAires, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  color: #0b2852;
  font-feature-settings: 'liga' off;
`;

export const StyledReceiptNumber = styled(StyledInvoiceDate)`
  text-align: right;
`;

export const StyledHealthInsurance = styled.div`
  text-transform: uppercase;
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #0b2852;
  font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

  label {
    opacity: 0.6;
    margin-right: 5px;
  }
`;

export const StyledTab = styled(Tab)`
  .ui.secondary.pointing.menu {
    overflow-y: hidden;
    border-bottom: 0 !important;

    .item {
      opacity: 0.6;
      border-bottom: 3px solid white;
      font-weight: bold;

      &.active {
        opacity: 1;
        border-bottom: 3px solid #306cf6;
      }
    }
  }
`;

const StyledInvoiceCompactStyles = css`
  margin: 4px 2px;

  ${StyledHeader} {
    padding: 3px;
  }

  ${StyledPharmacyInformationContainer} {
    > div {
      margin-top: 0;
    }
  }

  ${StyledPatientAddressContainer} {
    margin-top: 29px !important;
  }

  ${StyledTableWrapper} {
    height: 550px;
  }

  ${StyledInvoiceTable} {
    th,
    td {
      padding: 2px 10px !important;
    }
  }
`;

export const StyledInvoiceContentContainer = styled.div<{compact?: boolean}>`
  min-width: 1024px;
  border: 1px solid #dde5ed;
  border-radius: 5px;
  margin: 40px 20px;

  ${(props) => props.compact && StyledInvoiceCompactStyles};
`;

export const StyledAddressDiv = styled.div<{light?: boolean}>`
  text-transform: uppercase;
  font-family: Gilroy, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #0b2852;
  font-feature-settings: 'liga' off;
  ${(props) => (props.light ? 'opacity: 0.6;' : null)}
`;

export const StyledPrintableInvoiceContentContainer = styled.div`
  overflow: auto;
  page-break-after: always;

  ${StyledInvoiceContentContainer} {
    margin: 0;
  }

  ${StyledTableWrapper} {
    overflow: auto;
    height: 100%;
  }

  ${StyledInvoiceTable} {
    tr {
      break-inside: avoid;
    }

    thead,
    tfoot {
      display: table-row-group;
      position: relative;
      page-break-inside: avoid;
    }
  }
`;
