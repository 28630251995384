import React, {Suspense, useContext} from 'react';
import styled from '@emotion/styled';
import {FlagsProvider} from 'flagged';
import {Route, Switch} from 'react-router-dom';
import track from 'react-tracking';
import env from 'config/environment';
import ErrorBoundary from 'ui/@components/error-boundary';
import Loader from 'ui/@components/loader';
import FirebaseAuthContext from 'ui/@contexts/firebase-auth-context';
import useCodePush from 'ui/@hooks/use-code-push';
import useDeepLinking from 'ui/@hooks/use-deep-linking';
import useLocalizedRoutes from 'ui/@hooks/use-localized-routes';
import Admin from 'ui/admin';
import InvoiceById from 'ui/invoice-by-id';
import NotFound from 'ui/not-found';
import Logout from 'ui/public/logout';
import ShortUrl from 'ui/short-url';
import LocalizedRedirect from '../@components/localized-redirect';
import Client from '../client';

// Uncomment this to enable xstate inspection tools
// import {inspect} from '@xstate/inspect';
// inspect({
//   url: 'https://statecharts.io/inspect',
//   iframe: false,
// });

import 'semantic-ui-less/semantic.less';
import 'styles/fonts.css';
import 'styles/global.css';

const MandatoryUpdateLoaderContainer = styled.div`
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
`;

const Application: React.FC = () => {
  const {getRouteByName} = useLocalizedRoutes();
  const {isRedirecting, isAdmin, authState, isUserAnonymous} = useContext(FirebaseAuthContext);

  useDeepLinking();
  const {isInstallingMandatoryPackage} = useCodePush();

  if (isRedirecting || authState.status === 'verifying') return <Loader delay={0} />;

  const features = {
    admin: isAdmin ?? false,
    ...env.featureFlags,
  };

  return (
    <FlagsProvider features={features}>
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          {isInstallingMandatoryPackage && (
            <MandatoryUpdateLoaderContainer>
              <Loader delay={0} />
            </MandatoryUpdateLoaderContainer>
          )}

          <Switch>
            <Route path="/version" render={() => env.application.version} />

            <Route path="/invoice/:id" component={InvoiceById} />

            <Route {...getRouteByName('short-url')} component={ShortUrl} />

            <Route {...getRouteByName('logout')} component={Logout} />

            {isAdmin && (
              <LocalizedRedirect exact from={getRouteByName('landing').path} to="admin" />
            )}
            {isAdmin && <LocalizedRedirect from={getRouteByName('sign-up').path} to="admin" />}

            <Route {...getRouteByName('admin')} component={Admin} />

            {isUserAnonymous && (
              <LocalizedRedirect exact from={getRouteByName('landing').path} to="login" />
            )}

            <Route path="/" component={Client} />

            {/* Catch-all for lost people */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </FlagsProvider>
  );
};

export default track()(Application);
