import {createContext} from 'react';
import {AuthError} from 'firebase/auth';
import {AuthState} from 'ui/application/@components/firebase-auth-provider';

interface AuthContext {
  authState: AuthState;
  isAdmin?: boolean;
  isPatient?: boolean;
  isAcquisitionLead?: boolean;
  isRedirecting: boolean;
  setIsRedirecting: React.Dispatch<React.SetStateAction<boolean>>;
  isParentSession?: boolean;
  isUserAnonymous: boolean;
  signInWithRedirectError: AuthError | undefined;
  setSignInWithRedirectError: React.Dispatch<React.SetStateAction<AuthError | undefined>>;
}

const FirebaseAuthContext = createContext<AuthContext>(null as any);

export default FirebaseAuthContext;
