import {ExtraErrorData as ExtraErrorDataIntegration} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import {excludeGraphQLFetch} from 'apollo-link-sentry';
import env from 'config/environment';

const ignoreErrors = [
  /__show__deepen/,
  /_avast_submit/,
  /^No error$/,
  /Access is denied/,
  /anonymous function: captureException/,
  /Blocked a frame with origin/,
  /Cannot read properties of undefined \(reading 'default'\)/,
  /console is not defined/,
  /cordova/,
  /DataCloneError/,
  /does not have web implementation/,
  /Error: AccessDeny/,
  /event is not defined/,
  /feedConf/,
  /ibFindAllVideos/,
  /myGloFrameList/,
  /MyIPhoneApp/,
  /QuotaExceededError/,
  /ResizeObserver loop limit exceeded/,
  /SecurityError/,
  /snapchat.com/,
  /Unexpected token '<'/,
  /vid_mate_check is not defined/,
  /win\.document\.body/,
  /window\._sharedData\.entry_data/,
  /ztePageScrollModule/,
];

const initializeSentry = () => {
  Sentry.init({
    dsn: env.sentry.dsn,
    enabled: Boolean(env.sentry.dsn),
    environment: env.sentry.environment,
    release: env.application.version,
    ignoreErrors,
    beforeBreadcrumb: excludeGraphQLFetch,
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
    ],
    integrations: [new ExtraErrorDataIntegration()],
  });
};

export default initializeSentry;
