import React, {ReactNode} from 'react';
import {css, SerializedStyles} from '@emotion/react';
import styled from '@emotion/styled';
import {ReactComponent as AddIcon} from './add.svg';
import {ReactComponent as AutoRenewIcon} from './auto-renew.svg';
import {ReactComponent as CheckIcon} from './check.svg';
import {ReactComponent as ContactIcon} from './contact.svg';
import {ReactComponent as DeliveredIcon} from './delivered.svg';
import {ReactComponent as DeliveryIcon} from './delivery.svg';
import {ReactComponent as InboxingIcon} from './inboxing.svg';
import {ReactComponent as QuestionIcon} from './question.svg';
import {ReactComponent as RefreshIcon} from './refresh.svg';
import {ReactComponent as SuccessIcon} from './success.svg';
import {ReactComponent as WarningIcon} from './warning.svg';
import {ReactComponent as WorkInProgressIcon} from './work-in-progress.svg';
import {ReactComponent as XIcon} from './x.svg';

export type AlertType = 'danger' | 'info' | 'success' | 'warning' | 'default';
export type IconName =
  | 'add'
  | 'auto-renew'
  | 'check'
  | 'contact'
  | 'delivered'
  | 'delivery'
  | 'inboxing'
  | 'refresh'
  | 'success'
  | 'question'
  | 'warning'
  | 'work-in-progress'
  | 'x';

export type AlertProps = {
  type: AlertType;
  icon?: IconName;
  title?: ReactNode;
  style?: any;
  className?: string;
  actionButton?: ReactNode;
};

const AlertStyles: Record<AlertType, SerializedStyles> = {
  danger: css`
    background-color: var(--medzy-color-red-bg);
    fill: var(--medzy-color-red-medium);
    color: var(--medzy-color-red-text);
  `,
  default: css`
    background-color: var(--medzy-color-neutral);
    fill: var(--medzy-color-night);
    color: var(--medzy-color-night);
  `,
  success: css`
    background-color: var(--medzy-color-green-bg);
    fill: var(--medzy-color-green-medium);
    color: var(--medzy-color-green-text);
  `,
  warning: css`
    background-color: var(--medzy-color-yellow-bg);
    fill: var(--medzy-color-yellow-medium);
    color: var(--medzy-color-yellow-text);
  `,
  info: css`
    background-color: var(--medzy-color-accent-bg);
    fill: var(--medzy-color-accent);
    color: var(--medzy-color-accent);
  `,
};

const Icon: Record<IconName, ReactNode> = {
  'add': <AddIcon />,
  'auto-renew': <AutoRenewIcon />,
  'check': <CheckIcon />,
  'contact': <ContactIcon />,
  'delivered': <DeliveredIcon />,
  'delivery': <DeliveryIcon />,
  'inboxing': <InboxingIcon />,
  'question': <QuestionIcon />,
  'refresh': <RefreshIcon />,
  'success': <SuccessIcon />,
  'warning': <WarningIcon />,
  'work-in-progress': <WorkInProgressIcon />,
  'x': <XIcon />,
};

const Box = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;

  & > svg {
    flex: 0 0 auto;
    margin-right: 14px;
    fill: inherit;
  }

  ${({type}: AlertProps) => AlertStyles[type]}
`;

const Content = styled.div`
  flex: 1 1 auto;
  width: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 18px;

  a {
    text-decoration: underline;
    color: inherit;
  }
`;

const Title = styled.span`
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
`;

const Alert: React.FC<AlertProps> = (props) => {
  return (
    <Box type={props.type} style={props.style} className={props.className}>
      {props.icon && Icon[props.icon]}
      <Content>
        {props.title && <Title>{props.title}</Title>}
        {props.children}
      </Content>
      {props.actionButton}
    </Box>
  );
};

export default Alert;
