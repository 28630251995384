import {Response} from 'node-fetch';

export const assertSuccess = async (response: Response, failureMessage: string): Promise<void> => {
  if (!response.ok) {
    const responseText = await response.text();
    throw new Error(
      `${failureMessage}: ${response.status} ${response.statusText}: ${responseText}`
    );
  }
};

export class ImpossibleError extends Error {}

export const impossible = (why?: string): never => {
  throw new ImpossibleError(why);
};

export class RuntimeError extends Error {}

export const fail = (why: string): never => {
  throw new RuntimeError(why);
};

export const isSomeEnum = <T>(e: T, token: unknown): token is T[keyof T] =>
  Object.values(e).includes(token as T[keyof T]);
