import React from 'react';
import {useTranslation} from 'react-i18next';
import StatusLabel from 'ui/@components/status-label';
import useCurrencyFormat from 'ui/@hooks/use-currency-format';
import Checkbox from '../../checkbox';
import {
  StyledTd,
  StyledGridContainer,
  StyledStatusWrapper,
  StyledMoney,
  StyledCode,
  StyledStatusContainer,
} from '../styles';
import {EditorState, LineItemData, PreferredLanguage} from '../types';

const ServiceInvoiceLineItem: React.FC<{
  lineItem: LineItemData;
  language: PreferredLanguage;
  editorState?: {onToggle: () => void; state: EditorState};
}> = (props) => {
  const formatCurrency = useCurrencyFormat();

  const {t} = useTranslation(['adminOrders']);

  return (
    <tr>
      {props.editorState && (
        <StyledTd checkbox={true}>
          <Checkbox
            touched={false}
            error={null}
            checked={props.editorState.state.selectedServices.has(props.lineItem.service_id)}
            onChange={props.editorState.onToggle}
          />
        </StyledTd>
      )}
      <StyledTd>
        <StyledStatusWrapper small={props.editorState ? false : true}>
          <StyledGridContainer>
            <label>{t('adminOrders:invoice.line.qty', {lng: props.language})}</label>
            <StyledCode>{props.lineItem.quantity}</StyledCode>
            <label>{t('adminOrders:invoice.line.rx', {lng: props.language})}</label>
            <StyledCode>{props.lineItem.rxNumber}</StyledCode>
            <label>{t('adminOrders:invoice.line.din', {lng: props.language})}</label>
            <StyledCode>{props.lineItem.din}</StyledCode>
          </StyledGridContainer>
          {props.editorState && (
            <StyledStatusContainer>
              {props.lineItem.order_line_item_id && (
                <StatusLabel type="warning">
                  {t('adminOrders:invoice.line.ordered', {lng: props.language})}
                </StatusLabel>
              )}
            </StyledStatusContainer>
          )}
        </StyledStatusWrapper>
      </StyledTd>
      <StyledTd>
        <div>
          {[props.lineItem.drug_name, props.lineItem.drug_strength].filter((v) => !!v).join(' ')}
        </div>
        <div>{props.lineItem.prescriber ?? '-'}</div>
        <div>{props.lineItem.insuranceCompany}</div>
      </StyledTd>
      <StyledTd money={true}>
        <StyledMoney>{formatCurrency(props.lineItem.totalPrice)}</StyledMoney>
        <StyledMoney light>
          {props.lineItem.drugPrice ? formatCurrency(props.lineItem.drugPrice) : ''}
        </StyledMoney>
      </StyledTd>
      <StyledTd money={true}>
        <StyledMoney>
          {props.lineItem.rgamPrice ? formatCurrency(props.lineItem.rgamPrice) : ''}
        </StyledMoney>
        <StyledMoney light>
          {props.lineItem.grossPrice ? formatCurrency(props.lineItem.grossPrice) : ''}
        </StyledMoney>
      </StyledTd>
      <StyledTd money={true}>
        <StyledMoney>{formatCurrency(props.lineItem.insuredAmount)}</StyledMoney>
        <StyledMoney light>{formatCurrency(props.lineItem.professionalFees)}</StyledMoney>
      </StyledTd>
      <StyledTd money={true}>
        <StyledMoney>{formatCurrency(props.lineItem.deductible)}</StyledMoney>
      </StyledTd>
      <StyledTd money={true}>
        <StyledMoney>{formatCurrency(props.lineItem.copay)}</StyledMoney>
      </StyledTd>
      <StyledTd money={true}>
        <StyledMoney>{formatCurrency(props.lineItem.toPay)}</StyledMoney>
      </StyledTd>
    </tr>
  );
};

export default React.memo(ServiceInvoiceLineItem);
