import {css, SerializedStyles} from '@emotion/react';
import styled from '@emotion/styled';

export type StatusLabelType = 'danger' | 'info' | 'success' | 'warning' | 'default' | 'none';

type AlertColors = {
  text: string;
  bg: string;
};

const ALERT_COLORS: Record<StatusLabelType, AlertColors> = {
  danger: {
    text: 'var(--medzy-color-red-text)',
    bg: 'var(--medzy-color-red-bg)',
  },
  default: {
    text: 'var(--medzy-color-night)',
    bg: 'var(--medzy-color-neutral)',
  },
  success: {
    text: 'var(--medzy-color-green-text)',
    bg: 'var(--medzy-color-green-bg)',
  },
  warning: {
    text: 'var(--medzy-color-yellow-text)',
    bg: 'var(--medzy-color-yellow-bg)',
  },
  info: {
    text: 'var(--medzy-color-blue-text)',
    bg: 'var(--medzy-color-blue-bg)',
  },
  none: {
    text: 'var(--medzy-color-night)',
    bg: '#fff',
  },
};

export const ALERT_STYLES: Record<StatusLabelType, SerializedStyles> = {
  danger: css`
    background-color: var(--medzy-color-red-bg) !important;
    color: var(--medzy-color-red-text) !important;
    stroke: var(--medzy-color-red-text) !important;

    svg {
      fill: var(--medzy-color-red-text) !important;
    }
  `,
  default: css`
    background-color: var(--medzy-color-neutral) !important;
    color: var(--medzy-color-night) !important;
    stroke: var(--medzy-color-night) !important;

    svg {
      fill: var(--medzy-color-night) !important;
    }
  `,
  success: css`
    background-color: var(--medzy-color-green-bg) !important;
    color: var(--medzy-color-green-text) !important;
    stroke: var(--medzy-color-green-text) !important;

    svg {
      fill: var(--medzy-color-green-text) !important;
    }
  `,
  warning: css`
    background-color: var(--medzy-color-yellow-bg) !important;
    color: var(--medzy-color-yellow-text) !important;
    stroke: var(--medzy-color-yellow-text) !important;

    svg {
      fill: var(--medzy-color-yellow-text) !important;
    }
  `,
  info: css`
    background-color: var(--medzy-color-blue-bg) !important;
    color: var(--medzy-color-blue-text) !important;
    stroke: var(--medzy-color-blue-text) !important;

    svg {
      fill: var(--medzy-color-blue-text) !important;
    }
  `,
  none: css`
    color: var(--medzy-color-night) !important;

    svg {
      fill: var(--medzy-color-night) !important;
    }
  `,
};

const ArrowStyles = (color: string) => css`
  flex: 1 1 auto;
  padding: 5px 10px;
  border-radius: 0;
  margin: 0 !important;
  margin-left: 10px;
  text-align: center;

  &:first-of-type {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  &:last-child {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:not(:first-of-type) {
    padding-left: 14px;
    margin-left: 0 !important;
  }

  &:not(:last-child) {
    padding-right: 4px;
    margin-right: 2px !important;

    &::after {
      content: ' ';
      position: absolute;
      z-index: 1;
      top: 0;
      left: calc(100% + 2px);
      width: 0;
      height: 0;
      border-color: transparent transparent transparent #fff;
      border-style: solid;
      border-width: 14px 0 14px 8px;
    }

    &::before {
      content: ' ';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 100%;
      width: 0;
      height: 0;
      border-color: transparent transparent transparent ${color};
      border-style: solid;
      border-width: 14px 0 14px 8px;
    }
  }
`;

const CompactStyles = css`
  padding: 1px 7px;
  font-size: 12px;
`;

const LargeStyles = css`
  padding: 7.5px 8px !important;
  font-size: 14px;

  &:not(:last-child) {
    &::after {
      top: 0;
      left: calc(100% + 2px);
      border-width: 17.5px 0 17.5px 10px !important;
    }

    &::before {
      top: 0;
      left: 100%;
      border-width: 17.5px 0 17.5px 10px !important;
    }
  }
`;

const StatusLabel = styled.span<{
  type: StatusLabelType;
  large?: boolean;
  compact?: boolean;
  arrow?: boolean;
}>`
  display: inline-block;
  position: relative;
  padding: 2px 10px;
  border-radius: 25px;
  margin-left: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  pointer-events: auto;

  .medzy-icon {
    margin: -5px 2px;
  }

  &:first-of-type {
    margin-left: 0;
  }

  ${({compact}) => compact && CompactStyles};
  ${({large}) => large && LargeStyles};
  ${(props) => props.arrow && ArrowStyles(ALERT_COLORS[props.type].bg)};
  ${({type}) => ALERT_STYLES[type]};
`;

export default StatusLabel;
