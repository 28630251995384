/* eslint-disable */
/* THIS IS A GENERATED FILE - DO NOT MODIFY */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: number;
  date: string;
  float8: any;
  inet: string;
  insurance_type_enum: 'public' | 'private' | 'unknown';
  json: unknown;
  jsonb: unknown;
  name: any;
  numeric: number;
  oid: string;
  otp_request_type_enum: 'SMS' | 'EMAIL';
  patient_status_mode_enum: 'AUTO' | 'MANUAL';
  point: string;
  time: string;
  timestamp: string;
  timestamptz: string;
  upload_type_enum: 'INSURANCE' | 'PRESCRIPTION';
  uuid: string;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<Scalars['Float']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "account". All fields are combined with a logical 'AND'. */
export type Account_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Bool_Exp>>;
  _not?: InputMaybe<Account_Bool_Exp>;
  _or?: InputMaybe<Array<Account_Bool_Exp>>;
  acquired_patient?: InputMaybe<Acquired_Patient_Bool_Exp>;
  admin?: InputMaybe<Admin_Bool_Exp>;
  attribution?: InputMaybe<Attribution_Bool_Exp>;
  empego_account?: InputMaybe<Empego_Account_Bool_Exp>;
  faxes?: InputMaybe<Fax_Bool_Exp>;
  faxes_aggregate?: InputMaybe<Fax_Aggregate_Bool_Exp>;
  follow_ups?: InputMaybe<Follow_Up_Bool_Exp>;
  follow_ups_aggregate?: InputMaybe<Follow_Up_Aggregate_Bool_Exp>;
  form_consultations?: InputMaybe<Form_Consultation_Bool_Exp>;
  form_consultations_aggregate?: InputMaybe<Form_Consultation_Aggregate_Bool_Exp>;
  leomed_account?: InputMaybe<Leomed_Account_Bool_Exp>;
  milestones?: InputMaybe<Customer_Journey_Event_Bool_Exp>;
  otp_requests?: InputMaybe<Otp_Request_Bool_Exp>;
  otp_requests_aggregate?: InputMaybe<Otp_Request_Aggregate_Bool_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_notes?: InputMaybe<Patient_Note_Bool_Exp>;
  patient_notes_aggregate?: InputMaybe<Patient_Note_Aggregate_Bool_Exp>;
  program_enrollments?: InputMaybe<Program_Enrollment_Bool_Exp>;
  program_enrollments_aggregate?: InputMaybe<Program_Enrollment_Aggregate_Bool_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "account" */
export enum Account_Constraint {
  /** unique or primary key constraint on columns "uid" */
  Pk_402c2e1486caf21fd72ee7c5b1f = 'PK_402c2e1486caf21fd72ee7c5b1f'
}

/** input type for inserting data into table "account" */
export type Account_Insert_Input = {
  acquired_patient?: InputMaybe<Acquired_Patient_Obj_Rel_Insert_Input>;
  admin?: InputMaybe<Admin_Obj_Rel_Insert_Input>;
  attribution?: InputMaybe<Attribution_Obj_Rel_Insert_Input>;
  empego_account?: InputMaybe<Empego_Account_Obj_Rel_Insert_Input>;
  faxes?: InputMaybe<Fax_Arr_Rel_Insert_Input>;
  follow_ups?: InputMaybe<Follow_Up_Arr_Rel_Insert_Input>;
  form_consultations?: InputMaybe<Form_Consultation_Arr_Rel_Insert_Input>;
  leomed_account?: InputMaybe<Leomed_Account_Obj_Rel_Insert_Input>;
  otp_requests?: InputMaybe<Otp_Request_Arr_Rel_Insert_Input>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_notes?: InputMaybe<Patient_Note_Arr_Rel_Insert_Input>;
  program_enrollments?: InputMaybe<Program_Enrollment_Arr_Rel_Insert_Input>;
  uid?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "account" */
export type Account_Obj_Rel_Insert_Input = {
  data: Account_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_On_Conflict>;
};

/** on_conflict condition type for table "account" */
export type Account_On_Conflict = {
  constraint: Account_Constraint;
  update_columns?: Array<Account_Update_Column>;
  where?: InputMaybe<Account_Bool_Exp>;
};

/** Ordering options when selecting data from "account". */
export type Account_Order_By = {
  acquired_patient?: InputMaybe<Acquired_Patient_Order_By>;
  admin?: InputMaybe<Admin_Order_By>;
  attribution?: InputMaybe<Attribution_Order_By>;
  empego_account?: InputMaybe<Empego_Account_Order_By>;
  faxes_aggregate?: InputMaybe<Fax_Aggregate_Order_By>;
  follow_ups_aggregate?: InputMaybe<Follow_Up_Aggregate_Order_By>;
  form_consultations_aggregate?: InputMaybe<Form_Consultation_Aggregate_Order_By>;
  leomed_account?: InputMaybe<Leomed_Account_Order_By>;
  milestones_aggregate?: InputMaybe<Customer_Journey_Event_Aggregate_Order_By>;
  otp_requests_aggregate?: InputMaybe<Otp_Request_Aggregate_Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_notes_aggregate?: InputMaybe<Patient_Note_Aggregate_Order_By>;
  program_enrollments_aggregate?: InputMaybe<Program_Enrollment_Aggregate_Order_By>;
  uid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: account */
export type Account_Pk_Columns_Input = {
  uid: Scalars['String'];
};

/** select columns of table "account" */
export enum Account_Select_Column {
  /** column name */
  Uid = 'uid'
}

/** input type for updating data in table "account" */
export type Account_Set_Input = {
  uid?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "account" */
export type Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Stream_Cursor_Value_Input = {
  uid?: InputMaybe<Scalars['String']>;
};

/** update columns of table "account" */
export enum Account_Update_Column {
  /** column name */
  Uid = 'uid'
}

export type Account_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Acquired_Patient_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
  raw_body?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "acquired_patient". All fields are combined with a logical 'AND'. */
export type Acquired_Patient_Bool_Exp = {
  _and?: InputMaybe<Array<Acquired_Patient_Bool_Exp>>;
  _not?: InputMaybe<Acquired_Patient_Bool_Exp>;
  _or?: InputMaybe<Array<Acquired_Patient_Bool_Exp>>;
  acquired_patient_origin?: InputMaybe<Config_Acquired_Patient_Origin_Bool_Exp>;
  consented_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  follow_up_program?: InputMaybe<Config_Follow_Up_Program_Bool_Exp>;
  has_credentials?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lead?: InputMaybe<Account_Bool_Exp>;
  lead_uid?: InputMaybe<String_Comparison_Exp>;
  origin?: InputMaybe<Config_Acquired_Patient_Origin_Enum_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  program?: InputMaybe<Config_Program_Enum_Comparison_Exp>;
  raw_body?: InputMaybe<Jsonb_Comparison_Exp>;
  rejection_reason?: InputMaybe<String_Comparison_Exp>;
  sync?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "acquired_patient" */
export enum Acquired_Patient_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkFb12dd739126f7957a4ff3521a3 = 'PK_fb12dd739126f7957a4ff3521a3',
  /** unique or primary key constraint on columns "external_id", "origin" */
  Uq_05ed4f9aee93f560657dc48362c = 'UQ_05ed4f9aee93f560657dc48362c',
  /** unique or primary key constraint on columns "patient_uid" */
  Uq_5497d142b75ce66696652a4325b = 'UQ_5497d142b75ce66696652a4325b'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Acquired_Patient_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
  raw_body?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Acquired_Patient_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
  raw_body?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Acquired_Patient_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
  raw_body?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "acquired_patient" */
export type Acquired_Patient_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "acquired_patient" */
export type Acquired_Patient_Insert_Input = {
  acquired_patient_origin?: InputMaybe<Config_Acquired_Patient_Origin_Obj_Rel_Insert_Input>;
  consented_at?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  follow_up_program?: InputMaybe<Config_Follow_Up_Program_Obj_Rel_Insert_Input>;
  has_credentials?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  lead?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  lead_uid?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Config_Acquired_Patient_Origin_Enum>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  program?: InputMaybe<Config_Program_Enum>;
  raw_body?: InputMaybe<Scalars['jsonb']>;
  rejection_reason?: InputMaybe<Scalars['String']>;
  sync?: InputMaybe<Scalars['Boolean']>;
};

/** input type for inserting object relation for remote table "acquired_patient" */
export type Acquired_Patient_Obj_Rel_Insert_Input = {
  data: Acquired_Patient_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Acquired_Patient_On_Conflict>;
};

/** on_conflict condition type for table "acquired_patient" */
export type Acquired_Patient_On_Conflict = {
  constraint: Acquired_Patient_Constraint;
  update_columns?: Array<Acquired_Patient_Update_Column>;
  where?: InputMaybe<Acquired_Patient_Bool_Exp>;
};

/** Ordering options when selecting data from "acquired_patient". */
export type Acquired_Patient_Order_By = {
  acquired_patient_origin?: InputMaybe<Config_Acquired_Patient_Origin_Order_By>;
  consented_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  follow_up_program?: InputMaybe<Config_Follow_Up_Program_Order_By>;
  has_credentials?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lead?: InputMaybe<Account_Order_By>;
  lead_uid?: InputMaybe<Order_By>;
  origin?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  raw_body?: InputMaybe<Order_By>;
  rejection_reason?: InputMaybe<Order_By>;
  sync?: InputMaybe<Order_By>;
};

/** primary key columns input for table: acquired_patient */
export type Acquired_Patient_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Acquired_Patient_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
  raw_body?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "acquired_patient" */
export enum Acquired_Patient_Select_Column {
  /** column name */
  ConsentedAt = 'consented_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  HasCredentials = 'has_credentials',
  /** column name */
  Id = 'id',
  /** column name */
  LeadUid = 'lead_uid',
  /** column name */
  Origin = 'origin',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  Payload = 'payload',
  /** column name */
  Program = 'program',
  /** column name */
  RawBody = 'raw_body',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  Sync = 'sync'
}

/** input type for updating data in table "acquired_patient" */
export type Acquired_Patient_Set_Input = {
  consented_at?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  has_credentials?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  lead_uid?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Config_Acquired_Patient_Origin_Enum>;
  patient_uid?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  program?: InputMaybe<Config_Program_Enum>;
  raw_body?: InputMaybe<Scalars['jsonb']>;
  rejection_reason?: InputMaybe<Scalars['String']>;
  sync?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "acquired_patient" */
export type Acquired_Patient_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Acquired_Patient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Acquired_Patient_Stream_Cursor_Value_Input = {
  consented_at?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  has_credentials?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  lead_uid?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Config_Acquired_Patient_Origin_Enum>;
  patient_uid?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  program?: InputMaybe<Config_Program_Enum>;
  raw_body?: InputMaybe<Scalars['jsonb']>;
  rejection_reason?: InputMaybe<Scalars['String']>;
  sync?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "acquired_patient" */
export enum Acquired_Patient_Update_Column {
  /** column name */
  ConsentedAt = 'consented_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  HasCredentials = 'has_credentials',
  /** column name */
  Id = 'id',
  /** column name */
  LeadUid = 'lead_uid',
  /** column name */
  Origin = 'origin',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  Payload = 'payload',
  /** column name */
  Program = 'program',
  /** column name */
  RawBody = 'raw_body',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  Sync = 'sync'
}

export type Acquired_Patient_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Acquired_Patient_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Acquired_Patient_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Acquired_Patient_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Acquired_Patient_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Acquired_Patient_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Acquired_Patient_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Acquired_Patient_Set_Input>;
  /** filter the rows which have to be updated */
  where: Acquired_Patient_Bool_Exp;
};

export type Address_Aggregate_Bool_Exp = {
  count?: InputMaybe<Address_Aggregate_Bool_Exp_Count>;
};

export type Address_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Address_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "address" */
export type Address_Aggregate_Order_By = {
  avg?: InputMaybe<Address_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Address_Max_Order_By>;
  min?: InputMaybe<Address_Min_Order_By>;
  stddev?: InputMaybe<Address_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Address_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Address_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Address_Sum_Order_By>;
  var_pop?: InputMaybe<Address_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Address_Var_Samp_Order_By>;
  variance?: InputMaybe<Address_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Address_Append_Input = {
  autocomplete_details?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "address" */
export type Address_Arr_Rel_Insert_Input = {
  data: Array<Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** order by avg() on columns of table "address" */
export type Address_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  _and?: InputMaybe<Array<Address_Bool_Exp>>;
  _not?: InputMaybe<Address_Bool_Exp>;
  _or?: InputMaybe<Array<Address_Bool_Exp>>;
  address_line1?: InputMaybe<String_Comparison_Exp>;
  address_line2?: InputMaybe<String_Comparison_Exp>;
  address_line3?: InputMaybe<String_Comparison_Exp>;
  address_line4?: InputMaybe<String_Comparison_Exp>;
  address_type?: InputMaybe<Config_Address_Type_Bool_Exp>;
  autocomplete_details?: InputMaybe<Jsonb_Comparison_Exp>;
  contact_name?: InputMaybe<String_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  default_shipment_rate?: InputMaybe<Config_Shipment_Rate_Bool_Exp>;
  default_shipment_rate_code?: InputMaybe<String_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  instructions?: InputMaybe<String_Comparison_Exp>;
  is_default?: InputMaybe<Boolean_Comparison_Exp>;
  locality?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Point_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  orders?: InputMaybe<Order_Bool_Exp>;
  orders_aggregate?: InputMaybe<Order_Aggregate_Bool_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  patients?: InputMaybe<Patient_Bool_Exp>;
  patients_aggregate?: InputMaybe<Patient_Aggregate_Bool_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  postcode?: InputMaybe<String_Comparison_Exp>;
  preferred_carrier?: InputMaybe<Config_Shipment_Provider_Enum_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  shipment_rate_responses?: InputMaybe<Shipment_Rate_Response_Bool_Exp>;
  shipment_rate_responses_aggregate?: InputMaybe<Shipment_Rate_Response_Aggregate_Bool_Exp>;
  shipment_rates?: InputMaybe<Shipment_Rate_Bool_Exp>;
  shipment_rates_aggregate?: InputMaybe<Shipment_Rate_Aggregate_Bool_Exp>;
  shipment_rates_v2?: InputMaybe<Config_Region_Shipment_Rate_Bool_Exp>;
  type?: InputMaybe<Config_Address_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "address" */
export enum Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkD92de1f82754668b5f5f5dd4fd5 = 'PK_d92de1f82754668b5f5f5dd4fd5'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Address_Delete_At_Path_Input = {
  autocomplete_details?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Address_Delete_Elem_Input = {
  autocomplete_details?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Address_Delete_Key_Input = {
  autocomplete_details?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "address" */
export type Address_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "address" */
export type Address_Insert_Input = {
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  address_line3?: InputMaybe<Scalars['String']>;
  address_line4?: InputMaybe<Scalars['String']>;
  address_type?: InputMaybe<Config_Address_Type_Obj_Rel_Insert_Input>;
  autocomplete_details?: InputMaybe<Scalars['jsonb']>;
  contact_name?: InputMaybe<Scalars['String']>;
  /** ISO-3166 two-letter country code */
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default_shipment_rate?: InputMaybe<Config_Shipment_Rate_Obj_Rel_Insert_Input>;
  default_shipment_rate_code?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  instructions?: InputMaybe<Scalars['String']>;
  locality?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['point']>;
  note?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<Order_Arr_Rel_Insert_Input>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  patients?: InputMaybe<Patient_Arr_Rel_Insert_Input>;
  phone_number?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  preferred_carrier?: InputMaybe<Config_Shipment_Provider_Enum>;
  region?: InputMaybe<Scalars['String']>;
  shipment_rate_responses?: InputMaybe<Shipment_Rate_Response_Arr_Rel_Insert_Input>;
  shipment_rates?: InputMaybe<Shipment_Rate_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Config_Address_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "address" */
export type Address_Max_Order_By = {
  address_line1?: InputMaybe<Order_By>;
  address_line2?: InputMaybe<Order_By>;
  address_line3?: InputMaybe<Order_By>;
  address_line4?: InputMaybe<Order_By>;
  contact_name?: InputMaybe<Order_By>;
  /** ISO-3166 two-letter country code */
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_shipment_rate_code?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instructions?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "address" */
export type Address_Min_Order_By = {
  address_line1?: InputMaybe<Order_By>;
  address_line2?: InputMaybe<Order_By>;
  address_line3?: InputMaybe<Order_By>;
  address_line4?: InputMaybe<Order_By>;
  contact_name?: InputMaybe<Order_By>;
  /** ISO-3166 two-letter country code */
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_shipment_rate_code?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instructions?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "address" */
export type Address_Obj_Rel_Insert_Input = {
  data: Address_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** on_conflict condition type for table "address" */
export type Address_On_Conflict = {
  constraint: Address_Constraint;
  update_columns?: Array<Address_Update_Column>;
  where?: InputMaybe<Address_Bool_Exp>;
};

/** Ordering options when selecting data from "address". */
export type Address_Order_By = {
  address_line1?: InputMaybe<Order_By>;
  address_line2?: InputMaybe<Order_By>;
  address_line3?: InputMaybe<Order_By>;
  address_line4?: InputMaybe<Order_By>;
  address_type?: InputMaybe<Config_Address_Type_Order_By>;
  autocomplete_details?: InputMaybe<Order_By>;
  contact_name?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_shipment_rate?: InputMaybe<Config_Shipment_Rate_Order_By>;
  default_shipment_rate_code?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instructions?: InputMaybe<Order_By>;
  is_default?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  orders_aggregate?: InputMaybe<Order_Aggregate_Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  patients_aggregate?: InputMaybe<Patient_Aggregate_Order_By>;
  phone_number?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  preferred_carrier?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  shipment_rate_responses_aggregate?: InputMaybe<Shipment_Rate_Response_Aggregate_Order_By>;
  shipment_rates_aggregate?: InputMaybe<Shipment_Rate_Aggregate_Order_By>;
  shipment_rates_v2_aggregate?: InputMaybe<Config_Region_Shipment_Rate_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: address */
export type Address_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Address_Prepend_Input = {
  autocomplete_details?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "address" */
export enum Address_Select_Column {
  /** column name */
  AddressLine1 = 'address_line1',
  /** column name */
  AddressLine2 = 'address_line2',
  /** column name */
  AddressLine3 = 'address_line3',
  /** column name */
  AddressLine4 = 'address_line4',
  /** column name */
  AutocompleteDetails = 'autocomplete_details',
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultShipmentRateCode = 'default_shipment_rate_code',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  Locality = 'locality',
  /** column name */
  Location = 'location',
  /** column name */
  Note = 'note',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  PreferredCarrier = 'preferred_carrier',
  /** column name */
  Region = 'region',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "address" */
export type Address_Set_Input = {
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  address_line3?: InputMaybe<Scalars['String']>;
  address_line4?: InputMaybe<Scalars['String']>;
  autocomplete_details?: InputMaybe<Scalars['jsonb']>;
  contact_name?: InputMaybe<Scalars['String']>;
  /** ISO-3166 two-letter country code */
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default_shipment_rate_code?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  instructions?: InputMaybe<Scalars['String']>;
  locality?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['point']>;
  note?: InputMaybe<Scalars['String']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  preferred_carrier?: InputMaybe<Config_Shipment_Provider_Enum>;
  region?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Config_Address_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "address" */
export type Address_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "address" */
export type Address_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "address" */
export type Address_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "address" */
export type Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Address_Stream_Cursor_Value_Input = {
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  address_line3?: InputMaybe<Scalars['String']>;
  address_line4?: InputMaybe<Scalars['String']>;
  autocomplete_details?: InputMaybe<Scalars['jsonb']>;
  contact_name?: InputMaybe<Scalars['String']>;
  /** ISO-3166 two-letter country code */
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default_shipment_rate_code?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  instructions?: InputMaybe<Scalars['String']>;
  locality?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['point']>;
  note?: InputMaybe<Scalars['String']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  preferred_carrier?: InputMaybe<Config_Shipment_Provider_Enum>;
  region?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Config_Address_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "address" */
export type Address_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "address" */
export enum Address_Update_Column {
  /** column name */
  AddressLine1 = 'address_line1',
  /** column name */
  AddressLine2 = 'address_line2',
  /** column name */
  AddressLine3 = 'address_line3',
  /** column name */
  AddressLine4 = 'address_line4',
  /** column name */
  AutocompleteDetails = 'autocomplete_details',
  /** column name */
  ContactName = 'contact_name',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultShipmentRateCode = 'default_shipment_rate_code',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  Locality = 'locality',
  /** column name */
  Location = 'location',
  /** column name */
  Note = 'note',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  PreferredCarrier = 'preferred_carrier',
  /** column name */
  Region = 'region',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Address_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Address_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Address_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Address_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Address_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Address_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Address_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Address_Bool_Exp;
};

/** order by var_pop() on columns of table "address" */
export type Address_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "address" */
export type Address_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "address" */
export type Address_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "admin". All fields are combined with a logical 'AND'. */
export type Admin_Bool_Exp = {
  _and?: InputMaybe<Array<Admin_Bool_Exp>>;
  _not?: InputMaybe<Admin_Bool_Exp>;
  _or?: InputMaybe<Array<Admin_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<Note_Bool_Exp>;
  notes_aggregate?: InputMaybe<Note_Aggregate_Bool_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "admin" */
export enum Admin_Constraint {
  /** unique or primary key constraint on columns "uid" */
  Pk_1354aca257361f86b51e63c6026 = 'PK_1354aca257361f86b51e63c6026'
}

/** input type for inserting data into table "admin" */
export type Admin_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Note_Arr_Rel_Insert_Input>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** input type for inserting object relation for remote table "admin" */
export type Admin_Obj_Rel_Insert_Input = {
  data: Admin_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Admin_On_Conflict>;
};

/** on_conflict condition type for table "admin" */
export type Admin_On_Conflict = {
  constraint: Admin_Constraint;
  update_columns?: Array<Admin_Update_Column>;
  where?: InputMaybe<Admin_Bool_Exp>;
};

/** Ordering options when selecting data from "admin". */
export type Admin_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  notes_aggregate?: InputMaybe<Note_Aggregate_Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: admin */
export type Admin_Pk_Columns_Input = {
  uid: Scalars['String'];
};

/** select columns of table "admin" */
export enum Admin_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "admin" */
export type Admin_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "admin" */
export type Admin_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Admin_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Admin_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "admin" */
export enum Admin_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Admin_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Admin_Set_Input>;
  /** filter the rows which have to be updated */
  where: Admin_Bool_Exp;
};

/** Boolean expression to filter rows from the table "ai_integration.din_condition". All fields are combined with a logical 'AND'. */
export type Ai_Integration_Din_Condition_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Integration_Din_Condition_Bool_Exp>>;
  _not?: InputMaybe<Ai_Integration_Din_Condition_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Integration_Din_Condition_Bool_Exp>>;
  condition?: InputMaybe<String_Comparison_Exp>;
  din?: InputMaybe<Int_Comparison_Exp>;
  drug_id?: InputMaybe<Int_Comparison_Exp>;
  medication_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  refrigerated?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai_integration.din_condition" */
export enum Ai_Integration_Din_Condition_Constraint {
  /** unique or primary key constraint on columns "din" */
  DinConditionPkey = 'din_condition_pkey'
}

/** input type for incrementing numeric columns in table "ai_integration.din_condition" */
export type Ai_Integration_Din_Condition_Inc_Input = {
  din?: InputMaybe<Scalars['Int']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  refrigerated?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ai_integration.din_condition" */
export type Ai_Integration_Din_Condition_Insert_Input = {
  condition?: InputMaybe<Scalars['String']>;
  din?: InputMaybe<Scalars['Int']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  refrigerated?: InputMaybe<Scalars['Int']>;
};

/** on_conflict condition type for table "ai_integration.din_condition" */
export type Ai_Integration_Din_Condition_On_Conflict = {
  constraint: Ai_Integration_Din_Condition_Constraint;
  update_columns?: Array<Ai_Integration_Din_Condition_Update_Column>;
  where?: InputMaybe<Ai_Integration_Din_Condition_Bool_Exp>;
};

/** Ordering options when selecting data from "ai_integration.din_condition". */
export type Ai_Integration_Din_Condition_Order_By = {
  condition?: InputMaybe<Order_By>;
  din?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  refrigerated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai_integration.din_condition */
export type Ai_Integration_Din_Condition_Pk_Columns_Input = {
  din: Scalars['Int'];
};

/** select columns of table "ai_integration.din_condition" */
export enum Ai_Integration_Din_Condition_Select_Column {
  /** column name */
  Condition = 'condition',
  /** column name */
  Din = 'din',
  /** column name */
  DrugId = 'drug_id',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  Name = 'name',
  /** column name */
  Refrigerated = 'refrigerated'
}

/** input type for updating data in table "ai_integration.din_condition" */
export type Ai_Integration_Din_Condition_Set_Input = {
  condition?: InputMaybe<Scalars['String']>;
  din?: InputMaybe<Scalars['Int']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  refrigerated?: InputMaybe<Scalars['Int']>;
};

/** Streaming cursor of the table "ai_integration_din_condition" */
export type Ai_Integration_Din_Condition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Integration_Din_Condition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Integration_Din_Condition_Stream_Cursor_Value_Input = {
  condition?: InputMaybe<Scalars['String']>;
  din?: InputMaybe<Scalars['Int']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  refrigerated?: InputMaybe<Scalars['Int']>;
};

/** update columns of table "ai_integration.din_condition" */
export enum Ai_Integration_Din_Condition_Update_Column {
  /** column name */
  Condition = 'condition',
  /** column name */
  Din = 'din',
  /** column name */
  DrugId = 'drug_id',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  Name = 'name',
  /** column name */
  Refrigerated = 'refrigerated'
}

export type Ai_Integration_Din_Condition_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ai_Integration_Din_Condition_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Integration_Din_Condition_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Integration_Din_Condition_Bool_Exp;
};

/** Boolean expression to filter rows from the table "ai_integration.drug_din". All fields are combined with a logical 'AND'. */
export type Ai_Integration_Drug_Din_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Integration_Drug_Din_Bool_Exp>>;
  _not?: InputMaybe<Ai_Integration_Drug_Din_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Integration_Drug_Din_Bool_Exp>>;
  drug_id?: InputMaybe<Int_Comparison_Exp>;
  drug_name?: InputMaybe<String_Comparison_Exp>;
  drug_number?: InputMaybe<String_Comparison_Exp>;
  medication_id?: InputMaybe<Int_Comparison_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "ai_integration.drug_din". */
export type Ai_Integration_Drug_Din_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  drug_name?: InputMaybe<Order_By>;
  drug_number?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** select columns of table "ai_integration.drug_din" */
export enum Ai_Integration_Drug_Din_Select_Column {
  /** column name */
  DrugId = 'drug_id',
  /** column name */
  DrugName = 'drug_name',
  /** column name */
  DrugNumber = 'drug_number',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  ProductId = 'product_id'
}

/** Streaming cursor of the table "ai_integration_drug_din" */
export type Ai_Integration_Drug_Din_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Integration_Drug_Din_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Integration_Drug_Din_Stream_Cursor_Value_Input = {
  drug_id?: InputMaybe<Scalars['Int']>;
  drug_name?: InputMaybe<Scalars['String']>;
  drug_number?: InputMaybe<Scalars['String']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['Int']>;
};

/** Boolean expression to filter rows from the table "ai_integration.fax_patient_matching". All fields are combined with a logical 'AND'. */
export type Ai_Integration_Fax_Patient_Matching_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Integration_Fax_Patient_Matching_Bool_Exp>>;
  _not?: InputMaybe<Ai_Integration_Fax_Patient_Matching_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Integration_Fax_Patient_Matching_Bool_Exp>>;
  birthday?: InputMaybe<Date_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  health_insurance_number?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "ai_integration.fax_patient_matching" */
export type Ai_Integration_Fax_Patient_Matching_Insert_Input = {
  birthday?: InputMaybe<Scalars['date']>;
  first_name?: InputMaybe<Scalars['String']>;
  health_insurance_number?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

/** Ordering options when selecting data from "ai_integration.fax_patient_matching". */
export type Ai_Integration_Fax_Patient_Matching_Order_By = {
  birthday?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  health_insurance_number?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
};

/** select columns of table "ai_integration.fax_patient_matching" */
export enum Ai_Integration_Fax_Patient_Matching_Select_Column {
  /** column name */
  Birthday = 'birthday',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HealthInsuranceNumber = 'health_insurance_number',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Status = 'status',
  /** column name */
  Uid = 'uid'
}

/** input type for updating data in table "ai_integration.fax_patient_matching" */
export type Ai_Integration_Fax_Patient_Matching_Set_Input = {
  birthday?: InputMaybe<Scalars['date']>;
  first_name?: InputMaybe<Scalars['String']>;
  health_insurance_number?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ai_integration_fax_patient_matching" */
export type Ai_Integration_Fax_Patient_Matching_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Integration_Fax_Patient_Matching_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Integration_Fax_Patient_Matching_Stream_Cursor_Value_Input = {
  birthday?: InputMaybe<Scalars['date']>;
  first_name?: InputMaybe<Scalars['String']>;
  health_insurance_number?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

export type Ai_Integration_Fax_Patient_Matching_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Integration_Fax_Patient_Matching_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Integration_Fax_Patient_Matching_Bool_Exp;
};

export type Allergy_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Allergy_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Allergy_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Allergy_Aggregate_Bool_Exp_Count>;
};

export type Allergy_Aggregate_Bool_Exp_Bool_And = {
  arguments: Allergy_Select_Column_Allergy_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Allergy_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Allergy_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Allergy_Select_Column_Allergy_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Allergy_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Allergy_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Allergy_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Allergy_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "allergy" */
export type Allergy_Aggregate_Order_By = {
  avg?: InputMaybe<Allergy_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Allergy_Max_Order_By>;
  min?: InputMaybe<Allergy_Min_Order_By>;
  stddev?: InputMaybe<Allergy_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Allergy_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Allergy_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Allergy_Sum_Order_By>;
  var_pop?: InputMaybe<Allergy_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Allergy_Var_Samp_Order_By>;
  variance?: InputMaybe<Allergy_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "allergy" */
export type Allergy_Arr_Rel_Insert_Input = {
  data: Array<Allergy_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Allergy_On_Conflict>;
};

/** order by avg() on columns of table "allergy" */
export type Allergy_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "allergy". All fields are combined with a logical 'AND'. */
export type Allergy_Bool_Exp = {
  _and?: InputMaybe<Array<Allergy_Bool_Exp>>;
  _not?: InputMaybe<Allergy_Bool_Exp>;
  _or?: InputMaybe<Array<Allergy_Bool_Exp>>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name_en?: InputMaybe<String_Comparison_Exp>;
  name_fr?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_allergies?: InputMaybe<Patient_Allergies_Bool_Exp>;
  patient_allergies_aggregate?: InputMaybe<Patient_Allergies_Aggregate_Bool_Exp>;
  searchable?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "allergy" */
export enum Allergy_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkC9cb3ece73ddfde61d2ada768e1 = 'PK_c9cb3ece73ddfde61d2ada768e1',
  /** unique or primary key constraint on columns "name_en" */
  AllergyNameEnKey = 'allergy_name_en_key',
  /** unique or primary key constraint on columns "name_fr" */
  AllergyNameFrKey = 'allergy_name_fr_key'
}

/** input type for incrementing numeric columns in table "allergy" */
export type Allergy_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "allergy" */
export type Allergy_Insert_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_allergies?: InputMaybe<Patient_Allergies_Arr_Rel_Insert_Input>;
  searchable?: InputMaybe<Scalars['Boolean']>;
};

/** order by max() on columns of table "allergy" */
export type Allergy_Max_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_en?: InputMaybe<Order_By>;
  name_fr?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "allergy" */
export type Allergy_Min_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_en?: InputMaybe<Order_By>;
  name_fr?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "allergy" */
export type Allergy_Obj_Rel_Insert_Input = {
  data: Allergy_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Allergy_On_Conflict>;
};

/** on_conflict condition type for table "allergy" */
export type Allergy_On_Conflict = {
  constraint: Allergy_Constraint;
  update_columns?: Array<Allergy_Update_Column>;
  where?: InputMaybe<Allergy_Bool_Exp>;
};

/** Ordering options when selecting data from "allergy". */
export type Allergy_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_en?: InputMaybe<Order_By>;
  name_fr?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_allergies_aggregate?: InputMaybe<Patient_Allergies_Aggregate_Order_By>;
  searchable?: InputMaybe<Order_By>;
};

/** primary key columns input for table: allergy */
export type Allergy_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "allergy" */
export enum Allergy_Select_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameFr = 'name_fr',
  /** column name */
  Searchable = 'searchable'
}

/** select "allergy_aggregate_bool_exp_bool_and_arguments_columns" columns of table "allergy" */
export enum Allergy_Select_Column_Allergy_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Searchable = 'searchable'
}

/** select "allergy_aggregate_bool_exp_bool_or_arguments_columns" columns of table "allergy" */
export enum Allergy_Select_Column_Allergy_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Searchable = 'searchable'
}

/** input type for updating data in table "allergy" */
export type Allergy_Set_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
  searchable?: InputMaybe<Scalars['Boolean']>;
};

/** order by stddev() on columns of table "allergy" */
export type Allergy_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "allergy" */
export type Allergy_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "allergy" */
export type Allergy_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "allergy" */
export type Allergy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Allergy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Allergy_Stream_Cursor_Value_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
  searchable?: InputMaybe<Scalars['Boolean']>;
};

/** order by sum() on columns of table "allergy" */
export type Allergy_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "allergy" */
export enum Allergy_Update_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameFr = 'name_fr',
  /** column name */
  Searchable = 'searchable'
}

export type Allergy_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Allergy_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Allergy_Set_Input>;
  /** filter the rows which have to be updated */
  where: Allergy_Bool_Exp;
};

/** order by var_pop() on columns of table "allergy" */
export type Allergy_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "allergy" */
export type Allergy_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "allergy" */
export type Allergy_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Attribution_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "attribution". All fields are combined with a logical 'AND'. */
export type Attribution_Bool_Exp = {
  _and?: InputMaybe<Array<Attribution_Bool_Exp>>;
  _not?: InputMaybe<Attribution_Bool_Exp>;
  _or?: InputMaybe<Array<Attribution_Bool_Exp>>;
  account_uid?: InputMaybe<String_Comparison_Exp>;
  channel?: InputMaybe<Config_Channel_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  program?: InputMaybe<Config_Program_Enum_Comparison_Exp>;
  source?: InputMaybe<Config_Source_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "attribution" */
export enum Attribution_Constraint {
  /** unique or primary key constraint on columns "account_uid" */
  AttributionPkey = 'attribution_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Attribution_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Attribution_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Attribution_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "attribution" */
export type Attribution_Insert_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Config_Channel_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  program?: InputMaybe<Config_Program_Enum>;
  source?: InputMaybe<Config_Source_Enum>;
};

/** input type for inserting object relation for remote table "attribution" */
export type Attribution_Obj_Rel_Insert_Input = {
  data: Attribution_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Attribution_On_Conflict>;
};

/** on_conflict condition type for table "attribution" */
export type Attribution_On_Conflict = {
  constraint: Attribution_Constraint;
  update_columns?: Array<Attribution_Update_Column>;
  where?: InputMaybe<Attribution_Bool_Exp>;
};

/** Ordering options when selecting data from "attribution". */
export type Attribution_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  channel?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
};

/** primary key columns input for table: attribution */
export type Attribution_Pk_Columns_Input = {
  account_uid: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Attribution_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "attribution" */
export enum Attribution_Select_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Program = 'program',
  /** column name */
  Source = 'source'
}

/** input type for updating data in table "attribution" */
export type Attribution_Set_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Config_Channel_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  program?: InputMaybe<Config_Program_Enum>;
  source?: InputMaybe<Config_Source_Enum>;
};

/** Streaming cursor of the table "attribution" */
export type Attribution_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Attribution_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attribution_Stream_Cursor_Value_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Config_Channel_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  program?: InputMaybe<Config_Program_Enum>;
  source?: InputMaybe<Config_Source_Enum>;
};

/** update columns of table "attribution" */
export enum Attribution_Update_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Program = 'program',
  /** column name */
  Source = 'source'
}

export type Attribution_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Attribution_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Attribution_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Attribution_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Attribution_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Attribution_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attribution_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attribution_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Append_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "audit.logged_actions". All fields are combined with a logical 'AND'. */
export type Audit_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  action_tstamp_clk?: InputMaybe<Timestamptz_Comparison_Exp>;
  action_tstamp_stm?: InputMaybe<Timestamptz_Comparison_Exp>;
  action_tstamp_tx?: InputMaybe<Timestamptz_Comparison_Exp>;
  application_name?: InputMaybe<String_Comparison_Exp>;
  changed_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  client_addr?: InputMaybe<Inet_Comparison_Exp>;
  client_port?: InputMaybe<Int_Comparison_Exp>;
  client_query?: InputMaybe<String_Comparison_Exp>;
  event_id?: InputMaybe<Bigint_Comparison_Exp>;
  hasura_user?: InputMaybe<Jsonb_Comparison_Exp>;
  relid?: InputMaybe<Oid_Comparison_Exp>;
  row_data?: InputMaybe<Jsonb_Comparison_Exp>;
  schema_name?: InputMaybe<String_Comparison_Exp>;
  session_user_name?: InputMaybe<String_Comparison_Exp>;
  statement_only?: InputMaybe<Boolean_Comparison_Exp>;
  table_name?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "audit.logged_actions" */
export enum Audit_Logged_Actions_Constraint {
  /** unique or primary key constraint on columns "event_id" */
  LoggedActionsPkey = 'logged_actions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_Logged_Actions_Delete_At_Path_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Array<Scalars['String']>>;
  hasura_user?: InputMaybe<Array<Scalars['String']>>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_Logged_Actions_Delete_Elem_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['Int']>;
  hasura_user?: InputMaybe<Scalars['Int']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_Logged_Actions_Delete_Key_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['String']>;
  hasura_user?: InputMaybe<Scalars['String']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "audit.logged_actions" */
export type Audit_Logged_Actions_Inc_Input = {
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']>;
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "audit.logged_actions" */
export type Audit_Logged_Actions_Insert_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: InputMaybe<Scalars['String']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: InputMaybe<Scalars['timestamptz']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: InputMaybe<Scalars['timestamptz']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: InputMaybe<Scalars['timestamptz']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: InputMaybe<Scalars['String']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: InputMaybe<Scalars['inet']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: InputMaybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: InputMaybe<Scalars['oid']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name?: InputMaybe<Scalars['String']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: InputMaybe<Scalars['String']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?: InputMaybe<Scalars['Boolean']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: InputMaybe<Scalars['String']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']>;
};

/** on_conflict condition type for table "audit.logged_actions" */
export type Audit_Logged_Actions_On_Conflict = {
  constraint: Audit_Logged_Actions_Constraint;
  update_columns?: Array<Audit_Logged_Actions_Update_Column>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "audit.logged_actions". */
export type Audit_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  action_tstamp_clk?: InputMaybe<Order_By>;
  action_tstamp_stm?: InputMaybe<Order_By>;
  action_tstamp_tx?: InputMaybe<Order_By>;
  application_name?: InputMaybe<Order_By>;
  changed_fields?: InputMaybe<Order_By>;
  client_addr?: InputMaybe<Order_By>;
  client_port?: InputMaybe<Order_By>;
  client_query?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  hasura_user?: InputMaybe<Order_By>;
  relid?: InputMaybe<Order_By>;
  row_data?: InputMaybe<Order_By>;
  schema_name?: InputMaybe<Order_By>;
  session_user_name?: InputMaybe<Order_By>;
  statement_only?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audit.logged_actions */
export type Audit_Logged_Actions_Pk_Columns_Input = {
  /** Unique identifier for each auditable event */
  event_id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Prepend_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id'
}

/** input type for updating data in table "audit.logged_actions" */
export type Audit_Logged_Actions_Set_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: InputMaybe<Scalars['String']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: InputMaybe<Scalars['timestamptz']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: InputMaybe<Scalars['timestamptz']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: InputMaybe<Scalars['timestamptz']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: InputMaybe<Scalars['String']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: InputMaybe<Scalars['inet']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: InputMaybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: InputMaybe<Scalars['oid']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name?: InputMaybe<Scalars['String']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: InputMaybe<Scalars['String']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?: InputMaybe<Scalars['Boolean']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: InputMaybe<Scalars['String']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']>;
};

/** Streaming cursor of the table "audit_logged_actions" */
export type Audit_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Logged_Actions_Stream_Cursor_Value_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: InputMaybe<Scalars['String']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: InputMaybe<Scalars['timestamptz']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: InputMaybe<Scalars['timestamptz']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: InputMaybe<Scalars['timestamptz']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: InputMaybe<Scalars['String']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: InputMaybe<Scalars['inet']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: InputMaybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: InputMaybe<Scalars['oid']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name?: InputMaybe<Scalars['String']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: InputMaybe<Scalars['String']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?: InputMaybe<Scalars['Boolean']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: InputMaybe<Scalars['String']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']>;
};

/** update columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id'
}

export type Audit_Logged_Actions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Audit_Logged_Actions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Audit_Logged_Actions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Audit_Logged_Actions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Audit_Logged_Actions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audit_Logged_Actions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audit_Logged_Actions_Bool_Exp;
};

/** Boolean expression to filter rows from the table "audit.tableslist". All fields are combined with a logical 'AND'. */
export type Audit_Tableslist_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Tableslist_Bool_Exp>>;
  _not?: InputMaybe<Audit_Tableslist_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Tableslist_Bool_Exp>>;
  auditedtable?: InputMaybe<Name_Comparison_Exp>;
  schema?: InputMaybe<Name_Comparison_Exp>;
};

/** Ordering options when selecting data from "audit.tableslist". */
export type Audit_Tableslist_Order_By = {
  auditedtable?: InputMaybe<Order_By>;
  schema?: InputMaybe<Order_By>;
};

/** select columns of table "audit.tableslist" */
export enum Audit_Tableslist_Select_Column {
  /** column name */
  Auditedtable = 'auditedtable',
  /** column name */
  Schema = 'schema'
}

/** Streaming cursor of the table "audit_tableslist" */
export type Audit_Tableslist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Tableslist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Tableslist_Stream_Cursor_Value_Input = {
  auditedtable?: InputMaybe<Scalars['name']>;
  schema?: InputMaybe<Scalars['name']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to filter rows from the table "blacklist". All fields are combined with a logical 'AND'. */
export type Blacklist_Bool_Exp = {
  _and?: InputMaybe<Array<Blacklist_Bool_Exp>>;
  _not?: InputMaybe<Blacklist_Bool_Exp>;
  _or?: InputMaybe<Array<Blacklist_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "blacklist" */
export enum Blacklist_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_04dc42a96bf0914cda31b579702 = 'PK_04dc42a96bf0914cda31b579702'
}

/** input type for inserting data into table "blacklist" */
export type Blacklist_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "blacklist" */
export type Blacklist_On_Conflict = {
  constraint: Blacklist_Constraint;
  update_columns?: Array<Blacklist_Update_Column>;
  where?: InputMaybe<Blacklist_Bool_Exp>;
};

/** Ordering options when selecting data from "blacklist". */
export type Blacklist_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: blacklist */
export type Blacklist_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "blacklist" */
export enum Blacklist_Select_Column {
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "blacklist" */
export type Blacklist_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "blacklist" */
export type Blacklist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Blacklist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Blacklist_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "blacklist" */
export enum Blacklist_Update_Column {
  /** column name */
  Id = 'id'
}

export type Blacklist_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Blacklist_Set_Input>;
  /** filter the rows which have to be updated */
  where: Blacklist_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Booking_Event_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "booking_event". All fields are combined with a logical 'AND'. */
export type Booking_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Booking_Event_Bool_Exp>>;
  _not?: InputMaybe<Booking_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Booking_Event_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Config_Booking_Event_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  uri?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "booking_event" */
export enum Booking_Event_Constraint {
  /** unique or primary key constraint on columns "external_id" */
  BookingEventExternalIdKey = 'booking_event_external_id_key',
  /** unique or primary key constraint on columns "id" */
  BookingEventPkey = 'booking_event_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Booking_Event_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Booking_Event_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Booking_Event_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "booking_event" */
export type Booking_Event_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Config_Booking_Event_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  uri?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "booking_event" */
export type Booking_Event_Obj_Rel_Insert_Input = {
  data: Booking_Event_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Booking_Event_On_Conflict>;
};

/** on_conflict condition type for table "booking_event" */
export type Booking_Event_On_Conflict = {
  constraint: Booking_Event_Constraint;
  update_columns?: Array<Booking_Event_Update_Column>;
  where?: InputMaybe<Booking_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "booking_event". */
export type Booking_Event_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uri?: InputMaybe<Order_By>;
};

/** primary key columns input for table: booking_event */
export type Booking_Event_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Booking_Event_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "booking_event" */
export enum Booking_Event_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uri = 'uri'
}

/** input type for updating data in table "booking_event" */
export type Booking_Event_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Config_Booking_Event_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  uri?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "booking_event" */
export type Booking_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Booking_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Booking_Event_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Config_Booking_Event_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  uri?: InputMaybe<Scalars['String']>;
};

/** update columns of table "booking_event" */
export enum Booking_Event_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Uri = 'uri'
}

export type Booking_Event_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Booking_Event_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Booking_Event_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Booking_Event_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Booking_Event_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Booking_Event_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Booking_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Booking_Event_Bool_Exp;
};

export type Bridge_Incoming_Change_Aggregate_Bool_Exp = {
  count?: InputMaybe<Bridge_Incoming_Change_Aggregate_Bool_Exp_Count>;
};

export type Bridge_Incoming_Change_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Bridge_Incoming_Change_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bridge_Incoming_Change_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Aggregate_Order_By = {
  avg?: InputMaybe<Bridge_Incoming_Change_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bridge_Incoming_Change_Max_Order_By>;
  min?: InputMaybe<Bridge_Incoming_Change_Min_Order_By>;
  stddev?: InputMaybe<Bridge_Incoming_Change_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bridge_Incoming_Change_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bridge_Incoming_Change_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bridge_Incoming_Change_Sum_Order_By>;
  var_pop?: InputMaybe<Bridge_Incoming_Change_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bridge_Incoming_Change_Var_Samp_Order_By>;
  variance?: InputMaybe<Bridge_Incoming_Change_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Bridge_Incoming_Change_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Arr_Rel_Insert_Input = {
  data: Array<Bridge_Incoming_Change_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bridge_Incoming_Change_On_Conflict>;
};

/** order by avg() on columns of table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bridge.incoming_change". All fields are combined with a logical 'AND'. */
export type Bridge_Incoming_Change_Bool_Exp = {
  _and?: InputMaybe<Array<Bridge_Incoming_Change_Bool_Exp>>;
  _not?: InputMaybe<Bridge_Incoming_Change_Bool_Exp>;
  _or?: InputMaybe<Array<Bridge_Incoming_Change_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  prescription?: InputMaybe<Prescription_Bool_Exp>;
  prescription_id?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  service_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "bridge.incoming_change" */
export enum Bridge_Incoming_Change_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkC9fad02e7291df4d873f486780d = 'PK_c9fad02e7291df4d873f486780d'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Bridge_Incoming_Change_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Bridge_Incoming_Change_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Bridge_Incoming_Change_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  prescription?: InputMaybe<Prescription_Obj_Rel_Insert_Input>;
  prescription_id?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  prescription_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  prescription_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "bridge.incoming_change" */
export type Bridge_Incoming_Change_On_Conflict = {
  constraint: Bridge_Incoming_Change_Constraint;
  update_columns?: Array<Bridge_Incoming_Change_Update_Column>;
  where?: InputMaybe<Bridge_Incoming_Change_Bool_Exp>;
};

/** Ordering options when selecting data from "bridge.incoming_change". */
export type Bridge_Incoming_Change_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  prescription?: InputMaybe<Prescription_Order_By>;
  prescription_id?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bridge.incoming_change */
export type Bridge_Incoming_Change_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Bridge_Incoming_Change_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "bridge.incoming_change" */
export enum Bridge_Incoming_Change_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PrescriptionId = 'prescription_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  prescription_id?: InputMaybe<Scalars['uuid']>;
  service_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "bridge_incoming_change" */
export type Bridge_Incoming_Change_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bridge_Incoming_Change_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bridge_Incoming_Change_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  prescription_id?: InputMaybe<Scalars['uuid']>;
  service_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "bridge.incoming_change" */
export enum Bridge_Incoming_Change_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PrescriptionId = 'prescription_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Bridge_Incoming_Change_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Bridge_Incoming_Change_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Bridge_Incoming_Change_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Bridge_Incoming_Change_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Bridge_Incoming_Change_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bridge_Incoming_Change_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Bridge_Incoming_Change_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bridge_Incoming_Change_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bridge_Incoming_Change_Bool_Exp;
};

/** order by var_pop() on columns of table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "bridge.incoming_change" */
export type Bridge_Incoming_Change_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bridge.logipharm_patient_details". All fields are combined with a logical 'AND'. */
export type Bridge_Logipharm_Patient_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Bridge_Logipharm_Patient_Details_Bool_Exp>>;
  _not?: InputMaybe<Bridge_Logipharm_Patient_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Bridge_Logipharm_Patient_Details_Bool_Exp>>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  earliest_event_timestamp?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  most_recent_event_timestamp?: InputMaybe<Timestamp_Comparison_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "bridge.logipharm_patient_details". */
export type Bridge_Logipharm_Patient_Details_Order_By = {
  data?: InputMaybe<Order_By>;
  earliest_event_timestamp?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  most_recent_event_timestamp?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
};

/** select columns of table "bridge.logipharm_patient_details" */
export enum Bridge_Logipharm_Patient_Details_Select_Column {
  /** column name */
  Data = 'data',
  /** column name */
  EarliestEventTimestamp = 'earliest_event_timestamp',
  /** column name */
  Id = 'id',
  /** column name */
  MostRecentEventTimestamp = 'most_recent_event_timestamp',
  /** column name */
  PatientUid = 'patient_uid'
}

/** Streaming cursor of the table "bridge_logipharm_patient_details" */
export type Bridge_Logipharm_Patient_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bridge_Logipharm_Patient_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bridge_Logipharm_Patient_Details_Stream_Cursor_Value_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  earliest_event_timestamp?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  most_recent_event_timestamp?: InputMaybe<Scalars['timestamp']>;
  patient_uid?: InputMaybe<Scalars['String']>;
};

export type Bridge_Outgoing_Change_Aggregate_Bool_Exp = {
  count?: InputMaybe<Bridge_Outgoing_Change_Aggregate_Bool_Exp_Count>;
};

export type Bridge_Outgoing_Change_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Bridge_Outgoing_Change_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bridge_Outgoing_Change_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Aggregate_Order_By = {
  avg?: InputMaybe<Bridge_Outgoing_Change_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bridge_Outgoing_Change_Max_Order_By>;
  min?: InputMaybe<Bridge_Outgoing_Change_Min_Order_By>;
  stddev?: InputMaybe<Bridge_Outgoing_Change_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bridge_Outgoing_Change_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bridge_Outgoing_Change_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bridge_Outgoing_Change_Sum_Order_By>;
  var_pop?: InputMaybe<Bridge_Outgoing_Change_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bridge_Outgoing_Change_Var_Samp_Order_By>;
  variance?: InputMaybe<Bridge_Outgoing_Change_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Bridge_Outgoing_Change_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Arr_Rel_Insert_Input = {
  data: Array<Bridge_Outgoing_Change_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bridge_Outgoing_Change_On_Conflict>;
};

/** order by avg() on columns of table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bridge.outgoing_change". All fields are combined with a logical 'AND'. */
export type Bridge_Outgoing_Change_Bool_Exp = {
  _and?: InputMaybe<Array<Bridge_Outgoing_Change_Bool_Exp>>;
  _not?: InputMaybe<Bridge_Outgoing_Change_Bool_Exp>;
  _or?: InputMaybe<Array<Bridge_Outgoing_Change_Bool_Exp>>;
  cancelled_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  outgoing_change_responses?: InputMaybe<Bridge_Outgoing_Change_Response_Bool_Exp>;
  outgoing_change_responses_aggregate?: InputMaybe<Bridge_Outgoing_Change_Response_Aggregate_Bool_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  synced_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "bridge.outgoing_change" */
export enum Bridge_Outgoing_Change_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_85e1d28edd7a1b6525462715621 = 'PK_85e1d28edd7a1b6525462715621'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Bridge_Outgoing_Change_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Bridge_Outgoing_Change_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Bridge_Outgoing_Change_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Insert_Input = {
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  outgoing_change_responses?: InputMaybe<Bridge_Outgoing_Change_Response_Arr_Rel_Insert_Input>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  synced_at?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Max_Order_By = {
  cancelled_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  synced_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Min_Order_By = {
  cancelled_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  synced_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Obj_Rel_Insert_Input = {
  data: Bridge_Outgoing_Change_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Bridge_Outgoing_Change_On_Conflict>;
};

/** on_conflict condition type for table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_On_Conflict = {
  constraint: Bridge_Outgoing_Change_Constraint;
  update_columns?: Array<Bridge_Outgoing_Change_Update_Column>;
  where?: InputMaybe<Bridge_Outgoing_Change_Bool_Exp>;
};

/** Ordering options when selecting data from "bridge.outgoing_change". */
export type Bridge_Outgoing_Change_Order_By = {
  cancelled_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  outgoing_change_responses_aggregate?: InputMaybe<Bridge_Outgoing_Change_Response_Aggregate_Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  synced_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bridge.outgoing_change */
export type Bridge_Outgoing_Change_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Bridge_Outgoing_Change_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

export type Bridge_Outgoing_Change_Response_Aggregate_Bool_Exp = {
  count?: InputMaybe<Bridge_Outgoing_Change_Response_Aggregate_Bool_Exp_Count>;
};

export type Bridge_Outgoing_Change_Response_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Bridge_Outgoing_Change_Response_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Bridge_Outgoing_Change_Response_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Aggregate_Order_By = {
  avg?: InputMaybe<Bridge_Outgoing_Change_Response_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bridge_Outgoing_Change_Response_Max_Order_By>;
  min?: InputMaybe<Bridge_Outgoing_Change_Response_Min_Order_By>;
  stddev?: InputMaybe<Bridge_Outgoing_Change_Response_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bridge_Outgoing_Change_Response_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bridge_Outgoing_Change_Response_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bridge_Outgoing_Change_Response_Sum_Order_By>;
  var_pop?: InputMaybe<Bridge_Outgoing_Change_Response_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bridge_Outgoing_Change_Response_Var_Samp_Order_By>;
  variance?: InputMaybe<Bridge_Outgoing_Change_Response_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Arr_Rel_Insert_Input = {
  data: Array<Bridge_Outgoing_Change_Response_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Bridge_Outgoing_Change_Response_On_Conflict>;
};

/** order by avg() on columns of table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  outgoing_change_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bridge.outgoing_change_response". All fields are combined with a logical 'AND'. */
export type Bridge_Outgoing_Change_Response_Bool_Exp = {
  _and?: InputMaybe<Array<Bridge_Outgoing_Change_Response_Bool_Exp>>;
  _not?: InputMaybe<Bridge_Outgoing_Change_Response_Bool_Exp>;
  _or?: InputMaybe<Array<Bridge_Outgoing_Change_Response_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Json_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  outgoing_change?: InputMaybe<Bridge_Outgoing_Change_Bool_Exp>;
  outgoing_change_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "bridge.outgoing_change_response" */
export enum Bridge_Outgoing_Change_Response_Constraint {
  /** unique or primary key constraint on columns "id" */
  OutgoingChangeResponsePkey = 'outgoing_change_response_pkey'
}

/** input type for incrementing numeric columns in table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  outgoing_change_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['Int']>;
  outgoing_change?: InputMaybe<Bridge_Outgoing_Change_Obj_Rel_Insert_Input>;
  outgoing_change_id?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  outgoing_change_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  outgoing_change_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_On_Conflict = {
  constraint: Bridge_Outgoing_Change_Response_Constraint;
  update_columns?: Array<Bridge_Outgoing_Change_Response_Update_Column>;
  where?: InputMaybe<Bridge_Outgoing_Change_Response_Bool_Exp>;
};

/** Ordering options when selecting data from "bridge.outgoing_change_response". */
export type Bridge_Outgoing_Change_Response_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  outgoing_change?: InputMaybe<Bridge_Outgoing_Change_Order_By>;
  outgoing_change_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bridge.outgoing_change_response */
export type Bridge_Outgoing_Change_Response_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "bridge.outgoing_change_response" */
export enum Bridge_Outgoing_Change_Response_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  OutgoingChangeId = 'outgoing_change_id'
}

/** input type for updating data in table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['Int']>;
  outgoing_change_id?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  outgoing_change_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  outgoing_change_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  outgoing_change_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "bridge_outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bridge_Outgoing_Change_Response_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bridge_Outgoing_Change_Response_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['json']>;
  id?: InputMaybe<Scalars['Int']>;
  outgoing_change_id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  outgoing_change_id?: InputMaybe<Order_By>;
};

/** update columns of table "bridge.outgoing_change_response" */
export enum Bridge_Outgoing_Change_Response_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  OutgoingChangeId = 'outgoing_change_id'
}

export type Bridge_Outgoing_Change_Response_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bridge_Outgoing_Change_Response_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bridge_Outgoing_Change_Response_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bridge_Outgoing_Change_Response_Bool_Exp;
};

/** order by var_pop() on columns of table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  outgoing_change_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  outgoing_change_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "bridge.outgoing_change_response" */
export type Bridge_Outgoing_Change_Response_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  outgoing_change_id?: InputMaybe<Order_By>;
};

/** select columns of table "bridge.outgoing_change" */
export enum Bridge_Outgoing_Change_Select_Column {
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  SyncedAt = 'synced_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Set_Input = {
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  synced_at?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "bridge_outgoing_change" */
export type Bridge_Outgoing_Change_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bridge_Outgoing_Change_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bridge_Outgoing_Change_Stream_Cursor_Value_Input = {
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  synced_at?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "bridge.outgoing_change" */
export enum Bridge_Outgoing_Change_Update_Column {
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  SyncedAt = 'synced_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Bridge_Outgoing_Change_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Bridge_Outgoing_Change_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Bridge_Outgoing_Change_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Bridge_Outgoing_Change_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Bridge_Outgoing_Change_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Bridge_Outgoing_Change_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Bridge_Outgoing_Change_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bridge_Outgoing_Change_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bridge_Outgoing_Change_Bool_Exp;
};

/** order by var_pop() on columns of table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "bridge.outgoing_change" */
export type Bridge_Outgoing_Change_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Clinic_Append_Input = {
  google_place_data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "clinic". All fields are combined with a logical 'AND'. */
export type Clinic_Bool_Exp = {
  _and?: InputMaybe<Array<Clinic_Bool_Exp>>;
  _not?: InputMaybe<Clinic_Bool_Exp>;
  _or?: InputMaybe<Array<Clinic_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  google_place_data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "clinic" */
export enum Clinic_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClinicPkey = 'clinic_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Clinic_Delete_At_Path_Input = {
  google_place_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Clinic_Delete_Elem_Input = {
  google_place_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Clinic_Delete_Key_Input = {
  google_place_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "clinic" */
export type Clinic_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  google_place_data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** input type for inserting object relation for remote table "clinic" */
export type Clinic_Obj_Rel_Insert_Input = {
  data: Clinic_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Clinic_On_Conflict>;
};

/** on_conflict condition type for table "clinic" */
export type Clinic_On_Conflict = {
  constraint: Clinic_Constraint;
  update_columns?: Array<Clinic_Update_Column>;
  where?: InputMaybe<Clinic_Bool_Exp>;
};

/** Ordering options when selecting data from "clinic". */
export type Clinic_Order_By = {
  address?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  google_place_data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clinic */
export type Clinic_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Clinic_Prepend_Input = {
  google_place_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "clinic" */
export enum Clinic_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GooglePlaceData = 'google_place_data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "clinic" */
export type Clinic_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  google_place_data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "clinic" */
export type Clinic_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clinic_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clinic_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  google_place_data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "clinic" */
export enum Clinic_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GooglePlaceData = 'google_place_data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Clinic_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Clinic_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Clinic_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Clinic_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Clinic_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Clinic_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Clinic_Set_Input>;
  /** filter the rows which have to be updated */
  where: Clinic_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.acquired_patient_origin". All fields are combined with a logical 'AND'. */
export type Config_Acquired_Patient_Origin_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Acquired_Patient_Origin_Bool_Exp>>;
  _not?: InputMaybe<Config_Acquired_Patient_Origin_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Acquired_Patient_Origin_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.acquired_patient_origin" */
export enum Config_Acquired_Patient_Origin_Constraint {
  /** unique or primary key constraint on columns "value" */
  PkCeec37eee481d85f839840ad40f = 'PK_ceec37eee481d85f839840ad40f'
}

export enum Config_Acquired_Patient_Origin_Enum {
  CliniqueVaudreuil = 'CLINIQUE_VAUDREUIL',
  Fphq = 'FPHQ',
  Mdhub = 'MDHUB',
  Medzy = 'MEDZY',
  Posoplus = 'POSOPLUS',
  Tootelo = 'TOOTELO',
  Unknown = 'UNKNOWN'
}

/** Boolean expression to compare columns of type "config_acquired_patient_origin_enum". All fields are combined with logical 'AND'. */
export type Config_Acquired_Patient_Origin_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Acquired_Patient_Origin_Enum>;
  _in?: InputMaybe<Array<Config_Acquired_Patient_Origin_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Acquired_Patient_Origin_Enum>;
  _nin?: InputMaybe<Array<Config_Acquired_Patient_Origin_Enum>>;
};

/** input type for inserting data into table "config.acquired_patient_origin" */
export type Config_Acquired_Patient_Origin_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.acquired_patient_origin" */
export type Config_Acquired_Patient_Origin_Obj_Rel_Insert_Input = {
  data: Config_Acquired_Patient_Origin_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Acquired_Patient_Origin_On_Conflict>;
};

/** on_conflict condition type for table "config.acquired_patient_origin" */
export type Config_Acquired_Patient_Origin_On_Conflict = {
  constraint: Config_Acquired_Patient_Origin_Constraint;
  update_columns?: Array<Config_Acquired_Patient_Origin_Update_Column>;
  where?: InputMaybe<Config_Acquired_Patient_Origin_Bool_Exp>;
};

/** Ordering options when selecting data from "config.acquired_patient_origin". */
export type Config_Acquired_Patient_Origin_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.acquired_patient_origin */
export type Config_Acquired_Patient_Origin_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.acquired_patient_origin" */
export enum Config_Acquired_Patient_Origin_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.acquired_patient_origin" */
export type Config_Acquired_Patient_Origin_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_acquired_patient_origin" */
export type Config_Acquired_Patient_Origin_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Acquired_Patient_Origin_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Acquired_Patient_Origin_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.acquired_patient_origin" */
export enum Config_Acquired_Patient_Origin_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Acquired_Patient_Origin_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Acquired_Patient_Origin_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Acquired_Patient_Origin_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.address_type". All fields are combined with a logical 'AND'. */
export type Config_Address_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Address_Type_Bool_Exp>>;
  _not?: InputMaybe<Config_Address_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Address_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.address_type" */
export enum Config_Address_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  Pk_162f5946132d5cff450d1ac83ab = 'PK_162f5946132d5cff450d1ac83ab'
}

export enum Config_Address_Type_Enum {
  Home = 'HOME',
  Office = 'OFFICE',
  Other = 'OTHER'
}

/** Boolean expression to compare columns of type "config_address_type_enum". All fields are combined with logical 'AND'. */
export type Config_Address_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Address_Type_Enum>;
  _in?: InputMaybe<Array<Config_Address_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Address_Type_Enum>;
  _nin?: InputMaybe<Array<Config_Address_Type_Enum>>;
};

/** input type for inserting data into table "config.address_type" */
export type Config_Address_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.address_type" */
export type Config_Address_Type_Obj_Rel_Insert_Input = {
  data: Config_Address_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Address_Type_On_Conflict>;
};

/** on_conflict condition type for table "config.address_type" */
export type Config_Address_Type_On_Conflict = {
  constraint: Config_Address_Type_Constraint;
  update_columns?: Array<Config_Address_Type_Update_Column>;
  where?: InputMaybe<Config_Address_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "config.address_type". */
export type Config_Address_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.address_type */
export type Config_Address_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.address_type" */
export enum Config_Address_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.address_type" */
export type Config_Address_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_address_type" */
export type Config_Address_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Address_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Address_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.address_type" */
export enum Config_Address_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Address_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Address_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Address_Type_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.booking_event_type". All fields are combined with a logical 'AND'. */
export type Config_Booking_Event_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Booking_Event_Type_Bool_Exp>>;
  _not?: InputMaybe<Config_Booking_Event_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Booking_Event_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.booking_event_type" */
export enum Config_Booking_Event_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  BookingEventTypePkey = 'booking_event_type_pkey'
}

export enum Config_Booking_Event_Type_Enum {
  Calendly = 'CALENDLY'
}

/** Boolean expression to compare columns of type "config_booking_event_type_enum". All fields are combined with logical 'AND'. */
export type Config_Booking_Event_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Booking_Event_Type_Enum>;
  _in?: InputMaybe<Array<Config_Booking_Event_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Booking_Event_Type_Enum>;
  _nin?: InputMaybe<Array<Config_Booking_Event_Type_Enum>>;
};

/** input type for inserting data into table "config.booking_event_type" */
export type Config_Booking_Event_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.booking_event_type" */
export type Config_Booking_Event_Type_On_Conflict = {
  constraint: Config_Booking_Event_Type_Constraint;
  update_columns?: Array<Config_Booking_Event_Type_Update_Column>;
  where?: InputMaybe<Config_Booking_Event_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "config.booking_event_type". */
export type Config_Booking_Event_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.booking_event_type */
export type Config_Booking_Event_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.booking_event_type" */
export enum Config_Booking_Event_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.booking_event_type" */
export type Config_Booking_Event_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_booking_event_type" */
export type Config_Booking_Event_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Booking_Event_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Booking_Event_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.booking_event_type" */
export enum Config_Booking_Event_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Booking_Event_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Booking_Event_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Booking_Event_Type_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.channel". All fields are combined with a logical 'AND'. */
export type Config_Channel_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Channel_Bool_Exp>>;
  _not?: InputMaybe<Config_Channel_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Channel_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.channel" */
export enum Config_Channel_Constraint {
  /** unique or primary key constraint on columns "value" */
  ChannelPkey = 'channel_pkey'
}

export enum Config_Channel_Enum {
  Acquisition = 'ACQUISITION',
  Maple = 'MAPLE',
  Organic = 'ORGANIC',
  Other = 'OTHER',
  Partner = 'PARTNER'
}

/** Boolean expression to compare columns of type "config_channel_enum". All fields are combined with logical 'AND'. */
export type Config_Channel_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Channel_Enum>;
  _in?: InputMaybe<Array<Config_Channel_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Channel_Enum>;
  _nin?: InputMaybe<Array<Config_Channel_Enum>>;
};

/** input type for inserting data into table "config.channel" */
export type Config_Channel_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.channel" */
export type Config_Channel_On_Conflict = {
  constraint: Config_Channel_Constraint;
  update_columns?: Array<Config_Channel_Update_Column>;
  where?: InputMaybe<Config_Channel_Bool_Exp>;
};

/** Ordering options when selecting data from "config.channel". */
export type Config_Channel_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.channel */
export type Config_Channel_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.channel" */
export enum Config_Channel_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.channel" */
export type Config_Channel_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_channel" */
export type Config_Channel_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Channel_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Channel_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.channel" */
export enum Config_Channel_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Channel_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Channel_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Channel_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.consultation_category". All fields are combined with a logical 'AND'. */
export type Config_Consultation_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Consultation_Category_Bool_Exp>>;
  _not?: InputMaybe<Config_Consultation_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Consultation_Category_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
  consultation_program_by_category?: InputMaybe<Config_Consultation_Program_By_Category_Bool_Exp>;
  consultation_program_by_category_aggregate?: InputMaybe<Config_Consultation_Program_By_Category_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  label_en?: InputMaybe<String_Comparison_Exp>;
  label_fr?: InputMaybe<String_Comparison_Exp>;
  slug_en?: InputMaybe<String_Comparison_Exp>;
  slug_fr?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.consultation_category" */
export enum Config_Consultation_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConsultationCategoryPkey = 'consultation_category_pkey'
}

/** input type for inserting data into table "config.consultation_category" */
export type Config_Consultation_Category_Insert_Input = {
  category?: InputMaybe<Scalars['String']>;
  consultation_program_by_category?: InputMaybe<Config_Consultation_Program_By_Category_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  label_en?: InputMaybe<Scalars['String']>;
  label_fr?: InputMaybe<Scalars['String']>;
  slug_en?: InputMaybe<Scalars['String']>;
  slug_fr?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.consultation_category" */
export type Config_Consultation_Category_On_Conflict = {
  constraint: Config_Consultation_Category_Constraint;
  update_columns?: Array<Config_Consultation_Category_Update_Column>;
  where?: InputMaybe<Config_Consultation_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "config.consultation_category". */
export type Config_Consultation_Category_Order_By = {
  category?: InputMaybe<Order_By>;
  consultation_program_by_category_aggregate?: InputMaybe<Config_Consultation_Program_By_Category_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  label_en?: InputMaybe<Order_By>;
  label_fr?: InputMaybe<Order_By>;
  slug_en?: InputMaybe<Order_By>;
  slug_fr?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.consultation_category */
export type Config_Consultation_Category_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "config.consultation_category" */
export enum Config_Consultation_Category_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id',
  /** column name */
  LabelEn = 'label_en',
  /** column name */
  LabelFr = 'label_fr',
  /** column name */
  SlugEn = 'slug_en',
  /** column name */
  SlugFr = 'slug_fr'
}

/** input type for updating data in table "config.consultation_category" */
export type Config_Consultation_Category_Set_Input = {
  category?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  label_en?: InputMaybe<Scalars['String']>;
  label_fr?: InputMaybe<Scalars['String']>;
  slug_en?: InputMaybe<Scalars['String']>;
  slug_fr?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_consultation_category" */
export type Config_Consultation_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Consultation_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Consultation_Category_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  label_en?: InputMaybe<Scalars['String']>;
  label_fr?: InputMaybe<Scalars['String']>;
  slug_en?: InputMaybe<Scalars['String']>;
  slug_fr?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.consultation_category" */
export enum Config_Consultation_Category_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id',
  /** column name */
  LabelEn = 'label_en',
  /** column name */
  LabelFr = 'label_fr',
  /** column name */
  SlugEn = 'slug_en',
  /** column name */
  SlugFr = 'slug_fr'
}

export type Config_Consultation_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Consultation_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Consultation_Category_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.consultation_program". All fields are combined with a logical 'AND'. */
export type Config_Consultation_Program_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Consultation_Program_Bool_Exp>>;
  _not?: InputMaybe<Config_Consultation_Program_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Consultation_Program_Bool_Exp>>;
  form_consultations?: InputMaybe<Form_Consultation_Bool_Exp>;
  form_consultations_aggregate?: InputMaybe<Form_Consultation_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  label_en?: InputMaybe<String_Comparison_Exp>;
  label_fr?: InputMaybe<String_Comparison_Exp>;
  leomed_playbook_id?: InputMaybe<String_Comparison_Exp>;
  program?: InputMaybe<String_Comparison_Exp>;
  slug_en?: InputMaybe<String_Comparison_Exp>;
  slug_fr?: InputMaybe<String_Comparison_Exp>;
  tag_en?: InputMaybe<String_Comparison_Exp>;
  tag_fr?: InputMaybe<String_Comparison_Exp>;
};

export type Config_Consultation_Program_By_Category_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Config_Consultation_Program_By_Category_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Config_Consultation_Program_By_Category_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Config_Consultation_Program_By_Category_Aggregate_Bool_Exp_Count>;
};

export type Config_Consultation_Program_By_Category_Aggregate_Bool_Exp_Bool_And = {
  arguments: Config_Consultation_Program_By_Category_Select_Column_Config_Consultation_Program_By_Category_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Config_Consultation_Program_By_Category_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Config_Consultation_Program_By_Category_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Config_Consultation_Program_By_Category_Select_Column_Config_Consultation_Program_By_Category_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Config_Consultation_Program_By_Category_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Config_Consultation_Program_By_Category_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Config_Consultation_Program_By_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Config_Consultation_Program_By_Category_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "config.consultation_program_by_category" */
export type Config_Consultation_Program_By_Category_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Config_Consultation_Program_By_Category_Max_Order_By>;
  min?: InputMaybe<Config_Consultation_Program_By_Category_Min_Order_By>;
};

/** input type for inserting array relation for remote table "config.consultation_program_by_category" */
export type Config_Consultation_Program_By_Category_Arr_Rel_Insert_Input = {
  data: Array<Config_Consultation_Program_By_Category_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Consultation_Program_By_Category_On_Conflict>;
};

/** Boolean expression to filter rows from the table "config.consultation_program_by_category". All fields are combined with a logical 'AND'. */
export type Config_Consultation_Program_By_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Consultation_Program_By_Category_Bool_Exp>>;
  _not?: InputMaybe<Config_Consultation_Program_By_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Consultation_Program_By_Category_Bool_Exp>>;
  category_id?: InputMaybe<Uuid_Comparison_Exp>;
  consultation_program?: InputMaybe<Config_Consultation_Program_Bool_Exp>;
  is_visible_in_client_app?: InputMaybe<Boolean_Comparison_Exp>;
  program_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.consultation_program_by_category" */
export enum Config_Consultation_Program_By_Category_Constraint {
  /** unique or primary key constraint on columns "program_id", "category_id" */
  ConsultationProgramByCategoryPkey = 'consultation_program_by_category_pkey'
}

/** input type for inserting data into table "config.consultation_program_by_category" */
export type Config_Consultation_Program_By_Category_Insert_Input = {
  category_id?: InputMaybe<Scalars['uuid']>;
  consultation_program?: InputMaybe<Config_Consultation_Program_Obj_Rel_Insert_Input>;
  is_visible_in_client_app?: InputMaybe<Scalars['Boolean']>;
  program_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "config.consultation_program_by_category" */
export type Config_Consultation_Program_By_Category_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "config.consultation_program_by_category" */
export type Config_Consultation_Program_By_Category_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "config.consultation_program_by_category" */
export type Config_Consultation_Program_By_Category_On_Conflict = {
  constraint: Config_Consultation_Program_By_Category_Constraint;
  update_columns?: Array<Config_Consultation_Program_By_Category_Update_Column>;
  where?: InputMaybe<Config_Consultation_Program_By_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "config.consultation_program_by_category". */
export type Config_Consultation_Program_By_Category_Order_By = {
  category_id?: InputMaybe<Order_By>;
  consultation_program?: InputMaybe<Config_Consultation_Program_Order_By>;
  is_visible_in_client_app?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.consultation_program_by_category */
export type Config_Consultation_Program_By_Category_Pk_Columns_Input = {
  category_id: Scalars['uuid'];
  program_id: Scalars['uuid'];
};

/** select columns of table "config.consultation_program_by_category" */
export enum Config_Consultation_Program_By_Category_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  IsVisibleInClientApp = 'is_visible_in_client_app',
  /** column name */
  ProgramId = 'program_id'
}

/** select "config_consultation_program_by_category_aggregate_bool_exp_bool_and_arguments_columns" columns of table "config.consultation_program_by_category" */
export enum Config_Consultation_Program_By_Category_Select_Column_Config_Consultation_Program_By_Category_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsVisibleInClientApp = 'is_visible_in_client_app'
}

/** select "config_consultation_program_by_category_aggregate_bool_exp_bool_or_arguments_columns" columns of table "config.consultation_program_by_category" */
export enum Config_Consultation_Program_By_Category_Select_Column_Config_Consultation_Program_By_Category_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsVisibleInClientApp = 'is_visible_in_client_app'
}

/** input type for updating data in table "config.consultation_program_by_category" */
export type Config_Consultation_Program_By_Category_Set_Input = {
  category_id?: InputMaybe<Scalars['uuid']>;
  is_visible_in_client_app?: InputMaybe<Scalars['Boolean']>;
  program_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "config_consultation_program_by_category" */
export type Config_Consultation_Program_By_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Consultation_Program_By_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Consultation_Program_By_Category_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['uuid']>;
  is_visible_in_client_app?: InputMaybe<Scalars['Boolean']>;
  program_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "config.consultation_program_by_category" */
export enum Config_Consultation_Program_By_Category_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  IsVisibleInClientApp = 'is_visible_in_client_app',
  /** column name */
  ProgramId = 'program_id'
}

export type Config_Consultation_Program_By_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Consultation_Program_By_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Consultation_Program_By_Category_Bool_Exp;
};

/** unique or primary key constraints on table "config.consultation_program" */
export enum Config_Consultation_Program_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConsultationProgramPkey = 'consultation_program_pkey'
}

/** input type for inserting data into table "config.consultation_program" */
export type Config_Consultation_Program_Insert_Input = {
  form_consultations?: InputMaybe<Form_Consultation_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  label_en?: InputMaybe<Scalars['String']>;
  label_fr?: InputMaybe<Scalars['String']>;
  leomed_playbook_id?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  slug_en?: InputMaybe<Scalars['String']>;
  slug_fr?: InputMaybe<Scalars['String']>;
  tag_en?: InputMaybe<Scalars['String']>;
  tag_fr?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.consultation_program" */
export type Config_Consultation_Program_Obj_Rel_Insert_Input = {
  data: Config_Consultation_Program_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Consultation_Program_On_Conflict>;
};

/** on_conflict condition type for table "config.consultation_program" */
export type Config_Consultation_Program_On_Conflict = {
  constraint: Config_Consultation_Program_Constraint;
  update_columns?: Array<Config_Consultation_Program_Update_Column>;
  where?: InputMaybe<Config_Consultation_Program_Bool_Exp>;
};

/** Ordering options when selecting data from "config.consultation_program". */
export type Config_Consultation_Program_Order_By = {
  form_consultations_aggregate?: InputMaybe<Form_Consultation_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  label_en?: InputMaybe<Order_By>;
  label_fr?: InputMaybe<Order_By>;
  leomed_playbook_id?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  slug_en?: InputMaybe<Order_By>;
  slug_fr?: InputMaybe<Order_By>;
  tag_en?: InputMaybe<Order_By>;
  tag_fr?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.consultation_program */
export type Config_Consultation_Program_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "config.consultation_program" */
export enum Config_Consultation_Program_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LabelEn = 'label_en',
  /** column name */
  LabelFr = 'label_fr',
  /** column name */
  LeomedPlaybookId = 'leomed_playbook_id',
  /** column name */
  Program = 'program',
  /** column name */
  SlugEn = 'slug_en',
  /** column name */
  SlugFr = 'slug_fr',
  /** column name */
  TagEn = 'tag_en',
  /** column name */
  TagFr = 'tag_fr'
}

/** input type for updating data in table "config.consultation_program" */
export type Config_Consultation_Program_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  label_en?: InputMaybe<Scalars['String']>;
  label_fr?: InputMaybe<Scalars['String']>;
  leomed_playbook_id?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  slug_en?: InputMaybe<Scalars['String']>;
  slug_fr?: InputMaybe<Scalars['String']>;
  tag_en?: InputMaybe<Scalars['String']>;
  tag_fr?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_consultation_program" */
export type Config_Consultation_Program_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Consultation_Program_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Consultation_Program_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  label_en?: InputMaybe<Scalars['String']>;
  label_fr?: InputMaybe<Scalars['String']>;
  leomed_playbook_id?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  slug_en?: InputMaybe<Scalars['String']>;
  slug_fr?: InputMaybe<Scalars['String']>;
  tag_en?: InputMaybe<Scalars['String']>;
  tag_fr?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.consultation_program" */
export enum Config_Consultation_Program_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LabelEn = 'label_en',
  /** column name */
  LabelFr = 'label_fr',
  /** column name */
  LeomedPlaybookId = 'leomed_playbook_id',
  /** column name */
  Program = 'program',
  /** column name */
  SlugEn = 'slug_en',
  /** column name */
  SlugFr = 'slug_fr',
  /** column name */
  TagEn = 'tag_en',
  /** column name */
  TagFr = 'tag_fr'
}

export type Config_Consultation_Program_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Consultation_Program_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Consultation_Program_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.consultation_status". All fields are combined with a logical 'AND'. */
export type Config_Consultation_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Consultation_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Consultation_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Consultation_Status_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.consultation_status" */
export enum Config_Consultation_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  ConsultationStatusPkey = 'consultation_status_pkey'
}

export enum Config_Consultation_Status_Enum {
  AppointmentMade = 'APPOINTMENT_MADE',
  Done = 'DONE',
  Drop = 'DROP',
  NoAnswer = 'NO_ANSWER'
}

/** Boolean expression to compare columns of type "config_consultation_status_enum". All fields are combined with logical 'AND'. */
export type Config_Consultation_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Consultation_Status_Enum>;
  _in?: InputMaybe<Array<Config_Consultation_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Consultation_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Consultation_Status_Enum>>;
};

/** input type for inserting data into table "config.consultation_status" */
export type Config_Consultation_Status_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.consultation_status" */
export type Config_Consultation_Status_On_Conflict = {
  constraint: Config_Consultation_Status_Constraint;
  update_columns?: Array<Config_Consultation_Status_Update_Column>;
  where?: InputMaybe<Config_Consultation_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.consultation_status". */
export type Config_Consultation_Status_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.consultation_status */
export type Config_Consultation_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.consultation_status" */
export enum Config_Consultation_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.consultation_status" */
export type Config_Consultation_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_consultation_status" */
export type Config_Consultation_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Consultation_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Consultation_Status_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.consultation_status" */
export enum Config_Consultation_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Consultation_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Consultation_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Consultation_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.contact_status". All fields are combined with a logical 'AND'. */
export type Config_Contact_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Contact_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Contact_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Contact_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.contact_status" */
export enum Config_Contact_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  FormConsultationContactStatusPkey = 'form_consultation_contact_status_pkey'
}

export enum Config_Contact_Status_Enum {
  Booked = 'BOOKED',
  BookingSent = 'BOOKING_SENT',
  Done = 'DONE',
  FirstAttempt = 'FIRST_ATTEMPT',
  IssueBooking = 'ISSUE_BOOKING'
}

/** Boolean expression to compare columns of type "config_contact_status_enum". All fields are combined with logical 'AND'. */
export type Config_Contact_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Contact_Status_Enum>;
  _in?: InputMaybe<Array<Config_Contact_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Contact_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Contact_Status_Enum>>;
};

/** input type for inserting data into table "config.contact_status" */
export type Config_Contact_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.contact_status" */
export type Config_Contact_Status_On_Conflict = {
  constraint: Config_Contact_Status_Constraint;
  update_columns?: Array<Config_Contact_Status_Update_Column>;
  where?: InputMaybe<Config_Contact_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.contact_status". */
export type Config_Contact_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.contact_status */
export type Config_Contact_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.contact_status" */
export enum Config_Contact_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.contact_status" */
export type Config_Contact_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_contact_status" */
export type Config_Contact_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Contact_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Contact_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.contact_status" */
export enum Config_Contact_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Contact_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Contact_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Contact_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.customer_journey_event_name". All fields are combined with a logical 'AND'. */
export type Config_Customer_Journey_Event_Name_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Customer_Journey_Event_Name_Bool_Exp>>;
  _not?: InputMaybe<Config_Customer_Journey_Event_Name_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Customer_Journey_Event_Name_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.customer_journey_event_name" */
export enum Config_Customer_Journey_Event_Name_Constraint {
  /** unique or primary key constraint on columns "value" */
  CustomerJourneyEventNamePkey = 'customer_journey_event_name_pkey'
}

export enum Config_Customer_Journey_Event_Name_Enum {
  AccountCreated = 'ACCOUNT_CREATED',
  FirstInvoicedService = 'FIRST_INVOICED_SERVICE',
  FirstPrescriptionInPms = 'FIRST_PRESCRIPTION_IN_PMS',
  PartnerReferral = 'PARTNER_REFERRAL',
  PatientIsDependent = 'PATIENT_IS_DEPENDENT',
  SignUpCompleted = 'SIGN_UP_COMPLETED',
  SurveyCompleted = 'SURVEY_COMPLETED'
}

/** Boolean expression to compare columns of type "config_customer_journey_event_name_enum". All fields are combined with logical 'AND'. */
export type Config_Customer_Journey_Event_Name_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Customer_Journey_Event_Name_Enum>;
  _in?: InputMaybe<Array<Config_Customer_Journey_Event_Name_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Customer_Journey_Event_Name_Enum>;
  _nin?: InputMaybe<Array<Config_Customer_Journey_Event_Name_Enum>>;
};

/** input type for inserting data into table "config.customer_journey_event_name" */
export type Config_Customer_Journey_Event_Name_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.customer_journey_event_name" */
export type Config_Customer_Journey_Event_Name_On_Conflict = {
  constraint: Config_Customer_Journey_Event_Name_Constraint;
  update_columns?: Array<Config_Customer_Journey_Event_Name_Update_Column>;
  where?: InputMaybe<Config_Customer_Journey_Event_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "config.customer_journey_event_name". */
export type Config_Customer_Journey_Event_Name_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.customer_journey_event_name */
export type Config_Customer_Journey_Event_Name_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.customer_journey_event_name" */
export enum Config_Customer_Journey_Event_Name_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.customer_journey_event_name" */
export type Config_Customer_Journey_Event_Name_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_customer_journey_event_name" */
export type Config_Customer_Journey_Event_Name_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Customer_Journey_Event_Name_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Customer_Journey_Event_Name_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.customer_journey_event_name" */
export enum Config_Customer_Journey_Event_Name_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Customer_Journey_Event_Name_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Customer_Journey_Event_Name_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Customer_Journey_Event_Name_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.customer_journey_milestone". All fields are combined with a logical 'AND'. */
export type Config_Customer_Journey_Milestone_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Customer_Journey_Milestone_Bool_Exp>>;
  _not?: InputMaybe<Config_Customer_Journey_Milestone_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Customer_Journey_Milestone_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.customer_journey_milestone" */
export enum Config_Customer_Journey_Milestone_Constraint {
  /** unique or primary key constraint on columns "value" */
  CustomerJourneyMilestonePkey = 'customer_journey_milestone_pkey'
}

export enum Config_Customer_Journey_Milestone_Enum {
  /** Has ordered medication at least once */
  ActiveClient = 'ACTIVE_CLIENT',
  /** Has been referred to us by a partner, or has started the sign-up process */
  Lead = 'LEAD',
  /** Has a PMS account with prescriptions */
  PharmacyProspect = 'PHARMACY_PROSPECT',
  /** Has completed the sign-up process */
  Prospect = 'PROSPECT'
}

/** Boolean expression to compare columns of type "config_customer_journey_milestone_enum". All fields are combined with logical 'AND'. */
export type Config_Customer_Journey_Milestone_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Customer_Journey_Milestone_Enum>;
  _in?: InputMaybe<Array<Config_Customer_Journey_Milestone_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Customer_Journey_Milestone_Enum>;
  _nin?: InputMaybe<Array<Config_Customer_Journey_Milestone_Enum>>;
};

/** input type for inserting data into table "config.customer_journey_milestone" */
export type Config_Customer_Journey_Milestone_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.customer_journey_milestone" */
export type Config_Customer_Journey_Milestone_On_Conflict = {
  constraint: Config_Customer_Journey_Milestone_Constraint;
  update_columns?: Array<Config_Customer_Journey_Milestone_Update_Column>;
  where?: InputMaybe<Config_Customer_Journey_Milestone_Bool_Exp>;
};

/** Ordering options when selecting data from "config.customer_journey_milestone". */
export type Config_Customer_Journey_Milestone_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.customer_journey_milestone */
export type Config_Customer_Journey_Milestone_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.customer_journey_milestone" */
export enum Config_Customer_Journey_Milestone_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.customer_journey_milestone" */
export type Config_Customer_Journey_Milestone_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_customer_journey_milestone" */
export type Config_Customer_Journey_Milestone_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Customer_Journey_Milestone_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Customer_Journey_Milestone_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.customer_journey_milestone" */
export enum Config_Customer_Journey_Milestone_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Customer_Journey_Milestone_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Customer_Journey_Milestone_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Customer_Journey_Milestone_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.delivery_type". All fields are combined with a logical 'AND'. */
export type Config_Delivery_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Delivery_Type_Bool_Exp>>;
  _not?: InputMaybe<Config_Delivery_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Delivery_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.delivery_type" */
export enum Config_Delivery_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  DeliveryTypePkey = 'delivery_type_pkey'
}

export enum Config_Delivery_Type_Enum {
  PickUp = 'PICK_UP',
  Standard = 'STANDARD'
}

/** Boolean expression to compare columns of type "config_delivery_type_enum". All fields are combined with logical 'AND'. */
export type Config_Delivery_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Delivery_Type_Enum>;
  _in?: InputMaybe<Array<Config_Delivery_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Delivery_Type_Enum>;
  _nin?: InputMaybe<Array<Config_Delivery_Type_Enum>>;
};

/** input type for inserting data into table "config.delivery_type" */
export type Config_Delivery_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.delivery_type" */
export type Config_Delivery_Type_On_Conflict = {
  constraint: Config_Delivery_Type_Constraint;
  update_columns?: Array<Config_Delivery_Type_Update_Column>;
  where?: InputMaybe<Config_Delivery_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "config.delivery_type". */
export type Config_Delivery_Type_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.delivery_type */
export type Config_Delivery_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.delivery_type" */
export enum Config_Delivery_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.delivery_type" */
export type Config_Delivery_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_delivery_type" */
export type Config_Delivery_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Delivery_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Delivery_Type_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.delivery_type" */
export enum Config_Delivery_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Delivery_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Delivery_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Delivery_Type_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.dismissible_in_app_notification". All fields are combined with a logical 'AND'. */
export type Config_Dismissible_In_App_Notification_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Dismissible_In_App_Notification_Bool_Exp>>;
  _not?: InputMaybe<Config_Dismissible_In_App_Notification_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Dismissible_In_App_Notification_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.dismissible_in_app_notification" */
export enum Config_Dismissible_In_App_Notification_Constraint {
  /** unique or primary key constraint on columns "value" */
  DismissibleInAppNotificationPkey = 'dismissible_in_app_notification_pkey'
}

export enum Config_Dismissible_In_App_Notification_Enum {
  MedicationRenewal = 'MEDICATION_RENEWAL',
  TransferMedication = 'TRANSFER_MEDICATION'
}

/** Boolean expression to compare columns of type "config_dismissible_in_app_notification_enum". All fields are combined with logical 'AND'. */
export type Config_Dismissible_In_App_Notification_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Dismissible_In_App_Notification_Enum>;
  _in?: InputMaybe<Array<Config_Dismissible_In_App_Notification_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Dismissible_In_App_Notification_Enum>;
  _nin?: InputMaybe<Array<Config_Dismissible_In_App_Notification_Enum>>;
};

/** input type for inserting data into table "config.dismissible_in_app_notification" */
export type Config_Dismissible_In_App_Notification_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.dismissible_in_app_notification" */
export type Config_Dismissible_In_App_Notification_On_Conflict = {
  constraint: Config_Dismissible_In_App_Notification_Constraint;
  update_columns?: Array<Config_Dismissible_In_App_Notification_Update_Column>;
  where?: InputMaybe<Config_Dismissible_In_App_Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "config.dismissible_in_app_notification". */
export type Config_Dismissible_In_App_Notification_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.dismissible_in_app_notification */
export type Config_Dismissible_In_App_Notification_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.dismissible_in_app_notification" */
export enum Config_Dismissible_In_App_Notification_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.dismissible_in_app_notification" */
export type Config_Dismissible_In_App_Notification_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_dismissible_in_app_notification" */
export type Config_Dismissible_In_App_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Dismissible_In_App_Notification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Dismissible_In_App_Notification_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.dismissible_in_app_notification" */
export enum Config_Dismissible_In_App_Notification_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Dismissible_In_App_Notification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Dismissible_In_App_Notification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Dismissible_In_App_Notification_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.drug_price_source". All fields are combined with a logical 'AND'. */
export type Config_Drug_Price_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Drug_Price_Source_Bool_Exp>>;
  _not?: InputMaybe<Config_Drug_Price_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Drug_Price_Source_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.drug_price_source" */
export enum Config_Drug_Price_Source_Constraint {
  /** unique or primary key constraint on columns "value" */
  Pk_47a9c99ad9940b0e72ab6805b87 = 'PK_47a9c99ad9940b0e72ab6805b87'
}

export enum Config_Drug_Price_Source_Enum {
  /** Price received from Logipharm sync in response to an end user price quote */
  LogipharmPriceQuote = 'LOGIPHARM_PRICE_QUOTE',
  /** Price entered manually in response to an end user price quote */
  ManualPriceQuote = 'MANUAL_PRICE_QUOTE'
}

/** Boolean expression to compare columns of type "config_drug_price_source_enum". All fields are combined with logical 'AND'. */
export type Config_Drug_Price_Source_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Drug_Price_Source_Enum>;
  _in?: InputMaybe<Array<Config_Drug_Price_Source_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Drug_Price_Source_Enum>;
  _nin?: InputMaybe<Array<Config_Drug_Price_Source_Enum>>;
};

/** input type for inserting data into table "config.drug_price_source" */
export type Config_Drug_Price_Source_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.drug_price_source" */
export type Config_Drug_Price_Source_Obj_Rel_Insert_Input = {
  data: Config_Drug_Price_Source_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Drug_Price_Source_On_Conflict>;
};

/** on_conflict condition type for table "config.drug_price_source" */
export type Config_Drug_Price_Source_On_Conflict = {
  constraint: Config_Drug_Price_Source_Constraint;
  update_columns?: Array<Config_Drug_Price_Source_Update_Column>;
  where?: InputMaybe<Config_Drug_Price_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "config.drug_price_source". */
export type Config_Drug_Price_Source_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.drug_price_source */
export type Config_Drug_Price_Source_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.drug_price_source" */
export enum Config_Drug_Price_Source_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.drug_price_source" */
export type Config_Drug_Price_Source_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_drug_price_source" */
export type Config_Drug_Price_Source_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Drug_Price_Source_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Drug_Price_Source_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.drug_price_source" */
export enum Config_Drug_Price_Source_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Drug_Price_Source_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Drug_Price_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Drug_Price_Source_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.drug_property". All fields are combined with a logical 'AND'. */
export type Config_Drug_Property_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Drug_Property_Bool_Exp>>;
  _not?: InputMaybe<Config_Drug_Property_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Drug_Property_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.drug_property" */
export enum Config_Drug_Property_Constraint {
  /** unique or primary key constraint on columns "value" */
  DrugPropertyPkey = 'drug_property_pkey'
}

export enum Config_Drug_Property_Enum {
  NonPackable = 'NON_PACKABLE',
  Refrigerated = 'REFRIGERATED'
}

/** Boolean expression to compare columns of type "config_drug_property_enum". All fields are combined with logical 'AND'. */
export type Config_Drug_Property_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Drug_Property_Enum>;
  _in?: InputMaybe<Array<Config_Drug_Property_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Drug_Property_Enum>;
  _nin?: InputMaybe<Array<Config_Drug_Property_Enum>>;
};

/** input type for inserting data into table "config.drug_property" */
export type Config_Drug_Property_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.drug_property" */
export type Config_Drug_Property_On_Conflict = {
  constraint: Config_Drug_Property_Constraint;
  update_columns?: Array<Config_Drug_Property_Update_Column>;
  where?: InputMaybe<Config_Drug_Property_Bool_Exp>;
};

/** Ordering options when selecting data from "config.drug_property". */
export type Config_Drug_Property_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.drug_property */
export type Config_Drug_Property_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.drug_property" */
export enum Config_Drug_Property_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.drug_property" */
export type Config_Drug_Property_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_drug_property" */
export type Config_Drug_Property_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Drug_Property_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Drug_Property_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.drug_property" */
export enum Config_Drug_Property_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Drug_Property_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Drug_Property_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Drug_Property_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.empego_follow_up_status". All fields are combined with a logical 'AND'. */
export type Config_Empego_Follow_Up_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Empego_Follow_Up_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Empego_Follow_Up_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Empego_Follow_Up_Status_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.empego_follow_up_status" */
export enum Config_Empego_Follow_Up_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EmpegoFollowUpStatusPkey = 'empego_follow_up_status_pkey'
}

export enum Config_Empego_Follow_Up_Status_Enum {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Draft = 'DRAFT'
}

/** Boolean expression to compare columns of type "config_empego_follow_up_status_enum". All fields are combined with logical 'AND'. */
export type Config_Empego_Follow_Up_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Empego_Follow_Up_Status_Enum>;
  _in?: InputMaybe<Array<Config_Empego_Follow_Up_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Empego_Follow_Up_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Empego_Follow_Up_Status_Enum>>;
};

/** input type for inserting data into table "config.empego_follow_up_status" */
export type Config_Empego_Follow_Up_Status_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.empego_follow_up_status" */
export type Config_Empego_Follow_Up_Status_On_Conflict = {
  constraint: Config_Empego_Follow_Up_Status_Constraint;
  update_columns?: Array<Config_Empego_Follow_Up_Status_Update_Column>;
  where?: InputMaybe<Config_Empego_Follow_Up_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.empego_follow_up_status". */
export type Config_Empego_Follow_Up_Status_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.empego_follow_up_status */
export type Config_Empego_Follow_Up_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.empego_follow_up_status" */
export enum Config_Empego_Follow_Up_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.empego_follow_up_status" */
export type Config_Empego_Follow_Up_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_empego_follow_up_status" */
export type Config_Empego_Follow_Up_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Empego_Follow_Up_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Empego_Follow_Up_Status_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.empego_follow_up_status" */
export enum Config_Empego_Follow_Up_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Empego_Follow_Up_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Empego_Follow_Up_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Empego_Follow_Up_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.external_form". All fields are combined with a logical 'AND'. */
export type Config_External_Form_Bool_Exp = {
  _and?: InputMaybe<Array<Config_External_Form_Bool_Exp>>;
  _not?: InputMaybe<Config_External_Form_Bool_Exp>;
  _or?: InputMaybe<Array<Config_External_Form_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  external_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.external_form" */
export enum Config_External_Form_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExternalFormPkey = 'external_form_pkey'
}

/** input type for inserting data into table "config.external_form" */
export type Config_External_Form_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** on_conflict condition type for table "config.external_form" */
export type Config_External_Form_On_Conflict = {
  constraint: Config_External_Form_Constraint;
  update_columns?: Array<Config_External_Form_Update_Column>;
  where?: InputMaybe<Config_External_Form_Bool_Exp>;
};

/** Ordering options when selecting data from "config.external_form". */
export type Config_External_Form_Order_By = {
  comment?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.external_form */
export type Config_External_Form_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "config.external_form" */
export enum Config_External_Form_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "config.external_form" */
export type Config_External_Form_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "config_external_form" */
export type Config_External_Form_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_External_Form_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_External_Form_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "config.external_form" */
export enum Config_External_Form_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id'
}

export type Config_External_Form_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_External_Form_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_External_Form_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.fax_direction". All fields are combined with a logical 'AND'. */
export type Config_Fax_Direction_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Fax_Direction_Bool_Exp>>;
  _not?: InputMaybe<Config_Fax_Direction_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Fax_Direction_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.fax_direction" */
export enum Config_Fax_Direction_Constraint {
  /** unique or primary key constraint on columns "value" */
  PkCf51c73e6df3327534b4dd8de45 = 'PK_cf51c73e6df3327534b4dd8de45'
}

export enum Config_Fax_Direction_Enum {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

/** Boolean expression to compare columns of type "config_fax_direction_enum". All fields are combined with logical 'AND'. */
export type Config_Fax_Direction_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Fax_Direction_Enum>;
  _in?: InputMaybe<Array<Config_Fax_Direction_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Fax_Direction_Enum>;
  _nin?: InputMaybe<Array<Config_Fax_Direction_Enum>>;
};

/** input type for inserting data into table "config.fax_direction" */
export type Config_Fax_Direction_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.fax_direction" */
export type Config_Fax_Direction_On_Conflict = {
  constraint: Config_Fax_Direction_Constraint;
  update_columns?: Array<Config_Fax_Direction_Update_Column>;
  where?: InputMaybe<Config_Fax_Direction_Bool_Exp>;
};

/** Ordering options when selecting data from "config.fax_direction". */
export type Config_Fax_Direction_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.fax_direction */
export type Config_Fax_Direction_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.fax_direction" */
export enum Config_Fax_Direction_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.fax_direction" */
export type Config_Fax_Direction_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_fax_direction" */
export type Config_Fax_Direction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Fax_Direction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Fax_Direction_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.fax_direction" */
export enum Config_Fax_Direction_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Fax_Direction_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Fax_Direction_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Fax_Direction_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.fax_status". All fields are combined with a logical 'AND'. */
export type Config_Fax_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Fax_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Fax_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Fax_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.fax_status" */
export enum Config_Fax_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  Pk_33023ae6df6c9f1fda822590d66 = 'PK_33023ae6df6c9f1fda822590d66'
}

export enum Config_Fax_Status_Enum {
  AiAssigned = 'AI_ASSIGNED',
  Assigned = 'ASSIGNED',
  New = 'NEW',
  NotAssigned = 'NOT_ASSIGNED'
}

/** Boolean expression to compare columns of type "config_fax_status_enum". All fields are combined with logical 'AND'. */
export type Config_Fax_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Fax_Status_Enum>;
  _in?: InputMaybe<Array<Config_Fax_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Fax_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Fax_Status_Enum>>;
};

/** input type for inserting data into table "config.fax_status" */
export type Config_Fax_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.fax_status" */
export type Config_Fax_Status_On_Conflict = {
  constraint: Config_Fax_Status_Constraint;
  update_columns?: Array<Config_Fax_Status_Update_Column>;
  where?: InputMaybe<Config_Fax_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.fax_status". */
export type Config_Fax_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.fax_status */
export type Config_Fax_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.fax_status" */
export enum Config_Fax_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.fax_status" */
export type Config_Fax_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_fax_status" */
export type Config_Fax_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Fax_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Fax_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.fax_status" */
export enum Config_Fax_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Fax_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Fax_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Fax_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.fax_type". All fields are combined with a logical 'AND'. */
export type Config_Fax_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Fax_Type_Bool_Exp>>;
  _not?: InputMaybe<Config_Fax_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Fax_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.fax_type" */
export enum Config_Fax_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  PkF83cddad8cd5487a57d23671174 = 'PK_f83cddad8cd5487a57d23671174'
}

export enum Config_Fax_Type_Enum {
  /** Advertising */
  Ads = 'ADS',
  Business = 'BUSINESS',
  Distributors = 'DISTRIBUTORS',
  /** Communications submitted to a patient's healthcare provider */
  Doctor = 'DOCTOR',
  Insurance = 'INSURANCE',
  /** Inbound communications from the OPQ */
  Opq = 'OPQ',
  Other = 'OTHER',
  PatientFollowUp = 'PATIENT_FOLLOW_UP',
  /** Patient transferring in or out of Medzy */
  PatientTransfer = 'PATIENT_TRANSFER',
  /** Patient transferring in of Medzy */
  PatientTransferIn = 'PATIENT_TRANSFER_IN',
  /** Patient transferring out of Medzy */
  PatientTransferOut = 'PATIENT_TRANSFER_OUT',
  /** Pharmacological profile sent to a third-party health provider upon their request; claimable at RAMQ */
  PharmacoProfile = 'PHARMACO_PROFILE',
  /** Prescription received from a third-party health provider */
  Prescription = 'PRESCRIPTION',
  PrescriptionRenewalRequest = 'PRESCRIPTION_RENEWAL_REQUEST',
  Ramq = 'RAMQ'
}

/** Boolean expression to compare columns of type "config_fax_type_enum". All fields are combined with logical 'AND'. */
export type Config_Fax_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Fax_Type_Enum>;
  _in?: InputMaybe<Array<Config_Fax_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Fax_Type_Enum>;
  _nin?: InputMaybe<Array<Config_Fax_Type_Enum>>;
};

/** input type for inserting data into table "config.fax_type" */
export type Config_Fax_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.fax_type" */
export type Config_Fax_Type_On_Conflict = {
  constraint: Config_Fax_Type_Constraint;
  update_columns?: Array<Config_Fax_Type_Update_Column>;
  where?: InputMaybe<Config_Fax_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "config.fax_type". */
export type Config_Fax_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.fax_type */
export type Config_Fax_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.fax_type" */
export enum Config_Fax_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.fax_type" */
export type Config_Fax_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_fax_type" */
export type Config_Fax_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Fax_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Fax_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.fax_type" */
export enum Config_Fax_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Fax_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Fax_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Fax_Type_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.follow_up_program". All fields are combined with a logical 'AND'. */
export type Config_Follow_Up_Program_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Follow_Up_Program_Bool_Exp>>;
  _not?: InputMaybe<Config_Follow_Up_Program_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Follow_Up_Program_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "config.follow_up_program_by_origin". All fields are combined with a logical 'AND'. */
export type Config_Follow_Up_Program_By_Origin_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Follow_Up_Program_By_Origin_Bool_Exp>>;
  _not?: InputMaybe<Config_Follow_Up_Program_By_Origin_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Follow_Up_Program_By_Origin_Bool_Exp>>;
  origin?: InputMaybe<Config_Acquired_Patient_Origin_Enum_Comparison_Exp>;
  program?: InputMaybe<Config_Follow_Up_Program_Enum_Comparison_Exp>;
};

/** input type for inserting data into table "config.follow_up_program_by_origin" */
export type Config_Follow_Up_Program_By_Origin_Insert_Input = {
  origin?: InputMaybe<Config_Acquired_Patient_Origin_Enum>;
  program?: InputMaybe<Config_Follow_Up_Program_Enum>;
};

/** Ordering options when selecting data from "config.follow_up_program_by_origin". */
export type Config_Follow_Up_Program_By_Origin_Order_By = {
  origin?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
};

/** select columns of table "config.follow_up_program_by_origin" */
export enum Config_Follow_Up_Program_By_Origin_Select_Column {
  /** column name */
  Origin = 'origin',
  /** column name */
  Program = 'program'
}

/** input type for updating data in table "config.follow_up_program_by_origin" */
export type Config_Follow_Up_Program_By_Origin_Set_Input = {
  origin?: InputMaybe<Config_Acquired_Patient_Origin_Enum>;
  program?: InputMaybe<Config_Follow_Up_Program_Enum>;
};

/** Streaming cursor of the table "config_follow_up_program_by_origin" */
export type Config_Follow_Up_Program_By_Origin_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Follow_Up_Program_By_Origin_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Follow_Up_Program_By_Origin_Stream_Cursor_Value_Input = {
  origin?: InputMaybe<Config_Acquired_Patient_Origin_Enum>;
  program?: InputMaybe<Config_Follow_Up_Program_Enum>;
};

export type Config_Follow_Up_Program_By_Origin_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Follow_Up_Program_By_Origin_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Follow_Up_Program_By_Origin_Bool_Exp;
};

/** unique or primary key constraints on table "config.follow_up_program" */
export enum Config_Follow_Up_Program_Constraint {
  /** unique or primary key constraint on columns "value" */
  FollowUpProgramPkey = 'follow_up_program_pkey'
}

export enum Config_Follow_Up_Program_Enum {
  BirthControl = 'BIRTH_CONTROL',
  PatientCare = 'PATIENT_CARE',
  PharmacyChoice = 'PHARMACY_CHOICE',
  PspObesite = 'PSP_OBESITE',
  RxRenewal = 'RX_RENEWAL',
  UrinaryTractInfection = 'URINARY_TRACT_INFECTION'
}

/** Boolean expression to compare columns of type "config_follow_up_program_enum". All fields are combined with logical 'AND'. */
export type Config_Follow_Up_Program_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Follow_Up_Program_Enum>;
  _in?: InputMaybe<Array<Config_Follow_Up_Program_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Follow_Up_Program_Enum>;
  _nin?: InputMaybe<Array<Config_Follow_Up_Program_Enum>>;
};

/** input type for inserting data into table "config.follow_up_program" */
export type Config_Follow_Up_Program_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.follow_up_program" */
export type Config_Follow_Up_Program_Obj_Rel_Insert_Input = {
  data: Config_Follow_Up_Program_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Follow_Up_Program_On_Conflict>;
};

/** on_conflict condition type for table "config.follow_up_program" */
export type Config_Follow_Up_Program_On_Conflict = {
  constraint: Config_Follow_Up_Program_Constraint;
  update_columns?: Array<Config_Follow_Up_Program_Update_Column>;
  where?: InputMaybe<Config_Follow_Up_Program_Bool_Exp>;
};

/** Ordering options when selecting data from "config.follow_up_program". */
export type Config_Follow_Up_Program_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.follow_up_program */
export type Config_Follow_Up_Program_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.follow_up_program" */
export enum Config_Follow_Up_Program_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.follow_up_program" */
export type Config_Follow_Up_Program_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_follow_up_program" */
export type Config_Follow_Up_Program_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Follow_Up_Program_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Follow_Up_Program_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.follow_up_program" */
export enum Config_Follow_Up_Program_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Follow_Up_Program_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Follow_Up_Program_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Follow_Up_Program_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.follow_up_status". All fields are combined with a logical 'AND'. */
export type Config_Follow_Up_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Follow_Up_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Follow_Up_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Follow_Up_Status_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.follow_up_status" */
export enum Config_Follow_Up_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  FollowUpStatusPkey = 'follow_up_status_pkey'
}

export enum Config_Follow_Up_Status_Enum {
  Done = 'DONE',
  None = 'NONE',
  Scheduled = 'SCHEDULED'
}

/** Boolean expression to compare columns of type "config_follow_up_status_enum". All fields are combined with logical 'AND'. */
export type Config_Follow_Up_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Follow_Up_Status_Enum>;
  _in?: InputMaybe<Array<Config_Follow_Up_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Follow_Up_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Follow_Up_Status_Enum>>;
};

/** input type for inserting data into table "config.follow_up_status" */
export type Config_Follow_Up_Status_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.follow_up_status" */
export type Config_Follow_Up_Status_On_Conflict = {
  constraint: Config_Follow_Up_Status_Constraint;
  update_columns?: Array<Config_Follow_Up_Status_Update_Column>;
  where?: InputMaybe<Config_Follow_Up_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.follow_up_status". */
export type Config_Follow_Up_Status_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.follow_up_status */
export type Config_Follow_Up_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.follow_up_status" */
export enum Config_Follow_Up_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.follow_up_status" */
export type Config_Follow_Up_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_follow_up_status" */
export type Config_Follow_Up_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Follow_Up_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Follow_Up_Status_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.follow_up_status" */
export enum Config_Follow_Up_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Follow_Up_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Follow_Up_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Follow_Up_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.form_consultation_prescription_status". All fields are combined with a logical 'AND'. */
export type Config_Form_Consultation_Prescription_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Form_Consultation_Prescription_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Form_Consultation_Prescription_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Form_Consultation_Prescription_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.form_consultation_prescription_status" */
export enum Config_Form_Consultation_Prescription_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  FormConsultationPrescriptionStatusPkey = 'form_consultation_prescription_status_pkey'
}

export enum Config_Form_Consultation_Prescription_Status_Enum {
  AwaitingInformation = 'AWAITING_INFORMATION',
  Issued = 'ISSUED',
  NotApplicable = 'NOT_APPLICABLE',
  NotIssued = 'NOT_ISSUED',
  ReadyForAnalysis = 'READY_FOR_ANALYSIS'
}

/** Boolean expression to compare columns of type "config_form_consultation_prescription_status_enum". All fields are combined with logical 'AND'. */
export type Config_Form_Consultation_Prescription_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Form_Consultation_Prescription_Status_Enum>;
  _in?: InputMaybe<Array<Config_Form_Consultation_Prescription_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Form_Consultation_Prescription_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Form_Consultation_Prescription_Status_Enum>>;
};

/** input type for inserting data into table "config.form_consultation_prescription_status" */
export type Config_Form_Consultation_Prescription_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.form_consultation_prescription_status" */
export type Config_Form_Consultation_Prescription_Status_On_Conflict = {
  constraint: Config_Form_Consultation_Prescription_Status_Constraint;
  update_columns?: Array<Config_Form_Consultation_Prescription_Status_Update_Column>;
  where?: InputMaybe<Config_Form_Consultation_Prescription_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.form_consultation_prescription_status". */
export type Config_Form_Consultation_Prescription_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.form_consultation_prescription_status */
export type Config_Form_Consultation_Prescription_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.form_consultation_prescription_status" */
export enum Config_Form_Consultation_Prescription_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.form_consultation_prescription_status" */
export type Config_Form_Consultation_Prescription_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_form_consultation_prescription_status" */
export type Config_Form_Consultation_Prescription_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Form_Consultation_Prescription_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Form_Consultation_Prescription_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.form_consultation_prescription_status" */
export enum Config_Form_Consultation_Prescription_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Form_Consultation_Prescription_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Form_Consultation_Prescription_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Form_Consultation_Prescription_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.gap_status". All fields are combined with a logical 'AND'. */
export type Config_Gap_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Gap_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Gap_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Gap_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.gap_status" */
export enum Config_Gap_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  GapStatusPkey = 'gap_status_pkey'
}

export enum Config_Gap_Status_Enum {
  Done = 'DONE',
  None = 'NONE',
  Todo = 'TODO',
  ToBeSigned = 'TO_BE_SIGNED'
}

/** Boolean expression to compare columns of type "config_gap_status_enum". All fields are combined with logical 'AND'. */
export type Config_Gap_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Gap_Status_Enum>;
  _in?: InputMaybe<Array<Config_Gap_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Gap_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Gap_Status_Enum>>;
};

/** input type for inserting data into table "config.gap_status" */
export type Config_Gap_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.gap_status" */
export type Config_Gap_Status_On_Conflict = {
  constraint: Config_Gap_Status_Constraint;
  update_columns?: Array<Config_Gap_Status_Update_Column>;
  where?: InputMaybe<Config_Gap_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.gap_status". */
export type Config_Gap_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.gap_status */
export type Config_Gap_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.gap_status" */
export enum Config_Gap_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.gap_status" */
export type Config_Gap_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_gap_status" */
export type Config_Gap_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Gap_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Gap_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.gap_status" */
export enum Config_Gap_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Gap_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Gap_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Gap_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.identity_verification_method". All fields are combined with a logical 'AND'. */
export type Config_Identity_Verification_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Identity_Verification_Method_Bool_Exp>>;
  _not?: InputMaybe<Config_Identity_Verification_Method_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Identity_Verification_Method_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.identity_verification_method" */
export enum Config_Identity_Verification_Method_Constraint {
  /** unique or primary key constraint on columns "value" */
  IdentityVerificationMethodPkey = 'identity_verification_method_pkey'
}

export enum Config_Identity_Verification_Method_Enum {
  /** Identity verified using driver's license */
  DriversLicense = 'DRIVERS_LICENSE',
  /** Identity verified during introduction call */
  IntroductionCall = 'INTRODUCTION_CALL',
  /** Identity verified using passport */
  Passport = 'PASSPORT'
}

/** Boolean expression to compare columns of type "config_identity_verification_method_enum". All fields are combined with logical 'AND'. */
export type Config_Identity_Verification_Method_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Identity_Verification_Method_Enum>;
  _in?: InputMaybe<Array<Config_Identity_Verification_Method_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Identity_Verification_Method_Enum>;
  _nin?: InputMaybe<Array<Config_Identity_Verification_Method_Enum>>;
};

/** input type for inserting data into table "config.identity_verification_method" */
export type Config_Identity_Verification_Method_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.identity_verification_method" */
export type Config_Identity_Verification_Method_On_Conflict = {
  constraint: Config_Identity_Verification_Method_Constraint;
  update_columns?: Array<Config_Identity_Verification_Method_Update_Column>;
  where?: InputMaybe<Config_Identity_Verification_Method_Bool_Exp>;
};

/** Ordering options when selecting data from "config.identity_verification_method". */
export type Config_Identity_Verification_Method_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.identity_verification_method */
export type Config_Identity_Verification_Method_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.identity_verification_method" */
export enum Config_Identity_Verification_Method_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.identity_verification_method" */
export type Config_Identity_Verification_Method_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_identity_verification_method" */
export type Config_Identity_Verification_Method_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Identity_Verification_Method_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Identity_Verification_Method_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.identity_verification_method" */
export enum Config_Identity_Verification_Method_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Identity_Verification_Method_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Identity_Verification_Method_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Identity_Verification_Method_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.insurance_status". All fields are combined with a logical 'AND'. */
export type Config_Insurance_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Insurance_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Insurance_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Insurance_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.insurance_status" */
export enum Config_Insurance_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  PkB4bbdc66d8e9151c9a8c310e0db = 'PK_b4bbdc66d8e9151c9a8c310e0db'
}

export enum Config_Insurance_Status_Enum {
  /** The insurance was processed successfully */
  Accepted = 'ACCEPTED',
  /** The insurance has been archived */
  Archived = 'ARCHIVED',
  /** The insurance has been cancelled by the client */
  Cancelled = 'CANCELLED',
  /** Initial status */
  Pending = 'PENDING',
  /** The insurance is being processed */
  Processing = 'PROCESSING',
  /** The insurance was refused */
  Refused = 'REFUSED'
}

/** Boolean expression to compare columns of type "config_insurance_status_enum". All fields are combined with logical 'AND'. */
export type Config_Insurance_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Insurance_Status_Enum>;
  _in?: InputMaybe<Array<Config_Insurance_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Insurance_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Insurance_Status_Enum>>;
};

/** input type for inserting data into table "config.insurance_status" */
export type Config_Insurance_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.insurance_status" */
export type Config_Insurance_Status_Obj_Rel_Insert_Input = {
  data: Config_Insurance_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Insurance_Status_On_Conflict>;
};

/** on_conflict condition type for table "config.insurance_status" */
export type Config_Insurance_Status_On_Conflict = {
  constraint: Config_Insurance_Status_Constraint;
  update_columns?: Array<Config_Insurance_Status_Update_Column>;
  where?: InputMaybe<Config_Insurance_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.insurance_status". */
export type Config_Insurance_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.insurance_status */
export type Config_Insurance_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.insurance_status" */
export enum Config_Insurance_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.insurance_status" */
export type Config_Insurance_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_insurance_status" */
export type Config_Insurance_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Insurance_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Insurance_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.insurance_status" */
export enum Config_Insurance_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Insurance_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Insurance_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Insurance_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.invoice_adjustment_code". All fields are combined with a logical 'AND'. */
export type Config_Invoice_Adjustment_Code_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Invoice_Adjustment_Code_Bool_Exp>>;
  _not?: InputMaybe<Config_Invoice_Adjustment_Code_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Invoice_Adjustment_Code_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.invoice_adjustment_code" */
export enum Config_Invoice_Adjustment_Code_Constraint {
  /** unique or primary key constraint on columns "value" */
  InvoiceAdjustmentCodePkey = 'invoice_adjustment_code_pkey'
}

export enum Config_Invoice_Adjustment_Code_Enum {
  Accounting = 'ACCOUNTING',
  Devon = 'DEVON',
  Reproduction = 'REPRODUCTION'
}

/** Boolean expression to compare columns of type "config_invoice_adjustment_code_enum". All fields are combined with logical 'AND'. */
export type Config_Invoice_Adjustment_Code_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Invoice_Adjustment_Code_Enum>;
  _in?: InputMaybe<Array<Config_Invoice_Adjustment_Code_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Invoice_Adjustment_Code_Enum>;
  _nin?: InputMaybe<Array<Config_Invoice_Adjustment_Code_Enum>>;
};

/** input type for inserting data into table "config.invoice_adjustment_code" */
export type Config_Invoice_Adjustment_Code_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.invoice_adjustment_code" */
export type Config_Invoice_Adjustment_Code_On_Conflict = {
  constraint: Config_Invoice_Adjustment_Code_Constraint;
  update_columns?: Array<Config_Invoice_Adjustment_Code_Update_Column>;
  where?: InputMaybe<Config_Invoice_Adjustment_Code_Bool_Exp>;
};

/** Ordering options when selecting data from "config.invoice_adjustment_code". */
export type Config_Invoice_Adjustment_Code_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.invoice_adjustment_code */
export type Config_Invoice_Adjustment_Code_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.invoice_adjustment_code" */
export enum Config_Invoice_Adjustment_Code_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.invoice_adjustment_code" */
export type Config_Invoice_Adjustment_Code_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_invoice_adjustment_code" */
export type Config_Invoice_Adjustment_Code_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Invoice_Adjustment_Code_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Invoice_Adjustment_Code_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.invoice_adjustment_code" */
export enum Config_Invoice_Adjustment_Code_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Invoice_Adjustment_Code_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Invoice_Adjustment_Code_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Invoice_Adjustment_Code_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.invoice_status". All fields are combined with a logical 'AND'. */
export type Config_Invoice_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Invoice_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Invoice_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Invoice_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.invoice_status" */
export enum Config_Invoice_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  PkA6e99e6c7163b770c21a885d5bc = 'PK_a6e99e6c7163b770c21a885d5bc'
}

export enum Config_Invoice_Status_Enum {
  /** The invoice will be paid, but later */
  OnHold = 'ON_HOLD',
  /** The payments on this invoice exceed its amount */
  Overpaid = 'OVERPAID',
  /** The invoice is fully paid */
  Paid = 'PAID',
  /** There was an error when processing the payment */
  PaymentError = 'PAYMENT_ERROR',
  /** Initial status */
  Pending = 'PENDING',
  /** The payment is currently being processed */
  Processing = 'PROCESSING'
}

/** Boolean expression to compare columns of type "config_invoice_status_enum". All fields are combined with logical 'AND'. */
export type Config_Invoice_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Invoice_Status_Enum>;
  _in?: InputMaybe<Array<Config_Invoice_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Invoice_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Invoice_Status_Enum>>;
};

/** input type for inserting data into table "config.invoice_status" */
export type Config_Invoice_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.invoice_status" */
export type Config_Invoice_Status_Obj_Rel_Insert_Input = {
  data: Config_Invoice_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Invoice_Status_On_Conflict>;
};

/** on_conflict condition type for table "config.invoice_status" */
export type Config_Invoice_Status_On_Conflict = {
  constraint: Config_Invoice_Status_Constraint;
  update_columns?: Array<Config_Invoice_Status_Update_Column>;
  where?: InputMaybe<Config_Invoice_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.invoice_status". */
export type Config_Invoice_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.invoice_status */
export type Config_Invoice_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.invoice_status" */
export enum Config_Invoice_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.invoice_status" */
export type Config_Invoice_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_invoice_status" */
export type Config_Invoice_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Invoice_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Invoice_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.invoice_status" */
export enum Config_Invoice_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Invoice_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Invoice_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Invoice_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.invoice_type". All fields are combined with a logical 'AND'. */
export type Config_Invoice_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Invoice_Type_Bool_Exp>>;
  _not?: InputMaybe<Config_Invoice_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Invoice_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.invoice_type" */
export enum Config_Invoice_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  Pk_0b5121fb991e1afb01d556feacc = 'PK_0b5121fb991e1afb01d556feacc'
}

export enum Config_Invoice_Type_Enum {
  /** Invoice with manual amounts and uploaded file */
  Manual = 'MANUAL',
  /** Invoice with line items attached to services */
  Services = 'SERVICES'
}

/** Boolean expression to compare columns of type "config_invoice_type_enum". All fields are combined with logical 'AND'. */
export type Config_Invoice_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Invoice_Type_Enum>;
  _in?: InputMaybe<Array<Config_Invoice_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Invoice_Type_Enum>;
  _nin?: InputMaybe<Array<Config_Invoice_Type_Enum>>;
};

/** input type for inserting data into table "config.invoice_type" */
export type Config_Invoice_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.invoice_type" */
export type Config_Invoice_Type_Obj_Rel_Insert_Input = {
  data: Config_Invoice_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Invoice_Type_On_Conflict>;
};

/** on_conflict condition type for table "config.invoice_type" */
export type Config_Invoice_Type_On_Conflict = {
  constraint: Config_Invoice_Type_Constraint;
  update_columns?: Array<Config_Invoice_Type_Update_Column>;
  where?: InputMaybe<Config_Invoice_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "config.invoice_type". */
export type Config_Invoice_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.invoice_type */
export type Config_Invoice_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.invoice_type" */
export enum Config_Invoice_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.invoice_type" */
export type Config_Invoice_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_invoice_type" */
export type Config_Invoice_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Invoice_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Invoice_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.invoice_type" */
export enum Config_Invoice_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Invoice_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Invoice_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Invoice_Type_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.lab_status". All fields are combined with a logical 'AND'. */
export type Config_Lab_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Lab_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Lab_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Lab_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.lab_status" */
export enum Config_Lab_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  LabStatusPkey = 'lab_status_pkey'
}

export enum Config_Lab_Status_Enum {
  Issued = 'ISSUED',
  None = 'NONE',
  Todo = 'TODO'
}

/** Boolean expression to compare columns of type "config_lab_status_enum". All fields are combined with logical 'AND'. */
export type Config_Lab_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Lab_Status_Enum>;
  _in?: InputMaybe<Array<Config_Lab_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Lab_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Lab_Status_Enum>>;
};

/** input type for inserting data into table "config.lab_status" */
export type Config_Lab_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.lab_status" */
export type Config_Lab_Status_On_Conflict = {
  constraint: Config_Lab_Status_Constraint;
  update_columns?: Array<Config_Lab_Status_Update_Column>;
  where?: InputMaybe<Config_Lab_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.lab_status". */
export type Config_Lab_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.lab_status */
export type Config_Lab_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.lab_status" */
export enum Config_Lab_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.lab_status" */
export type Config_Lab_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_lab_status" */
export type Config_Lab_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Lab_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Lab_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.lab_status" */
export enum Config_Lab_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Lab_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Lab_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Lab_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.locker_provider". All fields are combined with a logical 'AND'. */
export type Config_Locker_Provider_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Locker_Provider_Bool_Exp>>;
  _not?: InputMaybe<Config_Locker_Provider_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Locker_Provider_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.locker_provider" */
export enum Config_Locker_Provider_Constraint {
  /** unique or primary key constraint on columns "value" */
  LockerProviderPkey = 'locker_provider_pkey'
}

export enum Config_Locker_Provider_Enum {
  Expedibox = 'EXPEDIBOX'
}

/** Boolean expression to compare columns of type "config_locker_provider_enum". All fields are combined with logical 'AND'. */
export type Config_Locker_Provider_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Locker_Provider_Enum>;
  _in?: InputMaybe<Array<Config_Locker_Provider_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Locker_Provider_Enum>;
  _nin?: InputMaybe<Array<Config_Locker_Provider_Enum>>;
};

/** input type for inserting data into table "config.locker_provider" */
export type Config_Locker_Provider_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.locker_provider" */
export type Config_Locker_Provider_On_Conflict = {
  constraint: Config_Locker_Provider_Constraint;
  update_columns?: Array<Config_Locker_Provider_Update_Column>;
  where?: InputMaybe<Config_Locker_Provider_Bool_Exp>;
};

/** Ordering options when selecting data from "config.locker_provider". */
export type Config_Locker_Provider_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.locker_provider */
export type Config_Locker_Provider_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.locker_provider" */
export enum Config_Locker_Provider_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.locker_provider" */
export type Config_Locker_Provider_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_locker_provider" */
export type Config_Locker_Provider_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Locker_Provider_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Locker_Provider_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.locker_provider" */
export enum Config_Locker_Provider_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Locker_Provider_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Locker_Provider_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Locker_Provider_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.locker_reservation_status". All fields are combined with a logical 'AND'. */
export type Config_Locker_Reservation_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Locker_Reservation_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Locker_Reservation_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Locker_Reservation_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.locker_reservation_status" */
export enum Config_Locker_Reservation_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  LockerReservationStatusPkey = 'locker_reservation_status_pkey'
}

/** input type for inserting data into table "config.locker_reservation_status" */
export type Config_Locker_Reservation_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.locker_reservation_status" */
export type Config_Locker_Reservation_Status_On_Conflict = {
  constraint: Config_Locker_Reservation_Status_Constraint;
  update_columns?: Array<Config_Locker_Reservation_Status_Update_Column>;
  where?: InputMaybe<Config_Locker_Reservation_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.locker_reservation_status". */
export type Config_Locker_Reservation_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.locker_reservation_status */
export type Config_Locker_Reservation_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.locker_reservation_status" */
export enum Config_Locker_Reservation_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.locker_reservation_status" */
export type Config_Locker_Reservation_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_locker_reservation_status" */
export type Config_Locker_Reservation_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Locker_Reservation_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Locker_Reservation_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.locker_reservation_status" */
export enum Config_Locker_Reservation_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Locker_Reservation_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Locker_Reservation_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Locker_Reservation_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.medication_modification_application". All fields are combined with a logical 'AND'. */
export type Config_Medication_Modification_Application_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Medication_Modification_Application_Bool_Exp>>;
  _not?: InputMaybe<Config_Medication_Modification_Application_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Medication_Modification_Application_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.medication_modification_application" */
export enum Config_Medication_Modification_Application_Constraint {
  /** unique or primary key constraint on columns "value" */
  MedicationModificationApplicationPkey = 'medication_modification_application_pkey'
}

export enum Config_Medication_Modification_Application_Enum {
  AllOrders = 'ALL_ORDERS',
  NextOrder = 'NEXT_ORDER'
}

/** Boolean expression to compare columns of type "config_medication_modification_application_enum". All fields are combined with logical 'AND'. */
export type Config_Medication_Modification_Application_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Medication_Modification_Application_Enum>;
  _in?: InputMaybe<Array<Config_Medication_Modification_Application_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Medication_Modification_Application_Enum>;
  _nin?: InputMaybe<Array<Config_Medication_Modification_Application_Enum>>;
};

/** input type for inserting data into table "config.medication_modification_application" */
export type Config_Medication_Modification_Application_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.medication_modification_application" */
export type Config_Medication_Modification_Application_On_Conflict = {
  constraint: Config_Medication_Modification_Application_Constraint;
  update_columns?: Array<Config_Medication_Modification_Application_Update_Column>;
  where?: InputMaybe<Config_Medication_Modification_Application_Bool_Exp>;
};

/** Ordering options when selecting data from "config.medication_modification_application". */
export type Config_Medication_Modification_Application_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.medication_modification_application */
export type Config_Medication_Modification_Application_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.medication_modification_application" */
export enum Config_Medication_Modification_Application_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.medication_modification_application" */
export type Config_Medication_Modification_Application_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_medication_modification_application" */
export type Config_Medication_Modification_Application_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Medication_Modification_Application_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Medication_Modification_Application_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.medication_modification_application" */
export enum Config_Medication_Modification_Application_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Medication_Modification_Application_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Medication_Modification_Application_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Medication_Modification_Application_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.medication_modification_reason". All fields are combined with a logical 'AND'. */
export type Config_Medication_Modification_Reason_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Medication_Modification_Reason_Bool_Exp>>;
  _not?: InputMaybe<Config_Medication_Modification_Reason_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Medication_Modification_Reason_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.medication_modification_reason" */
export enum Config_Medication_Modification_Reason_Constraint {
  /** unique or primary key constraint on columns "value" */
  MedicationModificationReasonPkey = 'medication_modification_reason_pkey'
}

export enum Config_Medication_Modification_Reason_Enum {
  ChangeDosage = 'CHANGE_DOSAGE',
  ChangePackaging = 'CHANGE_PACKAGING',
  ChangeQuantity = 'CHANGE_QUANTITY',
  ChangeTimeMedicationIsTaken = 'CHANGE_TIME_MEDICATION_IS_TAKEN',
  Other = 'OTHER',
  StopMedication = 'STOP_MEDICATION'
}

/** Boolean expression to compare columns of type "config_medication_modification_reason_enum". All fields are combined with logical 'AND'. */
export type Config_Medication_Modification_Reason_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Medication_Modification_Reason_Enum>;
  _in?: InputMaybe<Array<Config_Medication_Modification_Reason_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Medication_Modification_Reason_Enum>;
  _nin?: InputMaybe<Array<Config_Medication_Modification_Reason_Enum>>;
};

/** input type for inserting data into table "config.medication_modification_reason" */
export type Config_Medication_Modification_Reason_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.medication_modification_reason" */
export type Config_Medication_Modification_Reason_On_Conflict = {
  constraint: Config_Medication_Modification_Reason_Constraint;
  update_columns?: Array<Config_Medication_Modification_Reason_Update_Column>;
  where?: InputMaybe<Config_Medication_Modification_Reason_Bool_Exp>;
};

/** Ordering options when selecting data from "config.medication_modification_reason". */
export type Config_Medication_Modification_Reason_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.medication_modification_reason */
export type Config_Medication_Modification_Reason_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.medication_modification_reason" */
export enum Config_Medication_Modification_Reason_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.medication_modification_reason" */
export type Config_Medication_Modification_Reason_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_medication_modification_reason" */
export type Config_Medication_Modification_Reason_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Medication_Modification_Reason_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Medication_Modification_Reason_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.medication_modification_reason" */
export enum Config_Medication_Modification_Reason_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Medication_Modification_Reason_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Medication_Modification_Reason_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Medication_Modification_Reason_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.medication_packaging". All fields are combined with a logical 'AND'. */
export type Config_Medication_Packaging_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Medication_Packaging_Bool_Exp>>;
  _not?: InputMaybe<Config_Medication_Packaging_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Medication_Packaging_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.medication_packaging" */
export enum Config_Medication_Packaging_Constraint {
  /** unique or primary key constraint on columns "value" */
  Pk_419084e93f0c3ae21daeb00446c = 'PK_419084e93f0c3ae21daeb00446c'
}

export enum Config_Medication_Packaging_Enum {
  Bottle = 'BOTTLE',
  Dispill = 'DISPILL',
  Dose = 'DOSE',
  Original = 'ORIGINAL',
  Other = 'OTHER'
}

/** Boolean expression to compare columns of type "config_medication_packaging_enum". All fields are combined with logical 'AND'. */
export type Config_Medication_Packaging_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Medication_Packaging_Enum>;
  _in?: InputMaybe<Array<Config_Medication_Packaging_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Medication_Packaging_Enum>;
  _nin?: InputMaybe<Array<Config_Medication_Packaging_Enum>>;
};

/** input type for inserting data into table "config.medication_packaging" */
export type Config_Medication_Packaging_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.medication_packaging" */
export type Config_Medication_Packaging_Obj_Rel_Insert_Input = {
  data: Config_Medication_Packaging_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Medication_Packaging_On_Conflict>;
};

/** on_conflict condition type for table "config.medication_packaging" */
export type Config_Medication_Packaging_On_Conflict = {
  constraint: Config_Medication_Packaging_Constraint;
  update_columns?: Array<Config_Medication_Packaging_Update_Column>;
  where?: InputMaybe<Config_Medication_Packaging_Bool_Exp>;
};

/** Ordering options when selecting data from "config.medication_packaging". */
export type Config_Medication_Packaging_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.medication_packaging */
export type Config_Medication_Packaging_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.medication_packaging" */
export enum Config_Medication_Packaging_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.medication_packaging" */
export type Config_Medication_Packaging_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_medication_packaging" */
export type Config_Medication_Packaging_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Medication_Packaging_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Medication_Packaging_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.medication_packaging" */
export enum Config_Medication_Packaging_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Medication_Packaging_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Medication_Packaging_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Medication_Packaging_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.medication_status". All fields are combined with a logical 'AND'. */
export type Config_Medication_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Medication_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Medication_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Medication_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.medication_status" */
export enum Config_Medication_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  Pk_424ecf36844f9ad8cec088a1e2b = 'PK_424ecf36844f9ad8cec088a1e2b'
}

export enum Config_Medication_Status_Enum {
  Active = 'ACTIVE',
  Paused = 'PAUSED'
}

/** Boolean expression to compare columns of type "config_medication_status_enum". All fields are combined with logical 'AND'. */
export type Config_Medication_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Medication_Status_Enum>;
  _in?: InputMaybe<Array<Config_Medication_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Medication_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Medication_Status_Enum>>;
};

/** input type for inserting data into table "config.medication_status" */
export type Config_Medication_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.medication_status" */
export type Config_Medication_Status_Obj_Rel_Insert_Input = {
  data: Config_Medication_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Medication_Status_On_Conflict>;
};

/** on_conflict condition type for table "config.medication_status" */
export type Config_Medication_Status_On_Conflict = {
  constraint: Config_Medication_Status_Constraint;
  update_columns?: Array<Config_Medication_Status_Update_Column>;
  where?: InputMaybe<Config_Medication_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.medication_status". */
export type Config_Medication_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.medication_status */
export type Config_Medication_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.medication_status" */
export enum Config_Medication_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.medication_status" */
export type Config_Medication_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_medication_status" */
export type Config_Medication_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Medication_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Medication_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.medication_status" */
export enum Config_Medication_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Medication_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Medication_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Medication_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.medication_usage". All fields are combined with a logical 'AND'. */
export type Config_Medication_Usage_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Medication_Usage_Bool_Exp>>;
  _not?: InputMaybe<Config_Medication_Usage_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Medication_Usage_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.medication_usage" */
export enum Config_Medication_Usage_Constraint {
  /** unique or primary key constraint on columns "value" */
  Pk_28136831d1827826657f9e0df7e = 'PK_28136831d1827826657f9e0df7e'
}

export enum Config_Medication_Usage_Enum {
  AsNeeded = 'AS_NEEDED',
  Recurring = 'RECURRING'
}

/** Boolean expression to compare columns of type "config_medication_usage_enum". All fields are combined with logical 'AND'. */
export type Config_Medication_Usage_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Medication_Usage_Enum>;
  _in?: InputMaybe<Array<Config_Medication_Usage_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Medication_Usage_Enum>;
  _nin?: InputMaybe<Array<Config_Medication_Usage_Enum>>;
};

/** input type for inserting data into table "config.medication_usage" */
export type Config_Medication_Usage_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.medication_usage" */
export type Config_Medication_Usage_Obj_Rel_Insert_Input = {
  data: Config_Medication_Usage_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Medication_Usage_On_Conflict>;
};

/** on_conflict condition type for table "config.medication_usage" */
export type Config_Medication_Usage_On_Conflict = {
  constraint: Config_Medication_Usage_Constraint;
  update_columns?: Array<Config_Medication_Usage_Update_Column>;
  where?: InputMaybe<Config_Medication_Usage_Bool_Exp>;
};

/** Ordering options when selecting data from "config.medication_usage". */
export type Config_Medication_Usage_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.medication_usage */
export type Config_Medication_Usage_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.medication_usage" */
export enum Config_Medication_Usage_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.medication_usage" */
export type Config_Medication_Usage_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_medication_usage" */
export type Config_Medication_Usage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Medication_Usage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Medication_Usage_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.medication_usage" */
export enum Config_Medication_Usage_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Medication_Usage_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Medication_Usage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Medication_Usage_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.no_upcoming_order_reasons". All fields are combined with a logical 'AND'. */
export type Config_No_Upcoming_Order_Reasons_Bool_Exp = {
  _and?: InputMaybe<Array<Config_No_Upcoming_Order_Reasons_Bool_Exp>>;
  _not?: InputMaybe<Config_No_Upcoming_Order_Reasons_Bool_Exp>;
  _or?: InputMaybe<Array<Config_No_Upcoming_Order_Reasons_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.no_upcoming_order_reasons" */
export enum Config_No_Upcoming_Order_Reasons_Constraint {
  /** unique or primary key constraint on columns "value" */
  NoUpcomingOrderReasonsPkey = 'no_upcoming_order_reasons_pkey'
}

export enum Config_No_Upcoming_Order_Reasons_Enum {
  NoChronicRx = 'NO_CHRONIC_RX',
  NoFutureRx = 'NO_FUTURE_RX',
  NoRx = 'NO_RX',
  TransferOut = 'TRANSFER_OUT'
}

/** Boolean expression to compare columns of type "config_no_upcoming_order_reasons_enum". All fields are combined with logical 'AND'. */
export type Config_No_Upcoming_Order_Reasons_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_No_Upcoming_Order_Reasons_Enum>;
  _in?: InputMaybe<Array<Config_No_Upcoming_Order_Reasons_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_No_Upcoming_Order_Reasons_Enum>;
  _nin?: InputMaybe<Array<Config_No_Upcoming_Order_Reasons_Enum>>;
};

/** input type for inserting data into table "config.no_upcoming_order_reasons" */
export type Config_No_Upcoming_Order_Reasons_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.no_upcoming_order_reasons" */
export type Config_No_Upcoming_Order_Reasons_On_Conflict = {
  constraint: Config_No_Upcoming_Order_Reasons_Constraint;
  update_columns?: Array<Config_No_Upcoming_Order_Reasons_Update_Column>;
  where?: InputMaybe<Config_No_Upcoming_Order_Reasons_Bool_Exp>;
};

/** Ordering options when selecting data from "config.no_upcoming_order_reasons". */
export type Config_No_Upcoming_Order_Reasons_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.no_upcoming_order_reasons */
export type Config_No_Upcoming_Order_Reasons_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.no_upcoming_order_reasons" */
export enum Config_No_Upcoming_Order_Reasons_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.no_upcoming_order_reasons" */
export type Config_No_Upcoming_Order_Reasons_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_no_upcoming_order_reasons" */
export type Config_No_Upcoming_Order_Reasons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_No_Upcoming_Order_Reasons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_No_Upcoming_Order_Reasons_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.no_upcoming_order_reasons" */
export enum Config_No_Upcoming_Order_Reasons_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_No_Upcoming_Order_Reasons_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_No_Upcoming_Order_Reasons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_No_Upcoming_Order_Reasons_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.note_type". All fields are combined with a logical 'AND'. */
export type Config_Note_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Note_Type_Bool_Exp>>;
  _not?: InputMaybe<Config_Note_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Note_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.note_type" */
export enum Config_Note_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  Pk_2348bd4b805d9b5087ecf2c0b23 = 'PK_2348bd4b805d9b5087ecf2c0b23'
}

export enum Config_Note_Type_Enum {
  AutonomousReason = 'AUTONOMOUS_REASON',
  DependentReason = 'DEPENDENT_REASON',
  DropReason = 'DROP_REASON',
  Note = 'NOTE'
}

/** Boolean expression to compare columns of type "config_note_type_enum". All fields are combined with logical 'AND'. */
export type Config_Note_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Note_Type_Enum>;
  _in?: InputMaybe<Array<Config_Note_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Note_Type_Enum>;
  _nin?: InputMaybe<Array<Config_Note_Type_Enum>>;
};

/** input type for inserting data into table "config.note_type" */
export type Config_Note_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.note_type" */
export type Config_Note_Type_On_Conflict = {
  constraint: Config_Note_Type_Constraint;
  update_columns?: Array<Config_Note_Type_Update_Column>;
  where?: InputMaybe<Config_Note_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "config.note_type". */
export type Config_Note_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.note_type */
export type Config_Note_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.note_type" */
export enum Config_Note_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.note_type" */
export type Config_Note_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_note_type" */
export type Config_Note_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Note_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Note_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.note_type" */
export enum Config_Note_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Note_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Note_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Note_Type_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.notification_scope". All fields are combined with a logical 'AND'. */
export type Config_Notification_Scope_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Notification_Scope_Bool_Exp>>;
  _not?: InputMaybe<Config_Notification_Scope_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Notification_Scope_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.notification_scope" */
export enum Config_Notification_Scope_Constraint {
  /** unique or primary key constraint on columns "value" */
  NotificationScopePkey = 'notification_scope_pkey'
}

export enum Config_Notification_Scope_Enum {
  /** Notification scoped to the Account tab/menu-item */
  Account = 'ACCOUNT',
  /** Notification scoped to the Follow-ups tab/menu-item */
  FollowUps = 'FOLLOW_UPS',
  /** Notification scoped to the Home tab/menu-item */
  Home = 'HOME',
  /** Notification scoped to the Medication tab/menu-item */
  Medication = 'MEDICATION',
  /** Notification scoped to the Messages tab/menu-item */
  Messages = 'MESSAGES',
  /** Notification scoped to the Orders tab/menu-item */
  Orders = 'ORDERS'
}

/** Boolean expression to compare columns of type "config_notification_scope_enum". All fields are combined with logical 'AND'. */
export type Config_Notification_Scope_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Notification_Scope_Enum>;
  _in?: InputMaybe<Array<Config_Notification_Scope_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Notification_Scope_Enum>;
  _nin?: InputMaybe<Array<Config_Notification_Scope_Enum>>;
};

/** input type for inserting data into table "config.notification_scope" */
export type Config_Notification_Scope_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.notification_scope" */
export type Config_Notification_Scope_On_Conflict = {
  constraint: Config_Notification_Scope_Constraint;
  update_columns?: Array<Config_Notification_Scope_Update_Column>;
  where?: InputMaybe<Config_Notification_Scope_Bool_Exp>;
};

/** Ordering options when selecting data from "config.notification_scope". */
export type Config_Notification_Scope_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.notification_scope */
export type Config_Notification_Scope_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.notification_scope" */
export enum Config_Notification_Scope_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.notification_scope" */
export type Config_Notification_Scope_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_notification_scope" */
export type Config_Notification_Scope_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Notification_Scope_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Notification_Scope_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.notification_scope" */
export enum Config_Notification_Scope_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Notification_Scope_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Notification_Scope_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Notification_Scope_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.order_flag". All fields are combined with a logical 'AND'. */
export type Config_Order_Flag_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Order_Flag_Bool_Exp>>;
  _not?: InputMaybe<Config_Order_Flag_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Order_Flag_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.order_flag" */
export enum Config_Order_Flag_Constraint {
  /** unique or primary key constraint on columns "value" */
  OrderFlagPkey = 'order_flag_pkey'
}

export enum Config_Order_Flag_Enum {
  ContactedForConfirmation = 'CONTACTED_FOR_CONFIRMATION',
  EvaluationRequired = 'EVALUATION_REQUIRED',
  InsuranceError = 'INSURANCE_ERROR',
  MissingProduct = 'MISSING_PRODUCT',
  RenewalTooEarly = 'RENEWAL_TOO_EARLY',
  WaitingOnClient = 'WAITING_ON_CLIENT',
  WaitingOnPharmacist = 'WAITING_ON_PHARMACIST',
  WaitingOnPrescription = 'WAITING_ON_PRESCRIPTION'
}

/** Boolean expression to compare columns of type "config_order_flag_enum". All fields are combined with logical 'AND'. */
export type Config_Order_Flag_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Order_Flag_Enum>;
  _in?: InputMaybe<Array<Config_Order_Flag_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Order_Flag_Enum>;
  _nin?: InputMaybe<Array<Config_Order_Flag_Enum>>;
};

/** input type for inserting data into table "config.order_flag" */
export type Config_Order_Flag_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.order_flag" */
export type Config_Order_Flag_On_Conflict = {
  constraint: Config_Order_Flag_Constraint;
  update_columns?: Array<Config_Order_Flag_Update_Column>;
  where?: InputMaybe<Config_Order_Flag_Bool_Exp>;
};

/** Ordering options when selecting data from "config.order_flag". */
export type Config_Order_Flag_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.order_flag */
export type Config_Order_Flag_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.order_flag" */
export enum Config_Order_Flag_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.order_flag" */
export type Config_Order_Flag_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_order_flag" */
export type Config_Order_Flag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Order_Flag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Order_Flag_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.order_flag" */
export enum Config_Order_Flag_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Order_Flag_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Order_Flag_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Order_Flag_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.order_status". All fields are combined with a logical 'AND'. */
export type Config_Order_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Order_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Order_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Order_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.order_status" */
export enum Config_Order_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  PkA4d751fcdb8bcefec203501c11f = 'PK_a4d751fcdb8bcefec203501c11f'
}

export enum Config_Order_Status_Enum {
  /** The order was cancelled by an admin */
  AdminCancelled = 'ADMIN_CANCELLED',
  /** This is to flag the orders that patients have not paid yet to avoid delivery */
  AsynchronousOrder = 'ASYNCHRONOUS_ORDER',
  /** The order was cancelled by the customer */
  ClientCancelled = 'CLIENT_CANCELLED',
  /** The order has been confirmed with the client */
  Confirmed = 'CONFIRMED',
  /** Initial status */
  Pending = 'PENDING',
  /** The order is prepared to be sent */
  Prepared = 'PREPARED'
}

/** Boolean expression to compare columns of type "config_order_status_enum". All fields are combined with logical 'AND'. */
export type Config_Order_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Order_Status_Enum>;
  _in?: InputMaybe<Array<Config_Order_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Order_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Order_Status_Enum>>;
};

/** input type for inserting data into table "config.order_status" */
export type Config_Order_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.order_status" */
export type Config_Order_Status_Obj_Rel_Insert_Input = {
  data: Config_Order_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Order_Status_On_Conflict>;
};

/** on_conflict condition type for table "config.order_status" */
export type Config_Order_Status_On_Conflict = {
  constraint: Config_Order_Status_Constraint;
  update_columns?: Array<Config_Order_Status_Update_Column>;
  where?: InputMaybe<Config_Order_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.order_status". */
export type Config_Order_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.order_status */
export type Config_Order_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.order_status" */
export enum Config_Order_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.order_status" */
export type Config_Order_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_order_status" */
export type Config_Order_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Order_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Order_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.order_status" */
export enum Config_Order_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Order_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Order_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Order_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.outbound_fax_send_status". All fields are combined with a logical 'AND'. */
export type Config_Outbound_Fax_Send_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Outbound_Fax_Send_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Outbound_Fax_Send_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Outbound_Fax_Send_Status_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.outbound_fax_send_status" */
export enum Config_Outbound_Fax_Send_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  PkB4b3ea8934a72f9700162e8d646 = 'PK_b4b3ea8934a72f9700162e8d646'
}

export enum Config_Outbound_Fax_Send_Status_Enum {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Sent = 'SENT'
}

/** Boolean expression to compare columns of type "config_outbound_fax_send_status_enum". All fields are combined with logical 'AND'. */
export type Config_Outbound_Fax_Send_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Outbound_Fax_Send_Status_Enum>;
  _in?: InputMaybe<Array<Config_Outbound_Fax_Send_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Outbound_Fax_Send_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Outbound_Fax_Send_Status_Enum>>;
};

/** input type for inserting data into table "config.outbound_fax_send_status" */
export type Config_Outbound_Fax_Send_Status_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.outbound_fax_send_status" */
export type Config_Outbound_Fax_Send_Status_On_Conflict = {
  constraint: Config_Outbound_Fax_Send_Status_Constraint;
  update_columns?: Array<Config_Outbound_Fax_Send_Status_Update_Column>;
  where?: InputMaybe<Config_Outbound_Fax_Send_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.outbound_fax_send_status". */
export type Config_Outbound_Fax_Send_Status_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.outbound_fax_send_status */
export type Config_Outbound_Fax_Send_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.outbound_fax_send_status" */
export enum Config_Outbound_Fax_Send_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.outbound_fax_send_status" */
export type Config_Outbound_Fax_Send_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_outbound_fax_send_status" */
export type Config_Outbound_Fax_Send_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Outbound_Fax_Send_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Outbound_Fax_Send_Status_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.outbound_fax_send_status" */
export enum Config_Outbound_Fax_Send_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Outbound_Fax_Send_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Outbound_Fax_Send_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Outbound_Fax_Send_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.pathology". All fields are combined with a logical 'AND'. */
export type Config_Pathology_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Pathology_Bool_Exp>>;
  _not?: InputMaybe<Config_Pathology_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Pathology_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.pathology" */
export enum Config_Pathology_Constraint {
  /** unique or primary key constraint on columns "value" */
  PathologyPkey = 'pathology_pkey'
}

export enum Config_Pathology_Enum {
  AnxietyDepression = 'ANXIETY_DEPRESSION',
  Asthma = 'ASTHMA',
  AttentionDeficitDisorder = 'ATTENTION_DEFICIT_DISORDER',
  ChronicObstructivePulmonaryDisease = 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
  ChronicPain = 'CHRONIC_PAIN',
  Diabetes = 'DIABETES',
  Dyslipidemia = 'DYSLIPIDEMIA',
  Hypertension = 'HYPERTENSION',
  Hypothyroidism = 'HYPOTHYROIDISM',
  Migraine = 'MIGRAINE'
}

/** Boolean expression to compare columns of type "config_pathology_enum". All fields are combined with logical 'AND'. */
export type Config_Pathology_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Pathology_Enum>;
  _in?: InputMaybe<Array<Config_Pathology_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Pathology_Enum>;
  _nin?: InputMaybe<Array<Config_Pathology_Enum>>;
};

/** input type for inserting data into table "config.pathology" */
export type Config_Pathology_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.pathology" */
export type Config_Pathology_On_Conflict = {
  constraint: Config_Pathology_Constraint;
  update_columns?: Array<Config_Pathology_Update_Column>;
  where?: InputMaybe<Config_Pathology_Bool_Exp>;
};

/** Ordering options when selecting data from "config.pathology". */
export type Config_Pathology_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.pathology */
export type Config_Pathology_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.pathology" */
export enum Config_Pathology_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.pathology" */
export type Config_Pathology_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_pathology" */
export type Config_Pathology_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Pathology_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Pathology_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.pathology" */
export enum Config_Pathology_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Pathology_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Pathology_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Pathology_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.patient_status". All fields are combined with a logical 'AND'. */
export type Config_Patient_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Patient_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Patient_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Patient_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.patient_status" */
export enum Config_Patient_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  PkA636142912ae24a4ee30ec078b1 = 'PK_a636142912ae24a4ee30ec078b1'
}

export enum Config_Patient_Status_Enum {
  /** Has given us at least one contact information (phone or email) but hasn't yet converted. */
  Anonymous = 'ANONYMOUS',
  /** All people that have completed the signup process and the onboarding process but have no prescription to their account. */
  Complete = 'COMPLETE',
  /** Has been permanently deleted. */
  Deleted = 'DELETED',
  /** Denotes a patient who does not directly access Medzy and whose orders are managed by someone else as part of a group account (e.g. family account) */
  Dependent = 'DEPENDENT',
  /** All people that have EXPLICITLY said they were not interested in our service. */
  Drop = 'DROP',
  /** All people that have completed the signup process but have not completed the onboarding process. */
  Onboarding = 'ONBOARDING',
  /** Is not a patient yet */
  Prospect = 'PROSPECT',
  /** All people that have submitted the first step of the signup but have not completed it. */
  Registration = 'REGISTRATION'
}

/** Boolean expression to compare columns of type "config_patient_status_enum". All fields are combined with logical 'AND'. */
export type Config_Patient_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Patient_Status_Enum>;
  _in?: InputMaybe<Array<Config_Patient_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Patient_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Patient_Status_Enum>>;
};

/** input type for inserting data into table "config.patient_status" */
export type Config_Patient_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.patient_status" */
export type Config_Patient_Status_Obj_Rel_Insert_Input = {
  data: Config_Patient_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Patient_Status_On_Conflict>;
};

/** on_conflict condition type for table "config.patient_status" */
export type Config_Patient_Status_On_Conflict = {
  constraint: Config_Patient_Status_Constraint;
  update_columns?: Array<Config_Patient_Status_Update_Column>;
  where?: InputMaybe<Config_Patient_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.patient_status". */
export type Config_Patient_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.patient_status */
export type Config_Patient_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.patient_status" */
export enum Config_Patient_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.patient_status" */
export type Config_Patient_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_patient_status" */
export type Config_Patient_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Patient_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Patient_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.patient_status" */
export enum Config_Patient_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Patient_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Patient_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Patient_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.physical_prescription_return_strategy". All fields are combined with a logical 'AND'. */
export type Config_Physical_Prescription_Return_Strategy_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Physical_Prescription_Return_Strategy_Bool_Exp>>;
  _not?: InputMaybe<Config_Physical_Prescription_Return_Strategy_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Physical_Prescription_Return_Strategy_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.physical_prescription_return_strategy" */
export enum Config_Physical_Prescription_Return_Strategy_Constraint {
  /** unique or primary key constraint on columns "value" */
  PhysicalPrescriptionReturnStrategyPkey = 'physical_prescription_return_strategy_pkey'
}

export enum Config_Physical_Prescription_Return_Strategy_Enum {
  NotApplicable = 'NOT_APPLICABLE',
  StandAlone = 'STAND_ALONE',
  WithOrder = 'WITH_ORDER'
}

/** Boolean expression to compare columns of type "config_physical_prescription_return_strategy_enum". All fields are combined with logical 'AND'. */
export type Config_Physical_Prescription_Return_Strategy_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Physical_Prescription_Return_Strategy_Enum>;
  _in?: InputMaybe<Array<Config_Physical_Prescription_Return_Strategy_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Physical_Prescription_Return_Strategy_Enum>;
  _nin?: InputMaybe<Array<Config_Physical_Prescription_Return_Strategy_Enum>>;
};

/** input type for inserting data into table "config.physical_prescription_return_strategy" */
export type Config_Physical_Prescription_Return_Strategy_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.physical_prescription_return_strategy" */
export type Config_Physical_Prescription_Return_Strategy_On_Conflict = {
  constraint: Config_Physical_Prescription_Return_Strategy_Constraint;
  update_columns?: Array<Config_Physical_Prescription_Return_Strategy_Update_Column>;
  where?: InputMaybe<Config_Physical_Prescription_Return_Strategy_Bool_Exp>;
};

/** Ordering options when selecting data from "config.physical_prescription_return_strategy". */
export type Config_Physical_Prescription_Return_Strategy_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.physical_prescription_return_strategy */
export type Config_Physical_Prescription_Return_Strategy_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.physical_prescription_return_strategy" */
export enum Config_Physical_Prescription_Return_Strategy_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.physical_prescription_return_strategy" */
export type Config_Physical_Prescription_Return_Strategy_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_physical_prescription_return_strategy" */
export type Config_Physical_Prescription_Return_Strategy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Physical_Prescription_Return_Strategy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Physical_Prescription_Return_Strategy_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.physical_prescription_return_strategy" */
export enum Config_Physical_Prescription_Return_Strategy_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Physical_Prescription_Return_Strategy_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Physical_Prescription_Return_Strategy_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Physical_Prescription_Return_Strategy_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.prescription_renewal_request_item_status". All fields are combined with a logical 'AND'. */
export type Config_Prescription_Renewal_Request_Item_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Prescription_Renewal_Request_Item_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Prescription_Renewal_Request_Item_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Prescription_Renewal_Request_Item_Status_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.prescription_renewal_request_item_status" */
export enum Config_Prescription_Renewal_Request_Item_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  PrescriptionRenewalRequestItemStatusPkey = 'prescription_renewal_request_item_status_pkey'
}

export enum Config_Prescription_Renewal_Request_Item_Status_Enum {
  InProgress = 'IN_PROGRESS',
  NotSent = 'NOT_SENT',
  Refused = 'REFUSED',
  Renewed = 'RENEWED'
}

/** Boolean expression to compare columns of type "config_prescription_renewal_request_item_status_enum". All fields are combined with logical 'AND'. */
export type Config_Prescription_Renewal_Request_Item_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Prescription_Renewal_Request_Item_Status_Enum>;
  _in?: InputMaybe<Array<Config_Prescription_Renewal_Request_Item_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Prescription_Renewal_Request_Item_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Prescription_Renewal_Request_Item_Status_Enum>>;
};

/** input type for inserting data into table "config.prescription_renewal_request_item_status" */
export type Config_Prescription_Renewal_Request_Item_Status_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.prescription_renewal_request_item_status" */
export type Config_Prescription_Renewal_Request_Item_Status_On_Conflict = {
  constraint: Config_Prescription_Renewal_Request_Item_Status_Constraint;
  update_columns?: Array<Config_Prescription_Renewal_Request_Item_Status_Update_Column>;
  where?: InputMaybe<Config_Prescription_Renewal_Request_Item_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.prescription_renewal_request_item_status". */
export type Config_Prescription_Renewal_Request_Item_Status_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.prescription_renewal_request_item_status */
export type Config_Prescription_Renewal_Request_Item_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.prescription_renewal_request_item_status" */
export enum Config_Prescription_Renewal_Request_Item_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.prescription_renewal_request_item_status" */
export type Config_Prescription_Renewal_Request_Item_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_prescription_renewal_request_item_status" */
export type Config_Prescription_Renewal_Request_Item_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Prescription_Renewal_Request_Item_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Prescription_Renewal_Request_Item_Status_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.prescription_renewal_request_item_status" */
export enum Config_Prescription_Renewal_Request_Item_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Prescription_Renewal_Request_Item_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Prescription_Renewal_Request_Item_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Prescription_Renewal_Request_Item_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.price_quote_status". All fields are combined with a logical 'AND'. */
export type Config_Price_Quote_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Price_Quote_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Price_Quote_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Price_Quote_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.price_quote_status" */
export enum Config_Price_Quote_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  Pk_8643fdc6274a83f380654a93cde = 'PK_8643fdc6274a83f380654a93cde'
}

export enum Config_Price_Quote_Status_Enum {
  /** The quote was dismissed */
  Drop = 'DROP',
  /** The quote is being worked on */
  InProgress = 'IN_PROGRESS',
  /** Initial status */
  New = 'NEW',
  /** Initial status (deprecated) */
  Pending = 'PENDING',
  /** The quote has been sent */
  Sent = 'SENT'
}

/** Boolean expression to compare columns of type "config_price_quote_status_enum". All fields are combined with logical 'AND'. */
export type Config_Price_Quote_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Price_Quote_Status_Enum>;
  _in?: InputMaybe<Array<Config_Price_Quote_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Price_Quote_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Price_Quote_Status_Enum>>;
};

/** input type for inserting data into table "config.price_quote_status" */
export type Config_Price_Quote_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.price_quote_status" */
export type Config_Price_Quote_Status_Obj_Rel_Insert_Input = {
  data: Config_Price_Quote_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Price_Quote_Status_On_Conflict>;
};

/** on_conflict condition type for table "config.price_quote_status" */
export type Config_Price_Quote_Status_On_Conflict = {
  constraint: Config_Price_Quote_Status_Constraint;
  update_columns?: Array<Config_Price_Quote_Status_Update_Column>;
  where?: InputMaybe<Config_Price_Quote_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.price_quote_status". */
export type Config_Price_Quote_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.price_quote_status */
export type Config_Price_Quote_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.price_quote_status" */
export enum Config_Price_Quote_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.price_quote_status" */
export type Config_Price_Quote_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_price_quote_status" */
export type Config_Price_Quote_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Price_Quote_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Price_Quote_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.price_quote_status" */
export enum Config_Price_Quote_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Price_Quote_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Price_Quote_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Price_Quote_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.program". All fields are combined with a logical 'AND'. */
export type Config_Program_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Program_Bool_Exp>>;
  _not?: InputMaybe<Config_Program_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Program_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.program" */
export enum Config_Program_Constraint {
  /** unique or primary key constraint on columns "value" */
  ProgramPkey = 'program_pkey'
}

export enum Config_Program_Enum {
  AllergicConjunctivitis = 'ALLERGIC_CONJUNCTIVITIS',
  AllergicRhinitis = 'ALLERGIC_RHINITIS',
  B2B = 'B2B',
  BirthControl = 'BIRTH_CONTROL',
  ColdSores = 'COLD_SORES',
  Eczema = 'ECZEMA',
  EmergencyContraception = 'EMERGENCY_CONTRACEPTION',
  Expedibox = 'EXPEDIBOX',
  GastroesophagealReflux = 'GASTROESOPHAGEAL_REFLUX',
  Hemorrhoids = 'HEMORRHOIDS',
  Malaria = 'MALARIA',
  MenstrualCramps = 'MENSTRUAL_CRAMPS',
  MinorAcne = 'MINOR_ACNE',
  NauseauAndVomitingDuringPregnancy = 'NAUSEAU_AND_VOMITING_DURING_PREGNANCY',
  NauseaAndVomiting = 'NAUSEA_AND_VOMITING',
  PatientCare = 'PATIENT_CARE',
  PharmacyChoice = 'PHARMACY_CHOICE',
  PrenatalVitamins = 'PRENATAL_VITAMINS',
  PspObesite = 'PSP_OBESITE',
  QuitSmoking = 'QUIT_SMOKING',
  RxRenewal = 'RX_RENEWAL',
  TravellersDiarrhea = 'TRAVELLERS_DIARRHEA',
  UrinaryTractInfection = 'URINARY_TRACT_INFECTION',
  Vaginitis = 'VAGINITIS'
}

/** Boolean expression to compare columns of type "config_program_enum". All fields are combined with logical 'AND'. */
export type Config_Program_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Program_Enum>;
  _in?: InputMaybe<Array<Config_Program_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Program_Enum>;
  _nin?: InputMaybe<Array<Config_Program_Enum>>;
};

/** input type for inserting data into table "config.program" */
export type Config_Program_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.program" */
export type Config_Program_On_Conflict = {
  constraint: Config_Program_Constraint;
  update_columns?: Array<Config_Program_Update_Column>;
  where?: InputMaybe<Config_Program_Bool_Exp>;
};

/** Ordering options when selecting data from "config.program". */
export type Config_Program_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.program */
export type Config_Program_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.program" */
export enum Config_Program_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.program" */
export type Config_Program_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_program" */
export type Config_Program_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Program_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Program_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.program" */
export enum Config_Program_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Program_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Program_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Program_Bool_Exp;
};

/** order by aggregate values of table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Aggregate_Order_By = {
  avg?: InputMaybe<Config_Region_Shipment_Rate_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Config_Region_Shipment_Rate_Max_Order_By>;
  min?: InputMaybe<Config_Region_Shipment_Rate_Min_Order_By>;
  stddev?: InputMaybe<Config_Region_Shipment_Rate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Config_Region_Shipment_Rate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Config_Region_Shipment_Rate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Config_Region_Shipment_Rate_Sum_Order_By>;
  var_pop?: InputMaybe<Config_Region_Shipment_Rate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Config_Region_Shipment_Rate_Var_Samp_Order_By>;
  variance?: InputMaybe<Config_Region_Shipment_Rate_Variance_Order_By>;
};

/** order by avg() on columns of table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Avg_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "config.region_shipment_rate". All fields are combined with a logical 'AND'. */
export type Config_Region_Shipment_Rate_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Region_Shipment_Rate_Bool_Exp>>;
  _not?: InputMaybe<Config_Region_Shipment_Rate_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Region_Shipment_Rate_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  priority?: InputMaybe<Float_Comparison_Exp>;
  rate?: InputMaybe<Config_Shipment_Rate_Code_Enum_Comparison_Exp>;
  region_code?: InputMaybe<String_Comparison_Exp>;
  shipment_rate_details?: InputMaybe<Config_Shipment_Rate_Details_Bool_Exp>;
};

/** unique or primary key constraints on table "config.region_shipment_rate" */
export enum Config_Region_Shipment_Rate_Constraint {
  /** unique or primary key constraint on columns "id" */
  RegionShipmentRatePkey = 'region_shipment_rate_pkey'
}

/** input type for incrementing numeric columns in table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Inc_Input = {
  priority?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Float']>;
  rate?: InputMaybe<Config_Shipment_Rate_Code_Enum>;
  /** FSA or postal code */
  region_code?: InputMaybe<Scalars['String']>;
  shipment_rate_details?: InputMaybe<Config_Shipment_Rate_Details_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  /** FSA or postal code */
  region_code?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  /** FSA or postal code */
  region_code?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Obj_Rel_Insert_Input = {
  data: Config_Region_Shipment_Rate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Region_Shipment_Rate_On_Conflict>;
};

/** on_conflict condition type for table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_On_Conflict = {
  constraint: Config_Region_Shipment_Rate_Constraint;
  update_columns?: Array<Config_Region_Shipment_Rate_Update_Column>;
  where?: InputMaybe<Config_Region_Shipment_Rate_Bool_Exp>;
};

/** Ordering options when selecting data from "config.region_shipment_rate". */
export type Config_Region_Shipment_Rate_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  region_code?: InputMaybe<Order_By>;
  shipment_rate_details?: InputMaybe<Config_Shipment_Rate_Details_Order_By>;
};

/** primary key columns input for table: config.region_shipment_rate */
export type Config_Region_Shipment_Rate_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "config.region_shipment_rate" */
export enum Config_Region_Shipment_Rate_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Rate = 'rate',
  /** column name */
  RegionCode = 'region_code'
}

/** input type for updating data in table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Float']>;
  rate?: InputMaybe<Config_Shipment_Rate_Code_Enum>;
  /** FSA or postal code */
  region_code?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Stddev_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Stddev_Pop_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Stddev_Samp_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "config_region_shipment_rate" */
export type Config_Region_Shipment_Rate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Region_Shipment_Rate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Region_Shipment_Rate_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Float']>;
  rate?: InputMaybe<Config_Shipment_Rate_Code_Enum>;
  /** FSA or postal code */
  region_code?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Sum_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** update columns of table "config.region_shipment_rate" */
export enum Config_Region_Shipment_Rate_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Rate = 'rate',
  /** column name */
  RegionCode = 'region_code'
}

export type Config_Region_Shipment_Rate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Config_Region_Shipment_Rate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Region_Shipment_Rate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Region_Shipment_Rate_Bool_Exp;
};

/** order by var_pop() on columns of table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Var_Pop_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Var_Samp_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "config.region_shipment_rate" */
export type Config_Region_Shipment_Rate_Variance_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "config.relationship_type". All fields are combined with a logical 'AND'. */
export type Config_Relationship_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Relationship_Type_Bool_Exp>>;
  _not?: InputMaybe<Config_Relationship_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Relationship_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.relationship_type" */
export enum Config_Relationship_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  RelationshipTypePkey = 'relationship_type_pkey'
}

export enum Config_Relationship_Type_Enum {
  BusinessClient = 'BUSINESS_CLIENT',
  Child = 'CHILD',
  Other = 'OTHER',
  Parent = 'PARENT',
  Sibling = 'SIBLING',
  Spouse = 'SPOUSE'
}

/** Boolean expression to compare columns of type "config_relationship_type_enum". All fields are combined with logical 'AND'. */
export type Config_Relationship_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Relationship_Type_Enum>;
  _in?: InputMaybe<Array<Config_Relationship_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Relationship_Type_Enum>;
  _nin?: InputMaybe<Array<Config_Relationship_Type_Enum>>;
};

/** input type for inserting data into table "config.relationship_type" */
export type Config_Relationship_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.relationship_type" */
export type Config_Relationship_Type_On_Conflict = {
  constraint: Config_Relationship_Type_Constraint;
  update_columns?: Array<Config_Relationship_Type_Update_Column>;
  where?: InputMaybe<Config_Relationship_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "config.relationship_type". */
export type Config_Relationship_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.relationship_type */
export type Config_Relationship_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.relationship_type" */
export enum Config_Relationship_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.relationship_type" */
export type Config_Relationship_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_relationship_type" */
export type Config_Relationship_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Relationship_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Relationship_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.relationship_type" */
export enum Config_Relationship_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Relationship_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Relationship_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Relationship_Type_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.renewal_batch". All fields are combined with a logical 'AND'. */
export type Config_Renewal_Batch_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Renewal_Batch_Bool_Exp>>;
  _not?: InputMaybe<Config_Renewal_Batch_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Renewal_Batch_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.renewal_batch" */
export enum Config_Renewal_Batch_Constraint {
  /** unique or primary key constraint on columns "value" */
  RenewalSchedulePkey = 'renewal_schedule_pkey'
}

export enum Config_Renewal_Batch_Enum {
  F28S1 = 'F28_S1',
  F28S2 = 'F28_S2',
  F28S3 = 'F28_S3',
  F28S4 = 'F28_S4',
  F56S1 = 'F56_S1',
  F56S2 = 'F56_S2',
  F56S3 = 'F56_S3',
  F56S4 = 'F56_S4',
  F56S5 = 'F56_S5',
  F56S6 = 'F56_S6',
  F56S7 = 'F56_S7',
  F56S8 = 'F56_S8',
  F84S1 = 'F84_S1',
  F84S10 = 'F84_S10',
  F84S11 = 'F84_S11',
  F84S12 = 'F84_S12',
  F84S2 = 'F84_S2',
  F84S3 = 'F84_S3',
  F84S4 = 'F84_S4',
  F84S5 = 'F84_S5',
  F84S6 = 'F84_S6',
  F84S7 = 'F84_S7',
  F84S8 = 'F84_S8',
  F84S9 = 'F84_S9'
}

/** Boolean expression to compare columns of type "config_renewal_batch_enum". All fields are combined with logical 'AND'. */
export type Config_Renewal_Batch_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Renewal_Batch_Enum>;
  _in?: InputMaybe<Array<Config_Renewal_Batch_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Renewal_Batch_Enum>;
  _nin?: InputMaybe<Array<Config_Renewal_Batch_Enum>>;
};

/** input type for inserting data into table "config.renewal_batch" */
export type Config_Renewal_Batch_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.renewal_batch" */
export type Config_Renewal_Batch_On_Conflict = {
  constraint: Config_Renewal_Batch_Constraint;
  update_columns?: Array<Config_Renewal_Batch_Update_Column>;
  where?: InputMaybe<Config_Renewal_Batch_Bool_Exp>;
};

/** Ordering options when selecting data from "config.renewal_batch". */
export type Config_Renewal_Batch_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.renewal_batch */
export type Config_Renewal_Batch_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.renewal_batch" */
export enum Config_Renewal_Batch_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.renewal_batch" */
export type Config_Renewal_Batch_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_renewal_batch" */
export type Config_Renewal_Batch_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Renewal_Batch_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Renewal_Batch_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.renewal_batch" */
export enum Config_Renewal_Batch_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Renewal_Batch_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Renewal_Batch_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Renewal_Batch_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.renewal_schedule". All fields are combined with a logical 'AND'. */
export type Config_Renewal_Schedule_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Renewal_Schedule_Bool_Exp>>;
  _not?: InputMaybe<Config_Renewal_Schedule_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Renewal_Schedule_Bool_Exp>>;
  batch?: InputMaybe<Config_Renewal_Batch_Enum_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  offset_in_days?: InputMaybe<Int_Comparison_Exp>;
  period_in_days?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.renewal_schedule" */
export enum Config_Renewal_Schedule_Constraint {
  /** unique or primary key constraint on columns "id" */
  RenewalSchedulePkey1 = 'renewal_schedule_pkey1'
}

/** input type for incrementing numeric columns in table "config.renewal_schedule" */
export type Config_Renewal_Schedule_Inc_Input = {
  offset_in_days?: InputMaybe<Scalars['Int']>;
  period_in_days?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "config.renewal_schedule" */
export type Config_Renewal_Schedule_Insert_Input = {
  batch?: InputMaybe<Config_Renewal_Batch_Enum>;
  id?: InputMaybe<Scalars['String']>;
  offset_in_days?: InputMaybe<Scalars['Int']>;
  period_in_days?: InputMaybe<Scalars['Int']>;
};

/** on_conflict condition type for table "config.renewal_schedule" */
export type Config_Renewal_Schedule_On_Conflict = {
  constraint: Config_Renewal_Schedule_Constraint;
  update_columns?: Array<Config_Renewal_Schedule_Update_Column>;
  where?: InputMaybe<Config_Renewal_Schedule_Bool_Exp>;
};

/** Ordering options when selecting data from "config.renewal_schedule". */
export type Config_Renewal_Schedule_Order_By = {
  batch?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offset_in_days?: InputMaybe<Order_By>;
  period_in_days?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.renewal_schedule */
export type Config_Renewal_Schedule_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "config.renewal_schedule" */
export enum Config_Renewal_Schedule_Select_Column {
  /** column name */
  Batch = 'batch',
  /** column name */
  Id = 'id',
  /** column name */
  OffsetInDays = 'offset_in_days',
  /** column name */
  PeriodInDays = 'period_in_days'
}

/** input type for updating data in table "config.renewal_schedule" */
export type Config_Renewal_Schedule_Set_Input = {
  batch?: InputMaybe<Config_Renewal_Batch_Enum>;
  id?: InputMaybe<Scalars['String']>;
  offset_in_days?: InputMaybe<Scalars['Int']>;
  period_in_days?: InputMaybe<Scalars['Int']>;
};

/** Boolean expression to filter rows from the table "config.renewal_schedule_settings". All fields are combined with a logical 'AND'. */
export type Config_Renewal_Schedule_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Renewal_Schedule_Settings_Bool_Exp>>;
  _not?: InputMaybe<Config_Renewal_Schedule_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Renewal_Schedule_Settings_Bool_Exp>>;
  offset_in_days?: InputMaybe<Int_Comparison_Exp>;
  period_in_days?: InputMaybe<Int_Comparison_Exp>;
  renewal_schedule?: InputMaybe<String_Comparison_Exp>;
  starting_date?: InputMaybe<Date_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.renewal_schedule_settings" */
export enum Config_Renewal_Schedule_Settings_Constraint {
  /** unique or primary key constraint on columns "renewal_schedule" */
  RenewalScheduleSettingsPkey = 'renewal_schedule_settings_pkey'
}

/** input type for incrementing numeric columns in table "config.renewal_schedule_settings" */
export type Config_Renewal_Schedule_Settings_Inc_Input = {
  offset_in_days?: InputMaybe<Scalars['Int']>;
  period_in_days?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "config.renewal_schedule_settings" */
export type Config_Renewal_Schedule_Settings_Insert_Input = {
  offset_in_days?: InputMaybe<Scalars['Int']>;
  period_in_days?: InputMaybe<Scalars['Int']>;
  renewal_schedule?: InputMaybe<Scalars['String']>;
  starting_date?: InputMaybe<Scalars['date']>;
};

/** on_conflict condition type for table "config.renewal_schedule_settings" */
export type Config_Renewal_Schedule_Settings_On_Conflict = {
  constraint: Config_Renewal_Schedule_Settings_Constraint;
  update_columns?: Array<Config_Renewal_Schedule_Settings_Update_Column>;
  where?: InputMaybe<Config_Renewal_Schedule_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "config.renewal_schedule_settings". */
export type Config_Renewal_Schedule_Settings_Order_By = {
  offset_in_days?: InputMaybe<Order_By>;
  period_in_days?: InputMaybe<Order_By>;
  renewal_schedule?: InputMaybe<Order_By>;
  starting_date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.renewal_schedule_settings */
export type Config_Renewal_Schedule_Settings_Pk_Columns_Input = {
  renewal_schedule: Scalars['String'];
};

/** select columns of table "config.renewal_schedule_settings" */
export enum Config_Renewal_Schedule_Settings_Select_Column {
  /** column name */
  OffsetInDays = 'offset_in_days',
  /** column name */
  PeriodInDays = 'period_in_days',
  /** column name */
  RenewalSchedule = 'renewal_schedule',
  /** column name */
  StartingDate = 'starting_date'
}

/** input type for updating data in table "config.renewal_schedule_settings" */
export type Config_Renewal_Schedule_Settings_Set_Input = {
  offset_in_days?: InputMaybe<Scalars['Int']>;
  period_in_days?: InputMaybe<Scalars['Int']>;
  renewal_schedule?: InputMaybe<Scalars['String']>;
  starting_date?: InputMaybe<Scalars['date']>;
};

/** Streaming cursor of the table "config_renewal_schedule_settings" */
export type Config_Renewal_Schedule_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Renewal_Schedule_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Renewal_Schedule_Settings_Stream_Cursor_Value_Input = {
  offset_in_days?: InputMaybe<Scalars['Int']>;
  period_in_days?: InputMaybe<Scalars['Int']>;
  renewal_schedule?: InputMaybe<Scalars['String']>;
  starting_date?: InputMaybe<Scalars['date']>;
};

/** update columns of table "config.renewal_schedule_settings" */
export enum Config_Renewal_Schedule_Settings_Update_Column {
  /** column name */
  OffsetInDays = 'offset_in_days',
  /** column name */
  PeriodInDays = 'period_in_days',
  /** column name */
  RenewalSchedule = 'renewal_schedule',
  /** column name */
  StartingDate = 'starting_date'
}

export type Config_Renewal_Schedule_Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Config_Renewal_Schedule_Settings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Renewal_Schedule_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Renewal_Schedule_Settings_Bool_Exp;
};

/** Streaming cursor of the table "config_renewal_schedule" */
export type Config_Renewal_Schedule_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Renewal_Schedule_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Renewal_Schedule_Stream_Cursor_Value_Input = {
  batch?: InputMaybe<Config_Renewal_Batch_Enum>;
  id?: InputMaybe<Scalars['String']>;
  offset_in_days?: InputMaybe<Scalars['Int']>;
  period_in_days?: InputMaybe<Scalars['Int']>;
};

/** update columns of table "config.renewal_schedule" */
export enum Config_Renewal_Schedule_Update_Column {
  /** column name */
  Batch = 'batch',
  /** column name */
  Id = 'id',
  /** column name */
  OffsetInDays = 'offset_in_days',
  /** column name */
  PeriodInDays = 'period_in_days'
}

export type Config_Renewal_Schedule_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Config_Renewal_Schedule_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Renewal_Schedule_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Renewal_Schedule_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.service_request_status". All fields are combined with a logical 'AND'. */
export type Config_Service_Request_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Service_Request_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Service_Request_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Service_Request_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.service_request_status" */
export enum Config_Service_Request_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  Pk_4d708c44b49f420c86f230c2ce1 = 'PK_4d708c44b49f420c86f230c2ce1'
}

export enum Config_Service_Request_Status_Enum {
  /** Request has been accepted */
  Accepted = 'ACCEPTED',
  /** Cancelled by the client */
  Cancelled = 'CANCELLED',
  /** Initial status */
  Pending = 'PENDING',
  /** Request has been rejected */
  Rejected = 'REJECTED'
}

/** Boolean expression to compare columns of type "config_service_request_status_enum". All fields are combined with logical 'AND'. */
export type Config_Service_Request_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Service_Request_Status_Enum>;
  _in?: InputMaybe<Array<Config_Service_Request_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Service_Request_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Service_Request_Status_Enum>>;
};

/** input type for inserting data into table "config.service_request_status" */
export type Config_Service_Request_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.service_request_status" */
export type Config_Service_Request_Status_Obj_Rel_Insert_Input = {
  data: Config_Service_Request_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Service_Request_Status_On_Conflict>;
};

/** on_conflict condition type for table "config.service_request_status" */
export type Config_Service_Request_Status_On_Conflict = {
  constraint: Config_Service_Request_Status_Constraint;
  update_columns?: Array<Config_Service_Request_Status_Update_Column>;
  where?: InputMaybe<Config_Service_Request_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.service_request_status". */
export type Config_Service_Request_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.service_request_status */
export type Config_Service_Request_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.service_request_status" */
export enum Config_Service_Request_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.service_request_status" */
export type Config_Service_Request_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_service_request_status" */
export type Config_Service_Request_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Service_Request_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Service_Request_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.service_request_status" */
export enum Config_Service_Request_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Service_Request_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Service_Request_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Service_Request_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.service_sync_din_blacklist". All fields are combined with a logical 'AND'. */
export type Config_Service_Sync_Din_Blacklist_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Service_Sync_Din_Blacklist_Bool_Exp>>;
  _not?: InputMaybe<Config_Service_Sync_Din_Blacklist_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Service_Sync_Din_Blacklist_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  din?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.service_sync_din_blacklist" */
export enum Config_Service_Sync_Din_Blacklist_Constraint {
  /** unique or primary key constraint on columns "din" */
  ServiceSyncDinBlacklistDinKey = 'service_sync_din_blacklist_din_key',
  /** unique or primary key constraint on columns "id" */
  ServiceSyncDinBlacklistPkey = 'service_sync_din_blacklist_pkey'
}

/** input type for inserting data into table "config.service_sync_din_blacklist" */
export type Config_Service_Sync_Din_Blacklist_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  din?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** on_conflict condition type for table "config.service_sync_din_blacklist" */
export type Config_Service_Sync_Din_Blacklist_On_Conflict = {
  constraint: Config_Service_Sync_Din_Blacklist_Constraint;
  update_columns?: Array<Config_Service_Sync_Din_Blacklist_Update_Column>;
  where?: InputMaybe<Config_Service_Sync_Din_Blacklist_Bool_Exp>;
};

/** Ordering options when selecting data from "config.service_sync_din_blacklist". */
export type Config_Service_Sync_Din_Blacklist_Order_By = {
  description?: InputMaybe<Order_By>;
  din?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.service_sync_din_blacklist */
export type Config_Service_Sync_Din_Blacklist_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "config.service_sync_din_blacklist" */
export enum Config_Service_Sync_Din_Blacklist_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Din = 'din',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "config.service_sync_din_blacklist" */
export type Config_Service_Sync_Din_Blacklist_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  din?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "config_service_sync_din_blacklist" */
export type Config_Service_Sync_Din_Blacklist_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Service_Sync_Din_Blacklist_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Service_Sync_Din_Blacklist_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  din?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "config.service_sync_din_blacklist" */
export enum Config_Service_Sync_Din_Blacklist_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Din = 'din',
  /** column name */
  Id = 'id'
}

export type Config_Service_Sync_Din_Blacklist_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Service_Sync_Din_Blacklist_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Service_Sync_Din_Blacklist_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.service_type". All fields are combined with a logical 'AND'. */
export type Config_Service_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Service_Type_Bool_Exp>>;
  _not?: InputMaybe<Config_Service_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Service_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.service_type" */
export enum Config_Service_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  PkC9f2a58b6246fe146c905d85d5b = 'PK_c9f2a58b6246fe146c905d85d5b'
}

export enum Config_Service_Type_Enum {
  /** Not supported by the Medzy webapp and hidden all through the website. */
  Act = 'ACT',
  /** Supported by the Medzy webapp and shown all through the website. */
  Product = 'PRODUCT'
}

/** Boolean expression to compare columns of type "config_service_type_enum". All fields are combined with logical 'AND'. */
export type Config_Service_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Service_Type_Enum>;
  _in?: InputMaybe<Array<Config_Service_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Service_Type_Enum>;
  _nin?: InputMaybe<Array<Config_Service_Type_Enum>>;
};

/** input type for inserting data into table "config.service_type" */
export type Config_Service_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.service_type" */
export type Config_Service_Type_Obj_Rel_Insert_Input = {
  data: Config_Service_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Service_Type_On_Conflict>;
};

/** on_conflict condition type for table "config.service_type" */
export type Config_Service_Type_On_Conflict = {
  constraint: Config_Service_Type_Constraint;
  update_columns?: Array<Config_Service_Type_Update_Column>;
  where?: InputMaybe<Config_Service_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "config.service_type". */
export type Config_Service_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.service_type */
export type Config_Service_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.service_type" */
export enum Config_Service_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.service_type" */
export type Config_Service_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_service_type" */
export type Config_Service_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Service_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Service_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.service_type" */
export enum Config_Service_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Service_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Service_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Service_Type_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.sex". All fields are combined with a logical 'AND'. */
export type Config_Sex_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Sex_Bool_Exp>>;
  _not?: InputMaybe<Config_Sex_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Sex_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.sex" */
export enum Config_Sex_Constraint {
  /** unique or primary key constraint on columns "value" */
  SexPkey = 'sex_pkey'
}

export enum Config_Sex_Enum {
  Female = 'FEMALE',
  Male = 'MALE'
}

/** Boolean expression to compare columns of type "config_sex_enum". All fields are combined with logical 'AND'. */
export type Config_Sex_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Sex_Enum>;
  _in?: InputMaybe<Array<Config_Sex_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Sex_Enum>;
  _nin?: InputMaybe<Array<Config_Sex_Enum>>;
};

/** input type for inserting data into table "config.sex" */
export type Config_Sex_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.sex" */
export type Config_Sex_On_Conflict = {
  constraint: Config_Sex_Constraint;
  update_columns?: Array<Config_Sex_Update_Column>;
  where?: InputMaybe<Config_Sex_Bool_Exp>;
};

/** Ordering options when selecting data from "config.sex". */
export type Config_Sex_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.sex */
export type Config_Sex_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.sex" */
export enum Config_Sex_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.sex" */
export type Config_Sex_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_sex" */
export type Config_Sex_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Sex_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Sex_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.sex" */
export enum Config_Sex_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Sex_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Sex_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Sex_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.shipment_delay_type". All fields are combined with a logical 'AND'. */
export type Config_Shipment_Delay_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Shipment_Delay_Type_Bool_Exp>>;
  _not?: InputMaybe<Config_Shipment_Delay_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Shipment_Delay_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.shipment_delay_type" */
export enum Config_Shipment_Delay_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  ShipmentDelayTypePkey = 'shipment_delay_type_pkey'
}

export enum Config_Shipment_Delay_Type_Enum {
  BusinessDays = 'BUSINESS_DAYS',
  CalendarDays = 'CALENDAR_DAYS'
}

/** Boolean expression to compare columns of type "config_shipment_delay_type_enum". All fields are combined with logical 'AND'. */
export type Config_Shipment_Delay_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Shipment_Delay_Type_Enum>;
  _in?: InputMaybe<Array<Config_Shipment_Delay_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Shipment_Delay_Type_Enum>;
  _nin?: InputMaybe<Array<Config_Shipment_Delay_Type_Enum>>;
};

/** input type for inserting data into table "config.shipment_delay_type" */
export type Config_Shipment_Delay_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.shipment_delay_type" */
export type Config_Shipment_Delay_Type_On_Conflict = {
  constraint: Config_Shipment_Delay_Type_Constraint;
  update_columns?: Array<Config_Shipment_Delay_Type_Update_Column>;
  where?: InputMaybe<Config_Shipment_Delay_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "config.shipment_delay_type". */
export type Config_Shipment_Delay_Type_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.shipment_delay_type */
export type Config_Shipment_Delay_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.shipment_delay_type" */
export enum Config_Shipment_Delay_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.shipment_delay_type" */
export type Config_Shipment_Delay_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_shipment_delay_type" */
export type Config_Shipment_Delay_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Shipment_Delay_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Shipment_Delay_Type_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.shipment_delay_type" */
export enum Config_Shipment_Delay_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Shipment_Delay_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Shipment_Delay_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Shipment_Delay_Type_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.shipment_handoff_strategy". All fields are combined with a logical 'AND'. */
export type Config_Shipment_Handoff_Strategy_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Shipment_Handoff_Strategy_Bool_Exp>>;
  _not?: InputMaybe<Config_Shipment_Handoff_Strategy_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Shipment_Handoff_Strategy_Bool_Exp>>;
  strategy?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.shipment_handoff_strategy" */
export enum Config_Shipment_Handoff_Strategy_Constraint {
  /** unique or primary key constraint on columns "strategy" */
  ShipmentHandoffStrategyPkey = 'shipment_handoff_strategy_pkey'
}

export enum Config_Shipment_Handoff_Strategy_Enum {
  SafeDropAllowed = 'SAFE_DROP_ALLOWED',
  SignatureRequired = 'SIGNATURE_REQUIRED',
  Unknown = 'UNKNOWN'
}

/** Boolean expression to compare columns of type "config_shipment_handoff_strategy_enum". All fields are combined with logical 'AND'. */
export type Config_Shipment_Handoff_Strategy_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Shipment_Handoff_Strategy_Enum>;
  _in?: InputMaybe<Array<Config_Shipment_Handoff_Strategy_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Shipment_Handoff_Strategy_Enum>;
  _nin?: InputMaybe<Array<Config_Shipment_Handoff_Strategy_Enum>>;
};

/** input type for inserting data into table "config.shipment_handoff_strategy" */
export type Config_Shipment_Handoff_Strategy_Insert_Input = {
  strategy?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.shipment_handoff_strategy" */
export type Config_Shipment_Handoff_Strategy_On_Conflict = {
  constraint: Config_Shipment_Handoff_Strategy_Constraint;
  update_columns?: Array<Config_Shipment_Handoff_Strategy_Update_Column>;
  where?: InputMaybe<Config_Shipment_Handoff_Strategy_Bool_Exp>;
};

/** Ordering options when selecting data from "config.shipment_handoff_strategy". */
export type Config_Shipment_Handoff_Strategy_Order_By = {
  strategy?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.shipment_handoff_strategy */
export type Config_Shipment_Handoff_Strategy_Pk_Columns_Input = {
  strategy: Scalars['String'];
};

/** select columns of table "config.shipment_handoff_strategy" */
export enum Config_Shipment_Handoff_Strategy_Select_Column {
  /** column name */
  Strategy = 'strategy'
}

/** input type for updating data in table "config.shipment_handoff_strategy" */
export type Config_Shipment_Handoff_Strategy_Set_Input = {
  strategy?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_shipment_handoff_strategy" */
export type Config_Shipment_Handoff_Strategy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Shipment_Handoff_Strategy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Shipment_Handoff_Strategy_Stream_Cursor_Value_Input = {
  strategy?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.shipment_handoff_strategy" */
export enum Config_Shipment_Handoff_Strategy_Update_Column {
  /** column name */
  Strategy = 'strategy'
}

export type Config_Shipment_Handoff_Strategy_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Shipment_Handoff_Strategy_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Shipment_Handoff_Strategy_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.shipment_integration". All fields are combined with a logical 'AND'. */
export type Config_Shipment_Integration_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Shipment_Integration_Bool_Exp>>;
  _not?: InputMaybe<Config_Shipment_Integration_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Shipment_Integration_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.shipment_integration" */
export enum Config_Shipment_Integration_Constraint {
  /** unique or primary key constraint on columns "value" */
  Pk_3cf030eabfc2efe357ede83ef7d = 'PK_3cf030eabfc2efe357ede83ef7d'
}

export enum Config_Shipment_Integration_Enum {
  Intelcom = 'INTELCOM',
  /** Shipments and orders whose shipping modality was lost to time */
  Legacy = 'LEGACY',
  /** Shipments fulfilled by the pharmacy */
  Manual = 'MANUAL',
  ShipEngine = 'SHIP_ENGINE',
  Unknown = 'UNKNOWN',
  Vaistat = 'VAISTAT',
  Xpedigo = 'XPEDIGO'
}

/** Boolean expression to compare columns of type "config_shipment_integration_enum". All fields are combined with logical 'AND'. */
export type Config_Shipment_Integration_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Shipment_Integration_Enum>;
  _in?: InputMaybe<Array<Config_Shipment_Integration_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Shipment_Integration_Enum>;
  _nin?: InputMaybe<Array<Config_Shipment_Integration_Enum>>;
};

/** input type for inserting data into table "config.shipment_integration" */
export type Config_Shipment_Integration_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.shipment_integration" */
export type Config_Shipment_Integration_Obj_Rel_Insert_Input = {
  data: Config_Shipment_Integration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Shipment_Integration_On_Conflict>;
};

/** on_conflict condition type for table "config.shipment_integration" */
export type Config_Shipment_Integration_On_Conflict = {
  constraint: Config_Shipment_Integration_Constraint;
  update_columns?: Array<Config_Shipment_Integration_Update_Column>;
  where?: InputMaybe<Config_Shipment_Integration_Bool_Exp>;
};

/** Ordering options when selecting data from "config.shipment_integration". */
export type Config_Shipment_Integration_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.shipment_integration */
export type Config_Shipment_Integration_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.shipment_integration" */
export enum Config_Shipment_Integration_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.shipment_integration" */
export type Config_Shipment_Integration_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_shipment_integration" */
export type Config_Shipment_Integration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Shipment_Integration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Shipment_Integration_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.shipment_integration" */
export enum Config_Shipment_Integration_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Shipment_Integration_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Shipment_Integration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Shipment_Integration_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.shipment_provider". All fields are combined with a logical 'AND'. */
export type Config_Shipment_Provider_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Shipment_Provider_Bool_Exp>>;
  _not?: InputMaybe<Config_Shipment_Provider_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Shipment_Provider_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  shipment_provider_settings?: InputMaybe<Config_Shipment_Provider_Settings_Bool_Exp>;
  shipment_provider_settings_aggregate?: InputMaybe<Config_Shipment_Provider_Settings_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.shipment_provider" */
export enum Config_Shipment_Provider_Constraint {
  /** unique or primary key constraint on columns "value" */
  PkF09ac232fe413394047b35b9f56 = 'PK_f09ac232fe413394047b35b9f56'
}

export enum Config_Shipment_Provider_Enum {
  BoxKnight = 'BOX_KNIGHT',
  CanadaPost = 'CANADA_POST',
  Fedex = 'FEDEX',
  Intelcom = 'INTELCOM',
  /** Shipments and orders whose shipping modality was lost to time */
  Legacy = 'LEGACY',
  Other = 'OTHER',
  PharmacyCourrier = 'PHARMACY_COURRIER',
  Purolator = 'PUROLATOR',
  Tecor = 'TECOR',
  Ups = 'UPS',
  Vaistat = 'VAISTAT',
  Xpedigo = 'XPEDIGO'
}

/** Boolean expression to compare columns of type "config_shipment_provider_enum". All fields are combined with logical 'AND'. */
export type Config_Shipment_Provider_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Shipment_Provider_Enum>;
  _in?: InputMaybe<Array<Config_Shipment_Provider_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Shipment_Provider_Enum>;
  _nin?: InputMaybe<Array<Config_Shipment_Provider_Enum>>;
};

/** input type for inserting data into table "config.shipment_provider" */
export type Config_Shipment_Provider_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  shipment_provider_settings?: InputMaybe<Config_Shipment_Provider_Settings_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.shipment_provider" */
export type Config_Shipment_Provider_Obj_Rel_Insert_Input = {
  data: Config_Shipment_Provider_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Shipment_Provider_On_Conflict>;
};

/** on_conflict condition type for table "config.shipment_provider" */
export type Config_Shipment_Provider_On_Conflict = {
  constraint: Config_Shipment_Provider_Constraint;
  update_columns?: Array<Config_Shipment_Provider_Update_Column>;
  where?: InputMaybe<Config_Shipment_Provider_Bool_Exp>;
};

/** Ordering options when selecting data from "config.shipment_provider". */
export type Config_Shipment_Provider_Order_By = {
  comment?: InputMaybe<Order_By>;
  shipment_provider_settings_aggregate?: InputMaybe<Config_Shipment_Provider_Settings_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.shipment_provider */
export type Config_Shipment_Provider_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.shipment_provider" */
export enum Config_Shipment_Provider_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.shipment_provider" */
export type Config_Shipment_Provider_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Config_Shipment_Provider_Settings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Config_Shipment_Provider_Settings_Aggregate_Bool_Exp_Count>;
};

export type Config_Shipment_Provider_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Config_Shipment_Provider_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Config_Shipment_Provider_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Aggregate_Order_By = {
  avg?: InputMaybe<Config_Shipment_Provider_Settings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Config_Shipment_Provider_Settings_Max_Order_By>;
  min?: InputMaybe<Config_Shipment_Provider_Settings_Min_Order_By>;
  stddev?: InputMaybe<Config_Shipment_Provider_Settings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Config_Shipment_Provider_Settings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Config_Shipment_Provider_Settings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Config_Shipment_Provider_Settings_Sum_Order_By>;
  var_pop?: InputMaybe<Config_Shipment_Provider_Settings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Config_Shipment_Provider_Settings_Var_Samp_Order_By>;
  variance?: InputMaybe<Config_Shipment_Provider_Settings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Arr_Rel_Insert_Input = {
  data: Array<Config_Shipment_Provider_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Shipment_Provider_Settings_On_Conflict>;
};

/** order by avg() on columns of table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Avg_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "config.shipment_provider_settings". All fields are combined with a logical 'AND'. */
export type Config_Shipment_Provider_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Shipment_Provider_Settings_Bool_Exp>>;
  _not?: InputMaybe<Config_Shipment_Provider_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Shipment_Provider_Settings_Bool_Exp>>;
  note?: InputMaybe<String_Comparison_Exp>;
  priority?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<Config_Shipment_Provider_Enum_Comparison_Exp>;
  tracking_url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.shipment_provider_settings" */
export enum Config_Shipment_Provider_Settings_Constraint {
  /** unique or primary key constraint on columns "provider" */
  PkAc68685494a94a25ad5c65ea790 = 'PK_ac68685494a94a25ad5c65ea790',
  /** unique or primary key constraint on columns "provider" */
  UqAc68685494a94a25ad5c65ea790 = 'UQ_ac68685494a94a25ad5c65ea790'
}

/** input type for incrementing numeric columns in table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Inc_Input = {
  priority?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Insert_Input = {
  note?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Config_Shipment_Provider_Enum>;
  tracking_url?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Max_Order_By = {
  note?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  tracking_url?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Min_Order_By = {
  note?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  tracking_url?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_On_Conflict = {
  constraint: Config_Shipment_Provider_Settings_Constraint;
  update_columns?: Array<Config_Shipment_Provider_Settings_Update_Column>;
  where?: InputMaybe<Config_Shipment_Provider_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "config.shipment_provider_settings". */
export type Config_Shipment_Provider_Settings_Order_By = {
  note?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  tracking_url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.shipment_provider_settings */
export type Config_Shipment_Provider_Settings_Pk_Columns_Input = {
  provider: Config_Shipment_Provider_Enum;
};

/** select columns of table "config.shipment_provider_settings" */
export enum Config_Shipment_Provider_Settings_Select_Column {
  /** column name */
  Note = 'note',
  /** column name */
  Priority = 'priority',
  /** column name */
  Provider = 'provider',
  /** column name */
  TrackingUrl = 'tracking_url'
}

/** input type for updating data in table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Set_Input = {
  note?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Config_Shipment_Provider_Enum>;
  tracking_url?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Stddev_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Stddev_Pop_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Stddev_Samp_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "config_shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Shipment_Provider_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Shipment_Provider_Settings_Stream_Cursor_Value_Input = {
  note?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Config_Shipment_Provider_Enum>;
  tracking_url?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Sum_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** update columns of table "config.shipment_provider_settings" */
export enum Config_Shipment_Provider_Settings_Update_Column {
  /** column name */
  Note = 'note',
  /** column name */
  Priority = 'priority',
  /** column name */
  Provider = 'provider',
  /** column name */
  TrackingUrl = 'tracking_url'
}

export type Config_Shipment_Provider_Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Config_Shipment_Provider_Settings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Shipment_Provider_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Shipment_Provider_Settings_Bool_Exp;
};

/** order by var_pop() on columns of table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Var_Pop_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Var_Samp_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "config.shipment_provider_settings" */
export type Config_Shipment_Provider_Settings_Variance_Order_By = {
  priority?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "config_shipment_provider" */
export type Config_Shipment_Provider_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Shipment_Provider_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Shipment_Provider_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.shipment_provider" */
export enum Config_Shipment_Provider_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Shipment_Provider_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Shipment_Provider_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Shipment_Provider_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.shipment_rate". All fields are combined with a logical 'AND'. */
export type Config_Shipment_Rate_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Shipment_Rate_Bool_Exp>>;
  _not?: InputMaybe<Config_Shipment_Rate_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Shipment_Rate_Bool_Exp>>;
  booking_cutoff?: InputMaybe<Time_Comparison_Exp>;
  carrier?: InputMaybe<Config_Shipment_Provider_Enum_Comparison_Exp>;
  code?: InputMaybe<Config_Shipment_Rate_Code_Enum_Comparison_Exp>;
  delay?: InputMaybe<Int_Comparison_Exp>;
  delay_type?: InputMaybe<Config_Shipment_Delay_Type_Enum_Comparison_Exp>;
  handoff_strategy?: InputMaybe<Config_Shipment_Handoff_Strategy_Enum_Comparison_Exp>;
  integration?: InputMaybe<Config_Shipment_Integration_Enum_Comparison_Exp>;
  name_en?: InputMaybe<String_Comparison_Exp>;
  name_fr?: InputMaybe<String_Comparison_Exp>;
  shipment_rate_details?: InputMaybe<Config_Shipment_Rate_Code_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "config.shipment_rate_code". All fields are combined with a logical 'AND'. */
export type Config_Shipment_Rate_Code_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Shipment_Rate_Code_Bool_Exp>>;
  _not?: InputMaybe<Config_Shipment_Rate_Code_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Shipment_Rate_Code_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.shipment_rate_code" */
export enum Config_Shipment_Rate_Code_Constraint {
  /** unique or primary key constraint on columns "code" */
  ShipmentRatePkey = 'shipment_rate_pkey'
}

export enum Config_Shipment_Rate_Code_Enum {
  BoxKnightLegacy = 'BOX_KNIGHT_LEGACY',
  CanadaPostFallbackXpresspost_2DaysNoSignature = 'CANADA_POST_FALLBACK_XPRESSPOST_2_DAYS_NO_SIGNATURE',
  CanadaPostFallbackXpresspost_2DaysSignatureRequired = 'CANADA_POST_FALLBACK_XPRESSPOST_2_DAYS_SIGNATURE_REQUIRED',
  CanadaPostLegacy = 'CANADA_POST_LEGACY',
  CanadaPostXpresspost_2DaysNoSignature = 'CANADA_POST_XPRESSPOST_2_DAYS_NO_SIGNATURE',
  CanadaPostXpresspost_2DaysSignatureRequired = 'CANADA_POST_XPRESSPOST_2_DAYS_SIGNATURE_REQUIRED',
  FedexLegacy = 'FEDEX_LEGACY',
  IntelcomLegacy = 'INTELCOM_LEGACY',
  IntelcomNextDayNoSignature = 'INTELCOM_NEXT_DAY_NO_SIGNATURE',
  IntelcomNextDaySignatureRequired = 'INTELCOM_NEXT_DAY_SIGNATURE_REQUIRED',
  InPersonPickup = 'IN_PERSON_PICKUP',
  LegacyLegacy = 'LEGACY_LEGACY',
  Other = 'OTHER',
  OtherLegacy = 'OTHER_LEGACY',
  PharmacyCourierLegacy = 'PHARMACY_COURIER_LEGACY',
  PurolatorExpressNoSignature = 'PUROLATOR_EXPRESS_NO_SIGNATURE',
  PurolatorExpressSignatureRequired = 'PUROLATOR_EXPRESS_SIGNATURE_REQUIRED',
  PurolatorLegacy = 'PUROLATOR_LEGACY',
  TecorLegacy = 'TECOR_LEGACY',
  UpsLegacy = 'UPS_LEGACY',
  VaistatLegacy = 'VAISTAT_LEGACY',
  VaistatManualNextDayNoSignature = 'VAISTAT_MANUAL_NEXT_DAY_NO_SIGNATURE',
  VaistatManualNextDaySignatureRequired = 'VAISTAT_MANUAL_NEXT_DAY_SIGNATURE_REQUIRED',
  VaistatManualSameDayNoSignature = 'VAISTAT_MANUAL_SAME_DAY_NO_SIGNATURE',
  VaistatManualSameDaySignatureRequired = 'VAISTAT_MANUAL_SAME_DAY_SIGNATURE_REQUIRED',
  XpedigoLegacy = 'XPEDIGO_LEGACY',
  XpedigoNextDayNoSignature = 'XPEDIGO_NEXT_DAY_NO_SIGNATURE',
  XpedigoNextDaySignatureRequired = 'XPEDIGO_NEXT_DAY_SIGNATURE_REQUIRED',
  XpedigoSameDayNoSignature = 'XPEDIGO_SAME_DAY_NO_SIGNATURE',
  XpedigoSameDaySignatureRequired = 'XPEDIGO_SAME_DAY_SIGNATURE_REQUIRED'
}

/** Boolean expression to compare columns of type "config_shipment_rate_code_enum". All fields are combined with logical 'AND'. */
export type Config_Shipment_Rate_Code_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Shipment_Rate_Code_Enum>;
  _in?: InputMaybe<Array<Config_Shipment_Rate_Code_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Shipment_Rate_Code_Enum>;
  _nin?: InputMaybe<Array<Config_Shipment_Rate_Code_Enum>>;
};

/** input type for inserting data into table "config.shipment_rate_code" */
export type Config_Shipment_Rate_Code_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.shipment_rate_code" */
export type Config_Shipment_Rate_Code_Obj_Rel_Insert_Input = {
  data: Config_Shipment_Rate_Code_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Shipment_Rate_Code_On_Conflict>;
};

/** on_conflict condition type for table "config.shipment_rate_code" */
export type Config_Shipment_Rate_Code_On_Conflict = {
  constraint: Config_Shipment_Rate_Code_Constraint;
  update_columns?: Array<Config_Shipment_Rate_Code_Update_Column>;
  where?: InputMaybe<Config_Shipment_Rate_Code_Bool_Exp>;
};

/** Ordering options when selecting data from "config.shipment_rate_code". */
export type Config_Shipment_Rate_Code_Order_By = {
  code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.shipment_rate_code */
export type Config_Shipment_Rate_Code_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "config.shipment_rate_code" */
export enum Config_Shipment_Rate_Code_Select_Column {
  /** column name */
  Code = 'code'
}

/** input type for updating data in table "config.shipment_rate_code" */
export type Config_Shipment_Rate_Code_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_shipment_rate_code" */
export type Config_Shipment_Rate_Code_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Shipment_Rate_Code_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Shipment_Rate_Code_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.shipment_rate_code" */
export enum Config_Shipment_Rate_Code_Update_Column {
  /** column name */
  Code = 'code'
}

export type Config_Shipment_Rate_Code_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Shipment_Rate_Code_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Shipment_Rate_Code_Bool_Exp;
};

/** unique or primary key constraints on table "config.shipment_rate" */
export enum Config_Shipment_Rate_Constraint {
  /** unique or primary key constraint on columns "code" */
  ShipmentRatePkey1 = 'shipment_rate_pkey1'
}

/** Boolean expression to filter rows from the table "config.shipment_rate_details". All fields are combined with a logical 'AND'. */
export type Config_Shipment_Rate_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Shipment_Rate_Details_Bool_Exp>>;
  _not?: InputMaybe<Config_Shipment_Rate_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Shipment_Rate_Details_Bool_Exp>>;
  booking_cutoff?: InputMaybe<Time_Comparison_Exp>;
  carrier?: InputMaybe<Config_Shipment_Provider_Enum_Comparison_Exp>;
  code?: InputMaybe<Config_Shipment_Rate_Code_Enum_Comparison_Exp>;
  delay?: InputMaybe<Int_Comparison_Exp>;
  delay_type?: InputMaybe<Config_Shipment_Delay_Type_Enum_Comparison_Exp>;
  handoff_strategy?: InputMaybe<Config_Shipment_Handoff_Strategy_Enum_Comparison_Exp>;
  integration?: InputMaybe<Config_Shipment_Integration_Enum_Comparison_Exp>;
  name_en?: InputMaybe<String_Comparison_Exp>;
  name_fr?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.shipment_rate_details" */
export enum Config_Shipment_Rate_Details_Constraint {
  /** unique or primary key constraint on columns "code" */
  ShipmentRateDetailsPkey = 'shipment_rate_details_pkey'
}

/** input type for incrementing numeric columns in table "config.shipment_rate_details" */
export type Config_Shipment_Rate_Details_Inc_Input = {
  /** Shipping time, in days */
  delay?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "config.shipment_rate_details" */
export type Config_Shipment_Rate_Details_Insert_Input = {
  booking_cutoff?: InputMaybe<Scalars['time']>;
  carrier?: InputMaybe<Config_Shipment_Provider_Enum>;
  code?: InputMaybe<Config_Shipment_Rate_Code_Enum>;
  /** Shipping time, in days */
  delay?: InputMaybe<Scalars['Int']>;
  delay_type?: InputMaybe<Config_Shipment_Delay_Type_Enum>;
  handoff_strategy?: InputMaybe<Config_Shipment_Handoff_Strategy_Enum>;
  integration?: InputMaybe<Config_Shipment_Integration_Enum>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.shipment_rate_details" */
export type Config_Shipment_Rate_Details_Obj_Rel_Insert_Input = {
  data: Config_Shipment_Rate_Details_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Shipment_Rate_Details_On_Conflict>;
};

/** on_conflict condition type for table "config.shipment_rate_details" */
export type Config_Shipment_Rate_Details_On_Conflict = {
  constraint: Config_Shipment_Rate_Details_Constraint;
  update_columns?: Array<Config_Shipment_Rate_Details_Update_Column>;
  where?: InputMaybe<Config_Shipment_Rate_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "config.shipment_rate_details". */
export type Config_Shipment_Rate_Details_Order_By = {
  booking_cutoff?: InputMaybe<Order_By>;
  carrier?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  delay?: InputMaybe<Order_By>;
  delay_type?: InputMaybe<Order_By>;
  handoff_strategy?: InputMaybe<Order_By>;
  integration?: InputMaybe<Order_By>;
  name_en?: InputMaybe<Order_By>;
  name_fr?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.shipment_rate_details */
export type Config_Shipment_Rate_Details_Pk_Columns_Input = {
  code: Config_Shipment_Rate_Code_Enum;
};

/** select columns of table "config.shipment_rate_details" */
export enum Config_Shipment_Rate_Details_Select_Column {
  /** column name */
  BookingCutoff = 'booking_cutoff',
  /** column name */
  Carrier = 'carrier',
  /** column name */
  Code = 'code',
  /** column name */
  Delay = 'delay',
  /** column name */
  DelayType = 'delay_type',
  /** column name */
  HandoffStrategy = 'handoff_strategy',
  /** column name */
  Integration = 'integration',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameFr = 'name_fr'
}

/** input type for updating data in table "config.shipment_rate_details" */
export type Config_Shipment_Rate_Details_Set_Input = {
  booking_cutoff?: InputMaybe<Scalars['time']>;
  carrier?: InputMaybe<Config_Shipment_Provider_Enum>;
  code?: InputMaybe<Config_Shipment_Rate_Code_Enum>;
  /** Shipping time, in days */
  delay?: InputMaybe<Scalars['Int']>;
  delay_type?: InputMaybe<Config_Shipment_Delay_Type_Enum>;
  handoff_strategy?: InputMaybe<Config_Shipment_Handoff_Strategy_Enum>;
  integration?: InputMaybe<Config_Shipment_Integration_Enum>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_shipment_rate_details" */
export type Config_Shipment_Rate_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Shipment_Rate_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Shipment_Rate_Details_Stream_Cursor_Value_Input = {
  booking_cutoff?: InputMaybe<Scalars['time']>;
  carrier?: InputMaybe<Config_Shipment_Provider_Enum>;
  code?: InputMaybe<Config_Shipment_Rate_Code_Enum>;
  /** Shipping time, in days */
  delay?: InputMaybe<Scalars['Int']>;
  delay_type?: InputMaybe<Config_Shipment_Delay_Type_Enum>;
  handoff_strategy?: InputMaybe<Config_Shipment_Handoff_Strategy_Enum>;
  integration?: InputMaybe<Config_Shipment_Integration_Enum>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.shipment_rate_details" */
export enum Config_Shipment_Rate_Details_Update_Column {
  /** column name */
  BookingCutoff = 'booking_cutoff',
  /** column name */
  Carrier = 'carrier',
  /** column name */
  Code = 'code',
  /** column name */
  Delay = 'delay',
  /** column name */
  DelayType = 'delay_type',
  /** column name */
  HandoffStrategy = 'handoff_strategy',
  /** column name */
  Integration = 'integration',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameFr = 'name_fr'
}

export type Config_Shipment_Rate_Details_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Config_Shipment_Rate_Details_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Shipment_Rate_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Shipment_Rate_Details_Bool_Exp;
};

/** input type for incrementing numeric columns in table "config.shipment_rate" */
export type Config_Shipment_Rate_Inc_Input = {
  delay?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "config.shipment_rate" */
export type Config_Shipment_Rate_Insert_Input = {
  booking_cutoff?: InputMaybe<Scalars['time']>;
  carrier?: InputMaybe<Config_Shipment_Provider_Enum>;
  code?: InputMaybe<Config_Shipment_Rate_Code_Enum>;
  delay?: InputMaybe<Scalars['Int']>;
  delay_type?: InputMaybe<Config_Shipment_Delay_Type_Enum>;
  handoff_strategy?: InputMaybe<Config_Shipment_Handoff_Strategy_Enum>;
  integration?: InputMaybe<Config_Shipment_Integration_Enum>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
  shipment_rate_details?: InputMaybe<Config_Shipment_Rate_Code_Obj_Rel_Insert_Input>;
};

/** input type for inserting object relation for remote table "config.shipment_rate" */
export type Config_Shipment_Rate_Obj_Rel_Insert_Input = {
  data: Config_Shipment_Rate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Shipment_Rate_On_Conflict>;
};

/** on_conflict condition type for table "config.shipment_rate" */
export type Config_Shipment_Rate_On_Conflict = {
  constraint: Config_Shipment_Rate_Constraint;
  update_columns?: Array<Config_Shipment_Rate_Update_Column>;
  where?: InputMaybe<Config_Shipment_Rate_Bool_Exp>;
};

/** Ordering options when selecting data from "config.shipment_rate". */
export type Config_Shipment_Rate_Order_By = {
  booking_cutoff?: InputMaybe<Order_By>;
  carrier?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  delay?: InputMaybe<Order_By>;
  delay_type?: InputMaybe<Order_By>;
  handoff_strategy?: InputMaybe<Order_By>;
  integration?: InputMaybe<Order_By>;
  name_en?: InputMaybe<Order_By>;
  name_fr?: InputMaybe<Order_By>;
  shipment_rate_details?: InputMaybe<Config_Shipment_Rate_Code_Order_By>;
};

/** primary key columns input for table: config.shipment_rate */
export type Config_Shipment_Rate_Pk_Columns_Input = {
  code: Config_Shipment_Rate_Code_Enum;
};

/** select columns of table "config.shipment_rate" */
export enum Config_Shipment_Rate_Select_Column {
  /** column name */
  BookingCutoff = 'booking_cutoff',
  /** column name */
  Carrier = 'carrier',
  /** column name */
  Code = 'code',
  /** column name */
  Delay = 'delay',
  /** column name */
  DelayType = 'delay_type',
  /** column name */
  HandoffStrategy = 'handoff_strategy',
  /** column name */
  Integration = 'integration',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameFr = 'name_fr'
}

/** input type for updating data in table "config.shipment_rate" */
export type Config_Shipment_Rate_Set_Input = {
  booking_cutoff?: InputMaybe<Scalars['time']>;
  carrier?: InputMaybe<Config_Shipment_Provider_Enum>;
  code?: InputMaybe<Config_Shipment_Rate_Code_Enum>;
  delay?: InputMaybe<Scalars['Int']>;
  delay_type?: InputMaybe<Config_Shipment_Delay_Type_Enum>;
  handoff_strategy?: InputMaybe<Config_Shipment_Handoff_Strategy_Enum>;
  integration?: InputMaybe<Config_Shipment_Integration_Enum>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_shipment_rate" */
export type Config_Shipment_Rate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Shipment_Rate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Shipment_Rate_Stream_Cursor_Value_Input = {
  booking_cutoff?: InputMaybe<Scalars['time']>;
  carrier?: InputMaybe<Config_Shipment_Provider_Enum>;
  code?: InputMaybe<Config_Shipment_Rate_Code_Enum>;
  delay?: InputMaybe<Scalars['Int']>;
  delay_type?: InputMaybe<Config_Shipment_Delay_Type_Enum>;
  handoff_strategy?: InputMaybe<Config_Shipment_Handoff_Strategy_Enum>;
  integration?: InputMaybe<Config_Shipment_Integration_Enum>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.shipment_rate" */
export enum Config_Shipment_Rate_Update_Column {
  /** column name */
  BookingCutoff = 'booking_cutoff',
  /** column name */
  Carrier = 'carrier',
  /** column name */
  Code = 'code',
  /** column name */
  Delay = 'delay',
  /** column name */
  DelayType = 'delay_type',
  /** column name */
  HandoffStrategy = 'handoff_strategy',
  /** column name */
  Integration = 'integration',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameFr = 'name_fr'
}

export type Config_Shipment_Rate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Config_Shipment_Rate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Shipment_Rate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Shipment_Rate_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.shipment_status". All fields are combined with a logical 'AND'. */
export type Config_Shipment_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Shipment_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Shipment_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Shipment_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.shipment_status" */
export enum Config_Shipment_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  Pk_1513fb86fa37be468088f9600c9 = 'PK_1513fb86fa37be468088f9600c9'
}

export enum Config_Shipment_Status_Enum {
  /** The shipment has been cancelled */
  Cancelled = 'CANCELLED',
  /** The package has been well received */
  Delivered = 'DELIVERED',
  /** The package has been lost */
  Lost = 'LOST',
  /** The package is out for delivery */
  OutForDelivery = 'OUT_FOR_DELIVERY',
  /** Initial status */
  Pending = 'PENDING',
  /** The package has been picked up and is on route */
  Shipped = 'SHIPPED',
  /** The shipment's status is unknown */
  Unknown = 'UNKNOWN'
}

/** Boolean expression to compare columns of type "config_shipment_status_enum". All fields are combined with logical 'AND'. */
export type Config_Shipment_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Shipment_Status_Enum>;
  _in?: InputMaybe<Array<Config_Shipment_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Shipment_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Shipment_Status_Enum>>;
};

/** input type for inserting data into table "config.shipment_status" */
export type Config_Shipment_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.shipment_status" */
export type Config_Shipment_Status_Obj_Rel_Insert_Input = {
  data: Config_Shipment_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Shipment_Status_On_Conflict>;
};

/** on_conflict condition type for table "config.shipment_status" */
export type Config_Shipment_Status_On_Conflict = {
  constraint: Config_Shipment_Status_Constraint;
  update_columns?: Array<Config_Shipment_Status_Update_Column>;
  where?: InputMaybe<Config_Shipment_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.shipment_status". */
export type Config_Shipment_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.shipment_status */
export type Config_Shipment_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.shipment_status" */
export enum Config_Shipment_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.shipment_status" */
export type Config_Shipment_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_shipment_status" */
export type Config_Shipment_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Shipment_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Shipment_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.shipment_status" */
export enum Config_Shipment_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Shipment_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Shipment_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Shipment_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.shipping_manifest_status". All fields are combined with a logical 'AND'. */
export type Config_Shipping_Manifest_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Shipping_Manifest_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Shipping_Manifest_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Shipping_Manifest_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.shipping_manifest_status" */
export enum Config_Shipping_Manifest_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  PkCd06854813dc156308d89d14e0b = 'PK_cd06854813dc156308d89d14e0b'
}

export enum Config_Shipping_Manifest_Status_Enum {
  Completed = 'COMPLETED',
  Pending = 'PENDING'
}

/** Boolean expression to compare columns of type "config_shipping_manifest_status_enum". All fields are combined with logical 'AND'. */
export type Config_Shipping_Manifest_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Shipping_Manifest_Status_Enum>;
  _in?: InputMaybe<Array<Config_Shipping_Manifest_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Shipping_Manifest_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Shipping_Manifest_Status_Enum>>;
};

/** input type for inserting data into table "config.shipping_manifest_status" */
export type Config_Shipping_Manifest_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.shipping_manifest_status" */
export type Config_Shipping_Manifest_Status_On_Conflict = {
  constraint: Config_Shipping_Manifest_Status_Constraint;
  update_columns?: Array<Config_Shipping_Manifest_Status_Update_Column>;
  where?: InputMaybe<Config_Shipping_Manifest_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.shipping_manifest_status". */
export type Config_Shipping_Manifest_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.shipping_manifest_status */
export type Config_Shipping_Manifest_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.shipping_manifest_status" */
export enum Config_Shipping_Manifest_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.shipping_manifest_status" */
export type Config_Shipping_Manifest_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_shipping_manifest_status" */
export type Config_Shipping_Manifest_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Shipping_Manifest_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Shipping_Manifest_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.shipping_manifest_status" */
export enum Config_Shipping_Manifest_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Shipping_Manifest_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Shipping_Manifest_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Shipping_Manifest_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.source". All fields are combined with a logical 'AND'. */
export type Config_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Source_Bool_Exp>>;
  _not?: InputMaybe<Config_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Source_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.source" */
export enum Config_Source_Constraint {
  /** unique or primary key constraint on columns "value" */
  SourcePkey = 'source_pkey'
}

export enum Config_Source_Enum {
  CliniqueVaudreuil = 'CLINIQUE_VAUDREUIL',
  Fphq = 'FPHQ',
  Greenshield = 'GREENSHIELD',
  Mdhub = 'MDHUB',
  MedicQuebec = 'MEDIC_QUEBEC',
  Medzy = 'MEDZY',
  Posoplus = 'POSOPLUS',
  Soprema = 'SOPREMA',
  Tootelo = 'TOOTELO',
  Unknown = 'UNKNOWN'
}

/** Boolean expression to compare columns of type "config_source_enum". All fields are combined with logical 'AND'. */
export type Config_Source_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Source_Enum>;
  _in?: InputMaybe<Array<Config_Source_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Source_Enum>;
  _nin?: InputMaybe<Array<Config_Source_Enum>>;
};

/** input type for inserting data into table "config.source" */
export type Config_Source_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.source" */
export type Config_Source_On_Conflict = {
  constraint: Config_Source_Constraint;
  update_columns?: Array<Config_Source_Update_Column>;
  where?: InputMaybe<Config_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "config.source". */
export type Config_Source_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.source */
export type Config_Source_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.source" */
export enum Config_Source_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.source" */
export type Config_Source_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_source" */
export type Config_Source_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Source_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Source_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.source" */
export enum Config_Source_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Source_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Source_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.transfer_request_cancellation_reasons". All fields are combined with a logical 'AND'. */
export type Config_Transfer_Request_Cancellation_Reasons_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Transfer_Request_Cancellation_Reasons_Bool_Exp>>;
  _not?: InputMaybe<Config_Transfer_Request_Cancellation_Reasons_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Transfer_Request_Cancellation_Reasons_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.transfer_request_cancellation_reasons" */
export enum Config_Transfer_Request_Cancellation_Reasons_Constraint {
  /** unique or primary key constraint on columns "value" */
  TransferRequestCancellationReasonsPkey = 'transfer_request_cancellation_reasons_pkey'
}

export enum Config_Transfer_Request_Cancellation_Reasons_Enum {
  ClientChangedMind = 'CLIENT_CHANGED_MIND',
  OnAlert = 'ON_ALERT',
  Other = 'OTHER'
}

/** Boolean expression to compare columns of type "config_transfer_request_cancellation_reasons_enum". All fields are combined with logical 'AND'. */
export type Config_Transfer_Request_Cancellation_Reasons_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Transfer_Request_Cancellation_Reasons_Enum>;
  _in?: InputMaybe<Array<Config_Transfer_Request_Cancellation_Reasons_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Transfer_Request_Cancellation_Reasons_Enum>;
  _nin?: InputMaybe<Array<Config_Transfer_Request_Cancellation_Reasons_Enum>>;
};

/** input type for inserting data into table "config.transfer_request_cancellation_reasons" */
export type Config_Transfer_Request_Cancellation_Reasons_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.transfer_request_cancellation_reasons" */
export type Config_Transfer_Request_Cancellation_Reasons_On_Conflict = {
  constraint: Config_Transfer_Request_Cancellation_Reasons_Constraint;
  update_columns?: Array<Config_Transfer_Request_Cancellation_Reasons_Update_Column>;
  where?: InputMaybe<Config_Transfer_Request_Cancellation_Reasons_Bool_Exp>;
};

/** Ordering options when selecting data from "config.transfer_request_cancellation_reasons". */
export type Config_Transfer_Request_Cancellation_Reasons_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.transfer_request_cancellation_reasons */
export type Config_Transfer_Request_Cancellation_Reasons_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.transfer_request_cancellation_reasons" */
export enum Config_Transfer_Request_Cancellation_Reasons_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.transfer_request_cancellation_reasons" */
export type Config_Transfer_Request_Cancellation_Reasons_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_transfer_request_cancellation_reasons" */
export type Config_Transfer_Request_Cancellation_Reasons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Transfer_Request_Cancellation_Reasons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Transfer_Request_Cancellation_Reasons_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.transfer_request_cancellation_reasons" */
export enum Config_Transfer_Request_Cancellation_Reasons_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Transfer_Request_Cancellation_Reasons_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Transfer_Request_Cancellation_Reasons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Transfer_Request_Cancellation_Reasons_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.transfer_request_consent_type". All fields are combined with a logical 'AND'. */
export type Config_Transfer_Request_Consent_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Transfer_Request_Consent_Type_Bool_Exp>>;
  _not?: InputMaybe<Config_Transfer_Request_Consent_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Transfer_Request_Consent_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.transfer_request_consent_type" */
export enum Config_Transfer_Request_Consent_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  TransferRequestConsentTypePkey = 'transfer_request_consent_type_pkey'
}

export enum Config_Transfer_Request_Consent_Type_Enum {
  /** The patient has given verbal consent */
  Verbal = 'VERBAL',
  /** The patient has consented in writing */
  Written = 'WRITTEN'
}

/** Boolean expression to compare columns of type "config_transfer_request_consent_type_enum". All fields are combined with logical 'AND'. */
export type Config_Transfer_Request_Consent_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Transfer_Request_Consent_Type_Enum>;
  _in?: InputMaybe<Array<Config_Transfer_Request_Consent_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Transfer_Request_Consent_Type_Enum>;
  _nin?: InputMaybe<Array<Config_Transfer_Request_Consent_Type_Enum>>;
};

/** input type for inserting data into table "config.transfer_request_consent_type" */
export type Config_Transfer_Request_Consent_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.transfer_request_consent_type" */
export type Config_Transfer_Request_Consent_Type_On_Conflict = {
  constraint: Config_Transfer_Request_Consent_Type_Constraint;
  update_columns?: Array<Config_Transfer_Request_Consent_Type_Update_Column>;
  where?: InputMaybe<Config_Transfer_Request_Consent_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "config.transfer_request_consent_type". */
export type Config_Transfer_Request_Consent_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.transfer_request_consent_type */
export type Config_Transfer_Request_Consent_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.transfer_request_consent_type" */
export enum Config_Transfer_Request_Consent_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.transfer_request_consent_type" */
export type Config_Transfer_Request_Consent_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_transfer_request_consent_type" */
export type Config_Transfer_Request_Consent_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Transfer_Request_Consent_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Transfer_Request_Consent_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.transfer_request_consent_type" */
export enum Config_Transfer_Request_Consent_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Transfer_Request_Consent_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Transfer_Request_Consent_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Transfer_Request_Consent_Type_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.transfer_request_first_order_outcome". All fields are combined with a logical 'AND'. */
export type Config_Transfer_Request_First_Order_Outcome_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Transfer_Request_First_Order_Outcome_Bool_Exp>>;
  _not?: InputMaybe<Config_Transfer_Request_First_Order_Outcome_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Transfer_Request_First_Order_Outcome_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.transfer_request_first_order_outcome" */
export enum Config_Transfer_Request_First_Order_Outcome_Constraint {
  /** unique or primary key constraint on columns "value" */
  TransferRequestFirstOrderOutcomePkey = 'transfer_request_first_order_outcome_pkey'
}

export enum Config_Transfer_Request_First_Order_Outcome_Enum {
  Created = 'CREATED',
  NoOrderNecessary = 'NO_ORDER_NECESSARY'
}

/** Boolean expression to compare columns of type "config_transfer_request_first_order_outcome_enum". All fields are combined with logical 'AND'. */
export type Config_Transfer_Request_First_Order_Outcome_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Transfer_Request_First_Order_Outcome_Enum>;
  _in?: InputMaybe<Array<Config_Transfer_Request_First_Order_Outcome_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Transfer_Request_First_Order_Outcome_Enum>;
  _nin?: InputMaybe<Array<Config_Transfer_Request_First_Order_Outcome_Enum>>;
};

/** input type for inserting data into table "config.transfer_request_first_order_outcome" */
export type Config_Transfer_Request_First_Order_Outcome_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.transfer_request_first_order_outcome" */
export type Config_Transfer_Request_First_Order_Outcome_On_Conflict = {
  constraint: Config_Transfer_Request_First_Order_Outcome_Constraint;
  update_columns?: Array<Config_Transfer_Request_First_Order_Outcome_Update_Column>;
  where?: InputMaybe<Config_Transfer_Request_First_Order_Outcome_Bool_Exp>;
};

/** Ordering options when selecting data from "config.transfer_request_first_order_outcome". */
export type Config_Transfer_Request_First_Order_Outcome_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.transfer_request_first_order_outcome */
export type Config_Transfer_Request_First_Order_Outcome_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.transfer_request_first_order_outcome" */
export enum Config_Transfer_Request_First_Order_Outcome_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.transfer_request_first_order_outcome" */
export type Config_Transfer_Request_First_Order_Outcome_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_transfer_request_first_order_outcome" */
export type Config_Transfer_Request_First_Order_Outcome_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Transfer_Request_First_Order_Outcome_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Transfer_Request_First_Order_Outcome_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.transfer_request_first_order_outcome" */
export enum Config_Transfer_Request_First_Order_Outcome_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Transfer_Request_First_Order_Outcome_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Transfer_Request_First_Order_Outcome_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Transfer_Request_First_Order_Outcome_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.transfer_request_processing_status". All fields are combined with a logical 'AND'. */
export type Config_Transfer_Request_Processing_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Transfer_Request_Processing_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Transfer_Request_Processing_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Transfer_Request_Processing_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.transfer_request_processing_status" */
export enum Config_Transfer_Request_Processing_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  TransferRequestProcessingStatusPkey = 'transfer_request_processing_status_pkey'
}

export enum Config_Transfer_Request_Processing_Status_Enum {
  Cancelled = 'CANCELLED',
  InformationOnly = 'INFORMATION_ONLY',
  Initiated = 'INITIATED',
  NoRx = 'NO_RX',
  Transferred = 'TRANSFERRED'
}

/** Boolean expression to compare columns of type "config_transfer_request_processing_status_enum". All fields are combined with logical 'AND'. */
export type Config_Transfer_Request_Processing_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Transfer_Request_Processing_Status_Enum>;
  _in?: InputMaybe<Array<Config_Transfer_Request_Processing_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Transfer_Request_Processing_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Transfer_Request_Processing_Status_Enum>>;
};

/** input type for inserting data into table "config.transfer_request_processing_status" */
export type Config_Transfer_Request_Processing_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.transfer_request_processing_status" */
export type Config_Transfer_Request_Processing_Status_On_Conflict = {
  constraint: Config_Transfer_Request_Processing_Status_Constraint;
  update_columns?: Array<Config_Transfer_Request_Processing_Status_Update_Column>;
  where?: InputMaybe<Config_Transfer_Request_Processing_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.transfer_request_processing_status". */
export type Config_Transfer_Request_Processing_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.transfer_request_processing_status */
export type Config_Transfer_Request_Processing_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.transfer_request_processing_status" */
export enum Config_Transfer_Request_Processing_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.transfer_request_processing_status" */
export type Config_Transfer_Request_Processing_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_transfer_request_processing_status" */
export type Config_Transfer_Request_Processing_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Transfer_Request_Processing_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Transfer_Request_Processing_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.transfer_request_processing_status" */
export enum Config_Transfer_Request_Processing_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Transfer_Request_Processing_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Transfer_Request_Processing_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Transfer_Request_Processing_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.transfer_request_status". All fields are combined with a logical 'AND'. */
export type Config_Transfer_Request_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Transfer_Request_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Transfer_Request_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Transfer_Request_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.transfer_request_status" */
export enum Config_Transfer_Request_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  PkF98b6a2c0ee1c31d044ecd58b5a = 'PK_f98b6a2c0ee1c31d044ecd58b5a'
}

export enum Config_Transfer_Request_Status_Enum {
  /** The transfer has been cancelled by an admin */
  AdminCancelled = 'ADMIN_CANCELLED',
  /** Initial status */
  AwaitingConfirmation = 'AWAITING_CONFIRMATION',
  /** The transfer has been cancelled by client */
  ClientCancelled = 'CLIENT_CANCELLED',
  /** The transfer is completed */
  Completed = 'COMPLETED',
  /** There is no medication to transfer */
  Empty = 'EMPTY',
  /** Patient consented to transfer */
  Pending = 'PENDING',
  /** The transfer is being processed */
  Processing = 'PROCESSING',
  /** The transfer was refused */
  Refused = 'REFUSED'
}

/** Boolean expression to compare columns of type "config_transfer_request_status_enum". All fields are combined with logical 'AND'. */
export type Config_Transfer_Request_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Transfer_Request_Status_Enum>;
  _in?: InputMaybe<Array<Config_Transfer_Request_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Transfer_Request_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Transfer_Request_Status_Enum>>;
};

/** input type for inserting data into table "config.transfer_request_status" */
export type Config_Transfer_Request_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.transfer_request_status" */
export type Config_Transfer_Request_Status_Obj_Rel_Insert_Input = {
  data: Config_Transfer_Request_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Transfer_Request_Status_On_Conflict>;
};

/** on_conflict condition type for table "config.transfer_request_status" */
export type Config_Transfer_Request_Status_On_Conflict = {
  constraint: Config_Transfer_Request_Status_Constraint;
  update_columns?: Array<Config_Transfer_Request_Status_Update_Column>;
  where?: InputMaybe<Config_Transfer_Request_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.transfer_request_status". */
export type Config_Transfer_Request_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.transfer_request_status */
export type Config_Transfer_Request_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.transfer_request_status" */
export enum Config_Transfer_Request_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.transfer_request_status" */
export type Config_Transfer_Request_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_transfer_request_status" */
export type Config_Transfer_Request_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Transfer_Request_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Transfer_Request_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.transfer_request_status" */
export enum Config_Transfer_Request_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Transfer_Request_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Transfer_Request_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Transfer_Request_Status_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.treatment_type". All fields are combined with a logical 'AND'. */
export type Config_Treatment_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Treatment_Type_Bool_Exp>>;
  _not?: InputMaybe<Config_Treatment_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Treatment_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.treatment_type" */
export enum Config_Treatment_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  TreatmentTypePkey = 'treatment_type_pkey'
}

export enum Config_Treatment_Type_Enum {
  Chronic = 'CHRONIC',
  NotApplicable = 'NOT_APPLICABLE',
  Prn = 'PRN',
  Urgent = 'URGENT'
}

/** Boolean expression to compare columns of type "config_treatment_type_enum". All fields are combined with logical 'AND'. */
export type Config_Treatment_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Treatment_Type_Enum>;
  _in?: InputMaybe<Array<Config_Treatment_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Treatment_Type_Enum>;
  _nin?: InputMaybe<Array<Config_Treatment_Type_Enum>>;
};

/** input type for inserting data into table "config.treatment_type" */
export type Config_Treatment_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "config.treatment_type" */
export type Config_Treatment_Type_On_Conflict = {
  constraint: Config_Treatment_Type_Constraint;
  update_columns?: Array<Config_Treatment_Type_Update_Column>;
  where?: InputMaybe<Config_Treatment_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "config.treatment_type". */
export type Config_Treatment_Type_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.treatment_type */
export type Config_Treatment_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.treatment_type" */
export enum Config_Treatment_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.treatment_type" */
export type Config_Treatment_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_treatment_type" */
export type Config_Treatment_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Treatment_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Treatment_Type_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.treatment_type" */
export enum Config_Treatment_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export type Config_Treatment_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Treatment_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Treatment_Type_Bool_Exp;
};

/** Boolean expression to filter rows from the table "config.upload_status". All fields are combined with a logical 'AND'. */
export type Config_Upload_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Upload_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Upload_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Upload_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config.upload_status" */
export enum Config_Upload_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  Pk_413e81c32afe0ba94dc412754bb = 'PK_413e81c32afe0ba94dc412754bb'
}

export enum Config_Upload_Status_Enum {
  /** The upload was processed successfully */
  Accepted = 'ACCEPTED',
  /** The upload has been cancelled by an admin */
  AdminCancelled = 'ADMIN_CANCELLED',
  /** The upload has been cancelled by client */
  ClientCancelled = 'CLIENT_CANCELLED',
  /** Initial status */
  Pending = 'PENDING',
  /** The upload is being processed */
  Processing = 'PROCESSING',
  /** The upload was refused */
  Refused = 'REFUSED'
}

/** Boolean expression to compare columns of type "config_upload_status_enum". All fields are combined with logical 'AND'. */
export type Config_Upload_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Config_Upload_Status_Enum>;
  _in?: InputMaybe<Array<Config_Upload_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Config_Upload_Status_Enum>;
  _nin?: InputMaybe<Array<Config_Upload_Status_Enum>>;
};

/** input type for inserting data into table "config.upload_status" */
export type Config_Upload_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "config.upload_status" */
export type Config_Upload_Status_Obj_Rel_Insert_Input = {
  data: Config_Upload_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Upload_Status_On_Conflict>;
};

/** on_conflict condition type for table "config.upload_status" */
export type Config_Upload_Status_On_Conflict = {
  constraint: Config_Upload_Status_Constraint;
  update_columns?: Array<Config_Upload_Status_Update_Column>;
  where?: InputMaybe<Config_Upload_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config.upload_status". */
export type Config_Upload_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config.upload_status */
export type Config_Upload_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "config.upload_status" */
export enum Config_Upload_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "config.upload_status" */
export type Config_Upload_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "config_upload_status" */
export type Config_Upload_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Upload_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Upload_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config.upload_status" */
export enum Config_Upload_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Config_Upload_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Upload_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Upload_Status_Bool_Exp;
};

export type Consent_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Consent_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Consent_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Consent_Aggregate_Bool_Exp_Count>;
};

export type Consent_Aggregate_Bool_Exp_Bool_And = {
  arguments: Consent_Select_Column_Consent_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consent_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Consent_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Consent_Select_Column_Consent_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consent_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Consent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Consent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "consent" */
export type Consent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Consent_Max_Order_By>;
  min?: InputMaybe<Consent_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Consent_Append_Input = {
  updated_fields?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "consent" */
export type Consent_Arr_Rel_Insert_Input = {
  data: Array<Consent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Consent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "consent". All fields are combined with a logical 'AND'. */
export type Consent_Bool_Exp = {
  _and?: InputMaybe<Array<Consent_Bool_Exp>>;
  _not?: InputMaybe<Consent_Bool_Exp>;
  _or?: InputMaybe<Array<Consent_Bool_Exp>>;
  has_consented_for_close_person_access?: InputMaybe<Boolean_Comparison_Exp>;
  has_consented_to_receive_generic_medications?: InputMaybe<Boolean_Comparison_Exp>;
  has_consented_to_share_with_other_professionals?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  updated_fields?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "consent" */
export enum Consent_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConsentPkey = 'consent_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Consent_Delete_At_Path_Input = {
  updated_fields?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Consent_Delete_Elem_Input = {
  updated_fields?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Consent_Delete_Key_Input = {
  updated_fields?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "consent" */
export type Consent_Insert_Input = {
  has_consented_for_close_person_access?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_receive_generic_medications?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_share_with_other_professionals?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['String']>;
  updated_fields?: InputMaybe<Scalars['jsonb']>;
};

/** order by max() on columns of table "consent" */
export type Consent_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "consent" */
export type Consent_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "consent" */
export type Consent_On_Conflict = {
  constraint: Consent_Constraint;
  update_columns?: Array<Consent_Update_Column>;
  where?: InputMaybe<Consent_Bool_Exp>;
};

/** Ordering options when selecting data from "consent". */
export type Consent_Order_By = {
  has_consented_for_close_person_access?: InputMaybe<Order_By>;
  has_consented_to_receive_generic_medications?: InputMaybe<Order_By>;
  has_consented_to_share_with_other_professionals?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  updated_fields?: InputMaybe<Order_By>;
};

/** primary key columns input for table: consent */
export type Consent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Consent_Prepend_Input = {
  updated_fields?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "consent" */
export enum Consent_Select_Column {
  /** column name */
  HasConsentedForClosePersonAccess = 'has_consented_for_close_person_access',
  /** column name */
  HasConsentedToReceiveGenericMedications = 'has_consented_to_receive_generic_medications',
  /** column name */
  HasConsentedToShareWithOtherProfessionals = 'has_consented_to_share_with_other_professionals',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UpdatedFields = 'updated_fields'
}

/** select "consent_aggregate_bool_exp_bool_and_arguments_columns" columns of table "consent" */
export enum Consent_Select_Column_Consent_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasConsentedForClosePersonAccess = 'has_consented_for_close_person_access',
  /** column name */
  HasConsentedToReceiveGenericMedications = 'has_consented_to_receive_generic_medications',
  /** column name */
  HasConsentedToShareWithOtherProfessionals = 'has_consented_to_share_with_other_professionals'
}

/** select "consent_aggregate_bool_exp_bool_or_arguments_columns" columns of table "consent" */
export enum Consent_Select_Column_Consent_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasConsentedForClosePersonAccess = 'has_consented_for_close_person_access',
  /** column name */
  HasConsentedToReceiveGenericMedications = 'has_consented_to_receive_generic_medications',
  /** column name */
  HasConsentedToShareWithOtherProfessionals = 'has_consented_to_share_with_other_professionals'
}

/** input type for updating data in table "consent" */
export type Consent_Set_Input = {
  has_consented_for_close_person_access?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_receive_generic_medications?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_share_with_other_professionals?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['String']>;
  updated_fields?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "consent" */
export type Consent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Consent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Consent_Stream_Cursor_Value_Input = {
  has_consented_for_close_person_access?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_receive_generic_medications?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_share_with_other_professionals?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  updated_by?: InputMaybe<Scalars['String']>;
  updated_fields?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "consent" */
export enum Consent_Update_Column {
  /** column name */
  HasConsentedForClosePersonAccess = 'has_consented_for_close_person_access',
  /** column name */
  HasConsentedToReceiveGenericMedications = 'has_consented_to_receive_generic_medications',
  /** column name */
  HasConsentedToShareWithOtherProfessionals = 'has_consented_to_share_with_other_professionals',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UpdatedFields = 'updated_fields'
}

export type Consent_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Consent_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Consent_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Consent_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Consent_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Consent_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Consent_Set_Input>;
  /** filter the rows which have to be updated */
  where: Consent_Bool_Exp;
};

export type Consultation_Survey_Result_Aggregate_Bool_Exp = {
  count?: InputMaybe<Consultation_Survey_Result_Aggregate_Bool_Exp_Count>;
};

export type Consultation_Survey_Result_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Consultation_Survey_Result_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consultation_Survey_Result_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "consultation_survey_result" */
export type Consultation_Survey_Result_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Consultation_Survey_Result_Max_Order_By>;
  min?: InputMaybe<Consultation_Survey_Result_Min_Order_By>;
};

/** input type for inserting array relation for remote table "consultation_survey_result" */
export type Consultation_Survey_Result_Arr_Rel_Insert_Input = {
  data: Array<Consultation_Survey_Result_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Consultation_Survey_Result_On_Conflict>;
};

/** Boolean expression to filter rows from the table "consultation_survey_result". All fields are combined with a logical 'AND'. */
export type Consultation_Survey_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Consultation_Survey_Result_Bool_Exp>>;
  _not?: InputMaybe<Consultation_Survey_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Consultation_Survey_Result_Bool_Exp>>;
  consultation_id?: InputMaybe<Uuid_Comparison_Exp>;
  form_consultation?: InputMaybe<Form_Consultation_Bool_Exp>;
  survey_result?: InputMaybe<Survey_Result_Bool_Exp>;
  survey_result_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "consultation_survey_result" */
export enum Consultation_Survey_Result_Constraint {
  /** unique or primary key constraint on columns "consultation_id", "survey_result_id" */
  ConsultationSurveyResultPkey = 'consultation_survey_result_pkey'
}

/** input type for inserting data into table "consultation_survey_result" */
export type Consultation_Survey_Result_Insert_Input = {
  consultation_id?: InputMaybe<Scalars['uuid']>;
  form_consultation?: InputMaybe<Form_Consultation_Obj_Rel_Insert_Input>;
  survey_result?: InputMaybe<Survey_Result_Obj_Rel_Insert_Input>;
  survey_result_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "consultation_survey_result" */
export type Consultation_Survey_Result_Max_Order_By = {
  consultation_id?: InputMaybe<Order_By>;
  survey_result_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "consultation_survey_result" */
export type Consultation_Survey_Result_Min_Order_By = {
  consultation_id?: InputMaybe<Order_By>;
  survey_result_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "consultation_survey_result" */
export type Consultation_Survey_Result_On_Conflict = {
  constraint: Consultation_Survey_Result_Constraint;
  update_columns?: Array<Consultation_Survey_Result_Update_Column>;
  where?: InputMaybe<Consultation_Survey_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "consultation_survey_result". */
export type Consultation_Survey_Result_Order_By = {
  consultation_id?: InputMaybe<Order_By>;
  form_consultation?: InputMaybe<Form_Consultation_Order_By>;
  survey_result?: InputMaybe<Survey_Result_Order_By>;
  survey_result_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: consultation_survey_result */
export type Consultation_Survey_Result_Pk_Columns_Input = {
  consultation_id: Scalars['uuid'];
  survey_result_id: Scalars['uuid'];
};

/** select columns of table "consultation_survey_result" */
export enum Consultation_Survey_Result_Select_Column {
  /** column name */
  ConsultationId = 'consultation_id',
  /** column name */
  SurveyResultId = 'survey_result_id'
}

/** input type for updating data in table "consultation_survey_result" */
export type Consultation_Survey_Result_Set_Input = {
  consultation_id?: InputMaybe<Scalars['uuid']>;
  survey_result_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "consultation_survey_result" */
export type Consultation_Survey_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Consultation_Survey_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Consultation_Survey_Result_Stream_Cursor_Value_Input = {
  consultation_id?: InputMaybe<Scalars['uuid']>;
  survey_result_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "consultation_survey_result" */
export enum Consultation_Survey_Result_Update_Column {
  /** column name */
  ConsultationId = 'consultation_id',
  /** column name */
  SurveyResultId = 'survey_result_id'
}

export type Consultation_Survey_Result_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Consultation_Survey_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Consultation_Survey_Result_Bool_Exp;
};

export type Credit_Note_Aggregate_Bool_Exp = {
  count?: InputMaybe<Credit_Note_Aggregate_Bool_Exp_Count>;
};

export type Credit_Note_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Credit_Note_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Credit_Note_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "credit_note" */
export type Credit_Note_Aggregate_Order_By = {
  avg?: InputMaybe<Credit_Note_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Credit_Note_Max_Order_By>;
  min?: InputMaybe<Credit_Note_Min_Order_By>;
  stddev?: InputMaybe<Credit_Note_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Credit_Note_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Credit_Note_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Credit_Note_Sum_Order_By>;
  var_pop?: InputMaybe<Credit_Note_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Credit_Note_Var_Samp_Order_By>;
  variance?: InputMaybe<Credit_Note_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "credit_note" */
export type Credit_Note_Arr_Rel_Insert_Input = {
  data: Array<Credit_Note_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Credit_Note_On_Conflict>;
};

/** order by avg() on columns of table "credit_note" */
export type Credit_Note_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "credit_note". All fields are combined with a logical 'AND'. */
export type Credit_Note_Bool_Exp = {
  _and?: InputMaybe<Array<Credit_Note_Bool_Exp>>;
  _not?: InputMaybe<Credit_Note_Bool_Exp>;
  _or?: InputMaybe<Array<Credit_Note_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoice_id?: InputMaybe<Int_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "credit_note" */
export enum Credit_Note_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_1f08d9f374b8db39fd4c8e789df = 'PK_1f08d9f374b8db39fd4c8e789df'
}

/** input type for incrementing numeric columns in table "credit_note" */
export type Credit_Note_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "credit_note" */
export type Credit_Note_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "credit_note" */
export type Credit_Note_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "credit_note" */
export type Credit_Note_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "credit_note" */
export type Credit_Note_On_Conflict = {
  constraint: Credit_Note_Constraint;
  update_columns?: Array<Credit_Note_Update_Column>;
  where?: InputMaybe<Credit_Note_Bool_Exp>;
};

/** Ordering options when selecting data from "credit_note". */
export type Credit_Note_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: credit_note */
export type Credit_Note_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "credit_note" */
export enum Credit_Note_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "credit_note" */
export type Credit_Note_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "credit_note" */
export type Credit_Note_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "credit_note" */
export type Credit_Note_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "credit_note" */
export type Credit_Note_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "credit_note" */
export type Credit_Note_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Credit_Note_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Credit_Note_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "credit_note" */
export type Credit_Note_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** update columns of table "credit_note" */
export enum Credit_Note_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Credit_Note_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Credit_Note_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Credit_Note_Set_Input>;
  /** filter the rows which have to be updated */
  where: Credit_Note_Bool_Exp;
};

/** order by var_pop() on columns of table "credit_note" */
export type Credit_Note_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "credit_note" */
export type Credit_Note_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "credit_note" */
export type Credit_Note_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** order by aggregate values of table "customer_journey_event" */
export type Customer_Journey_Event_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Journey_Event_Max_Order_By>;
  min?: InputMaybe<Customer_Journey_Event_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Customer_Journey_Event_Append_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "customer_journey_event". All fields are combined with a logical 'AND'. */
export type Customer_Journey_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Journey_Event_Bool_Exp>>;
  _not?: InputMaybe<Customer_Journey_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Journey_Event_Bool_Exp>>;
  account_uid?: InputMaybe<String_Comparison_Exp>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  milestone_attained?: InputMaybe<Config_Customer_Journey_Milestone_Enum_Comparison_Exp>;
  name?: InputMaybe<Config_Customer_Journey_Event_Name_Enum_Comparison_Exp>;
  occured_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_journey_event" */
export enum Customer_Journey_Event_Constraint {
  /** unique or primary key constraint on columns "name", "account_uid" */
  CustomerJourneyEventAccountUidNameKey = 'customer_journey_event_account_uid_name_key',
  /** unique or primary key constraint on columns "id" */
  CustomerJourneyEventPkey = 'customer_journey_event_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Customer_Journey_Event_Delete_At_Path_Input = {
  attributes?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Customer_Journey_Event_Delete_Elem_Input = {
  attributes?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Customer_Journey_Event_Delete_Key_Input = {
  attributes?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "customer_journey_event" */
export type Customer_Journey_Event_Insert_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  milestone_attained?: InputMaybe<Config_Customer_Journey_Milestone_Enum>;
  name?: InputMaybe<Config_Customer_Journey_Event_Name_Enum>;
  occured_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "customer_journey_event" */
export type Customer_Journey_Event_Max_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occured_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "customer_journey_event" */
export type Customer_Journey_Event_Min_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occured_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "customer_journey_event" */
export type Customer_Journey_Event_On_Conflict = {
  constraint: Customer_Journey_Event_Constraint;
  update_columns?: Array<Customer_Journey_Event_Update_Column>;
  where?: InputMaybe<Customer_Journey_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_journey_event". */
export type Customer_Journey_Event_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  attributes?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  milestone_attained?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  occured_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_journey_event */
export type Customer_Journey_Event_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Customer_Journey_Event_Prepend_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "customer_journey_event" */
export enum Customer_Journey_Event_Select_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Id = 'id',
  /** column name */
  MilestoneAttained = 'milestone_attained',
  /** column name */
  Name = 'name',
  /** column name */
  OccuredAt = 'occured_at'
}

/** input type for updating data in table "customer_journey_event" */
export type Customer_Journey_Event_Set_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  milestone_attained?: InputMaybe<Config_Customer_Journey_Milestone_Enum>;
  name?: InputMaybe<Config_Customer_Journey_Event_Name_Enum>;
  occured_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "customer_journey_event" */
export type Customer_Journey_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Journey_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Journey_Event_Stream_Cursor_Value_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  milestone_attained?: InputMaybe<Config_Customer_Journey_Milestone_Enum>;
  name?: InputMaybe<Config_Customer_Journey_Event_Name_Enum>;
  occured_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "customer_journey_event" */
export enum Customer_Journey_Event_Update_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  Id = 'id',
  /** column name */
  MilestoneAttained = 'milestone_attained',
  /** column name */
  Name = 'name',
  /** column name */
  OccuredAt = 'occured_at'
}

export type Customer_Journey_Event_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Customer_Journey_Event_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Customer_Journey_Event_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Customer_Journey_Event_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Customer_Journey_Event_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Customer_Journey_Event_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Journey_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Journey_Event_Bool_Exp;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

export type Drug_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Drug_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Drug_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Drug_Aggregate_Bool_Exp_Count>;
};

export type Drug_Aggregate_Bool_Exp_Bool_And = {
  arguments: Drug_Select_Column_Drug_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Drug_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Drug_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Drug_Select_Column_Drug_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Drug_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Drug_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Drug_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Drug_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "drug" */
export type Drug_Aggregate_Order_By = {
  avg?: InputMaybe<Drug_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Drug_Max_Order_By>;
  min?: InputMaybe<Drug_Min_Order_By>;
  stddev?: InputMaybe<Drug_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Drug_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Drug_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Drug_Sum_Order_By>;
  var_pop?: InputMaybe<Drug_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Drug_Var_Samp_Order_By>;
  variance?: InputMaybe<Drug_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "drug" */
export type Drug_Arr_Rel_Insert_Input = {
  data: Array<Drug_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Drug_On_Conflict>;
};

/** order by avg() on columns of table "drug" */
export type Drug_Avg_Order_By = {
  conservation_in_days?: InputMaybe<Order_By>;
  conservation_in_days_2?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "drug". All fields are combined with a logical 'AND'. */
export type Drug_Bool_Exp = {
  _and?: InputMaybe<Array<Drug_Bool_Exp>>;
  _not?: InputMaybe<Drug_Bool_Exp>;
  _or?: InputMaybe<Array<Drug_Bool_Exp>>;
  company?: InputMaybe<String_Comparison_Exp>;
  conservation_in_days?: InputMaybe<Numeric_Comparison_Exp>;
  conservation_in_days_2?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  drug_equivalence?: InputMaybe<Drug_Equivalence_Bool_Exp>;
  drug_equivalence_id?: InputMaybe<Uuid_Comparison_Exp>;
  drug_form?: InputMaybe<Drug_Form_Bool_Exp>;
  drug_form_id?: InputMaybe<Uuid_Comparison_Exp>;
  drug_properties?: InputMaybe<Drug_Properties_Bool_Exp>;
  drug_properties_aggregate?: InputMaybe<Drug_Properties_Aggregate_Bool_Exp>;
  form?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ingredient?: InputMaybe<String_Comparison_Exp>;
  legal_status_qc?: InputMaybe<String_Comparison_Exp>;
  medication?: InputMaybe<Medication_Bool_Exp>;
  medication_aggregate?: InputMaybe<Medication_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name_en?: InputMaybe<String_Comparison_Exp>;
  name_fr?: InputMaybe<String_Comparison_Exp>;
  number?: InputMaybe<String_Comparison_Exp>;
  price_quote_line_items?: InputMaybe<Price_Quote_Line_Item_Bool_Exp>;
  price_quote_line_items_aggregate?: InputMaybe<Price_Quote_Line_Item_Aggregate_Bool_Exp>;
  service_requests?: InputMaybe<Service_Request_Bool_Exp>;
  service_requests_aggregate?: InputMaybe<Service_Request_Aggregate_Bool_Exp>;
  strength?: InputMaybe<String_Comparison_Exp>;
  strength_en?: InputMaybe<String_Comparison_Exp>;
  strength_fr?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  was_ceased?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "drug" */
export enum Drug_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_84d53e7c3b5e562421850eb9723 = 'PK_84d53e7c3b5e562421850eb9723',
  /** unique or primary key constraint on columns "number" */
  UqB886b7d717ccb0213b82db46af4 = 'UQ_b886b7d717ccb0213b82db46af4'
}

/** Boolean expression to filter rows from the table "drug_equivalence". All fields are combined with a logical 'AND'. */
export type Drug_Equivalence_Bool_Exp = {
  _and?: InputMaybe<Array<Drug_Equivalence_Bool_Exp>>;
  _not?: InputMaybe<Drug_Equivalence_Bool_Exp>;
  _or?: InputMaybe<Array<Drug_Equivalence_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  drugs?: InputMaybe<Drug_Bool_Exp>;
  drugs_aggregate?: InputMaybe<Drug_Aggregate_Bool_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  form?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  innovative_drug_en?: InputMaybe<String_Comparison_Exp>;
  innovative_drug_fr?: InputMaybe<String_Comparison_Exp>;
  is_a_drug?: InputMaybe<Boolean_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_obsolete?: InputMaybe<Boolean_Comparison_Exp>;
  is_precise?: InputMaybe<Boolean_Comparison_Exp>;
  medication?: InputMaybe<Medication_Bool_Exp>;
  medication_aggregate?: InputMaybe<Medication_Aggregate_Bool_Exp>;
  simple_generic_name_en?: InputMaybe<String_Comparison_Exp>;
  simple_generic_name_fr?: InputMaybe<String_Comparison_Exp>;
  strength?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "drug_equivalence" */
export enum Drug_Equivalence_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_28d95391c25c72c8be1733df12d = 'PK_28d95391c25c72c8be1733df12d',
  /** unique or primary key constraint on columns "external_id" */
  UqB84c0070b93cc0cd69920b6256b = 'UQ_b84c0070b93cc0cd69920b6256b'
}

/** input type for inserting data into table "drug_equivalence" */
export type Drug_Equivalence_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drugs?: InputMaybe<Drug_Arr_Rel_Insert_Input>;
  /** Either RxVigilance FgenPlus.dat column 1: "code-GEN" (see https://manager.vigilance.ca/documentation/resource/RxVigilance_FichiersDeDonnees/fr/fgenplus.html) OR RxVigilance generxplus.dat column 1 "Identifiant unique" (see https://manager.vigilance.ca/documentation/resource/RxVigilance_FichiersDeDonnees/fr/generxplus.html) */
  external_id?: InputMaybe<Scalars['String']>;
  form?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  innovative_drug_en?: InputMaybe<Scalars['String']>;
  innovative_drug_fr?: InputMaybe<Scalars['String']>;
  is_a_drug?: InputMaybe<Scalars['Boolean']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_obsolete?: InputMaybe<Scalars['Boolean']>;
  is_precise?: InputMaybe<Scalars['Boolean']>;
  medication?: InputMaybe<Medication_Arr_Rel_Insert_Input>;
  simple_generic_name_en?: InputMaybe<Scalars['String']>;
  simple_generic_name_fr?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** input type for inserting object relation for remote table "drug_equivalence" */
export type Drug_Equivalence_Obj_Rel_Insert_Input = {
  data: Drug_Equivalence_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Drug_Equivalence_On_Conflict>;
};

/** on_conflict condition type for table "drug_equivalence" */
export type Drug_Equivalence_On_Conflict = {
  constraint: Drug_Equivalence_Constraint;
  update_columns?: Array<Drug_Equivalence_Update_Column>;
  where?: InputMaybe<Drug_Equivalence_Bool_Exp>;
};

/** Ordering options when selecting data from "drug_equivalence". */
export type Drug_Equivalence_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drugs_aggregate?: InputMaybe<Drug_Aggregate_Order_By>;
  external_id?: InputMaybe<Order_By>;
  form?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  innovative_drug_en?: InputMaybe<Order_By>;
  innovative_drug_fr?: InputMaybe<Order_By>;
  is_a_drug?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_obsolete?: InputMaybe<Order_By>;
  is_precise?: InputMaybe<Order_By>;
  medication_aggregate?: InputMaybe<Medication_Aggregate_Order_By>;
  simple_generic_name_en?: InputMaybe<Order_By>;
  simple_generic_name_fr?: InputMaybe<Order_By>;
  strength?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: drug_equivalence */
export type Drug_Equivalence_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "drug_equivalence" */
export enum Drug_Equivalence_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Form = 'form',
  /** column name */
  Id = 'id',
  /** column name */
  InnovativeDrugEn = 'innovative_drug_en',
  /** column name */
  InnovativeDrugFr = 'innovative_drug_fr',
  /** column name */
  IsADrug = 'is_a_drug',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsObsolete = 'is_obsolete',
  /** column name */
  IsPrecise = 'is_precise',
  /** column name */
  SimpleGenericNameEn = 'simple_generic_name_en',
  /** column name */
  SimpleGenericNameFr = 'simple_generic_name_fr',
  /** column name */
  Strength = 'strength',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "drug_equivalence" */
export type Drug_Equivalence_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  /** Either RxVigilance FgenPlus.dat column 1: "code-GEN" (see https://manager.vigilance.ca/documentation/resource/RxVigilance_FichiersDeDonnees/fr/fgenplus.html) OR RxVigilance generxplus.dat column 1 "Identifiant unique" (see https://manager.vigilance.ca/documentation/resource/RxVigilance_FichiersDeDonnees/fr/generxplus.html) */
  external_id?: InputMaybe<Scalars['String']>;
  form?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  innovative_drug_en?: InputMaybe<Scalars['String']>;
  innovative_drug_fr?: InputMaybe<Scalars['String']>;
  is_a_drug?: InputMaybe<Scalars['Boolean']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_obsolete?: InputMaybe<Scalars['Boolean']>;
  is_precise?: InputMaybe<Scalars['Boolean']>;
  simple_generic_name_en?: InputMaybe<Scalars['String']>;
  simple_generic_name_fr?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "drug_equivalence" */
export type Drug_Equivalence_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Drug_Equivalence_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Drug_Equivalence_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  /** Either RxVigilance FgenPlus.dat column 1: "code-GEN" (see https://manager.vigilance.ca/documentation/resource/RxVigilance_FichiersDeDonnees/fr/fgenplus.html) OR RxVigilance generxplus.dat column 1 "Identifiant unique" (see https://manager.vigilance.ca/documentation/resource/RxVigilance_FichiersDeDonnees/fr/generxplus.html) */
  external_id?: InputMaybe<Scalars['String']>;
  form?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  innovative_drug_en?: InputMaybe<Scalars['String']>;
  innovative_drug_fr?: InputMaybe<Scalars['String']>;
  is_a_drug?: InputMaybe<Scalars['Boolean']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_obsolete?: InputMaybe<Scalars['Boolean']>;
  is_precise?: InputMaybe<Scalars['Boolean']>;
  simple_generic_name_en?: InputMaybe<Scalars['String']>;
  simple_generic_name_fr?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "drug_equivalence" */
export enum Drug_Equivalence_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Form = 'form',
  /** column name */
  Id = 'id',
  /** column name */
  InnovativeDrugEn = 'innovative_drug_en',
  /** column name */
  InnovativeDrugFr = 'innovative_drug_fr',
  /** column name */
  IsADrug = 'is_a_drug',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsObsolete = 'is_obsolete',
  /** column name */
  IsPrecise = 'is_precise',
  /** column name */
  SimpleGenericNameEn = 'simple_generic_name_en',
  /** column name */
  SimpleGenericNameFr = 'simple_generic_name_fr',
  /** column name */
  Strength = 'strength',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Drug_Equivalence_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Drug_Equivalence_Set_Input>;
  /** filter the rows which have to be updated */
  where: Drug_Equivalence_Bool_Exp;
};

/** Boolean expression to filter rows from the table "drug_form". All fields are combined with a logical 'AND'. */
export type Drug_Form_Bool_Exp = {
  _and?: InputMaybe<Array<Drug_Form_Bool_Exp>>;
  _not?: InputMaybe<Drug_Form_Bool_Exp>;
  _or?: InputMaybe<Array<Drug_Form_Bool_Exp>>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name_en?: InputMaybe<String_Comparison_Exp>;
  name_fr?: InputMaybe<String_Comparison_Exp>;
  posology_gender_fr?: InputMaybe<String_Comparison_Exp>;
  posology_plural_en?: InputMaybe<String_Comparison_Exp>;
  posology_plural_fr?: InputMaybe<String_Comparison_Exp>;
  posology_singular_en?: InputMaybe<String_Comparison_Exp>;
  posology_singular_fr?: InputMaybe<String_Comparison_Exp>;
  prescription_gender_fr?: InputMaybe<String_Comparison_Exp>;
  prescription_plural_en?: InputMaybe<String_Comparison_Exp>;
  prescription_plural_fr?: InputMaybe<String_Comparison_Exp>;
  prescription_singular_en?: InputMaybe<String_Comparison_Exp>;
  prescription_singular_fr?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "drug_form" */
export enum Drug_Form_Constraint {
  /** unique or primary key constraint on columns "external_id" */
  DrugFormExternalIdKey = 'drug_form_external_id_key',
  /** unique or primary key constraint on columns "id" */
  DrugFormPkey = 'drug_form_pkey'
}

/** input type for inserting data into table "drug_form" */
export type Drug_Form_Insert_Input = {
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
  posology_gender_fr?: InputMaybe<Scalars['String']>;
  posology_plural_en?: InputMaybe<Scalars['String']>;
  posology_plural_fr?: InputMaybe<Scalars['String']>;
  posology_singular_en?: InputMaybe<Scalars['String']>;
  /** Comprimés, gouttes, applications, inhalations, etc. */
  posology_singular_fr?: InputMaybe<Scalars['String']>;
  prescription_gender_fr?: InputMaybe<Scalars['String']>;
  prescription_plural_en?: InputMaybe<Scalars['String']>;
  prescription_plural_fr?: InputMaybe<Scalars['String']>;
  prescription_singular_en?: InputMaybe<Scalars['String']>;
  /** Comprimés, ml, grammes, cartouches, etc. */
  prescription_singular_fr?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "drug_form" */
export type Drug_Form_Obj_Rel_Insert_Input = {
  data: Drug_Form_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Drug_Form_On_Conflict>;
};

/** on_conflict condition type for table "drug_form" */
export type Drug_Form_On_Conflict = {
  constraint: Drug_Form_Constraint;
  update_columns?: Array<Drug_Form_Update_Column>;
  where?: InputMaybe<Drug_Form_Bool_Exp>;
};

/** Ordering options when selecting data from "drug_form". */
export type Drug_Form_Order_By = {
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_en?: InputMaybe<Order_By>;
  name_fr?: InputMaybe<Order_By>;
  posology_gender_fr?: InputMaybe<Order_By>;
  posology_plural_en?: InputMaybe<Order_By>;
  posology_plural_fr?: InputMaybe<Order_By>;
  posology_singular_en?: InputMaybe<Order_By>;
  posology_singular_fr?: InputMaybe<Order_By>;
  prescription_gender_fr?: InputMaybe<Order_By>;
  prescription_plural_en?: InputMaybe<Order_By>;
  prescription_plural_fr?: InputMaybe<Order_By>;
  prescription_singular_en?: InputMaybe<Order_By>;
  prescription_singular_fr?: InputMaybe<Order_By>;
};

/** primary key columns input for table: drug_form */
export type Drug_Form_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "drug_form" */
export enum Drug_Form_Select_Column {
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameFr = 'name_fr',
  /** column name */
  PosologyGenderFr = 'posology_gender_fr',
  /** column name */
  PosologyPluralEn = 'posology_plural_en',
  /** column name */
  PosologyPluralFr = 'posology_plural_fr',
  /** column name */
  PosologySingularEn = 'posology_singular_en',
  /** column name */
  PosologySingularFr = 'posology_singular_fr',
  /** column name */
  PrescriptionGenderFr = 'prescription_gender_fr',
  /** column name */
  PrescriptionPluralEn = 'prescription_plural_en',
  /** column name */
  PrescriptionPluralFr = 'prescription_plural_fr',
  /** column name */
  PrescriptionSingularEn = 'prescription_singular_en',
  /** column name */
  PrescriptionSingularFr = 'prescription_singular_fr'
}

/** input type for updating data in table "drug_form" */
export type Drug_Form_Set_Input = {
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
  posology_gender_fr?: InputMaybe<Scalars['String']>;
  posology_plural_en?: InputMaybe<Scalars['String']>;
  posology_plural_fr?: InputMaybe<Scalars['String']>;
  posology_singular_en?: InputMaybe<Scalars['String']>;
  /** Comprimés, gouttes, applications, inhalations, etc. */
  posology_singular_fr?: InputMaybe<Scalars['String']>;
  prescription_gender_fr?: InputMaybe<Scalars['String']>;
  prescription_plural_en?: InputMaybe<Scalars['String']>;
  prescription_plural_fr?: InputMaybe<Scalars['String']>;
  prescription_singular_en?: InputMaybe<Scalars['String']>;
  /** Comprimés, ml, grammes, cartouches, etc. */
  prescription_singular_fr?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "drug_form" */
export type Drug_Form_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Drug_Form_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Drug_Form_Stream_Cursor_Value_Input = {
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
  posology_gender_fr?: InputMaybe<Scalars['String']>;
  posology_plural_en?: InputMaybe<Scalars['String']>;
  posology_plural_fr?: InputMaybe<Scalars['String']>;
  posology_singular_en?: InputMaybe<Scalars['String']>;
  /** Comprimés, gouttes, applications, inhalations, etc. */
  posology_singular_fr?: InputMaybe<Scalars['String']>;
  prescription_gender_fr?: InputMaybe<Scalars['String']>;
  prescription_plural_en?: InputMaybe<Scalars['String']>;
  prescription_plural_fr?: InputMaybe<Scalars['String']>;
  prescription_singular_en?: InputMaybe<Scalars['String']>;
  /** Comprimés, ml, grammes, cartouches, etc. */
  prescription_singular_fr?: InputMaybe<Scalars['String']>;
};

/** update columns of table "drug_form" */
export enum Drug_Form_Update_Column {
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameFr = 'name_fr',
  /** column name */
  PosologyGenderFr = 'posology_gender_fr',
  /** column name */
  PosologyPluralEn = 'posology_plural_en',
  /** column name */
  PosologyPluralFr = 'posology_plural_fr',
  /** column name */
  PosologySingularEn = 'posology_singular_en',
  /** column name */
  PosologySingularFr = 'posology_singular_fr',
  /** column name */
  PrescriptionGenderFr = 'prescription_gender_fr',
  /** column name */
  PrescriptionPluralEn = 'prescription_plural_en',
  /** column name */
  PrescriptionPluralFr = 'prescription_plural_fr',
  /** column name */
  PrescriptionSingularEn = 'prescription_singular_en',
  /** column name */
  PrescriptionSingularFr = 'prescription_singular_fr'
}

export type Drug_Form_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Drug_Form_Set_Input>;
  /** filter the rows which have to be updated */
  where: Drug_Form_Bool_Exp;
};

/** Boolean expression to filter rows from the table "drug_generic". All fields are combined with a logical 'AND'. */
export type Drug_Generic_Bool_Exp = {
  _and?: InputMaybe<Array<Drug_Generic_Bool_Exp>>;
  _not?: InputMaybe<Drug_Generic_Bool_Exp>;
  _or?: InputMaybe<Array<Drug_Generic_Bool_Exp>>;
  brand_name_din?: InputMaybe<String_Comparison_Exp>;
  company?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  form?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ingredient?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  number?: InputMaybe<String_Comparison_Exp>;
  strength?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** Ordering options when selecting data from "drug_generic". */
export type Drug_Generic_Order_By = {
  brand_name_din?: InputMaybe<Order_By>;
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  form?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ingredient?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  strength?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "drug_generic" */
export enum Drug_Generic_Select_Column {
  /** column name */
  BrandNameDin = 'brand_name_din',
  /** column name */
  Company = 'company',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Form = 'form',
  /** column name */
  Id = 'id',
  /** column name */
  Ingredient = 'ingredient',
  /** column name */
  Name = 'name',
  /** column name */
  Number = 'number',
  /** column name */
  Strength = 'strength',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "drug_generic" */
export type Drug_Generic_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Drug_Generic_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Drug_Generic_Stream_Cursor_Value_Input = {
  brand_name_din?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  form?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ingredient?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** input type for incrementing numeric columns in table "drug" */
export type Drug_Inc_Input = {
  conservation_in_days?: InputMaybe<Scalars['numeric']>;
  conservation_in_days_2?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "drug" */
export type Drug_Insert_Input = {
  company?: InputMaybe<Scalars['String']>;
  conservation_in_days?: InputMaybe<Scalars['numeric']>;
  conservation_in_days_2?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug_equivalence?: InputMaybe<Drug_Equivalence_Obj_Rel_Insert_Input>;
  drug_equivalence_id?: InputMaybe<Scalars['uuid']>;
  drug_form?: InputMaybe<Drug_Form_Obj_Rel_Insert_Input>;
  drug_form_id?: InputMaybe<Scalars['uuid']>;
  drug_properties?: InputMaybe<Drug_Properties_Arr_Rel_Insert_Input>;
  /** Deprecated, use drug_form_id */
  form?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ingredient?: InputMaybe<Scalars['String']>;
  legal_status_qc?: InputMaybe<Scalars['String']>;
  medication?: InputMaybe<Medication_Arr_Rel_Insert_Input>;
  /** Deprecated, use name_en or name_fr */
  name?: InputMaybe<Scalars['String']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  price_quote_line_items?: InputMaybe<Price_Quote_Line_Item_Arr_Rel_Insert_Input>;
  service_requests?: InputMaybe<Service_Request_Arr_Rel_Insert_Input>;
  /** Deprecated, use strength_en or strength_fr */
  strength?: InputMaybe<Scalars['String']>;
  strength_en?: InputMaybe<Scalars['String']>;
  strength_fr?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  was_ceased?: InputMaybe<Scalars['Boolean']>;
};

/** order by max() on columns of table "drug" */
export type Drug_Max_Order_By = {
  company?: InputMaybe<Order_By>;
  conservation_in_days?: InputMaybe<Order_By>;
  conservation_in_days_2?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drug_equivalence_id?: InputMaybe<Order_By>;
  drug_form_id?: InputMaybe<Order_By>;
  /** Deprecated, use drug_form_id */
  form?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ingredient?: InputMaybe<Order_By>;
  legal_status_qc?: InputMaybe<Order_By>;
  /** Deprecated, use name_en or name_fr */
  name?: InputMaybe<Order_By>;
  name_en?: InputMaybe<Order_By>;
  name_fr?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  /** Deprecated, use strength_en or strength_fr */
  strength?: InputMaybe<Order_By>;
  strength_en?: InputMaybe<Order_By>;
  strength_fr?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "drug" */
export type Drug_Min_Order_By = {
  company?: InputMaybe<Order_By>;
  conservation_in_days?: InputMaybe<Order_By>;
  conservation_in_days_2?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drug_equivalence_id?: InputMaybe<Order_By>;
  drug_form_id?: InputMaybe<Order_By>;
  /** Deprecated, use drug_form_id */
  form?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ingredient?: InputMaybe<Order_By>;
  legal_status_qc?: InputMaybe<Order_By>;
  /** Deprecated, use name_en or name_fr */
  name?: InputMaybe<Order_By>;
  name_en?: InputMaybe<Order_By>;
  name_fr?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  /** Deprecated, use strength_en or strength_fr */
  strength?: InputMaybe<Order_By>;
  strength_en?: InputMaybe<Order_By>;
  strength_fr?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "drug" */
export type Drug_Obj_Rel_Insert_Input = {
  data: Drug_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Drug_On_Conflict>;
};

/** on_conflict condition type for table "drug" */
export type Drug_On_Conflict = {
  constraint: Drug_Constraint;
  update_columns?: Array<Drug_Update_Column>;
  where?: InputMaybe<Drug_Bool_Exp>;
};

/** Ordering options when selecting data from "drug". */
export type Drug_Order_By = {
  company?: InputMaybe<Order_By>;
  conservation_in_days?: InputMaybe<Order_By>;
  conservation_in_days_2?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drug_equivalence?: InputMaybe<Drug_Equivalence_Order_By>;
  drug_equivalence_id?: InputMaybe<Order_By>;
  drug_form?: InputMaybe<Drug_Form_Order_By>;
  drug_form_id?: InputMaybe<Order_By>;
  drug_properties_aggregate?: InputMaybe<Drug_Properties_Aggregate_Order_By>;
  form?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ingredient?: InputMaybe<Order_By>;
  legal_status_qc?: InputMaybe<Order_By>;
  medication_aggregate?: InputMaybe<Medication_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  name_en?: InputMaybe<Order_By>;
  name_fr?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  price_quote_line_items_aggregate?: InputMaybe<Price_Quote_Line_Item_Aggregate_Order_By>;
  service_requests_aggregate?: InputMaybe<Service_Request_Aggregate_Order_By>;
  strength?: InputMaybe<Order_By>;
  strength_en?: InputMaybe<Order_By>;
  strength_fr?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  was_ceased?: InputMaybe<Order_By>;
};

/** primary key columns input for table: drug */
export type Drug_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** Boolean expression to filter rows from the table "drug_price". All fields are combined with a logical 'AND'. */
export type Drug_Price_Bool_Exp = {
  _and?: InputMaybe<Array<Drug_Price_Bool_Exp>>;
  _not?: InputMaybe<Drug_Price_Bool_Exp>;
  _or?: InputMaybe<Array<Drug_Price_Bool_Exp>>;
  din?: InputMaybe<String_Comparison_Exp>;
  drug_price_source?: InputMaybe<Config_Drug_Price_Source_Bool_Exp>;
  private_insurer_dispensing_fee?: InputMaybe<Int_Comparison_Exp>;
  private_insurer_drug_cost?: InputMaybe<Int_Comparison_Exp>;
  private_insurer_total_cost?: InputMaybe<Int_Comparison_Exp>;
  provincial_insurer_dispensing_fee?: InputMaybe<Int_Comparison_Exp>;
  provincial_insurer_drug_cost?: InputMaybe<Int_Comparison_Exp>;
  provincial_insurer_total_cost?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  service_period_in_months?: InputMaybe<Int_Comparison_Exp>;
  source_value?: InputMaybe<Config_Drug_Price_Source_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "drug_price" */
export enum Drug_Price_Constraint {
  /** unique or primary key constraint on columns "quantity", "service_period_in_months", "din" */
  Pk_810afbd6c686b605378e4e70716 = 'PK_810afbd6c686b605378e4e70716'
}

/** input type for incrementing numeric columns in table "drug_price" */
export type Drug_Price_Inc_Input = {
  private_insurer_dispensing_fee?: InputMaybe<Scalars['Int']>;
  /** Before-insurance cost paid by the patient for the drug. Note that this may differ from the wholesale price. */
  private_insurer_drug_cost?: InputMaybe<Scalars['Int']>;
  private_insurer_total_cost?: InputMaybe<Scalars['Int']>;
  provincial_insurer_dispensing_fee?: InputMaybe<Scalars['Int']>;
  /** Before-insurance cost paid by the patient for the drug. Note that this may differ from the wholesale price. */
  provincial_insurer_drug_cost?: InputMaybe<Scalars['Int']>;
  provincial_insurer_total_cost?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  /** -1 means the price applies regardless of the service period */
  service_period_in_months?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "drug_price" */
export type Drug_Price_Insert_Input = {
  din?: InputMaybe<Scalars['String']>;
  drug_price_source?: InputMaybe<Config_Drug_Price_Source_Obj_Rel_Insert_Input>;
  private_insurer_dispensing_fee?: InputMaybe<Scalars['Int']>;
  /** Before-insurance cost paid by the patient for the drug. Note that this may differ from the wholesale price. */
  private_insurer_drug_cost?: InputMaybe<Scalars['Int']>;
  private_insurer_total_cost?: InputMaybe<Scalars['Int']>;
  provincial_insurer_dispensing_fee?: InputMaybe<Scalars['Int']>;
  /** Before-insurance cost paid by the patient for the drug. Note that this may differ from the wholesale price. */
  provincial_insurer_drug_cost?: InputMaybe<Scalars['Int']>;
  provincial_insurer_total_cost?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  /** -1 means the price applies regardless of the service period */
  service_period_in_months?: InputMaybe<Scalars['Int']>;
  source_value?: InputMaybe<Config_Drug_Price_Source_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** on_conflict condition type for table "drug_price" */
export type Drug_Price_On_Conflict = {
  constraint: Drug_Price_Constraint;
  update_columns?: Array<Drug_Price_Update_Column>;
  where?: InputMaybe<Drug_Price_Bool_Exp>;
};

/** Ordering options when selecting data from "drug_price". */
export type Drug_Price_Order_By = {
  din?: InputMaybe<Order_By>;
  drug_price_source?: InputMaybe<Config_Drug_Price_Source_Order_By>;
  private_insurer_dispensing_fee?: InputMaybe<Order_By>;
  private_insurer_drug_cost?: InputMaybe<Order_By>;
  private_insurer_total_cost?: InputMaybe<Order_By>;
  provincial_insurer_dispensing_fee?: InputMaybe<Order_By>;
  provincial_insurer_drug_cost?: InputMaybe<Order_By>;
  provincial_insurer_total_cost?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  service_period_in_months?: InputMaybe<Order_By>;
  source_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: drug_price */
export type Drug_Price_Pk_Columns_Input = {
  din: Scalars['String'];
  quantity: Scalars['Int'];
  /** -1 means the price applies regardless of the service period */
  service_period_in_months: Scalars['Int'];
};

/** select columns of table "drug_price" */
export enum Drug_Price_Select_Column {
  /** column name */
  Din = 'din',
  /** column name */
  PrivateInsurerDispensingFee = 'private_insurer_dispensing_fee',
  /** column name */
  PrivateInsurerDrugCost = 'private_insurer_drug_cost',
  /** column name */
  PrivateInsurerTotalCost = 'private_insurer_total_cost',
  /** column name */
  ProvincialInsurerDispensingFee = 'provincial_insurer_dispensing_fee',
  /** column name */
  ProvincialInsurerDrugCost = 'provincial_insurer_drug_cost',
  /** column name */
  ProvincialInsurerTotalCost = 'provincial_insurer_total_cost',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServicePeriodInMonths = 'service_period_in_months',
  /** column name */
  SourceValue = 'source_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "drug_price" */
export type Drug_Price_Set_Input = {
  din?: InputMaybe<Scalars['String']>;
  private_insurer_dispensing_fee?: InputMaybe<Scalars['Int']>;
  /** Before-insurance cost paid by the patient for the drug. Note that this may differ from the wholesale price. */
  private_insurer_drug_cost?: InputMaybe<Scalars['Int']>;
  private_insurer_total_cost?: InputMaybe<Scalars['Int']>;
  provincial_insurer_dispensing_fee?: InputMaybe<Scalars['Int']>;
  /** Before-insurance cost paid by the patient for the drug. Note that this may differ from the wholesale price. */
  provincial_insurer_drug_cost?: InputMaybe<Scalars['Int']>;
  provincial_insurer_total_cost?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  /** -1 means the price applies regardless of the service period */
  service_period_in_months?: InputMaybe<Scalars['Int']>;
  source_value?: InputMaybe<Config_Drug_Price_Source_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "drug_price" */
export type Drug_Price_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Drug_Price_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Drug_Price_Stream_Cursor_Value_Input = {
  din?: InputMaybe<Scalars['String']>;
  private_insurer_dispensing_fee?: InputMaybe<Scalars['Int']>;
  /** Before-insurance cost paid by the patient for the drug. Note that this may differ from the wholesale price. */
  private_insurer_drug_cost?: InputMaybe<Scalars['Int']>;
  private_insurer_total_cost?: InputMaybe<Scalars['Int']>;
  provincial_insurer_dispensing_fee?: InputMaybe<Scalars['Int']>;
  /** Before-insurance cost paid by the patient for the drug. Note that this may differ from the wholesale price. */
  provincial_insurer_drug_cost?: InputMaybe<Scalars['Int']>;
  provincial_insurer_total_cost?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  /** -1 means the price applies regardless of the service period */
  service_period_in_months?: InputMaybe<Scalars['Int']>;
  source_value?: InputMaybe<Config_Drug_Price_Source_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "drug_price" */
export enum Drug_Price_Update_Column {
  /** column name */
  Din = 'din',
  /** column name */
  PrivateInsurerDispensingFee = 'private_insurer_dispensing_fee',
  /** column name */
  PrivateInsurerDrugCost = 'private_insurer_drug_cost',
  /** column name */
  PrivateInsurerTotalCost = 'private_insurer_total_cost',
  /** column name */
  ProvincialInsurerDispensingFee = 'provincial_insurer_dispensing_fee',
  /** column name */
  ProvincialInsurerDrugCost = 'provincial_insurer_drug_cost',
  /** column name */
  ProvincialInsurerTotalCost = 'provincial_insurer_total_cost',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ServicePeriodInMonths = 'service_period_in_months',
  /** column name */
  SourceValue = 'source_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Drug_Price_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Drug_Price_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Drug_Price_Set_Input>;
  /** filter the rows which have to be updated */
  where: Drug_Price_Bool_Exp;
};

export type Drug_Properties_Aggregate_Bool_Exp = {
  count?: InputMaybe<Drug_Properties_Aggregate_Bool_Exp_Count>;
};

export type Drug_Properties_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Drug_Properties_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Drug_Properties_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "drug_properties" */
export type Drug_Properties_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Drug_Properties_Max_Order_By>;
  min?: InputMaybe<Drug_Properties_Min_Order_By>;
};

/** input type for inserting array relation for remote table "drug_properties" */
export type Drug_Properties_Arr_Rel_Insert_Input = {
  data: Array<Drug_Properties_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Drug_Properties_On_Conflict>;
};

/** Boolean expression to filter rows from the table "drug_properties". All fields are combined with a logical 'AND'. */
export type Drug_Properties_Bool_Exp = {
  _and?: InputMaybe<Array<Drug_Properties_Bool_Exp>>;
  _not?: InputMaybe<Drug_Properties_Bool_Exp>;
  _or?: InputMaybe<Array<Drug_Properties_Bool_Exp>>;
  drug_number?: InputMaybe<String_Comparison_Exp>;
  drug_property?: InputMaybe<Config_Drug_Property_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "drug_properties" */
export enum Drug_Properties_Constraint {
  /** unique or primary key constraint on columns "drug_number", "drug_property" */
  DrugPropertiesDrugNumberDrugPropertyKey = 'drug_properties_drug_number_drug_property_key',
  /** unique or primary key constraint on columns "drug_number", "drug_property" */
  DrugPropertiesPkey = 'drug_properties_pkey'
}

/** input type for inserting data into table "drug_properties" */
export type Drug_Properties_Insert_Input = {
  drug_number?: InputMaybe<Scalars['String']>;
  drug_property?: InputMaybe<Config_Drug_Property_Enum>;
};

/** order by max() on columns of table "drug_properties" */
export type Drug_Properties_Max_Order_By = {
  drug_number?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "drug_properties" */
export type Drug_Properties_Min_Order_By = {
  drug_number?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "drug_properties" */
export type Drug_Properties_On_Conflict = {
  constraint: Drug_Properties_Constraint;
  update_columns?: Array<Drug_Properties_Update_Column>;
  where?: InputMaybe<Drug_Properties_Bool_Exp>;
};

/** Ordering options when selecting data from "drug_properties". */
export type Drug_Properties_Order_By = {
  drug_number?: InputMaybe<Order_By>;
  drug_property?: InputMaybe<Order_By>;
};

/** primary key columns input for table: drug_properties */
export type Drug_Properties_Pk_Columns_Input = {
  drug_number: Scalars['String'];
  drug_property: Config_Drug_Property_Enum;
};

/** select columns of table "drug_properties" */
export enum Drug_Properties_Select_Column {
  /** column name */
  DrugNumber = 'drug_number',
  /** column name */
  DrugProperty = 'drug_property'
}

/** input type for updating data in table "drug_properties" */
export type Drug_Properties_Set_Input = {
  drug_number?: InputMaybe<Scalars['String']>;
  drug_property?: InputMaybe<Config_Drug_Property_Enum>;
};

/** Streaming cursor of the table "drug_properties" */
export type Drug_Properties_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Drug_Properties_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Drug_Properties_Stream_Cursor_Value_Input = {
  drug_number?: InputMaybe<Scalars['String']>;
  drug_property?: InputMaybe<Config_Drug_Property_Enum>;
};

/** update columns of table "drug_properties" */
export enum Drug_Properties_Update_Column {
  /** column name */
  DrugNumber = 'drug_number',
  /** column name */
  DrugProperty = 'drug_property'
}

export type Drug_Properties_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Drug_Properties_Set_Input>;
  /** filter the rows which have to be updated */
  where: Drug_Properties_Bool_Exp;
};

/** select columns of table "drug" */
export enum Drug_Select_Column {
  /** column name */
  Company = 'company',
  /** column name */
  ConservationInDays = 'conservation_in_days',
  /** column name */
  ConservationInDays_2 = 'conservation_in_days_2',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DrugEquivalenceId = 'drug_equivalence_id',
  /** column name */
  DrugFormId = 'drug_form_id',
  /** column name */
  Form = 'form',
  /** column name */
  Id = 'id',
  /** column name */
  Ingredient = 'ingredient',
  /** column name */
  LegalStatusQc = 'legal_status_qc',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameFr = 'name_fr',
  /** column name */
  Number = 'number',
  /** column name */
  Strength = 'strength',
  /** column name */
  StrengthEn = 'strength_en',
  /** column name */
  StrengthFr = 'strength_fr',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WasCeased = 'was_ceased'
}

/** select "drug_aggregate_bool_exp_bool_and_arguments_columns" columns of table "drug" */
export enum Drug_Select_Column_Drug_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  WasCeased = 'was_ceased'
}

/** select "drug_aggregate_bool_exp_bool_or_arguments_columns" columns of table "drug" */
export enum Drug_Select_Column_Drug_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  WasCeased = 'was_ceased'
}

/** input type for updating data in table "drug" */
export type Drug_Set_Input = {
  company?: InputMaybe<Scalars['String']>;
  conservation_in_days?: InputMaybe<Scalars['numeric']>;
  conservation_in_days_2?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug_equivalence_id?: InputMaybe<Scalars['uuid']>;
  drug_form_id?: InputMaybe<Scalars['uuid']>;
  /** Deprecated, use drug_form_id */
  form?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ingredient?: InputMaybe<Scalars['String']>;
  legal_status_qc?: InputMaybe<Scalars['String']>;
  /** Deprecated, use name_en or name_fr */
  name?: InputMaybe<Scalars['String']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  /** Deprecated, use strength_en or strength_fr */
  strength?: InputMaybe<Scalars['String']>;
  strength_en?: InputMaybe<Scalars['String']>;
  strength_fr?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  was_ceased?: InputMaybe<Scalars['Boolean']>;
};

/** order by stddev() on columns of table "drug" */
export type Drug_Stddev_Order_By = {
  conservation_in_days?: InputMaybe<Order_By>;
  conservation_in_days_2?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "drug" */
export type Drug_Stddev_Pop_Order_By = {
  conservation_in_days?: InputMaybe<Order_By>;
  conservation_in_days_2?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "drug" */
export type Drug_Stddev_Samp_Order_By = {
  conservation_in_days?: InputMaybe<Order_By>;
  conservation_in_days_2?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "drug" */
export type Drug_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Drug_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Drug_Stream_Cursor_Value_Input = {
  company?: InputMaybe<Scalars['String']>;
  conservation_in_days?: InputMaybe<Scalars['numeric']>;
  conservation_in_days_2?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug_equivalence_id?: InputMaybe<Scalars['uuid']>;
  drug_form_id?: InputMaybe<Scalars['uuid']>;
  /** Deprecated, use drug_form_id */
  form?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ingredient?: InputMaybe<Scalars['String']>;
  legal_status_qc?: InputMaybe<Scalars['String']>;
  /** Deprecated, use name_en or name_fr */
  name?: InputMaybe<Scalars['String']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  /** Deprecated, use strength_en or strength_fr */
  strength?: InputMaybe<Scalars['String']>;
  strength_en?: InputMaybe<Scalars['String']>;
  strength_fr?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  was_ceased?: InputMaybe<Scalars['Boolean']>;
};

/** order by sum() on columns of table "drug" */
export type Drug_Sum_Order_By = {
  conservation_in_days?: InputMaybe<Order_By>;
  conservation_in_days_2?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "drug" */
export enum Drug_Update_Column {
  /** column name */
  Company = 'company',
  /** column name */
  ConservationInDays = 'conservation_in_days',
  /** column name */
  ConservationInDays_2 = 'conservation_in_days_2',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DrugEquivalenceId = 'drug_equivalence_id',
  /** column name */
  DrugFormId = 'drug_form_id',
  /** column name */
  Form = 'form',
  /** column name */
  Id = 'id',
  /** column name */
  Ingredient = 'ingredient',
  /** column name */
  LegalStatusQc = 'legal_status_qc',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameFr = 'name_fr',
  /** column name */
  Number = 'number',
  /** column name */
  Strength = 'strength',
  /** column name */
  StrengthEn = 'strength_en',
  /** column name */
  StrengthFr = 'strength_fr',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WasCeased = 'was_ceased'
}

export type Drug_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Drug_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Drug_Set_Input>;
  /** filter the rows which have to be updated */
  where: Drug_Bool_Exp;
};

/** order by var_pop() on columns of table "drug" */
export type Drug_Var_Pop_Order_By = {
  conservation_in_days?: InputMaybe<Order_By>;
  conservation_in_days_2?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "drug" */
export type Drug_Var_Samp_Order_By = {
  conservation_in_days?: InputMaybe<Order_By>;
  conservation_in_days_2?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "drug" */
export type Drug_Variance_Order_By = {
  conservation_in_days?: InputMaybe<Order_By>;
  conservation_in_days_2?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "empego_account". All fields are combined with a logical 'AND'. */
export type Empego_Account_Bool_Exp = {
  _and?: InputMaybe<Array<Empego_Account_Bool_Exp>>;
  _not?: InputMaybe<Empego_Account_Bool_Exp>;
  _or?: InputMaybe<Array<Empego_Account_Bool_Exp>>;
  account_uid?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  empego_patient_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "empego_account" */
export enum Empego_Account_Constraint {
  /** unique or primary key constraint on columns "empego_patient_id" */
  EmpegoAccountEmpegoPatientIdKey = 'empego_account_empego_patient_id_key',
  /** unique or primary key constraint on columns "account_uid" */
  EmpegoAccountPkey = 'empego_account_pkey'
}

/** input type for inserting data into table "empego_account" */
export type Empego_Account_Insert_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  empego_patient_id?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "empego_account" */
export type Empego_Account_Obj_Rel_Insert_Input = {
  data: Empego_Account_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Empego_Account_On_Conflict>;
};

/** on_conflict condition type for table "empego_account" */
export type Empego_Account_On_Conflict = {
  constraint: Empego_Account_Constraint;
  update_columns?: Array<Empego_Account_Update_Column>;
  where?: InputMaybe<Empego_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "empego_account". */
export type Empego_Account_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  empego_patient_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: empego_account */
export type Empego_Account_Pk_Columns_Input = {
  account_uid: Scalars['String'];
};

/** select columns of table "empego_account" */
export enum Empego_Account_Select_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmpegoPatientId = 'empego_patient_id'
}

/** input type for updating data in table "empego_account" */
export type Empego_Account_Set_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  empego_patient_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "empego_account" */
export type Empego_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Empego_Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Empego_Account_Stream_Cursor_Value_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  empego_patient_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "empego_account" */
export enum Empego_Account_Update_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmpegoPatientId = 'empego_patient_id'
}

export type Empego_Account_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Empego_Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: Empego_Account_Bool_Exp;
};

export type Fax_Aggregate_Bool_Exp = {
  count?: InputMaybe<Fax_Aggregate_Bool_Exp_Count>;
};

export type Fax_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Fax_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Fax_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "fax" */
export type Fax_Aggregate_Order_By = {
  avg?: InputMaybe<Fax_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Fax_Max_Order_By>;
  min?: InputMaybe<Fax_Min_Order_By>;
  stddev?: InputMaybe<Fax_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Fax_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Fax_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Fax_Sum_Order_By>;
  var_pop?: InputMaybe<Fax_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Fax_Var_Samp_Order_By>;
  variance?: InputMaybe<Fax_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Fax_Append_Input = {
  ai_assignation?: InputMaybe<Scalars['jsonb']>;
  properties?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "fax" */
export type Fax_Arr_Rel_Insert_Input = {
  data: Array<Fax_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Fax_On_Conflict>;
};

/** order by avg() on columns of table "fax" */
export type Fax_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "fax". All fields are combined with a logical 'AND'. */
export type Fax_Bool_Exp = {
  _and?: InputMaybe<Array<Fax_Bool_Exp>>;
  _not?: InputMaybe<Fax_Bool_Exp>;
  _or?: InputMaybe<Array<Fax_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_uid?: InputMaybe<String_Comparison_Exp>;
  ai_assignation?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  direction?: InputMaybe<Config_Fax_Direction_Enum_Comparison_Exp>;
  filename?: InputMaybe<String_Comparison_Exp>;
  hellofax_guid?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  outbound_send_status?: InputMaybe<Config_Outbound_Fax_Send_Status_Enum_Comparison_Exp>;
  outbound_to_number?: InputMaybe<String_Comparison_Exp>;
  prescription_renewal_request_id?: InputMaybe<Uuid_Comparison_Exp>;
  printed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  properties?: InputMaybe<Jsonb_Comparison_Exp>;
  srfax_guid?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Config_Fax_Status_Enum_Comparison_Exp>;
  type?: InputMaybe<Config_Fax_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "fax" */
export enum Fax_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_692f161de4983494880cf05737f = 'PK_692f161de4983494880cf05737f',
  /** unique or primary key constraint on columns "hellofax_guid" */
  UqFcfdc17ac1d7c354d594e757ec9 = 'UQ_fcfdc17ac1d7c354d594e757ec9',
  /** unique or primary key constraint on columns "srfax_guid" */
  FaxSrfaxGuidKey = 'fax_srfax_guid_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Fax_Delete_At_Path_Input = {
  ai_assignation?: InputMaybe<Array<Scalars['String']>>;
  properties?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Fax_Delete_Elem_Input = {
  ai_assignation?: InputMaybe<Scalars['Int']>;
  properties?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Fax_Delete_Key_Input = {
  ai_assignation?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "fax" */
export type Fax_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "fax" */
export type Fax_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_uid?: InputMaybe<Scalars['String']>;
  ai_assignation?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  direction?: InputMaybe<Config_Fax_Direction_Enum>;
  filename?: InputMaybe<Scalars['String']>;
  hellofax_guid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  outbound_send_status?: InputMaybe<Config_Outbound_Fax_Send_Status_Enum>;
  outbound_to_number?: InputMaybe<Scalars['String']>;
  prescription_renewal_request_id?: InputMaybe<Scalars['uuid']>;
  printed_at?: InputMaybe<Scalars['timestamp']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  srfax_guid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Fax_Status_Enum>;
  type?: InputMaybe<Config_Fax_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "fax" */
export type Fax_Max_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  hellofax_guid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  outbound_to_number?: InputMaybe<Order_By>;
  prescription_renewal_request_id?: InputMaybe<Order_By>;
  printed_at?: InputMaybe<Order_By>;
  srfax_guid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "fax" */
export type Fax_Min_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  hellofax_guid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  outbound_to_number?: InputMaybe<Order_By>;
  prescription_renewal_request_id?: InputMaybe<Order_By>;
  printed_at?: InputMaybe<Order_By>;
  srfax_guid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "fax" */
export type Fax_On_Conflict = {
  constraint: Fax_Constraint;
  update_columns?: Array<Fax_Update_Column>;
  where?: InputMaybe<Fax_Bool_Exp>;
};

/** Ordering options when selecting data from "fax". */
export type Fax_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_uid?: InputMaybe<Order_By>;
  ai_assignation?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  hellofax_guid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  outbound_send_status?: InputMaybe<Order_By>;
  outbound_to_number?: InputMaybe<Order_By>;
  prescription_renewal_request_id?: InputMaybe<Order_By>;
  printed_at?: InputMaybe<Order_By>;
  properties?: InputMaybe<Order_By>;
  srfax_guid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fax */
export type Fax_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Fax_Prepend_Input = {
  ai_assignation?: InputMaybe<Scalars['jsonb']>;
  properties?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "fax" */
export enum Fax_Select_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  AiAssignation = 'ai_assignation',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Direction = 'direction',
  /** column name */
  Filename = 'filename',
  /** column name */
  HellofaxGuid = 'hellofax_guid',
  /** column name */
  Id = 'id',
  /** column name */
  OutboundSendStatus = 'outbound_send_status',
  /** column name */
  OutboundToNumber = 'outbound_to_number',
  /** column name */
  PrescriptionRenewalRequestId = 'prescription_renewal_request_id',
  /** column name */
  PrintedAt = 'printed_at',
  /** column name */
  Properties = 'properties',
  /** column name */
  SrfaxGuid = 'srfax_guid',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "fax" */
export type Fax_Set_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  ai_assignation?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  direction?: InputMaybe<Config_Fax_Direction_Enum>;
  filename?: InputMaybe<Scalars['String']>;
  hellofax_guid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  outbound_send_status?: InputMaybe<Config_Outbound_Fax_Send_Status_Enum>;
  outbound_to_number?: InputMaybe<Scalars['String']>;
  prescription_renewal_request_id?: InputMaybe<Scalars['uuid']>;
  printed_at?: InputMaybe<Scalars['timestamp']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  srfax_guid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Fax_Status_Enum>;
  type?: InputMaybe<Config_Fax_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "fax" */
export type Fax_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "fax" */
export type Fax_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "fax" */
export type Fax_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "fax" */
export type Fax_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fax_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fax_Stream_Cursor_Value_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  ai_assignation?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  direction?: InputMaybe<Config_Fax_Direction_Enum>;
  filename?: InputMaybe<Scalars['String']>;
  hellofax_guid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  outbound_send_status?: InputMaybe<Config_Outbound_Fax_Send_Status_Enum>;
  outbound_to_number?: InputMaybe<Scalars['String']>;
  prescription_renewal_request_id?: InputMaybe<Scalars['uuid']>;
  printed_at?: InputMaybe<Scalars['timestamp']>;
  properties?: InputMaybe<Scalars['jsonb']>;
  srfax_guid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Fax_Status_Enum>;
  type?: InputMaybe<Config_Fax_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "fax" */
export type Fax_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "fax" */
export enum Fax_Update_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  AiAssignation = 'ai_assignation',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Direction = 'direction',
  /** column name */
  Filename = 'filename',
  /** column name */
  HellofaxGuid = 'hellofax_guid',
  /** column name */
  Id = 'id',
  /** column name */
  OutboundSendStatus = 'outbound_send_status',
  /** column name */
  OutboundToNumber = 'outbound_to_number',
  /** column name */
  PrescriptionRenewalRequestId = 'prescription_renewal_request_id',
  /** column name */
  PrintedAt = 'printed_at',
  /** column name */
  Properties = 'properties',
  /** column name */
  SrfaxGuid = 'srfax_guid',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Fax_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Fax_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Fax_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Fax_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Fax_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Fax_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Fax_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Fax_Set_Input>;
  /** filter the rows which have to be updated */
  where: Fax_Bool_Exp;
};

/** order by var_pop() on columns of table "fax" */
export type Fax_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "fax" */
export type Fax_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "fax" */
export type Fax_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type Follow_Up_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Follow_Up_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Follow_Up_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Follow_Up_Aggregate_Bool_Exp_Count>;
};

export type Follow_Up_Aggregate_Bool_Exp_Bool_And = {
  arguments: Follow_Up_Select_Column_Follow_Up_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Follow_Up_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Follow_Up_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Follow_Up_Select_Column_Follow_Up_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Follow_Up_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Follow_Up_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Follow_Up_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Follow_Up_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "follow_up" */
export type Follow_Up_Aggregate_Order_By = {
  avg?: InputMaybe<Follow_Up_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Follow_Up_Max_Order_By>;
  min?: InputMaybe<Follow_Up_Min_Order_By>;
  stddev?: InputMaybe<Follow_Up_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Follow_Up_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Follow_Up_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Follow_Up_Sum_Order_By>;
  var_pop?: InputMaybe<Follow_Up_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Follow_Up_Var_Samp_Order_By>;
  variance?: InputMaybe<Follow_Up_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "follow_up" */
export type Follow_Up_Arr_Rel_Insert_Input = {
  data: Array<Follow_Up_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Follow_Up_On_Conflict>;
};

/** order by avg() on columns of table "follow_up" */
export type Follow_Up_Avg_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "follow_up". All fields are combined with a logical 'AND'. */
export type Follow_Up_Bool_Exp = {
  _and?: InputMaybe<Array<Follow_Up_Bool_Exp>>;
  _not?: InputMaybe<Follow_Up_Bool_Exp>;
  _or?: InputMaybe<Array<Follow_Up_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_uid?: InputMaybe<String_Comparison_Exp>;
  answered_at?: InputMaybe<Timestamp_Comparison_Exp>;
  cancelled_at?: InputMaybe<Timestamp_Comparison_Exp>;
  completed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  contact_status?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  dismissed_by_patient?: InputMaybe<Boolean_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  program?: InputMaybe<String_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
  seq_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Config_Empego_Follow_Up_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "follow_up" */
export enum Follow_Up_Constraint {
  /** unique or primary key constraint on columns "external_id" */
  FollowUpExternalIdKey = 'follow_up_external_id_key',
  /** unique or primary key constraint on columns "id" */
  FollowUpPkey = 'follow_up_pkey',
  /** unique or primary key constraint on columns "seq_id" */
  FollowUpSeqIdKey = 'follow_up_seq_id_key'
}

/** input type for incrementing numeric columns in table "follow_up" */
export type Follow_Up_Inc_Input = {
  seq_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "follow_up" */
export type Follow_Up_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_uid?: InputMaybe<Scalars['String']>;
  answered_at?: InputMaybe<Scalars['timestamp']>;
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  completed_at?: InputMaybe<Scalars['timestamp']>;
  contact_status?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  dismissed_by_patient?: InputMaybe<Scalars['Boolean']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  sent_at?: InputMaybe<Scalars['timestamp']>;
  seq_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Config_Empego_Follow_Up_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  url?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "follow_up" */
export type Follow_Up_Max_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  answered_at?: InputMaybe<Order_By>;
  cancelled_at?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  contact_status?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "follow_up" */
export type Follow_Up_Min_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  answered_at?: InputMaybe<Order_By>;
  cancelled_at?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  contact_status?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "follow_up" */
export type Follow_Up_On_Conflict = {
  constraint: Follow_Up_Constraint;
  update_columns?: Array<Follow_Up_Update_Column>;
  where?: InputMaybe<Follow_Up_Bool_Exp>;
};

/** Ordering options when selecting data from "follow_up". */
export type Follow_Up_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_uid?: InputMaybe<Order_By>;
  answered_at?: InputMaybe<Order_By>;
  cancelled_at?: InputMaybe<Order_By>;
  completed_at?: InputMaybe<Order_By>;
  contact_status?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dismissed_by_patient?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: follow_up */
export type Follow_Up_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "follow_up" */
export enum Follow_Up_Select_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  AnsweredAt = 'answered_at',
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  ContactStatus = 'contact_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DismissedByPatient = 'dismissed_by_patient',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Program = 'program',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  SeqId = 'seq_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** select "follow_up_aggregate_bool_exp_bool_and_arguments_columns" columns of table "follow_up" */
export enum Follow_Up_Select_Column_Follow_Up_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DismissedByPatient = 'dismissed_by_patient'
}

/** select "follow_up_aggregate_bool_exp_bool_or_arguments_columns" columns of table "follow_up" */
export enum Follow_Up_Select_Column_Follow_Up_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DismissedByPatient = 'dismissed_by_patient'
}

/** input type for updating data in table "follow_up" */
export type Follow_Up_Set_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  answered_at?: InputMaybe<Scalars['timestamp']>;
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  completed_at?: InputMaybe<Scalars['timestamp']>;
  contact_status?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  dismissed_by_patient?: InputMaybe<Scalars['Boolean']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  sent_at?: InputMaybe<Scalars['timestamp']>;
  seq_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Config_Empego_Follow_Up_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  url?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "follow_up" */
export type Follow_Up_Stddev_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "follow_up" */
export type Follow_Up_Stddev_Pop_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "follow_up" */
export type Follow_Up_Stddev_Samp_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "follow_up" */
export type Follow_Up_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Follow_Up_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Follow_Up_Stream_Cursor_Value_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  answered_at?: InputMaybe<Scalars['timestamp']>;
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  completed_at?: InputMaybe<Scalars['timestamp']>;
  contact_status?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  dismissed_by_patient?: InputMaybe<Scalars['Boolean']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  sent_at?: InputMaybe<Scalars['timestamp']>;
  seq_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Config_Empego_Follow_Up_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  url?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "follow_up" */
export type Follow_Up_Sum_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

/** update columns of table "follow_up" */
export enum Follow_Up_Update_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  AnsweredAt = 'answered_at',
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  ContactStatus = 'contact_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DismissedByPatient = 'dismissed_by_patient',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Program = 'program',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  SeqId = 'seq_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type Follow_Up_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Follow_Up_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Follow_Up_Set_Input>;
  /** filter the rows which have to be updated */
  where: Follow_Up_Bool_Exp;
};

/** order by var_pop() on columns of table "follow_up" */
export type Follow_Up_Var_Pop_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "follow_up" */
export type Follow_Up_Var_Samp_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "follow_up" */
export type Follow_Up_Variance_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

export type Form_Consultation_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Form_Consultation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Form_Consultation_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Form_Consultation_Aggregate_Bool_Exp_Count>;
};

export type Form_Consultation_Aggregate_Bool_Exp_Bool_And = {
  arguments: Form_Consultation_Select_Column_Form_Consultation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Form_Consultation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Form_Consultation_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Form_Consultation_Select_Column_Form_Consultation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Form_Consultation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Form_Consultation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Form_Consultation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Form_Consultation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "form_consultation" */
export type Form_Consultation_Aggregate_Order_By = {
  avg?: InputMaybe<Form_Consultation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Form_Consultation_Max_Order_By>;
  min?: InputMaybe<Form_Consultation_Min_Order_By>;
  stddev?: InputMaybe<Form_Consultation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Form_Consultation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Form_Consultation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Form_Consultation_Sum_Order_By>;
  var_pop?: InputMaybe<Form_Consultation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Form_Consultation_Var_Samp_Order_By>;
  variance?: InputMaybe<Form_Consultation_Variance_Order_By>;
};

/** Boolean expression to filter rows from the table "form_consultation_and_follow_up". All fields are combined with a logical 'AND'. */
export type Form_Consultation_And_Follow_Up_Bool_Exp = {
  _and?: InputMaybe<Array<Form_Consultation_And_Follow_Up_Bool_Exp>>;
  _not?: InputMaybe<Form_Consultation_And_Follow_Up_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Consultation_And_Follow_Up_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_uid?: InputMaybe<String_Comparison_Exp>;
  asynchronous?: InputMaybe<Boolean_Comparison_Exp>;
  booking_event?: InputMaybe<Booking_Event_Bool_Exp>;
  booking_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  consultation_program?: InputMaybe<Config_Consultation_Program_Bool_Exp>;
  contact_status?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  first_call_attempted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  form_completed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  form_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  prescription_status?: InputMaybe<String_Comparison_Exp>;
  program_id?: InputMaybe<Uuid_Comparison_Exp>;
  seq_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "form_consultation_and_follow_up". */
export type Form_Consultation_And_Follow_Up_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_uid?: InputMaybe<Order_By>;
  asynchronous?: InputMaybe<Order_By>;
  booking_event?: InputMaybe<Booking_Event_Order_By>;
  booking_event_id?: InputMaybe<Order_By>;
  consultation_program?: InputMaybe<Config_Consultation_Program_Order_By>;
  contact_status?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_call_attempted_at?: InputMaybe<Order_By>;
  form_completed_at?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  prescription_status?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** select columns of table "form_consultation_and_follow_up" */
export enum Form_Consultation_And_Follow_Up_Select_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  Asynchronous = 'asynchronous',
  /** column name */
  BookingEventId = 'booking_event_id',
  /** column name */
  ContactStatus = 'contact_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstCallAttemptedAt = 'first_call_attempted_at',
  /** column name */
  FormCompletedAt = 'form_completed_at',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  PrescriptionStatus = 'prescription_status',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  SeqId = 'seq_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** Streaming cursor of the table "form_consultation_and_follow_up" */
export type Form_Consultation_And_Follow_Up_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Form_Consultation_And_Follow_Up_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Form_Consultation_And_Follow_Up_Stream_Cursor_Value_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  asynchronous?: InputMaybe<Scalars['Boolean']>;
  booking_event_id?: InputMaybe<Scalars['uuid']>;
  contact_status?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  first_call_attempted_at?: InputMaybe<Scalars['timestamp']>;
  form_completed_at?: InputMaybe<Scalars['timestamp']>;
  form_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  prescription_status?: InputMaybe<Scalars['String']>;
  program_id?: InputMaybe<Scalars['uuid']>;
  seq_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  url?: InputMaybe<Scalars['String']>;
};

/** input type for inserting array relation for remote table "form_consultation" */
export type Form_Consultation_Arr_Rel_Insert_Input = {
  data: Array<Form_Consultation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Consultation_On_Conflict>;
};

/** order by avg() on columns of table "form_consultation" */
export type Form_Consultation_Avg_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "form_consultation". All fields are combined with a logical 'AND'. */
export type Form_Consultation_Bool_Exp = {
  _and?: InputMaybe<Array<Form_Consultation_Bool_Exp>>;
  _not?: InputMaybe<Form_Consultation_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Consultation_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_uid?: InputMaybe<String_Comparison_Exp>;
  asynchronous?: InputMaybe<Boolean_Comparison_Exp>;
  booking_event?: InputMaybe<Booking_Event_Bool_Exp>;
  booking_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  cancelled_at?: InputMaybe<Timestamp_Comparison_Exp>;
  consultation_program?: InputMaybe<Config_Consultation_Program_Bool_Exp>;
  consultation_survey_results?: InputMaybe<Consultation_Survey_Result_Bool_Exp>;
  consultation_survey_results_aggregate?: InputMaybe<Consultation_Survey_Result_Aggregate_Bool_Exp>;
  contact_status?: InputMaybe<Config_Contact_Status_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  dismissed_by_patient?: InputMaybe<Boolean_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamp_Comparison_Exp>;
  first_call_attempted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  form_completed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  form_fill_url?: InputMaybe<String_Comparison_Exp>;
  form_id?: InputMaybe<String_Comparison_Exp>;
  has_latest_notification_been_seen?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  latest_survey_result?: InputMaybe<Survey_Result_Bool_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  notification_last_seen_at?: InputMaybe<Timestamp_Comparison_Exp>;
  notification_last_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  patient_care?: InputMaybe<Patient_Care_Bool_Exp>;
  prescription_status?: InputMaybe<Config_Form_Consultation_Prescription_Status_Enum_Comparison_Exp>;
  processed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  program_id?: InputMaybe<Uuid_Comparison_Exp>;
  seq_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_consultation" */
export enum Form_Consultation_Constraint {
  /** unique or primary key constraint on columns "booking_event_id" */
  FormConsultationBookingEventIdKey = 'form_consultation_booking_event_id_key',
  /** unique or primary key constraint on columns "form_id" */
  FormConsultationFormIdKey = 'form_consultation_form_id_key',
  /** unique or primary key constraint on columns "id" */
  FormConsultationPkey = 'form_consultation_pkey',
  /** unique or primary key constraint on columns "seq_id" */
  FormConsultationSeqIdKey = 'form_consultation_seq_id_key'
}

/** input type for incrementing numeric columns in table "form_consultation" */
export type Form_Consultation_Inc_Input = {
  seq_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "form_consultation" */
export type Form_Consultation_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_uid?: InputMaybe<Scalars['String']>;
  asynchronous?: InputMaybe<Scalars['Boolean']>;
  booking_event?: InputMaybe<Booking_Event_Obj_Rel_Insert_Input>;
  booking_event_id?: InputMaybe<Scalars['uuid']>;
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  consultation_program?: InputMaybe<Config_Consultation_Program_Obj_Rel_Insert_Input>;
  consultation_survey_results?: InputMaybe<Consultation_Survey_Result_Arr_Rel_Insert_Input>;
  contact_status?: InputMaybe<Config_Contact_Status_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  dismissed_by_patient?: InputMaybe<Scalars['Boolean']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  first_call_attempted_at?: InputMaybe<Scalars['timestamp']>;
  form_completed_at?: InputMaybe<Scalars['timestamp']>;
  form_fill_url?: InputMaybe<Scalars['String']>;
  form_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  notification_last_seen_at?: InputMaybe<Scalars['timestamp']>;
  notification_last_updated_at?: InputMaybe<Scalars['timestamp']>;
  patient_care?: InputMaybe<Patient_Care_Obj_Rel_Insert_Input>;
  prescription_status?: InputMaybe<Config_Form_Consultation_Prescription_Status_Enum>;
  /** Deprecated - 2022-08-10 */
  processed_at?: InputMaybe<Scalars['timestamptz']>;
  program_id?: InputMaybe<Scalars['uuid']>;
  seq_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "form_consultation" */
export type Form_Consultation_Max_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  booking_event_id?: InputMaybe<Order_By>;
  cancelled_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  first_call_attempted_at?: InputMaybe<Order_By>;
  form_completed_at?: InputMaybe<Order_By>;
  form_fill_url?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  notification_last_seen_at?: InputMaybe<Order_By>;
  notification_last_updated_at?: InputMaybe<Order_By>;
  /** Deprecated - 2022-08-10 */
  processed_at?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "form_consultation" */
export type Form_Consultation_Min_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  booking_event_id?: InputMaybe<Order_By>;
  cancelled_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  first_call_attempted_at?: InputMaybe<Order_By>;
  form_completed_at?: InputMaybe<Order_By>;
  form_fill_url?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  notification_last_seen_at?: InputMaybe<Order_By>;
  notification_last_updated_at?: InputMaybe<Order_By>;
  /** Deprecated - 2022-08-10 */
  processed_at?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "form_consultation" */
export type Form_Consultation_Obj_Rel_Insert_Input = {
  data: Form_Consultation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Consultation_On_Conflict>;
};

/** on_conflict condition type for table "form_consultation" */
export type Form_Consultation_On_Conflict = {
  constraint: Form_Consultation_Constraint;
  update_columns?: Array<Form_Consultation_Update_Column>;
  where?: InputMaybe<Form_Consultation_Bool_Exp>;
};

/** Ordering options when selecting data from "form_consultation". */
export type Form_Consultation_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_uid?: InputMaybe<Order_By>;
  asynchronous?: InputMaybe<Order_By>;
  booking_event?: InputMaybe<Booking_Event_Order_By>;
  booking_event_id?: InputMaybe<Order_By>;
  cancelled_at?: InputMaybe<Order_By>;
  consultation_program?: InputMaybe<Config_Consultation_Program_Order_By>;
  consultation_survey_results_aggregate?: InputMaybe<Consultation_Survey_Result_Aggregate_Order_By>;
  contact_status?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dismissed_by_patient?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  first_call_attempted_at?: InputMaybe<Order_By>;
  form_completed_at?: InputMaybe<Order_By>;
  form_fill_url?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  has_latest_notification_been_seen?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latest_survey_result_aggregate?: InputMaybe<Survey_Result_Aggregate_Order_By>;
  note?: InputMaybe<Order_By>;
  notification_last_seen_at?: InputMaybe<Order_By>;
  notification_last_updated_at?: InputMaybe<Order_By>;
  patient_care?: InputMaybe<Patient_Care_Order_By>;
  prescription_status?: InputMaybe<Order_By>;
  processed_at?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: form_consultation */
export type Form_Consultation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "form_consultation" */
export enum Form_Consultation_Select_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  Asynchronous = 'asynchronous',
  /** column name */
  BookingEventId = 'booking_event_id',
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  ContactStatus = 'contact_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DismissedByPatient = 'dismissed_by_patient',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  FirstCallAttemptedAt = 'first_call_attempted_at',
  /** column name */
  FormCompletedAt = 'form_completed_at',
  /** column name */
  FormFillUrl = 'form_fill_url',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  NotificationLastSeenAt = 'notification_last_seen_at',
  /** column name */
  NotificationLastUpdatedAt = 'notification_last_updated_at',
  /** column name */
  PrescriptionStatus = 'prescription_status',
  /** column name */
  ProcessedAt = 'processed_at',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  SeqId = 'seq_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "form_consultation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "form_consultation" */
export enum Form_Consultation_Select_Column_Form_Consultation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Asynchronous = 'asynchronous',
  /** column name */
  DismissedByPatient = 'dismissed_by_patient'
}

/** select "form_consultation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "form_consultation" */
export enum Form_Consultation_Select_Column_Form_Consultation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Asynchronous = 'asynchronous',
  /** column name */
  DismissedByPatient = 'dismissed_by_patient'
}

/** input type for updating data in table "form_consultation" */
export type Form_Consultation_Set_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  asynchronous?: InputMaybe<Scalars['Boolean']>;
  booking_event_id?: InputMaybe<Scalars['uuid']>;
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  contact_status?: InputMaybe<Config_Contact_Status_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  dismissed_by_patient?: InputMaybe<Scalars['Boolean']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  first_call_attempted_at?: InputMaybe<Scalars['timestamp']>;
  form_completed_at?: InputMaybe<Scalars['timestamp']>;
  form_fill_url?: InputMaybe<Scalars['String']>;
  form_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  notification_last_seen_at?: InputMaybe<Scalars['timestamp']>;
  notification_last_updated_at?: InputMaybe<Scalars['timestamp']>;
  prescription_status?: InputMaybe<Config_Form_Consultation_Prescription_Status_Enum>;
  /** Deprecated - 2022-08-10 */
  processed_at?: InputMaybe<Scalars['timestamptz']>;
  program_id?: InputMaybe<Scalars['uuid']>;
  seq_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "form_consultation" */
export type Form_Consultation_Stddev_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "form_consultation" */
export type Form_Consultation_Stddev_Pop_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "form_consultation" */
export type Form_Consultation_Stddev_Samp_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "form_consultation" */
export type Form_Consultation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Form_Consultation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Form_Consultation_Stream_Cursor_Value_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  asynchronous?: InputMaybe<Scalars['Boolean']>;
  booking_event_id?: InputMaybe<Scalars['uuid']>;
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  contact_status?: InputMaybe<Config_Contact_Status_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  dismissed_by_patient?: InputMaybe<Scalars['Boolean']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  first_call_attempted_at?: InputMaybe<Scalars['timestamp']>;
  form_completed_at?: InputMaybe<Scalars['timestamp']>;
  form_fill_url?: InputMaybe<Scalars['String']>;
  form_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  notification_last_seen_at?: InputMaybe<Scalars['timestamp']>;
  notification_last_updated_at?: InputMaybe<Scalars['timestamp']>;
  prescription_status?: InputMaybe<Config_Form_Consultation_Prescription_Status_Enum>;
  /** Deprecated - 2022-08-10 */
  processed_at?: InputMaybe<Scalars['timestamptz']>;
  program_id?: InputMaybe<Scalars['uuid']>;
  seq_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "form_consultation" */
export type Form_Consultation_Sum_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

/** update columns of table "form_consultation" */
export enum Form_Consultation_Update_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  Asynchronous = 'asynchronous',
  /** column name */
  BookingEventId = 'booking_event_id',
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  ContactStatus = 'contact_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DismissedByPatient = 'dismissed_by_patient',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  FirstCallAttemptedAt = 'first_call_attempted_at',
  /** column name */
  FormCompletedAt = 'form_completed_at',
  /** column name */
  FormFillUrl = 'form_fill_url',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  NotificationLastSeenAt = 'notification_last_seen_at',
  /** column name */
  NotificationLastUpdatedAt = 'notification_last_updated_at',
  /** column name */
  PrescriptionStatus = 'prescription_status',
  /** column name */
  ProcessedAt = 'processed_at',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  SeqId = 'seq_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Form_Consultation_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Form_Consultation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Form_Consultation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Form_Consultation_Bool_Exp;
};

/** order by var_pop() on columns of table "form_consultation" */
export type Form_Consultation_Var_Pop_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "form_consultation" */
export type Form_Consultation_Var_Samp_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "form_consultation" */
export type Form_Consultation_Variance_Order_By = {
  seq_id?: InputMaybe<Order_By>;
};

export type Fresh_Rate_Response_For_Address_Args = {
  address_id?: InputMaybe<Scalars['Int']>;
};

export type Health_Condition_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Health_Condition_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Health_Condition_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Health_Condition_Aggregate_Bool_Exp_Count>;
};

export type Health_Condition_Aggregate_Bool_Exp_Bool_And = {
  arguments: Health_Condition_Select_Column_Health_Condition_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Health_Condition_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Health_Condition_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Health_Condition_Select_Column_Health_Condition_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Health_Condition_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Health_Condition_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Health_Condition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Health_Condition_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "health_condition" */
export type Health_Condition_Aggregate_Order_By = {
  avg?: InputMaybe<Health_Condition_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Health_Condition_Max_Order_By>;
  min?: InputMaybe<Health_Condition_Min_Order_By>;
  stddev?: InputMaybe<Health_Condition_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Health_Condition_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Health_Condition_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Health_Condition_Sum_Order_By>;
  var_pop?: InputMaybe<Health_Condition_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Health_Condition_Var_Samp_Order_By>;
  variance?: InputMaybe<Health_Condition_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "health_condition" */
export type Health_Condition_Arr_Rel_Insert_Input = {
  data: Array<Health_Condition_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Health_Condition_On_Conflict>;
};

/** order by avg() on columns of table "health_condition" */
export type Health_Condition_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "health_condition". All fields are combined with a logical 'AND'. */
export type Health_Condition_Bool_Exp = {
  _and?: InputMaybe<Array<Health_Condition_Bool_Exp>>;
  _not?: InputMaybe<Health_Condition_Bool_Exp>;
  _or?: InputMaybe<Array<Health_Condition_Bool_Exp>>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name_en?: InputMaybe<String_Comparison_Exp>;
  name_fr?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_health_conditions?: InputMaybe<Patient_Health_Conditions_Bool_Exp>;
  patient_health_conditions_aggregate?: InputMaybe<Patient_Health_Conditions_Aggregate_Bool_Exp>;
  searchable?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "health_condition" */
export enum Health_Condition_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkEf2f58d03ff6cb40aff870f5847 = 'PK_ef2f58d03ff6cb40aff870f5847',
  /** unique or primary key constraint on columns "name_en" */
  HealthConditionNameEnKey = 'health_condition_name_en_key',
  /** unique or primary key constraint on columns "name_fr" */
  HealthConditionNameFrKey = 'health_condition_name_fr_key'
}

/** input type for incrementing numeric columns in table "health_condition" */
export type Health_Condition_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "health_condition" */
export type Health_Condition_Insert_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_health_conditions?: InputMaybe<Patient_Health_Conditions_Arr_Rel_Insert_Input>;
  searchable?: InputMaybe<Scalars['Boolean']>;
};

/** order by max() on columns of table "health_condition" */
export type Health_Condition_Max_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_en?: InputMaybe<Order_By>;
  name_fr?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "health_condition" */
export type Health_Condition_Min_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_en?: InputMaybe<Order_By>;
  name_fr?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "health_condition" */
export type Health_Condition_Obj_Rel_Insert_Input = {
  data: Health_Condition_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Health_Condition_On_Conflict>;
};

/** on_conflict condition type for table "health_condition" */
export type Health_Condition_On_Conflict = {
  constraint: Health_Condition_Constraint;
  update_columns?: Array<Health_Condition_Update_Column>;
  where?: InputMaybe<Health_Condition_Bool_Exp>;
};

/** Ordering options when selecting data from "health_condition". */
export type Health_Condition_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_en?: InputMaybe<Order_By>;
  name_fr?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_health_conditions_aggregate?: InputMaybe<Patient_Health_Conditions_Aggregate_Order_By>;
  searchable?: InputMaybe<Order_By>;
};

/** primary key columns input for table: health_condition */
export type Health_Condition_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "health_condition" */
export enum Health_Condition_Select_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameFr = 'name_fr',
  /** column name */
  Searchable = 'searchable'
}

/** select "health_condition_aggregate_bool_exp_bool_and_arguments_columns" columns of table "health_condition" */
export enum Health_Condition_Select_Column_Health_Condition_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Searchable = 'searchable'
}

/** select "health_condition_aggregate_bool_exp_bool_or_arguments_columns" columns of table "health_condition" */
export enum Health_Condition_Select_Column_Health_Condition_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Searchable = 'searchable'
}

/** input type for updating data in table "health_condition" */
export type Health_Condition_Set_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
  searchable?: InputMaybe<Scalars['Boolean']>;
};

/** order by stddev() on columns of table "health_condition" */
export type Health_Condition_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "health_condition" */
export type Health_Condition_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "health_condition" */
export type Health_Condition_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "health_condition" */
export type Health_Condition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Health_Condition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Health_Condition_Stream_Cursor_Value_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name_en?: InputMaybe<Scalars['String']>;
  name_fr?: InputMaybe<Scalars['String']>;
  searchable?: InputMaybe<Scalars['Boolean']>;
};

/** order by sum() on columns of table "health_condition" */
export type Health_Condition_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "health_condition" */
export enum Health_Condition_Update_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameFr = 'name_fr',
  /** column name */
  Searchable = 'searchable'
}

export type Health_Condition_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Health_Condition_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Health_Condition_Set_Input>;
  /** filter the rows which have to be updated */
  where: Health_Condition_Bool_Exp;
};

/** order by var_pop() on columns of table "health_condition" */
export type Health_Condition_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "health_condition" */
export type Health_Condition_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "health_condition" */
export type Health_Condition_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Identity_Verification_Aggregate_Bool_Exp = {
  count?: InputMaybe<Identity_Verification_Aggregate_Bool_Exp_Count>;
};

export type Identity_Verification_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Identity_Verification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Identity_Verification_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "identity_verification" */
export type Identity_Verification_Aggregate_Order_By = {
  avg?: InputMaybe<Identity_Verification_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Identity_Verification_Max_Order_By>;
  min?: InputMaybe<Identity_Verification_Min_Order_By>;
  stddev?: InputMaybe<Identity_Verification_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Identity_Verification_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Identity_Verification_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Identity_Verification_Sum_Order_By>;
  var_pop?: InputMaybe<Identity_Verification_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Identity_Verification_Var_Samp_Order_By>;
  variance?: InputMaybe<Identity_Verification_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "identity_verification" */
export type Identity_Verification_Arr_Rel_Insert_Input = {
  data: Array<Identity_Verification_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Identity_Verification_On_Conflict>;
};

/** order by avg() on columns of table "identity_verification" */
export type Identity_Verification_Avg_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "identity_verification". All fields are combined with a logical 'AND'. */
export type Identity_Verification_Bool_Exp = {
  _and?: InputMaybe<Array<Identity_Verification_Bool_Exp>>;
  _not?: InputMaybe<Identity_Verification_Bool_Exp>;
  _or?: InputMaybe<Array<Identity_Verification_Bool_Exp>>;
  approved_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  approved_by?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  method?: InputMaybe<Config_Identity_Verification_Method_Enum_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  rejected_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  rejected_by?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  upload?: InputMaybe<Upload_Bool_Exp>;
  upload_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "identity_verification" */
export enum Identity_Verification_Constraint {
  /** unique or primary key constraint on columns "method", "patient_uid" */
  IdentityVerificationPatientUidMethodIdx = 'identity_verification_patient_uid_method_idx',
  /** unique or primary key constraint on columns "id" */
  IdentityVerificationPkey = 'identity_verification_pkey'
}

/** input type for incrementing numeric columns in table "identity_verification" */
export type Identity_Verification_Inc_Input = {
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "identity_verification" */
export type Identity_Verification_Insert_Input = {
  approved_at?: InputMaybe<Scalars['timestamptz']>;
  approved_by?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  method?: InputMaybe<Config_Identity_Verification_Method_Enum>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  rejected_at?: InputMaybe<Scalars['timestamptz']>;
  rejected_by?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upload?: InputMaybe<Upload_Obj_Rel_Insert_Input>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "identity_verification" */
export type Identity_Verification_Max_Order_By = {
  approved_at?: InputMaybe<Order_By>;
  approved_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  rejected_at?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "identity_verification" */
export type Identity_Verification_Min_Order_By = {
  approved_at?: InputMaybe<Order_By>;
  approved_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  rejected_at?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "identity_verification" */
export type Identity_Verification_On_Conflict = {
  constraint: Identity_Verification_Constraint;
  update_columns?: Array<Identity_Verification_Update_Column>;
  where?: InputMaybe<Identity_Verification_Bool_Exp>;
};

/** Ordering options when selecting data from "identity_verification". */
export type Identity_Verification_Order_By = {
  approved_at?: InputMaybe<Order_By>;
  approved_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  rejected_at?: InputMaybe<Order_By>;
  rejected_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload?: InputMaybe<Upload_Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: identity_verification */
export type Identity_Verification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "identity_verification" */
export enum Identity_Verification_Select_Column {
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Method = 'method',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  RejectedBy = 'rejected_by',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadId = 'upload_id'
}

/** input type for updating data in table "identity_verification" */
export type Identity_Verification_Set_Input = {
  approved_at?: InputMaybe<Scalars['timestamptz']>;
  approved_by?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  method?: InputMaybe<Config_Identity_Verification_Method_Enum>;
  patient_uid?: InputMaybe<Scalars['String']>;
  rejected_at?: InputMaybe<Scalars['timestamptz']>;
  rejected_by?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "identity_verification" */
export type Identity_Verification_Stddev_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "identity_verification" */
export type Identity_Verification_Stddev_Pop_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "identity_verification" */
export type Identity_Verification_Stddev_Samp_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "identity_verification" */
export type Identity_Verification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Identity_Verification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Identity_Verification_Stream_Cursor_Value_Input = {
  approved_at?: InputMaybe<Scalars['timestamptz']>;
  approved_by?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  method?: InputMaybe<Config_Identity_Verification_Method_Enum>;
  patient_uid?: InputMaybe<Scalars['String']>;
  rejected_at?: InputMaybe<Scalars['timestamptz']>;
  rejected_by?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "identity_verification" */
export type Identity_Verification_Sum_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** update columns of table "identity_verification" */
export enum Identity_Verification_Update_Column {
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Method = 'method',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  RejectedBy = 'rejected_by',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadId = 'upload_id'
}

export type Identity_Verification_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Identity_Verification_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Identity_Verification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Identity_Verification_Bool_Exp;
};

/** order by var_pop() on columns of table "identity_verification" */
export type Identity_Verification_Var_Pop_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "identity_verification" */
export type Identity_Verification_Var_Samp_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "identity_verification" */
export type Identity_Verification_Variance_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

export type In_App_Notification_Dismissal_Aggregate_Bool_Exp = {
  count?: InputMaybe<In_App_Notification_Dismissal_Aggregate_Bool_Exp_Count>;
};

export type In_App_Notification_Dismissal_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<In_App_Notification_Dismissal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<In_App_Notification_Dismissal_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "in_app_notification_dismissal" */
export type In_App_Notification_Dismissal_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<In_App_Notification_Dismissal_Max_Order_By>;
  min?: InputMaybe<In_App_Notification_Dismissal_Min_Order_By>;
};

/** input type for inserting array relation for remote table "in_app_notification_dismissal" */
export type In_App_Notification_Dismissal_Arr_Rel_Insert_Input = {
  data: Array<In_App_Notification_Dismissal_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<In_App_Notification_Dismissal_On_Conflict>;
};

/** Boolean expression to filter rows from the table "in_app_notification_dismissal". All fields are combined with a logical 'AND'. */
export type In_App_Notification_Dismissal_Bool_Exp = {
  _and?: InputMaybe<Array<In_App_Notification_Dismissal_Bool_Exp>>;
  _not?: InputMaybe<In_App_Notification_Dismissal_Bool_Exp>;
  _or?: InputMaybe<Array<In_App_Notification_Dismissal_Bool_Exp>>;
  dismissed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  dismissed_in_app_notification?: InputMaybe<Config_Dismissible_In_App_Notification_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "in_app_notification_dismissal" */
export enum In_App_Notification_Dismissal_Constraint {
  /** unique or primary key constraint on columns "patient_uid", "dismissed_in_app_notification" */
  InAppNotificationDismissalPatientUidDismissedInAppKey = 'in_app_notification_dismissal_patient_uid_dismissed_in_app__key',
  /** unique or primary key constraint on columns "id" */
  InAppNotificationDismissalPkey = 'in_app_notification_dismissal_pkey'
}

/** input type for inserting data into table "in_app_notification_dismissal" */
export type In_App_Notification_Dismissal_Insert_Input = {
  dismissed_at?: InputMaybe<Scalars['timestamp']>;
  dismissed_in_app_notification?: InputMaybe<Config_Dismissible_In_App_Notification_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_uid?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "in_app_notification_dismissal" */
export type In_App_Notification_Dismissal_Max_Order_By = {
  dismissed_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "in_app_notification_dismissal" */
export type In_App_Notification_Dismissal_Min_Order_By = {
  dismissed_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "in_app_notification_dismissal" */
export type In_App_Notification_Dismissal_On_Conflict = {
  constraint: In_App_Notification_Dismissal_Constraint;
  update_columns?: Array<In_App_Notification_Dismissal_Update_Column>;
  where?: InputMaybe<In_App_Notification_Dismissal_Bool_Exp>;
};

/** Ordering options when selecting data from "in_app_notification_dismissal". */
export type In_App_Notification_Dismissal_Order_By = {
  dismissed_at?: InputMaybe<Order_By>;
  dismissed_in_app_notification?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: in_app_notification_dismissal */
export type In_App_Notification_Dismissal_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "in_app_notification_dismissal" */
export enum In_App_Notification_Dismissal_Select_Column {
  /** column name */
  DismissedAt = 'dismissed_at',
  /** column name */
  DismissedInAppNotification = 'dismissed_in_app_notification',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid'
}

/** input type for updating data in table "in_app_notification_dismissal" */
export type In_App_Notification_Dismissal_Set_Input = {
  dismissed_at?: InputMaybe<Scalars['timestamp']>;
  dismissed_in_app_notification?: InputMaybe<Config_Dismissible_In_App_Notification_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_uid?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "in_app_notification_dismissal" */
export type In_App_Notification_Dismissal_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: In_App_Notification_Dismissal_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type In_App_Notification_Dismissal_Stream_Cursor_Value_Input = {
  dismissed_at?: InputMaybe<Scalars['timestamp']>;
  dismissed_in_app_notification?: InputMaybe<Config_Dismissible_In_App_Notification_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_uid?: InputMaybe<Scalars['String']>;
};

/** update columns of table "in_app_notification_dismissal" */
export enum In_App_Notification_Dismissal_Update_Column {
  /** column name */
  DismissedAt = 'dismissed_at',
  /** column name */
  DismissedInAppNotification = 'dismissed_in_app_notification',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid'
}

export type In_App_Notification_Dismissal_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<In_App_Notification_Dismissal_Set_Input>;
  /** filter the rows which have to be updated */
  where: In_App_Notification_Dismissal_Bool_Exp;
};

/** Boolean expression to compare columns of type "inet". All fields are combined with logical 'AND'. */
export type Inet_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['inet']>;
  _gt?: InputMaybe<Scalars['inet']>;
  _gte?: InputMaybe<Scalars['inet']>;
  _in?: InputMaybe<Array<Scalars['inet']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['inet']>;
  _lte?: InputMaybe<Scalars['inet']>;
  _neq?: InputMaybe<Scalars['inet']>;
  _nin?: InputMaybe<Array<Scalars['inet']>>;
};

/** Boolean expression to filter rows from the table "insurance". All fields are combined with a logical 'AND'. */
export type Insurance_Bool_Exp = {
  _and?: InputMaybe<Array<Insurance_Bool_Exp>>;
  _not?: InputMaybe<Insurance_Bool_Exp>;
  _or?: InputMaybe<Array<Insurance_Bool_Exp>>;
  cancelled?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  insurance_status?: InputMaybe<Config_Insurance_Status_Bool_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Config_Insurance_Status_Enum_Comparison_Exp>;
  type?: InputMaybe<Insurance_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  upload?: InputMaybe<Upload_Bool_Exp>;
  upload_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "insurance" */
export enum Insurance_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_07152a21fd75ea211dcea53e3c4 = 'PK_07152a21fd75ea211dcea53e3c4'
}

/** input type for incrementing numeric columns in table "insurance" */
export type Insurance_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "insurance" */
export type Insurance_Insert_Input = {
  cancelled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  insurance_status?: InputMaybe<Config_Insurance_Status_Obj_Rel_Insert_Input>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Insurance_Status_Enum>;
  type?: InputMaybe<Scalars['insurance_type_enum']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  upload?: InputMaybe<Upload_Obj_Rel_Insert_Input>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** on_conflict condition type for table "insurance" */
export type Insurance_On_Conflict = {
  constraint: Insurance_Constraint;
  update_columns?: Array<Insurance_Update_Column>;
  where?: InputMaybe<Insurance_Bool_Exp>;
};

/** Ordering options when selecting data from "insurance". */
export type Insurance_Order_By = {
  cancelled?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurance_status?: InputMaybe<Config_Insurance_Status_Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload?: InputMaybe<Upload_Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: insurance */
export type Insurance_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "insurance" */
export enum Insurance_Select_Column {
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadId = 'upload_id'
}

/** input type for updating data in table "insurance" */
export type Insurance_Set_Input = {
  cancelled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Insurance_Status_Enum>;
  type?: InputMaybe<Scalars['insurance_type_enum']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** Streaming cursor of the table "insurance" */
export type Insurance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Insurance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Insurance_Stream_Cursor_Value_Input = {
  cancelled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Insurance_Status_Enum>;
  type?: InputMaybe<Scalars['insurance_type_enum']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** Boolean expression to compare columns of type "insurance_type_enum". All fields are combined with logical 'AND'. */
export type Insurance_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['insurance_type_enum']>;
  _gt?: InputMaybe<Scalars['insurance_type_enum']>;
  _gte?: InputMaybe<Scalars['insurance_type_enum']>;
  _in?: InputMaybe<Array<Scalars['insurance_type_enum']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['insurance_type_enum']>;
  _lte?: InputMaybe<Scalars['insurance_type_enum']>;
  _neq?: InputMaybe<Scalars['insurance_type_enum']>;
  _nin?: InputMaybe<Array<Scalars['insurance_type_enum']>>;
};

/** update columns of table "insurance" */
export enum Insurance_Update_Column {
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadId = 'upload_id'
}

export type Insurance_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Insurance_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Insurance_Set_Input>;
  /** filter the rows which have to be updated */
  where: Insurance_Bool_Exp;
};

export type Invoice_Adjustment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Invoice_Adjustment_Aggregate_Bool_Exp_Count>;
};

export type Invoice_Adjustment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoice_Adjustment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoice_Adjustment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "invoice_adjustment" */
export type Invoice_Adjustment_Aggregate_Order_By = {
  avg?: InputMaybe<Invoice_Adjustment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoice_Adjustment_Max_Order_By>;
  min?: InputMaybe<Invoice_Adjustment_Min_Order_By>;
  stddev?: InputMaybe<Invoice_Adjustment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoice_Adjustment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoice_Adjustment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoice_Adjustment_Sum_Order_By>;
  var_pop?: InputMaybe<Invoice_Adjustment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoice_Adjustment_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoice_Adjustment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice_adjustment" */
export type Invoice_Adjustment_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Adjustment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_Adjustment_On_Conflict>;
};

/** order by avg() on columns of table "invoice_adjustment" */
export type Invoice_Adjustment_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice_adjustment". All fields are combined with a logical 'AND'. */
export type Invoice_Adjustment_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Adjustment_Bool_Exp>>;
  _not?: InputMaybe<Invoice_Adjustment_Bool_Exp>;
  _or?: InputMaybe<Array<Invoice_Adjustment_Bool_Exp>>;
  adjustment_code?: InputMaybe<Config_Invoice_Adjustment_Code_Enum_Comparison_Exp>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoice_id?: InputMaybe<Int_Comparison_Exp>;
  performed_by?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice_adjustment" */
export enum Invoice_Adjustment_Constraint {
  /** unique or primary key constraint on columns "invoice_id", "adjustment_code" */
  InvoiceAdjustmentInvoiceIdAdjustmentCodeKey = 'invoice_adjustment_invoice_id_adjustment_code_key',
  /** unique or primary key constraint on columns "id" */
  InvoiceAdjustmentPkey = 'invoice_adjustment_pkey'
}

/** input type for incrementing numeric columns in table "invoice_adjustment" */
export type Invoice_Adjustment_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "invoice_adjustment" */
export type Invoice_Adjustment_Insert_Input = {
  adjustment_code?: InputMaybe<Config_Invoice_Adjustment_Code_Enum>;
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  performed_by?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "invoice_adjustment" */
export type Invoice_Adjustment_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  performed_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "invoice_adjustment" */
export type Invoice_Adjustment_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  performed_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "invoice_adjustment" */
export type Invoice_Adjustment_On_Conflict = {
  constraint: Invoice_Adjustment_Constraint;
  update_columns?: Array<Invoice_Adjustment_Update_Column>;
  where?: InputMaybe<Invoice_Adjustment_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice_adjustment". */
export type Invoice_Adjustment_Order_By = {
  adjustment_code?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  performed_by?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoice_adjustment */
export type Invoice_Adjustment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "invoice_adjustment" */
export enum Invoice_Adjustment_Select_Column {
  /** column name */
  AdjustmentCode = 'adjustment_code',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  PerformedBy = 'performed_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "invoice_adjustment" */
export type Invoice_Adjustment_Set_Input = {
  adjustment_code?: InputMaybe<Config_Invoice_Adjustment_Code_Enum>;
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  performed_by?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "invoice_adjustment" */
export type Invoice_Adjustment_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "invoice_adjustment" */
export type Invoice_Adjustment_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "invoice_adjustment" */
export type Invoice_Adjustment_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoice_adjustment" */
export type Invoice_Adjustment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Adjustment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Adjustment_Stream_Cursor_Value_Input = {
  adjustment_code?: InputMaybe<Config_Invoice_Adjustment_Code_Enum>;
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  performed_by?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "invoice_adjustment" */
export type Invoice_Adjustment_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** update columns of table "invoice_adjustment" */
export enum Invoice_Adjustment_Update_Column {
  /** column name */
  AdjustmentCode = 'adjustment_code',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  PerformedBy = 'performed_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Invoice_Adjustment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Adjustment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Adjustment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Adjustment_Bool_Exp;
};

/** order by var_pop() on columns of table "invoice_adjustment" */
export type Invoice_Adjustment_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "invoice_adjustment" */
export type Invoice_Adjustment_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "invoice_adjustment" */
export type Invoice_Adjustment_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

export type Invoice_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Invoice_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Invoice_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Invoice_Aggregate_Bool_Exp_Count>;
};

export type Invoice_Aggregate_Bool_Exp_Bool_And = {
  arguments: Invoice_Select_Column_Invoice_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoice_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoice_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Invoice_Select_Column_Invoice_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoice_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoice_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoice_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "invoice" */
export type Invoice_Aggregate_Order_By = {
  avg?: InputMaybe<Invoice_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoice_Max_Order_By>;
  min?: InputMaybe<Invoice_Min_Order_By>;
  stddev?: InputMaybe<Invoice_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoice_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoice_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoice_Sum_Order_By>;
  var_pop?: InputMaybe<Invoice_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoice_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoice_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice" */
export type Invoice_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};

/** order by avg() on columns of table "invoice" */
export type Invoice_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  refunded_amount_old?: InputMaybe<Order_By>;
  total_rx_amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice". All fields are combined with a logical 'AND'. */
export type Invoice_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Bool_Exp>>;
  _not?: InputMaybe<Invoice_Bool_Exp>;
  _or?: InputMaybe<Array<Invoice_Bool_Exp>>;
  adjustment_amount?: InputMaybe<Int_Comparison_Exp>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  balance?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  credit_notes?: InputMaybe<Credit_Note_Bool_Exp>;
  credit_notes_aggregate?: InputMaybe<Credit_Note_Aggregate_Bool_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  details?: InputMaybe<Json_Comparison_Exp>;
  document_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invoice_adjustments?: InputMaybe<Invoice_Adjustment_Bool_Exp>;
  invoice_adjustments_aggregate?: InputMaybe<Invoice_Adjustment_Aggregate_Bool_Exp>;
  invoice_line_items?: InputMaybe<Invoice_Line_Item_Bool_Exp>;
  invoice_line_items_aggregate?: InputMaybe<Invoice_Line_Item_Aggregate_Bool_Exp>;
  invoice_status?: InputMaybe<Config_Invoice_Status_Bool_Exp>;
  invoice_type?: InputMaybe<Config_Invoice_Type_Bool_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  outdated?: InputMaybe<Boolean_Comparison_Exp>;
  paid?: InputMaybe<Int_Comparison_Exp>;
  refund_reason?: InputMaybe<String_Comparison_Exp>;
  refunded_amount?: InputMaybe<Int_Comparison_Exp>;
  refunded_amount_old?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Config_Invoice_Status_Enum_Comparison_Exp>;
  total_rx_amount?: InputMaybe<Int_Comparison_Exp>;
  transactions?: InputMaybe<Transaction_Bool_Exp>;
  transactions_aggregate?: InputMaybe<Transaction_Aggregate_Bool_Exp>;
  type?: InputMaybe<Config_Invoice_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice" */
export enum Invoice_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_15d25c200d9bcd8a33f698daf18 = 'PK_15d25c200d9bcd8a33f698daf18'
}

/** input type for incrementing numeric columns in table "invoice" */
export type Invoice_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  refunded_amount_old?: InputMaybe<Scalars['Int']>;
  total_rx_amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "invoice" */
export type Invoice_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  credit_notes?: InputMaybe<Credit_Note_Arr_Rel_Insert_Input>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  details?: InputMaybe<Scalars['json']>;
  document_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_adjustments?: InputMaybe<Invoice_Adjustment_Arr_Rel_Insert_Input>;
  invoice_line_items?: InputMaybe<Invoice_Line_Item_Arr_Rel_Insert_Input>;
  invoice_status?: InputMaybe<Config_Invoice_Status_Obj_Rel_Insert_Input>;
  invoice_type?: InputMaybe<Config_Invoice_Type_Obj_Rel_Insert_Input>;
  order?: InputMaybe<Order_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['Int']>;
  outdated?: InputMaybe<Scalars['Boolean']>;
  refund_reason?: InputMaybe<Scalars['String']>;
  refunded_amount_old?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Config_Invoice_Status_Enum>;
  total_rx_amount?: InputMaybe<Scalars['Int']>;
  transactions?: InputMaybe<Transaction_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Config_Invoice_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

export type Invoice_Line_Item_Aggregate_Bool_Exp = {
  count?: InputMaybe<Invoice_Line_Item_Aggregate_Bool_Exp_Count>;
};

export type Invoice_Line_Item_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoice_Line_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoice_Line_Item_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "invoice_line_item" */
export type Invoice_Line_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Invoice_Line_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoice_Line_Item_Max_Order_By>;
  min?: InputMaybe<Invoice_Line_Item_Min_Order_By>;
  stddev?: InputMaybe<Invoice_Line_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoice_Line_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoice_Line_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoice_Line_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Invoice_Line_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoice_Line_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoice_Line_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice_line_item" */
export type Invoice_Line_Item_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Line_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_Line_Item_On_Conflict>;
};

/** order by avg() on columns of table "invoice_line_item" */
export type Invoice_Line_Item_Avg_Order_By = {
  invoice_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice_line_item". All fields are combined with a logical 'AND'. */
export type Invoice_Line_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Line_Item_Bool_Exp>>;
  _not?: InputMaybe<Invoice_Line_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Invoice_Line_Item_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoice_id?: InputMaybe<Int_Comparison_Exp>;
  order_line_item?: InputMaybe<Order_Line_Item_Bool_Exp>;
  order_line_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  service_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice_line_item" */
export enum Invoice_Line_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_4ffb12a7ac2bb69aa7234f30b85 = 'PK_4ffb12a7ac2bb69aa7234f30b85'
}

/** input type for incrementing numeric columns in table "invoice_line_item" */
export type Invoice_Line_Item_Inc_Input = {
  invoice_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "invoice_line_item" */
export type Invoice_Line_Item_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  order_line_item?: InputMaybe<Order_Line_Item_Obj_Rel_Insert_Input>;
  order_line_item_id?: InputMaybe<Scalars['uuid']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  service_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "invoice_line_item" */
export type Invoice_Line_Item_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  order_line_item_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "invoice_line_item" */
export type Invoice_Line_Item_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  order_line_item_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "invoice_line_item" */
export type Invoice_Line_Item_On_Conflict = {
  constraint: Invoice_Line_Item_Constraint;
  update_columns?: Array<Invoice_Line_Item_Update_Column>;
  where?: InputMaybe<Invoice_Line_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice_line_item". */
export type Invoice_Line_Item_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  order_line_item?: InputMaybe<Order_Line_Item_Order_By>;
  order_line_item_id?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoice_line_item */
export type Invoice_Line_Item_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "invoice_line_item" */
export enum Invoice_Line_Item_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  OrderLineItemId = 'order_line_item_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "invoice_line_item" */
export type Invoice_Line_Item_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  order_line_item_id?: InputMaybe<Scalars['uuid']>;
  service_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "invoice_line_item" */
export type Invoice_Line_Item_Stddev_Order_By = {
  invoice_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "invoice_line_item" */
export type Invoice_Line_Item_Stddev_Pop_Order_By = {
  invoice_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "invoice_line_item" */
export type Invoice_Line_Item_Stddev_Samp_Order_By = {
  invoice_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoice_line_item" */
export type Invoice_Line_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Line_Item_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Line_Item_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  order_line_item_id?: InputMaybe<Scalars['uuid']>;
  service_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "invoice_line_item" */
export type Invoice_Line_Item_Sum_Order_By = {
  invoice_id?: InputMaybe<Order_By>;
};

/** update columns of table "invoice_line_item" */
export enum Invoice_Line_Item_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  OrderLineItemId = 'order_line_item_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Invoice_Line_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Line_Item_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Line_Item_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Line_Item_Bool_Exp;
};

/** order by var_pop() on columns of table "invoice_line_item" */
export type Invoice_Line_Item_Var_Pop_Order_By = {
  invoice_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "invoice_line_item" */
export type Invoice_Line_Item_Var_Samp_Order_By = {
  invoice_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "invoice_line_item" */
export type Invoice_Line_Item_Variance_Order_By = {
  invoice_id?: InputMaybe<Order_By>;
};

/** order by max() on columns of table "invoice" */
export type Invoice_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  refund_reason?: InputMaybe<Order_By>;
  refunded_amount_old?: InputMaybe<Order_By>;
  total_rx_amount?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "invoice" */
export type Invoice_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  refund_reason?: InputMaybe<Order_By>;
  refunded_amount_old?: InputMaybe<Order_By>;
  total_rx_amount?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "invoice" */
export type Invoice_Obj_Rel_Insert_Input = {
  data: Invoice_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};

/** on_conflict condition type for table "invoice" */
export type Invoice_On_Conflict = {
  constraint: Invoice_Constraint;
  update_columns?: Array<Invoice_Update_Column>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice". */
export type Invoice_Order_By = {
  adjustment_amount?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  credit_notes_aggregate?: InputMaybe<Credit_Note_Aggregate_Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_adjustments_aggregate?: InputMaybe<Invoice_Adjustment_Aggregate_Order_By>;
  invoice_line_items_aggregate?: InputMaybe<Invoice_Line_Item_Aggregate_Order_By>;
  invoice_status?: InputMaybe<Config_Invoice_Status_Order_By>;
  invoice_type?: InputMaybe<Config_Invoice_Type_Order_By>;
  order?: InputMaybe<Order_Order_By>;
  order_id?: InputMaybe<Order_By>;
  outdated?: InputMaybe<Order_By>;
  paid?: InputMaybe<Order_By>;
  refund_reason?: InputMaybe<Order_By>;
  refunded_amount?: InputMaybe<Order_By>;
  refunded_amount_old?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total_rx_amount?: InputMaybe<Order_By>;
  transactions_aggregate?: InputMaybe<Transaction_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoice */
export type Invoice_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "invoice" */
export enum Invoice_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Details = 'details',
  /** column name */
  DocumentUrl = 'document_url',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Outdated = 'outdated',
  /** column name */
  RefundReason = 'refund_reason',
  /** column name */
  RefundedAmountOld = 'refunded_amount_old',
  /** column name */
  Status = 'status',
  /** column name */
  TotalRxAmount = 'total_rx_amount',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "invoice_aggregate_bool_exp_bool_and_arguments_columns" columns of table "invoice" */
export enum Invoice_Select_Column_Invoice_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Outdated = 'outdated'
}

/** select "invoice_aggregate_bool_exp_bool_or_arguments_columns" columns of table "invoice" */
export enum Invoice_Select_Column_Invoice_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Outdated = 'outdated'
}

/** input type for updating data in table "invoice" */
export type Invoice_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  details?: InputMaybe<Scalars['json']>;
  document_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  outdated?: InputMaybe<Scalars['Boolean']>;
  refund_reason?: InputMaybe<Scalars['String']>;
  refunded_amount_old?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Config_Invoice_Status_Enum>;
  total_rx_amount?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Config_Invoice_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "invoice" */
export type Invoice_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  refunded_amount_old?: InputMaybe<Order_By>;
  total_rx_amount?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "invoice" */
export type Invoice_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  refunded_amount_old?: InputMaybe<Order_By>;
  total_rx_amount?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "invoice" */
export type Invoice_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  refunded_amount_old?: InputMaybe<Order_By>;
  total_rx_amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoice" */
export type Invoice_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  details?: InputMaybe<Scalars['json']>;
  document_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  outdated?: InputMaybe<Scalars['Boolean']>;
  refund_reason?: InputMaybe<Scalars['String']>;
  refunded_amount_old?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Config_Invoice_Status_Enum>;
  total_rx_amount?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Config_Invoice_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "invoice" */
export type Invoice_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  refunded_amount_old?: InputMaybe<Order_By>;
  total_rx_amount?: InputMaybe<Order_By>;
};

/** update columns of table "invoice" */
export enum Invoice_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Details = 'details',
  /** column name */
  DocumentUrl = 'document_url',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Outdated = 'outdated',
  /** column name */
  RefundReason = 'refund_reason',
  /** column name */
  RefundedAmountOld = 'refunded_amount_old',
  /** column name */
  Status = 'status',
  /** column name */
  TotalRxAmount = 'total_rx_amount',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Invoice_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Bool_Exp;
};

/** order by var_pop() on columns of table "invoice" */
export type Invoice_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  refunded_amount_old?: InputMaybe<Order_By>;
  total_rx_amount?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "invoice" */
export type Invoice_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  refunded_amount_old?: InputMaybe<Order_By>;
  total_rx_amount?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "invoice" */
export type Invoice_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  refunded_amount_old?: InputMaybe<Order_By>;
  total_rx_amount?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** Boolean expression to filter rows from the table "leomed_account". All fields are combined with a logical 'AND'. */
export type Leomed_Account_Bool_Exp = {
  _and?: InputMaybe<Array<Leomed_Account_Bool_Exp>>;
  _not?: InputMaybe<Leomed_Account_Bool_Exp>;
  _or?: InputMaybe<Array<Leomed_Account_Bool_Exp>>;
  account_uid?: InputMaybe<String_Comparison_Exp>;
  leomed_subject_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "leomed_account" */
export enum Leomed_Account_Constraint {
  /** unique or primary key constraint on columns "leomed_subject_id" */
  LeomedAccountLeomedSubjectIdKey = 'leomed_account_leomed_subject_id_key',
  /** unique or primary key constraint on columns "account_uid" */
  LeomedAccountPkey = 'leomed_account_pkey'
}

/** input type for inserting data into table "leomed_account" */
export type Leomed_Account_Insert_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  leomed_subject_id?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "leomed_account" */
export type Leomed_Account_Obj_Rel_Insert_Input = {
  data: Leomed_Account_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Leomed_Account_On_Conflict>;
};

/** on_conflict condition type for table "leomed_account" */
export type Leomed_Account_On_Conflict = {
  constraint: Leomed_Account_Constraint;
  update_columns?: Array<Leomed_Account_Update_Column>;
  where?: InputMaybe<Leomed_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "leomed_account". */
export type Leomed_Account_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  leomed_subject_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: leomed_account */
export type Leomed_Account_Pk_Columns_Input = {
  account_uid: Scalars['String'];
};

/** select columns of table "leomed_account" */
export enum Leomed_Account_Select_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  LeomedSubjectId = 'leomed_subject_id'
}

/** input type for updating data in table "leomed_account" */
export type Leomed_Account_Set_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  leomed_subject_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "leomed_account" */
export type Leomed_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leomed_Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Leomed_Account_Stream_Cursor_Value_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  leomed_subject_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "leomed_account" */
export enum Leomed_Account_Update_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  LeomedSubjectId = 'leomed_subject_id'
}

export type Leomed_Account_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Leomed_Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: Leomed_Account_Bool_Exp;
};

/** Boolean expression to filter rows from the table "locker". All fields are combined with a logical 'AND'. */
export type Locker_Bool_Exp = {
  _and?: InputMaybe<Array<Locker_Bool_Exp>>;
  _not?: InputMaybe<Locker_Bool_Exp>;
  _or?: InputMaybe<Array<Locker_Bool_Exp>>;
  address_line1?: InputMaybe<String_Comparison_Exp>;
  address_line2?: InputMaybe<String_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  default_shipment_rate?: InputMaybe<Config_Shipment_Rate_Bool_Exp>;
  default_shipment_rate_code?: InputMaybe<String_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  locality?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Point_Comparison_Exp>;
  location_name?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  postcode?: InputMaybe<String_Comparison_Exp>;
  provider?: InputMaybe<Config_Locker_Provider_Enum_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  shipment_rates_v2?: InputMaybe<Config_Region_Shipment_Rate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "locker" */
export enum Locker_Constraint {
  /** unique or primary key constraint on columns "external_id", "provider" */
  LockerExternalIdProviderKey = 'locker_external_id_provider_key',
  /** unique or primary key constraint on columns "id" */
  LockerPkey = 'locker_pkey'
}

/** input type for inserting data into table "locker" */
export type Locker_Insert_Input = {
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default_shipment_rate?: InputMaybe<Config_Shipment_Rate_Obj_Rel_Insert_Input>;
  default_shipment_rate_code?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locality?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['point']>;
  location_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Config_Locker_Provider_Enum>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** input type for inserting object relation for remote table "locker" */
export type Locker_Obj_Rel_Insert_Input = {
  data: Locker_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Locker_On_Conflict>;
};

/** on_conflict condition type for table "locker" */
export type Locker_On_Conflict = {
  constraint: Locker_Constraint;
  update_columns?: Array<Locker_Update_Column>;
  where?: InputMaybe<Locker_Bool_Exp>;
};

/** Ordering options when selecting data from "locker". */
export type Locker_Order_By = {
  address_line1?: InputMaybe<Order_By>;
  address_line2?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_shipment_rate?: InputMaybe<Config_Shipment_Rate_Order_By>;
  default_shipment_rate_code?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locality?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  shipment_rates_v2_aggregate?: InputMaybe<Config_Region_Shipment_Rate_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: locker */
export type Locker_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "locker_reservation". All fields are combined with a logical 'AND'. */
export type Locker_Reservation_Bool_Exp = {
  _and?: InputMaybe<Array<Locker_Reservation_Bool_Exp>>;
  _not?: InputMaybe<Locker_Reservation_Bool_Exp>;
  _or?: InputMaybe<Array<Locker_Reservation_Bool_Exp>>;
  admin_pickup_code?: InputMaybe<Int_Comparison_Exp>;
  cancelled_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deposit_code?: InputMaybe<Int_Comparison_Exp>;
  deposit_expires_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deposited_at?: InputMaybe<Timestamp_Comparison_Exp>;
  door_position?: InputMaybe<String_Comparison_Exp>;
  door_size?: InputMaybe<String_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  locker?: InputMaybe<Locker_Bool_Exp>;
  locker_id?: InputMaybe<Uuid_Comparison_Exp>;
  notify_receiver?: InputMaybe<Boolean_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  picked_up_at?: InputMaybe<Timestamp_Comparison_Exp>;
  pickup_code?: InputMaybe<Int_Comparison_Exp>;
  reminded_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reservation_ended_at?: InputMaybe<Timestamp_Comparison_Exp>;
  reserved_at?: InputMaybe<Timestamp_Comparison_Exp>;
  shipment_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "locker_reservation" */
export enum Locker_Reservation_Constraint {
  /** unique or primary key constraint on columns "external_id" */
  LockerReservationExternalIdKey = 'locker_reservation_external_id_key',
  /** unique or primary key constraint on columns "id" */
  LockerReservationPkey = 'locker_reservation_pkey'
}

/** input type for incrementing numeric columns in table "locker_reservation" */
export type Locker_Reservation_Inc_Input = {
  admin_pickup_code?: InputMaybe<Scalars['Int']>;
  deposit_code?: InputMaybe<Scalars['Int']>;
  pickup_code?: InputMaybe<Scalars['Int']>;
  shipment_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "locker_reservation" */
export type Locker_Reservation_Insert_Input = {
  admin_pickup_code?: InputMaybe<Scalars['Int']>;
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  deposit_code?: InputMaybe<Scalars['Int']>;
  deposit_expires_at?: InputMaybe<Scalars['timestamp']>;
  deposited_at?: InputMaybe<Scalars['timestamp']>;
  door_position?: InputMaybe<Scalars['String']>;
  door_size?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locker?: InputMaybe<Locker_Obj_Rel_Insert_Input>;
  locker_id?: InputMaybe<Scalars['uuid']>;
  notify_receiver?: InputMaybe<Scalars['Boolean']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  picked_up_at?: InputMaybe<Scalars['timestamp']>;
  pickup_code?: InputMaybe<Scalars['Int']>;
  reminded_at?: InputMaybe<Scalars['timestamp']>;
  reservation_ended_at?: InputMaybe<Scalars['timestamp']>;
  reserved_at?: InputMaybe<Scalars['timestamp']>;
  shipment_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting object relation for remote table "locker_reservation" */
export type Locker_Reservation_Obj_Rel_Insert_Input = {
  data: Locker_Reservation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Locker_Reservation_On_Conflict>;
};

/** on_conflict condition type for table "locker_reservation" */
export type Locker_Reservation_On_Conflict = {
  constraint: Locker_Reservation_Constraint;
  update_columns?: Array<Locker_Reservation_Update_Column>;
  where?: InputMaybe<Locker_Reservation_Bool_Exp>;
};

/** Ordering options when selecting data from "locker_reservation". */
export type Locker_Reservation_Order_By = {
  admin_pickup_code?: InputMaybe<Order_By>;
  cancelled_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  deposit_code?: InputMaybe<Order_By>;
  deposit_expires_at?: InputMaybe<Order_By>;
  deposited_at?: InputMaybe<Order_By>;
  door_position?: InputMaybe<Order_By>;
  door_size?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locker?: InputMaybe<Locker_Order_By>;
  locker_id?: InputMaybe<Order_By>;
  notify_receiver?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  picked_up_at?: InputMaybe<Order_By>;
  pickup_code?: InputMaybe<Order_By>;
  reminded_at?: InputMaybe<Order_By>;
  reservation_ended_at?: InputMaybe<Order_By>;
  reserved_at?: InputMaybe<Order_By>;
  shipment_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: locker_reservation */
export type Locker_Reservation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "locker_reservation" */
export enum Locker_Reservation_Select_Column {
  /** column name */
  AdminPickupCode = 'admin_pickup_code',
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DepositCode = 'deposit_code',
  /** column name */
  DepositExpiresAt = 'deposit_expires_at',
  /** column name */
  DepositedAt = 'deposited_at',
  /** column name */
  DoorPosition = 'door_position',
  /** column name */
  DoorSize = 'door_size',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  LockerId = 'locker_id',
  /** column name */
  NotifyReceiver = 'notify_receiver',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PickedUpAt = 'picked_up_at',
  /** column name */
  PickupCode = 'pickup_code',
  /** column name */
  RemindedAt = 'reminded_at',
  /** column name */
  ReservationEndedAt = 'reservation_ended_at',
  /** column name */
  ReservedAt = 'reserved_at',
  /** column name */
  ShipmentId = 'shipment_id'
}

/** input type for updating data in table "locker_reservation" */
export type Locker_Reservation_Set_Input = {
  admin_pickup_code?: InputMaybe<Scalars['Int']>;
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  deposit_code?: InputMaybe<Scalars['Int']>;
  deposit_expires_at?: InputMaybe<Scalars['timestamp']>;
  deposited_at?: InputMaybe<Scalars['timestamp']>;
  door_position?: InputMaybe<Scalars['String']>;
  door_size?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locker_id?: InputMaybe<Scalars['uuid']>;
  notify_receiver?: InputMaybe<Scalars['Boolean']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  picked_up_at?: InputMaybe<Scalars['timestamp']>;
  pickup_code?: InputMaybe<Scalars['Int']>;
  reminded_at?: InputMaybe<Scalars['timestamp']>;
  reservation_ended_at?: InputMaybe<Scalars['timestamp']>;
  reserved_at?: InputMaybe<Scalars['timestamp']>;
  shipment_id?: InputMaybe<Scalars['Int']>;
};

/** Streaming cursor of the table "locker_reservation" */
export type Locker_Reservation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locker_Reservation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locker_Reservation_Stream_Cursor_Value_Input = {
  admin_pickup_code?: InputMaybe<Scalars['Int']>;
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  deposit_code?: InputMaybe<Scalars['Int']>;
  deposit_expires_at?: InputMaybe<Scalars['timestamp']>;
  deposited_at?: InputMaybe<Scalars['timestamp']>;
  door_position?: InputMaybe<Scalars['String']>;
  door_size?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locker_id?: InputMaybe<Scalars['uuid']>;
  notify_receiver?: InputMaybe<Scalars['Boolean']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  picked_up_at?: InputMaybe<Scalars['timestamp']>;
  pickup_code?: InputMaybe<Scalars['Int']>;
  reminded_at?: InputMaybe<Scalars['timestamp']>;
  reservation_ended_at?: InputMaybe<Scalars['timestamp']>;
  reserved_at?: InputMaybe<Scalars['timestamp']>;
  shipment_id?: InputMaybe<Scalars['Int']>;
};

/** update columns of table "locker_reservation" */
export enum Locker_Reservation_Update_Column {
  /** column name */
  AdminPickupCode = 'admin_pickup_code',
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DepositCode = 'deposit_code',
  /** column name */
  DepositExpiresAt = 'deposit_expires_at',
  /** column name */
  DepositedAt = 'deposited_at',
  /** column name */
  DoorPosition = 'door_position',
  /** column name */
  DoorSize = 'door_size',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  LockerId = 'locker_id',
  /** column name */
  NotifyReceiver = 'notify_receiver',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PickedUpAt = 'picked_up_at',
  /** column name */
  PickupCode = 'pickup_code',
  /** column name */
  RemindedAt = 'reminded_at',
  /** column name */
  ReservationEndedAt = 'reservation_ended_at',
  /** column name */
  ReservedAt = 'reserved_at',
  /** column name */
  ShipmentId = 'shipment_id'
}

export type Locker_Reservation_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Locker_Reservation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Locker_Reservation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Locker_Reservation_Bool_Exp;
};

/** select columns of table "locker" */
export enum Locker_Select_Column {
  /** column name */
  AddressLine1 = 'address_line1',
  /** column name */
  AddressLine2 = 'address_line2',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultShipmentRateCode = 'default_shipment_rate_code',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  Location = 'location',
  /** column name */
  LocationName = 'location_name',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  Provider = 'provider',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "locker" */
export type Locker_Set_Input = {
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default_shipment_rate_code?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locality?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['point']>;
  location_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Config_Locker_Provider_Enum>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "locker" */
export type Locker_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locker_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locker_Stream_Cursor_Value_Input = {
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default_shipment_rate_code?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locality?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['point']>;
  location_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Config_Locker_Provider_Enum>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "locker" */
export enum Locker_Update_Column {
  /** column name */
  AddressLine1 = 'address_line1',
  /** column name */
  AddressLine2 = 'address_line2',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultShipmentRateCode = 'default_shipment_rate_code',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Locality = 'locality',
  /** column name */
  Location = 'location',
  /** column name */
  LocationName = 'location_name',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  Provider = 'provider',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Locker_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Locker_Set_Input>;
  /** filter the rows which have to be updated */
  where: Locker_Bool_Exp;
};

export type Medication_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Medication_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Medication_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Medication_Aggregate_Bool_Exp_Count>;
};

export type Medication_Aggregate_Bool_Exp_Bool_And = {
  arguments: Medication_Select_Column_Medication_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Medication_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Medication_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Medication_Select_Column_Medication_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Medication_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Medication_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Medication_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Medication_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "medication" */
export type Medication_Aggregate_Order_By = {
  avg?: InputMaybe<Medication_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Medication_Max_Order_By>;
  min?: InputMaybe<Medication_Min_Order_By>;
  stddev?: InputMaybe<Medication_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Medication_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Medication_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Medication_Sum_Order_By>;
  var_pop?: InputMaybe<Medication_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Medication_Var_Samp_Order_By>;
  variance?: InputMaybe<Medication_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "medication" */
export type Medication_Arr_Rel_Insert_Input = {
  data: Array<Medication_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Medication_On_Conflict>;
};

/** order by avg() on columns of table "medication" */
export type Medication_Avg_Order_By = {
  /** This indicates the last specific drug served */
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "medication". All fields are combined with a logical 'AND'. */
export type Medication_Bool_Exp = {
  _and?: InputMaybe<Array<Medication_Bool_Exp>>;
  _not?: InputMaybe<Medication_Bool_Exp>;
  _or?: InputMaybe<Array<Medication_Bool_Exp>>;
  auto_renew?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  drug?: InputMaybe<Drug_Bool_Exp>;
  drug_equivalence?: InputMaybe<Drug_Equivalence_Bool_Exp>;
  drug_equivalence_id?: InputMaybe<Uuid_Comparison_Exp>;
  drug_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_non_prescription_drug?: InputMaybe<Boolean_Comparison_Exp>;
  medication_packaging?: InputMaybe<Config_Medication_Packaging_Bool_Exp>;
  medication_status?: InputMaybe<Config_Medication_Status_Bool_Exp>;
  medication_usage?: InputMaybe<Config_Medication_Usage_Bool_Exp>;
  order_line_items?: InputMaybe<Order_Line_Item_Bool_Exp>;
  order_line_items_aggregate?: InputMaybe<Order_Line_Item_Aggregate_Bool_Exp>;
  packaging?: InputMaybe<Config_Medication_Packaging_Enum_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  preferred_quantity?: InputMaybe<String_Comparison_Exp>;
  prescription_renewal_request_items?: InputMaybe<Prescription_Renewal_Request_Item_Bool_Exp>;
  prescription_renewal_request_items_aggregate?: InputMaybe<Prescription_Renewal_Request_Item_Aggregate_Bool_Exp>;
  prescriptions?: InputMaybe<Prescription_Bool_Exp>;
  prescriptions_aggregate?: InputMaybe<Prescription_Aggregate_Bool_Exp>;
  requested_quantity?: InputMaybe<String_Comparison_Exp>;
  schedule?: InputMaybe<Json_Comparison_Exp>;
  service_requests?: InputMaybe<Service_Request_Bool_Exp>;
  service_requests_aggregate?: InputMaybe<Service_Request_Aggregate_Bool_Exp>;
  specifications?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Config_Medication_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  usage?: InputMaybe<Config_Medication_Usage_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "medication" */
export enum Medication_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_0682f5b7379fea3c2fdb77d6545 = 'PK_0682f5b7379fea3c2fdb77d6545'
}

/** input type for incrementing numeric columns in table "medication" */
export type Medication_Inc_Input = {
  /** This indicates the last specific drug served */
  drug_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "medication" */
export type Medication_Insert_Input = {
  auto_renew?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug?: InputMaybe<Drug_Obj_Rel_Insert_Input>;
  drug_equivalence?: InputMaybe<Drug_Equivalence_Obj_Rel_Insert_Input>;
  drug_equivalence_id?: InputMaybe<Scalars['uuid']>;
  /** This indicates the last specific drug served */
  drug_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  medication_packaging?: InputMaybe<Config_Medication_Packaging_Obj_Rel_Insert_Input>;
  medication_status?: InputMaybe<Config_Medication_Status_Obj_Rel_Insert_Input>;
  medication_usage?: InputMaybe<Config_Medication_Usage_Obj_Rel_Insert_Input>;
  order_line_items?: InputMaybe<Order_Line_Item_Arr_Rel_Insert_Input>;
  packaging?: InputMaybe<Config_Medication_Packaging_Enum>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  /** DEPRECATED (2023/11) */
  preferred_quantity?: InputMaybe<Scalars['String']>;
  prescription_renewal_request_items?: InputMaybe<Prescription_Renewal_Request_Item_Arr_Rel_Insert_Input>;
  prescriptions?: InputMaybe<Prescription_Arr_Rel_Insert_Input>;
  requested_quantity?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['json']>;
  service_requests?: InputMaybe<Service_Request_Arr_Rel_Insert_Input>;
  specifications?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Medication_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  /** Deprecated since 2022-09-20 */
  usage?: InputMaybe<Config_Medication_Usage_Enum>;
};

/** order by max() on columns of table "medication" */
export type Medication_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drug_equivalence_id?: InputMaybe<Order_By>;
  /** This indicates the last specific drug served */
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/11) */
  preferred_quantity?: InputMaybe<Order_By>;
  requested_quantity?: InputMaybe<Order_By>;
  specifications?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "medication" */
export type Medication_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drug_equivalence_id?: InputMaybe<Order_By>;
  /** This indicates the last specific drug served */
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/11) */
  preferred_quantity?: InputMaybe<Order_By>;
  requested_quantity?: InputMaybe<Order_By>;
  specifications?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "medication_modification_request". All fields are combined with a logical 'AND'. */
export type Medication_Modification_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Medication_Modification_Request_Bool_Exp>>;
  _not?: InputMaybe<Medication_Modification_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Medication_Modification_Request_Bool_Exp>>;
  application?: InputMaybe<Config_Medication_Modification_Application_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  details?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  medication?: InputMaybe<Medication_Bool_Exp>;
  medication_id?: InputMaybe<Int_Comparison_Exp>;
  reason?: InputMaybe<Config_Medication_Modification_Reason_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "medication_modification_request" */
export enum Medication_Modification_Request_Constraint {
  /** unique or primary key constraint on columns "id" */
  MedicationModificationRequestPkey = 'medication_modification_request_pkey'
}

/** input type for incrementing numeric columns in table "medication_modification_request" */
export type Medication_Modification_Request_Inc_Input = {
  medication_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "medication_modification_request" */
export type Medication_Modification_Request_Insert_Input = {
  application?: InputMaybe<Config_Medication_Modification_Application_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  medication?: InputMaybe<Medication_Obj_Rel_Insert_Input>;
  medication_id?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Config_Medication_Modification_Reason_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** on_conflict condition type for table "medication_modification_request" */
export type Medication_Modification_Request_On_Conflict = {
  constraint: Medication_Modification_Request_Constraint;
  update_columns?: Array<Medication_Modification_Request_Update_Column>;
  where?: InputMaybe<Medication_Modification_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "medication_modification_request". */
export type Medication_Modification_Request_Order_By = {
  application?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication?: InputMaybe<Medication_Order_By>;
  medication_id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: medication_modification_request */
export type Medication_Modification_Request_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "medication_modification_request" */
export enum Medication_Modification_Request_Select_Column {
  /** column name */
  Application = 'application',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "medication_modification_request" */
export type Medication_Modification_Request_Set_Input = {
  application?: InputMaybe<Config_Medication_Modification_Application_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Config_Medication_Modification_Reason_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "medication_modification_request" */
export type Medication_Modification_Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Medication_Modification_Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Medication_Modification_Request_Stream_Cursor_Value_Input = {
  application?: InputMaybe<Config_Medication_Modification_Application_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  reason?: InputMaybe<Config_Medication_Modification_Reason_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "medication_modification_request" */
export enum Medication_Modification_Request_Update_Column {
  /** column name */
  Application = 'application',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Medication_Modification_Request_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Medication_Modification_Request_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Medication_Modification_Request_Set_Input>;
  /** filter the rows which have to be updated */
  where: Medication_Modification_Request_Bool_Exp;
};

/** input type for inserting object relation for remote table "medication" */
export type Medication_Obj_Rel_Insert_Input = {
  data: Medication_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Medication_On_Conflict>;
};

/** on_conflict condition type for table "medication" */
export type Medication_On_Conflict = {
  constraint: Medication_Constraint;
  update_columns?: Array<Medication_Update_Column>;
  where?: InputMaybe<Medication_Bool_Exp>;
};

/** Ordering options when selecting data from "medication". */
export type Medication_Order_By = {
  auto_renew?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drug?: InputMaybe<Drug_Order_By>;
  drug_equivalence?: InputMaybe<Drug_Equivalence_Order_By>;
  drug_equivalence_id?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_non_prescription_drug?: InputMaybe<Order_By>;
  medication_packaging?: InputMaybe<Config_Medication_Packaging_Order_By>;
  medication_status?: InputMaybe<Config_Medication_Status_Order_By>;
  medication_usage?: InputMaybe<Config_Medication_Usage_Order_By>;
  order_line_items_aggregate?: InputMaybe<Order_Line_Item_Aggregate_Order_By>;
  packaging?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  preferred_quantity?: InputMaybe<Order_By>;
  prescription_renewal_request_items_aggregate?: InputMaybe<Prescription_Renewal_Request_Item_Aggregate_Order_By>;
  prescriptions_aggregate?: InputMaybe<Prescription_Aggregate_Order_By>;
  requested_quantity?: InputMaybe<Order_By>;
  schedule?: InputMaybe<Order_By>;
  service_requests_aggregate?: InputMaybe<Service_Request_Aggregate_Order_By>;
  specifications?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  usage?: InputMaybe<Order_By>;
};

/** primary key columns input for table: medication */
export type Medication_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "medication" */
export enum Medication_Select_Column {
  /** column name */
  AutoRenew = 'auto_renew',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DrugEquivalenceId = 'drug_equivalence_id',
  /** column name */
  DrugId = 'drug_id',
  /** column name */
  Id = 'id',
  /** column name */
  Packaging = 'packaging',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PreferredQuantity = 'preferred_quantity',
  /** column name */
  RequestedQuantity = 'requested_quantity',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  Specifications = 'specifications',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Usage = 'usage'
}

/** select "medication_aggregate_bool_exp_bool_and_arguments_columns" columns of table "medication" */
export enum Medication_Select_Column_Medication_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AutoRenew = 'auto_renew'
}

/** select "medication_aggregate_bool_exp_bool_or_arguments_columns" columns of table "medication" */
export enum Medication_Select_Column_Medication_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AutoRenew = 'auto_renew'
}

/** input type for updating data in table "medication" */
export type Medication_Set_Input = {
  auto_renew?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug_equivalence_id?: InputMaybe<Scalars['uuid']>;
  /** This indicates the last specific drug served */
  drug_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  packaging?: InputMaybe<Config_Medication_Packaging_Enum>;
  patient_uid?: InputMaybe<Scalars['String']>;
  /** DEPRECATED (2023/11) */
  preferred_quantity?: InputMaybe<Scalars['String']>;
  requested_quantity?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['json']>;
  specifications?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Medication_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  /** Deprecated since 2022-09-20 */
  usage?: InputMaybe<Config_Medication_Usage_Enum>;
};

/** order by stddev() on columns of table "medication" */
export type Medication_Stddev_Order_By = {
  /** This indicates the last specific drug served */
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "medication" */
export type Medication_Stddev_Pop_Order_By = {
  /** This indicates the last specific drug served */
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "medication" */
export type Medication_Stddev_Samp_Order_By = {
  /** This indicates the last specific drug served */
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "medication" */
export type Medication_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Medication_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Medication_Stream_Cursor_Value_Input = {
  auto_renew?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug_equivalence_id?: InputMaybe<Scalars['uuid']>;
  /** This indicates the last specific drug served */
  drug_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  packaging?: InputMaybe<Config_Medication_Packaging_Enum>;
  patient_uid?: InputMaybe<Scalars['String']>;
  /** DEPRECATED (2023/11) */
  preferred_quantity?: InputMaybe<Scalars['String']>;
  requested_quantity?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['json']>;
  specifications?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Medication_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  /** Deprecated since 2022-09-20 */
  usage?: InputMaybe<Config_Medication_Usage_Enum>;
};

/** order by sum() on columns of table "medication" */
export type Medication_Sum_Order_By = {
  /** This indicates the last specific drug served */
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "medication" */
export enum Medication_Update_Column {
  /** column name */
  AutoRenew = 'auto_renew',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DrugEquivalenceId = 'drug_equivalence_id',
  /** column name */
  DrugId = 'drug_id',
  /** column name */
  Id = 'id',
  /** column name */
  Packaging = 'packaging',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PreferredQuantity = 'preferred_quantity',
  /** column name */
  RequestedQuantity = 'requested_quantity',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  Specifications = 'specifications',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Usage = 'usage'
}

export type Medication_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Medication_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Medication_Set_Input>;
  /** filter the rows which have to be updated */
  where: Medication_Bool_Exp;
};

/** order by var_pop() on columns of table "medication" */
export type Medication_Var_Pop_Order_By = {
  /** This indicates the last specific drug served */
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "medication" */
export type Medication_Var_Samp_Order_By = {
  /** This indicates the last specific drug served */
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "medication" */
export type Medication_Variance_Order_By = {
  /** This indicates the last specific drug served */
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "migrations". All fields are combined with a logical 'AND'. */
export type Migrations_Bool_Exp = {
  _and?: InputMaybe<Array<Migrations_Bool_Exp>>;
  _not?: InputMaybe<Migrations_Bool_Exp>;
  _or?: InputMaybe<Array<Migrations_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  timestamp?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "migrations" */
export enum Migrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_8c82d7f526340ab734260ea46be = 'PK_8c82d7f526340ab734260ea46be'
}

/** input type for incrementing numeric columns in table "migrations" */
export type Migrations_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "migrations" */
export type Migrations_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['bigint']>;
};

/** on_conflict condition type for table "migrations" */
export type Migrations_On_Conflict = {
  constraint: Migrations_Constraint;
  update_columns?: Array<Migrations_Update_Column>;
  where?: InputMaybe<Migrations_Bool_Exp>;
};

/** Ordering options when selecting data from "migrations". */
export type Migrations_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: migrations */
export type Migrations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "migrations" */
export enum Migrations_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Timestamp = 'timestamp'
}

/** input type for updating data in table "migrations" */
export type Migrations_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['bigint']>;
};

/** Streaming cursor of the table "migrations" */
export type Migrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Migrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Migrations_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['bigint']>;
};

/** update columns of table "migrations" */
export enum Migrations_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Timestamp = 'timestamp'
}

export type Migrations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Migrations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Migrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Migrations_Bool_Exp;
};

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type Name_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['name']>;
  _gt?: InputMaybe<Scalars['name']>;
  _gte?: InputMaybe<Scalars['name']>;
  _in?: InputMaybe<Array<Scalars['name']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['name']>;
  _lte?: InputMaybe<Scalars['name']>;
  _neq?: InputMaybe<Scalars['name']>;
  _nin?: InputMaybe<Array<Scalars['name']>>;
};

export type Note_Aggregate_Bool_Exp = {
  count?: InputMaybe<Note_Aggregate_Bool_Exp_Count>;
};

export type Note_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Note_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Note_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "note" */
export type Note_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Note_Max_Order_By>;
  min?: InputMaybe<Note_Min_Order_By>;
};

/** input type for inserting array relation for remote table "note" */
export type Note_Arr_Rel_Insert_Input = {
  data: Array<Note_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Note_On_Conflict>;
};

/** Boolean expression to filter rows from the table "note". All fields are combined with a logical 'AND'. */
export type Note_Bool_Exp = {
  _and?: InputMaybe<Array<Note_Bool_Exp>>;
  _not?: InputMaybe<Note_Bool_Exp>;
  _or?: InputMaybe<Array<Note_Bool_Exp>>;
  author?: InputMaybe<Admin_Bool_Exp>;
  author_uid?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  pinned_at?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "note" */
export enum Note_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotePkey = 'note_pkey'
}

/** input type for inserting data into table "note" */
export type Note_Insert_Input = {
  author?: InputMaybe<Admin_Obj_Rel_Insert_Input>;
  author_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  pinned_at?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "note" */
export type Note_Max_Order_By = {
  author_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  pinned_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "note" */
export type Note_Min_Order_By = {
  author_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  pinned_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "note" */
export type Note_Obj_Rel_Insert_Input = {
  data: Note_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Note_On_Conflict>;
};

/** on_conflict condition type for table "note" */
export type Note_On_Conflict = {
  constraint: Note_Constraint;
  update_columns?: Array<Note_Update_Column>;
  where?: InputMaybe<Note_Bool_Exp>;
};

/** Ordering options when selecting data from "note". */
export type Note_Order_By = {
  author?: InputMaybe<Admin_Order_By>;
  author_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  pinned_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: note */
export type Note_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "note" */
export enum Note_Select_Column {
  /** column name */
  AuthorUid = 'author_uid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  PinnedAt = 'pinned_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "note" */
export type Note_Set_Input = {
  author_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  pinned_at?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "note" */
export type Note_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Note_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Note_Stream_Cursor_Value_Input = {
  author_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  note?: InputMaybe<Scalars['String']>;
  pinned_at?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "note" */
export enum Note_Update_Column {
  /** column name */
  AuthorUid = 'author_uid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  PinnedAt = 'pinned_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Note_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Note_Set_Input>;
  /** filter the rows which have to be updated */
  where: Note_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Notification_Append_Input = {
  text?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "notification". All fields are combined with a logical 'AND'. */
export type Notification_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Bool_Exp>>;
  _not?: InputMaybe<Notification_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification_scope?: InputMaybe<Config_Notification_Scope_Enum_Comparison_Exp>;
  patient_notifications?: InputMaybe<Patient_Notification_Bool_Exp>;
  patient_notifications_aggregate?: InputMaybe<Patient_Notification_Aggregate_Bool_Exp>;
  starting_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  text?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification" */
export enum Notification_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationPkey = 'notification_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Notification_Delete_At_Path_Input = {
  text?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Notification_Delete_Elem_Input = {
  text?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Notification_Delete_Key_Input = {
  text?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "notification" */
export type Notification_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  notification_scope?: InputMaybe<Config_Notification_Scope_Enum>;
  patient_notifications?: InputMaybe<Patient_Notification_Arr_Rel_Insert_Input>;
  starting_at?: InputMaybe<Scalars['timestamptz']>;
  text?: InputMaybe<Scalars['jsonb']>;
};

/** on_conflict condition type for table "notification" */
export type Notification_On_Conflict = {
  constraint: Notification_Constraint;
  update_columns?: Array<Notification_Update_Column>;
  where?: InputMaybe<Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "notification". */
export type Notification_Order_By = {
  id?: InputMaybe<Order_By>;
  notification_scope?: InputMaybe<Order_By>;
  patient_notifications_aggregate?: InputMaybe<Patient_Notification_Aggregate_Order_By>;
  starting_at?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification */
export type Notification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Notification_Prepend_Input = {
  text?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "notification" */
export enum Notification_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NotificationScope = 'notification_scope',
  /** column name */
  StartingAt = 'starting_at',
  /** column name */
  Text = 'text'
}

/** input type for updating data in table "notification" */
export type Notification_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  notification_scope?: InputMaybe<Config_Notification_Scope_Enum>;
  starting_at?: InputMaybe<Scalars['timestamptz']>;
  text?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "notification" */
export type Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  notification_scope?: InputMaybe<Config_Notification_Scope_Enum>;
  starting_at?: InputMaybe<Scalars['timestamptz']>;
  text?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "notification" */
export enum Notification_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NotificationScope = 'notification_scope',
  /** column name */
  StartingAt = 'starting_at',
  /** column name */
  Text = 'text'
}

export type Notification_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Notification_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Notification_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Notification_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Notification_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Notification_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Bool_Exp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** Boolean expression to compare columns of type "oid". All fields are combined with logical 'AND'. */
export type Oid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['oid']>;
  _gt?: InputMaybe<Scalars['oid']>;
  _gte?: InputMaybe<Scalars['oid']>;
  _in?: InputMaybe<Array<Scalars['oid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['oid']>;
  _lte?: InputMaybe<Scalars['oid']>;
  _neq?: InputMaybe<Scalars['oid']>;
  _nin?: InputMaybe<Array<Scalars['oid']>>;
};

export type Order_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Order_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Order_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Order_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Order_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Order_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Order_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Order_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Order_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Order_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Order_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Order_Aggregate_Bool_Exp_Var_Samp>;
};

export type Order_Aggregate_Bool_Exp_Avg = {
  arguments: Order_Select_Column_Order_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Order_Aggregate_Bool_Exp_Bool_And = {
  arguments: Order_Select_Column_Order_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Order_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Order_Select_Column_Order_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Order_Aggregate_Bool_Exp_Corr = {
  arguments: Order_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Order_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Order_Select_Column_Order_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Order_Select_Column_Order_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Order_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Order_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Order_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Order_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Order_Select_Column_Order_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Order_Select_Column_Order_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Order_Aggregate_Bool_Exp_Max = {
  arguments: Order_Select_Column_Order_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Order_Aggregate_Bool_Exp_Min = {
  arguments: Order_Select_Column_Order_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Order_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Order_Select_Column_Order_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Order_Aggregate_Bool_Exp_Sum = {
  arguments: Order_Select_Column_Order_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Order_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Order_Select_Column_Order_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** order by aggregate values of table "order" */
export type Order_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Max_Order_By>;
  min?: InputMaybe<Order_Min_Order_By>;
  stddev?: InputMaybe<Order_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order" */
export type Order_Arr_Rel_Insert_Input = {
  data: Array<Order_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Order_On_Conflict>;
};

/** order by avg() on columns of table "order" */
export type Order_Avg_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  planned_shipment_rate?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order". All fields are combined with a logical 'AND'. */
export type Order_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Bool_Exp>>;
  _not?: InputMaybe<Order_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  address_id?: InputMaybe<Int_Comparison_Exp>;
  cancelled_at?: InputMaybe<Timestamp_Comparison_Exp>;
  cancelled_by_account_uid?: InputMaybe<String_Comparison_Exp>;
  confirmation?: InputMaybe<Order_Confirmation_Bool_Exp>;
  contacted_for_confirmation?: InputMaybe<Boolean_Comparison_Exp>;
  contacted_for_payment?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  delivery_type?: InputMaybe<Config_Delivery_Type_Enum_Comparison_Exp>;
  details?: InputMaybe<Orders_Dashboard_Bool_Exp>;
  flag?: InputMaybe<Config_Order_Flag_Enum_Comparison_Exp>;
  has_narcotic_drugs?: InputMaybe<Boolean_Comparison_Exp>;
  has_refrigerated_drugs?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invoice_status?: InputMaybe<String_Comparison_Exp>;
  invoices?: InputMaybe<Invoice_Bool_Exp>;
  invoices_aggregate?: InputMaybe<Invoice_Aggregate_Bool_Exp>;
  line_items?: InputMaybe<Order_Line_Item_Bool_Exp>;
  line_items_aggregate?: InputMaybe<Order_Line_Item_Aggregate_Bool_Exp>;
  locker?: InputMaybe<Locker_Bool_Exp>;
  locker_id?: InputMaybe<Uuid_Comparison_Exp>;
  notes?: InputMaybe<Order_Note_Bool_Exp>;
  notes_aggregate?: InputMaybe<Order_Note_Aggregate_Bool_Exp>;
  order_confirmation?: InputMaybe<Order_Confirmation_Bool_Exp>;
  order_confirmations?: InputMaybe<Order_Confirmation_Bool_Exp>;
  order_confirmations_aggregate?: InputMaybe<Order_Confirmation_Aggregate_Bool_Exp>;
  order_count?: InputMaybe<Bigint_Comparison_Exp>;
  order_line_items?: InputMaybe<Order_Line_Item_Bool_Exp>;
  order_line_items_aggregate?: InputMaybe<Order_Line_Item_Aggregate_Bool_Exp>;
  order_status?: InputMaybe<Config_Order_Status_Bool_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_note?: InputMaybe<String_Comparison_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  paysafe_card_payment_token?: InputMaybe<String_Comparison_Exp>;
  planned_arrival_date?: InputMaybe<Date_Comparison_Exp>;
  planned_shipment_provider?: InputMaybe<Config_Shipment_Provider_Enum_Comparison_Exp>;
  planned_shipment_rate?: InputMaybe<Int_Comparison_Exp>;
  planned_shipment_rate_code?: InputMaybe<String_Comparison_Exp>;
  renewal_schedule?: InputMaybe<Config_Renewal_Batch_Enum_Comparison_Exp>;
  renewed?: InputMaybe<Boolean_Comparison_Exp>;
  score?: InputMaybe<Float8_Comparison_Exp>;
  shipment_provider?: InputMaybe<Config_Shipment_Provider_Bool_Exp>;
  shipment_rate?: InputMaybe<Shipment_Rate_Bool_Exp>;
  shipment_rate_v3?: InputMaybe<Config_Shipment_Rate_Bool_Exp>;
  shipment_status?: InputMaybe<String_Comparison_Exp>;
  shipments?: InputMaybe<Shipment_Bool_Exp>;
  shipments_aggregate?: InputMaybe<Shipment_Aggregate_Bool_Exp>;
  status?: InputMaybe<Config_Order_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Order_Confirmation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Confirmation_Aggregate_Bool_Exp_Count>;
};

export type Order_Confirmation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Confirmation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Confirmation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "order_confirmation" */
export type Order_Confirmation_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Confirmation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Confirmation_Max_Order_By>;
  min?: InputMaybe<Order_Confirmation_Min_Order_By>;
  stddev?: InputMaybe<Order_Confirmation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Confirmation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Confirmation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Confirmation_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Confirmation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Confirmation_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Confirmation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_confirmation" */
export type Order_Confirmation_Arr_Rel_Insert_Input = {
  data: Array<Order_Confirmation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Confirmation_On_Conflict>;
};

/** order by avg() on columns of table "order_confirmation" */
export type Order_Confirmation_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_confirmation". All fields are combined with a logical 'AND'. */
export type Order_Confirmation_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Confirmation_Bool_Exp>>;
  _not?: InputMaybe<Order_Confirmation_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Confirmation_Bool_Exp>>;
  confirmed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  confirmed_by?: InputMaybe<Account_Bool_Exp>;
  confirmed_by_uid?: InputMaybe<String_Comparison_Exp>;
  confirmed_via?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_confirmation" */
export enum Order_Confirmation_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_9a8a0cc2670f15a53ce4e09c892 = 'PK_9a8a0cc2670f15a53ce4e09c892',
  /** unique or primary key constraint on columns "order_id" */
  RelFe17070fc005df108ad6251de6 = 'REL_fe17070fc005df108ad6251de6'
}

/** input type for incrementing numeric columns in table "order_confirmation" */
export type Order_Confirmation_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_confirmation" */
export type Order_Confirmation_Insert_Input = {
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  confirmed_by?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  confirmed_by_uid?: InputMaybe<Scalars['String']>;
  confirmed_via?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Order_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "order_confirmation" */
export type Order_Confirmation_Max_Order_By = {
  confirmed_at?: InputMaybe<Order_By>;
  confirmed_by_uid?: InputMaybe<Order_By>;
  confirmed_via?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "order_confirmation" */
export type Order_Confirmation_Min_Order_By = {
  confirmed_at?: InputMaybe<Order_By>;
  confirmed_by_uid?: InputMaybe<Order_By>;
  confirmed_via?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "order_confirmation" */
export type Order_Confirmation_Obj_Rel_Insert_Input = {
  data: Order_Confirmation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Confirmation_On_Conflict>;
};

/** on_conflict condition type for table "order_confirmation" */
export type Order_Confirmation_On_Conflict = {
  constraint: Order_Confirmation_Constraint;
  update_columns?: Array<Order_Confirmation_Update_Column>;
  where?: InputMaybe<Order_Confirmation_Bool_Exp>;
};

/** Ordering options when selecting data from "order_confirmation". */
export type Order_Confirmation_Order_By = {
  confirmed_at?: InputMaybe<Order_By>;
  confirmed_by?: InputMaybe<Account_Order_By>;
  confirmed_by_uid?: InputMaybe<Order_By>;
  confirmed_via?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_confirmation */
export type Order_Confirmation_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "order_confirmation" */
export enum Order_Confirmation_Select_Column {
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  ConfirmedByUid = 'confirmed_by_uid',
  /** column name */
  ConfirmedVia = 'confirmed_via',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id'
}

/** input type for updating data in table "order_confirmation" */
export type Order_Confirmation_Set_Input = {
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  confirmed_by_uid?: InputMaybe<Scalars['String']>;
  confirmed_via?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "order_confirmation" */
export type Order_Confirmation_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_confirmation" */
export type Order_Confirmation_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_confirmation" */
export type Order_Confirmation_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_confirmation" */
export type Order_Confirmation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Confirmation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Confirmation_Stream_Cursor_Value_Input = {
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  confirmed_by_uid?: InputMaybe<Scalars['String']>;
  confirmed_via?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_confirmation" */
export type Order_Confirmation_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** update columns of table "order_confirmation" */
export enum Order_Confirmation_Update_Column {
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  ConfirmedByUid = 'confirmed_by_uid',
  /** column name */
  ConfirmedVia = 'confirmed_via',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id'
}

export type Order_Confirmation_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_Confirmation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Confirmation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Confirmation_Bool_Exp;
};

/** order by var_pop() on columns of table "order_confirmation" */
export type Order_Confirmation_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "order_confirmation" */
export type Order_Confirmation_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "order_confirmation" */
export type Order_Confirmation_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "order" */
export enum Order_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_1031171c13130102495201e3e20 = 'PK_1031171c13130102495201e3e20'
}

/** input type for incrementing numeric columns in table "order" */
export type Order_Inc_Input = {
  address_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  /** DEPRECATED (2023/10) */
  planned_shipment_rate?: InputMaybe<Scalars['Int']>;
  score?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "order" */
export type Order_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['Int']>;
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  cancelled_by_account_uid?: InputMaybe<Scalars['String']>;
  confirmation?: InputMaybe<Order_Confirmation_Obj_Rel_Insert_Input>;
  contacted_for_confirmation?: InputMaybe<Scalars['Boolean']>;
  contacted_for_payment?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  delivery_type?: InputMaybe<Config_Delivery_Type_Enum>;
  details?: InputMaybe<Orders_Dashboard_Obj_Rel_Insert_Input>;
  flag?: InputMaybe<Config_Order_Flag_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  invoices?: InputMaybe<Invoice_Arr_Rel_Insert_Input>;
  line_items?: InputMaybe<Order_Line_Item_Arr_Rel_Insert_Input>;
  locker?: InputMaybe<Locker_Obj_Rel_Insert_Input>;
  locker_id?: InputMaybe<Scalars['uuid']>;
  notes?: InputMaybe<Order_Note_Arr_Rel_Insert_Input>;
  order_confirmation?: InputMaybe<Order_Confirmation_Obj_Rel_Insert_Input>;
  order_confirmations?: InputMaybe<Order_Confirmation_Arr_Rel_Insert_Input>;
  order_line_items?: InputMaybe<Order_Line_Item_Arr_Rel_Insert_Input>;
  order_status?: InputMaybe<Config_Order_Status_Obj_Rel_Insert_Input>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_note?: InputMaybe<Scalars['String']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  paysafe_card_payment_token?: InputMaybe<Scalars['String']>;
  planned_arrival_date?: InputMaybe<Scalars['date']>;
  planned_shipment_provider?: InputMaybe<Config_Shipment_Provider_Enum>;
  /** DEPRECATED (2023/10) */
  planned_shipment_rate?: InputMaybe<Scalars['Int']>;
  planned_shipment_rate_code?: InputMaybe<Scalars['String']>;
  renewal_schedule?: InputMaybe<Config_Renewal_Batch_Enum>;
  renewed?: InputMaybe<Scalars['Boolean']>;
  score?: InputMaybe<Scalars['float8']>;
  shipment_provider?: InputMaybe<Config_Shipment_Provider_Obj_Rel_Insert_Input>;
  shipment_rate?: InputMaybe<Shipment_Rate_Obj_Rel_Insert_Input>;
  shipment_rate_v3?: InputMaybe<Config_Shipment_Rate_Obj_Rel_Insert_Input>;
  shipments?: InputMaybe<Shipment_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Config_Order_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

export type Order_Line_Item_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Line_Item_Aggregate_Bool_Exp_Count>;
};

export type Order_Line_Item_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Line_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Line_Item_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "order_line_item" */
export type Order_Line_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Line_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Line_Item_Max_Order_By>;
  min?: InputMaybe<Order_Line_Item_Min_Order_By>;
  stddev?: InputMaybe<Order_Line_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Line_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Line_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Line_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Line_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Line_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Line_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_line_item" */
export type Order_Line_Item_Arr_Rel_Insert_Input = {
  data: Array<Order_Line_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Line_Item_On_Conflict>;
};

/** order by avg() on columns of table "order_line_item" */
export type Order_Line_Item_Avg_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED, use medication_id instead */
  product_id?: InputMaybe<Order_By>;
  quantity_served?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_line_item". All fields are combined with a logical 'AND'. */
export type Order_Line_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Line_Item_Bool_Exp>>;
  _not?: InputMaybe<Order_Line_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Line_Item_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  drug?: InputMaybe<Drug_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_line_items?: InputMaybe<Invoice_Line_Item_Bool_Exp>;
  invoice_line_items_aggregate?: InputMaybe<Invoice_Line_Item_Aggregate_Bool_Exp>;
  medication?: InputMaybe<Medication_Bool_Exp>;
  medication_id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  packaging?: InputMaybe<Config_Medication_Packaging_Enum_Comparison_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  quantity_served?: InputMaybe<Numeric_Comparison_Exp>;
  requested_quantity?: InputMaybe<String_Comparison_Exp>;
  schedule?: InputMaybe<Json_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_line_item" */
export enum Order_Line_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkC4c5bcb010a01f13385ef7e8b49 = 'PK_c4c5bcb010a01f13385ef7e8b49',
  /** unique or primary key constraint on columns "medication_id", "order_id" */
  Uq_427a3162c0014f9f78f487990cc = 'UQ_427a3162c0014f9f78f487990cc'
}

/** input type for incrementing numeric columns in table "order_line_item" */
export type Order_Line_Item_Inc_Input = {
  medication_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  /** DEPRECATED, use medication_id instead */
  product_id?: InputMaybe<Scalars['Int']>;
  quantity_served?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "order_line_item" */
export type Order_Line_Item_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug?: InputMaybe<Drug_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_line_items?: InputMaybe<Invoice_Line_Item_Arr_Rel_Insert_Input>;
  medication?: InputMaybe<Medication_Obj_Rel_Insert_Input>;
  medication_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Order_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['Int']>;
  packaging?: InputMaybe<Config_Medication_Packaging_Enum>;
  /** DEPRECATED, use medication_id instead */
  product_id?: InputMaybe<Scalars['Int']>;
  quantity_served?: InputMaybe<Scalars['numeric']>;
  requested_quantity?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['json']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "order_line_item" */
export type Order_Line_Item_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED, use medication_id instead */
  product_id?: InputMaybe<Order_By>;
  quantity_served?: InputMaybe<Order_By>;
  requested_quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "order_line_item" */
export type Order_Line_Item_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED, use medication_id instead */
  product_id?: InputMaybe<Order_By>;
  quantity_served?: InputMaybe<Order_By>;
  requested_quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "order_line_item" */
export type Order_Line_Item_Obj_Rel_Insert_Input = {
  data: Order_Line_Item_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Line_Item_On_Conflict>;
};

/** on_conflict condition type for table "order_line_item" */
export type Order_Line_Item_On_Conflict = {
  constraint: Order_Line_Item_Constraint;
  update_columns?: Array<Order_Line_Item_Update_Column>;
  where?: InputMaybe<Order_Line_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "order_line_item". */
export type Order_Line_Item_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drug?: InputMaybe<Drug_Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_line_items_aggregate?: InputMaybe<Invoice_Line_Item_Aggregate_Order_By>;
  medication?: InputMaybe<Medication_Order_By>;
  medication_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  order_id?: InputMaybe<Order_By>;
  packaging?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_served?: InputMaybe<Order_By>;
  requested_quantity?: InputMaybe<Order_By>;
  schedule?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_line_item */
export type Order_Line_Item_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "order_line_item" */
export enum Order_Line_Item_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Packaging = 'packaging',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  QuantityServed = 'quantity_served',
  /** column name */
  RequestedQuantity = 'requested_quantity',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "order_line_item" */
export type Order_Line_Item_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  packaging?: InputMaybe<Config_Medication_Packaging_Enum>;
  /** DEPRECATED, use medication_id instead */
  product_id?: InputMaybe<Scalars['Int']>;
  quantity_served?: InputMaybe<Scalars['numeric']>;
  requested_quantity?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['json']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "order_line_item" */
export type Order_Line_Item_Stddev_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED, use medication_id instead */
  product_id?: InputMaybe<Order_By>;
  quantity_served?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_line_item" */
export type Order_Line_Item_Stddev_Pop_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED, use medication_id instead */
  product_id?: InputMaybe<Order_By>;
  quantity_served?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_line_item" */
export type Order_Line_Item_Stddev_Samp_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED, use medication_id instead */
  product_id?: InputMaybe<Order_By>;
  quantity_served?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_line_item" */
export type Order_Line_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Line_Item_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Line_Item_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  packaging?: InputMaybe<Config_Medication_Packaging_Enum>;
  /** DEPRECATED, use medication_id instead */
  product_id?: InputMaybe<Scalars['Int']>;
  quantity_served?: InputMaybe<Scalars['numeric']>;
  requested_quantity?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['json']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "order_line_item" */
export type Order_Line_Item_Sum_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED, use medication_id instead */
  product_id?: InputMaybe<Order_By>;
  quantity_served?: InputMaybe<Order_By>;
};

/** update columns of table "order_line_item" */
export enum Order_Line_Item_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Packaging = 'packaging',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  QuantityServed = 'quantity_served',
  /** column name */
  RequestedQuantity = 'requested_quantity',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Order_Line_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_Line_Item_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Line_Item_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Line_Item_Bool_Exp;
};

/** order by var_pop() on columns of table "order_line_item" */
export type Order_Line_Item_Var_Pop_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED, use medication_id instead */
  product_id?: InputMaybe<Order_By>;
  quantity_served?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "order_line_item" */
export type Order_Line_Item_Var_Samp_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED, use medication_id instead */
  product_id?: InputMaybe<Order_By>;
  quantity_served?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "order_line_item" */
export type Order_Line_Item_Variance_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED, use medication_id instead */
  product_id?: InputMaybe<Order_By>;
  quantity_served?: InputMaybe<Order_By>;
};

/** order by max() on columns of table "order" */
export type Order_Max_Order_By = {
  address_id?: InputMaybe<Order_By>;
  cancelled_at?: InputMaybe<Order_By>;
  cancelled_by_account_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locker_id?: InputMaybe<Order_By>;
  patient_note?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  paysafe_card_payment_token?: InputMaybe<Order_By>;
  planned_arrival_date?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  planned_shipment_rate?: InputMaybe<Order_By>;
  planned_shipment_rate_code?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "order" */
export type Order_Min_Order_By = {
  address_id?: InputMaybe<Order_By>;
  cancelled_at?: InputMaybe<Order_By>;
  cancelled_by_account_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locker_id?: InputMaybe<Order_By>;
  patient_note?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  paysafe_card_payment_token?: InputMaybe<Order_By>;
  planned_arrival_date?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  planned_shipment_rate?: InputMaybe<Order_By>;
  planned_shipment_rate_code?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

export type Order_Note_Aggregate_Bool_Exp = {
  count?: InputMaybe<Order_Note_Aggregate_Bool_Exp_Count>;
};

export type Order_Note_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Order_Note_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Order_Note_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "order_note" */
export type Order_Note_Aggregate_Order_By = {
  avg?: InputMaybe<Order_Note_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_Note_Max_Order_By>;
  min?: InputMaybe<Order_Note_Min_Order_By>;
  stddev?: InputMaybe<Order_Note_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Order_Note_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Order_Note_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Order_Note_Sum_Order_By>;
  var_pop?: InputMaybe<Order_Note_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Order_Note_Var_Samp_Order_By>;
  variance?: InputMaybe<Order_Note_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_note" */
export type Order_Note_Arr_Rel_Insert_Input = {
  data: Array<Order_Note_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Order_Note_On_Conflict>;
};

/** order by avg() on columns of table "order_note" */
export type Order_Note_Avg_Order_By = {
  order_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_note". All fields are combined with a logical 'AND'. */
export type Order_Note_Bool_Exp = {
  _and?: InputMaybe<Array<Order_Note_Bool_Exp>>;
  _not?: InputMaybe<Order_Note_Bool_Exp>;
  _or?: InputMaybe<Array<Order_Note_Bool_Exp>>;
  note?: InputMaybe<Note_Bool_Exp>;
  note_id?: InputMaybe<Uuid_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_note" */
export enum Order_Note_Constraint {
  /** unique or primary key constraint on columns "note_id" */
  OrderNoteNoteIdKey = 'order_note_note_id_key',
  /** unique or primary key constraint on columns "note_id", "order_id" */
  OrderNotePkey = 'order_note_pkey'
}

/** input type for incrementing numeric columns in table "order_note" */
export type Order_Note_Inc_Input = {
  order_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_note" */
export type Order_Note_Insert_Input = {
  note?: InputMaybe<Note_Obj_Rel_Insert_Input>;
  note_id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<Order_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "order_note" */
export type Order_Note_Max_Order_By = {
  note_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "order_note" */
export type Order_Note_Min_Order_By = {
  note_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "order_note" */
export type Order_Note_On_Conflict = {
  constraint: Order_Note_Constraint;
  update_columns?: Array<Order_Note_Update_Column>;
  where?: InputMaybe<Order_Note_Bool_Exp>;
};

/** Ordering options when selecting data from "order_note". */
export type Order_Note_Order_By = {
  note?: InputMaybe<Note_Order_By>;
  note_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order_note */
export type Order_Note_Pk_Columns_Input = {
  note_id: Scalars['uuid'];
  order_id: Scalars['Int'];
};

/** select columns of table "order_note" */
export enum Order_Note_Select_Column {
  /** column name */
  NoteId = 'note_id',
  /** column name */
  OrderId = 'order_id'
}

/** input type for updating data in table "order_note" */
export type Order_Note_Set_Input = {
  note_id?: InputMaybe<Scalars['uuid']>;
  order_id?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "order_note" */
export type Order_Note_Stddev_Order_By = {
  order_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_note" */
export type Order_Note_Stddev_Pop_Order_By = {
  order_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_note" */
export type Order_Note_Stddev_Samp_Order_By = {
  order_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_note" */
export type Order_Note_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Note_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Note_Stream_Cursor_Value_Input = {
  note_id?: InputMaybe<Scalars['uuid']>;
  order_id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_note" */
export type Order_Note_Sum_Order_By = {
  order_id?: InputMaybe<Order_By>;
};

/** update columns of table "order_note" */
export enum Order_Note_Update_Column {
  /** column name */
  NoteId = 'note_id',
  /** column name */
  OrderId = 'order_id'
}

export type Order_Note_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_Note_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Note_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Note_Bool_Exp;
};

/** order by var_pop() on columns of table "order_note" */
export type Order_Note_Var_Pop_Order_By = {
  order_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "order_note" */
export type Order_Note_Var_Samp_Order_By = {
  order_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "order_note" */
export type Order_Note_Variance_Order_By = {
  order_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "order" */
export type Order_Obj_Rel_Insert_Input = {
  data: Order_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Order_On_Conflict>;
};

/** on_conflict condition type for table "order" */
export type Order_On_Conflict = {
  constraint: Order_Constraint;
  update_columns?: Array<Order_Update_Column>;
  where?: InputMaybe<Order_Bool_Exp>;
};

/** Ordering options when selecting data from "order". */
export type Order_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  address_id?: InputMaybe<Order_By>;
  cancelled_at?: InputMaybe<Order_By>;
  cancelled_by_account_uid?: InputMaybe<Order_By>;
  confirmation?: InputMaybe<Order_Confirmation_Order_By>;
  contacted_for_confirmation?: InputMaybe<Order_By>;
  contacted_for_payment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  delivery_type?: InputMaybe<Order_By>;
  details?: InputMaybe<Orders_Dashboard_Order_By>;
  flag?: InputMaybe<Order_By>;
  has_narcotic_drugs?: InputMaybe<Order_By>;
  has_refrigerated_drugs?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_status?: InputMaybe<Order_By>;
  invoices_aggregate?: InputMaybe<Invoice_Aggregate_Order_By>;
  line_items_aggregate?: InputMaybe<Order_Line_Item_Aggregate_Order_By>;
  locker?: InputMaybe<Locker_Order_By>;
  locker_id?: InputMaybe<Order_By>;
  notes_aggregate?: InputMaybe<Order_Note_Aggregate_Order_By>;
  order_confirmation?: InputMaybe<Order_Confirmation_Order_By>;
  order_confirmations_aggregate?: InputMaybe<Order_Confirmation_Aggregate_Order_By>;
  order_count?: InputMaybe<Order_By>;
  order_line_items_aggregate?: InputMaybe<Order_Line_Item_Aggregate_Order_By>;
  order_status?: InputMaybe<Config_Order_Status_Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_note?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  paysafe_card_payment_token?: InputMaybe<Order_By>;
  planned_arrival_date?: InputMaybe<Order_By>;
  planned_shipment_provider?: InputMaybe<Order_By>;
  planned_shipment_rate?: InputMaybe<Order_By>;
  planned_shipment_rate_code?: InputMaybe<Order_By>;
  renewal_schedule?: InputMaybe<Order_By>;
  renewed?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  shipment_provider?: InputMaybe<Config_Shipment_Provider_Order_By>;
  shipment_rate?: InputMaybe<Shipment_Rate_Order_By>;
  shipment_rate_v3?: InputMaybe<Config_Shipment_Rate_Order_By>;
  shipment_status?: InputMaybe<Order_By>;
  shipments_aggregate?: InputMaybe<Shipment_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: order */
export type Order_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "order" */
export enum Order_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  CancelledByAccountUid = 'cancelled_by_account_uid',
  /** column name */
  ContactedForConfirmation = 'contacted_for_confirmation',
  /** column name */
  ContactedForPayment = 'contacted_for_payment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeliveryType = 'delivery_type',
  /** column name */
  Flag = 'flag',
  /** column name */
  Id = 'id',
  /** column name */
  LockerId = 'locker_id',
  /** column name */
  PatientNote = 'patient_note',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PaysafeCardPaymentToken = 'paysafe_card_payment_token',
  /** column name */
  PlannedArrivalDate = 'planned_arrival_date',
  /** column name */
  PlannedShipmentProvider = 'planned_shipment_provider',
  /** column name */
  PlannedShipmentRate = 'planned_shipment_rate',
  /** column name */
  PlannedShipmentRateCode = 'planned_shipment_rate_code',
  /** column name */
  RenewalSchedule = 'renewal_schedule',
  /** column name */
  Renewed = 'renewed',
  /** column name */
  Score = 'score',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "order_aggregate_bool_exp_avg_arguments_columns" columns of table "order" */
export enum Order_Select_Column_Order_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Score = 'score'
}

/** select "order_aggregate_bool_exp_bool_and_arguments_columns" columns of table "order" */
export enum Order_Select_Column_Order_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ContactedForConfirmation = 'contacted_for_confirmation',
  /** column name */
  ContactedForPayment = 'contacted_for_payment',
  /** column name */
  Renewed = 'renewed'
}

/** select "order_aggregate_bool_exp_bool_or_arguments_columns" columns of table "order" */
export enum Order_Select_Column_Order_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ContactedForConfirmation = 'contacted_for_confirmation',
  /** column name */
  ContactedForPayment = 'contacted_for_payment',
  /** column name */
  Renewed = 'renewed'
}

/** select "order_aggregate_bool_exp_corr_arguments_columns" columns of table "order" */
export enum Order_Select_Column_Order_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Score = 'score'
}

/** select "order_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "order" */
export enum Order_Select_Column_Order_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Score = 'score'
}

/** select "order_aggregate_bool_exp_max_arguments_columns" columns of table "order" */
export enum Order_Select_Column_Order_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Score = 'score'
}

/** select "order_aggregate_bool_exp_min_arguments_columns" columns of table "order" */
export enum Order_Select_Column_Order_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Score = 'score'
}

/** select "order_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "order" */
export enum Order_Select_Column_Order_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Score = 'score'
}

/** select "order_aggregate_bool_exp_sum_arguments_columns" columns of table "order" */
export enum Order_Select_Column_Order_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Score = 'score'
}

/** select "order_aggregate_bool_exp_var_samp_arguments_columns" columns of table "order" */
export enum Order_Select_Column_Order_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Score = 'score'
}

/** input type for updating data in table "order" */
export type Order_Set_Input = {
  address_id?: InputMaybe<Scalars['Int']>;
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  cancelled_by_account_uid?: InputMaybe<Scalars['String']>;
  contacted_for_confirmation?: InputMaybe<Scalars['Boolean']>;
  contacted_for_payment?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  delivery_type?: InputMaybe<Config_Delivery_Type_Enum>;
  flag?: InputMaybe<Config_Order_Flag_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  locker_id?: InputMaybe<Scalars['uuid']>;
  patient_note?: InputMaybe<Scalars['String']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  paysafe_card_payment_token?: InputMaybe<Scalars['String']>;
  planned_arrival_date?: InputMaybe<Scalars['date']>;
  planned_shipment_provider?: InputMaybe<Config_Shipment_Provider_Enum>;
  /** DEPRECATED (2023/10) */
  planned_shipment_rate?: InputMaybe<Scalars['Int']>;
  planned_shipment_rate_code?: InputMaybe<Scalars['String']>;
  renewal_schedule?: InputMaybe<Config_Renewal_Batch_Enum>;
  renewed?: InputMaybe<Scalars['Boolean']>;
  score?: InputMaybe<Scalars['float8']>;
  status?: InputMaybe<Config_Order_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "order" */
export type Order_Stddev_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  planned_shipment_rate?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order" */
export type Order_Stddev_Pop_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  planned_shipment_rate?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order" */
export type Order_Stddev_Samp_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  planned_shipment_rate?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order" */
export type Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['Int']>;
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  cancelled_by_account_uid?: InputMaybe<Scalars['String']>;
  contacted_for_confirmation?: InputMaybe<Scalars['Boolean']>;
  contacted_for_payment?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  delivery_type?: InputMaybe<Config_Delivery_Type_Enum>;
  flag?: InputMaybe<Config_Order_Flag_Enum>;
  id?: InputMaybe<Scalars['Int']>;
  locker_id?: InputMaybe<Scalars['uuid']>;
  patient_note?: InputMaybe<Scalars['String']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  paysafe_card_payment_token?: InputMaybe<Scalars['String']>;
  planned_arrival_date?: InputMaybe<Scalars['date']>;
  planned_shipment_provider?: InputMaybe<Config_Shipment_Provider_Enum>;
  /** DEPRECATED (2023/10) */
  planned_shipment_rate?: InputMaybe<Scalars['Int']>;
  planned_shipment_rate_code?: InputMaybe<Scalars['String']>;
  renewal_schedule?: InputMaybe<Config_Renewal_Batch_Enum>;
  renewed?: InputMaybe<Scalars['Boolean']>;
  score?: InputMaybe<Scalars['float8']>;
  status?: InputMaybe<Config_Order_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "order" */
export type Order_Sum_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  planned_shipment_rate?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** update columns of table "order" */
export enum Order_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  CancelledByAccountUid = 'cancelled_by_account_uid',
  /** column name */
  ContactedForConfirmation = 'contacted_for_confirmation',
  /** column name */
  ContactedForPayment = 'contacted_for_payment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeliveryType = 'delivery_type',
  /** column name */
  Flag = 'flag',
  /** column name */
  Id = 'id',
  /** column name */
  LockerId = 'locker_id',
  /** column name */
  PatientNote = 'patient_note',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PaysafeCardPaymentToken = 'paysafe_card_payment_token',
  /** column name */
  PlannedArrivalDate = 'planned_arrival_date',
  /** column name */
  PlannedShipmentProvider = 'planned_shipment_provider',
  /** column name */
  PlannedShipmentRate = 'planned_shipment_rate',
  /** column name */
  PlannedShipmentRateCode = 'planned_shipment_rate_code',
  /** column name */
  RenewalSchedule = 'renewal_schedule',
  /** column name */
  Renewed = 'renewed',
  /** column name */
  Score = 'score',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Order_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Order_Bool_Exp;
};

/** order by var_pop() on columns of table "order" */
export type Order_Var_Pop_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  planned_shipment_rate?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "order" */
export type Order_Var_Samp_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  planned_shipment_rate?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "order" */
export type Order_Variance_Order_By = {
  address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  planned_shipment_rate?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "orders_dashboard". All fields are combined with a logical 'AND'. */
export type Orders_Dashboard_Bool_Exp = {
  _and?: InputMaybe<Array<Orders_Dashboard_Bool_Exp>>;
  _not?: InputMaybe<Orders_Dashboard_Bool_Exp>;
  _or?: InputMaybe<Array<Orders_Dashboard_Bool_Exp>>;
  cancelled_at?: InputMaybe<Timestamp_Comparison_Exp>;
  cancelled_by?: InputMaybe<Account_Bool_Exp>;
  cancelled_by_account_uid?: InputMaybe<String_Comparison_Exp>;
  carrier?: InputMaybe<String_Comparison_Exp>;
  confirmed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  confirmed_via?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  delivery_type?: InputMaybe<String_Comparison_Exp>;
  flag?: InputMaybe<String_Comparison_Exp>;
  has_refrigerated_drugs?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invoice_amount?: InputMaybe<Int_Comparison_Exp>;
  invoice_id?: InputMaybe<Int_Comparison_Exp>;
  invoice_outdated?: InputMaybe<Boolean_Comparison_Exp>;
  invoice_paid_amount?: InputMaybe<Int_Comparison_Exp>;
  invoice_status?: InputMaybe<String_Comparison_Exp>;
  invoice_total_rx_amount?: InputMaybe<Int_Comparison_Exp>;
  invoice_type?: InputMaybe<String_Comparison_Exp>;
  is_phone_only?: InputMaybe<Boolean_Comparison_Exp>;
  is_profile_complete?: InputMaybe<Boolean_Comparison_Exp>;
  locker_id?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  planned_arrival_date?: InputMaybe<Date_Comparison_Exp>;
  renewed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  score?: InputMaybe<Float8_Comparison_Exp>;
  shipment_id?: InputMaybe<Int_Comparison_Exp>;
  shipment_is_lost?: InputMaybe<Boolean_Comparison_Exp>;
  shipment_label_printed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  shipment_status?: InputMaybe<String_Comparison_Exp>;
  shipping_date?: InputMaybe<Date_Comparison_Exp>;
  shipping_delay?: InputMaybe<Int_Comparison_Exp>;
  shipping_delay_type?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  verified_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** input type for inserting data into table "orders_dashboard" */
export type Orders_Dashboard_Insert_Input = {
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  cancelled_by?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  cancelled_by_account_uid?: InputMaybe<Scalars['String']>;
  carrier?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  confirmed_via?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  delivery_type?: InputMaybe<Scalars['String']>;
  flag?: InputMaybe<Scalars['String']>;
  has_refrigerated_drugs?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_amount?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  invoice_outdated?: InputMaybe<Scalars['Boolean']>;
  invoice_paid_amount?: InputMaybe<Scalars['Int']>;
  invoice_status?: InputMaybe<Scalars['String']>;
  invoice_total_rx_amount?: InputMaybe<Scalars['Int']>;
  invoice_type?: InputMaybe<Scalars['String']>;
  is_phone_only?: InputMaybe<Scalars['Boolean']>;
  is_profile_complete?: InputMaybe<Scalars['Boolean']>;
  locker_id?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  planned_arrival_date?: InputMaybe<Scalars['date']>;
  renewed_at?: InputMaybe<Scalars['timestamp']>;
  score?: InputMaybe<Scalars['float8']>;
  shipment_id?: InputMaybe<Scalars['Int']>;
  shipment_is_lost?: InputMaybe<Scalars['Boolean']>;
  shipment_label_printed_at?: InputMaybe<Scalars['timestamp']>;
  shipment_status?: InputMaybe<Scalars['String']>;
  shipping_date?: InputMaybe<Scalars['date']>;
  shipping_delay?: InputMaybe<Scalars['Int']>;
  shipping_delay_type?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  verified_at?: InputMaybe<Scalars['timestamp']>;
};

/** input type for inserting object relation for remote table "orders_dashboard" */
export type Orders_Dashboard_Obj_Rel_Insert_Input = {
  data: Orders_Dashboard_Insert_Input;
};

/** Ordering options when selecting data from "orders_dashboard". */
export type Orders_Dashboard_Order_By = {
  cancelled_at?: InputMaybe<Order_By>;
  cancelled_by?: InputMaybe<Account_Order_By>;
  cancelled_by_account_uid?: InputMaybe<Order_By>;
  carrier?: InputMaybe<Order_By>;
  confirmed_at?: InputMaybe<Order_By>;
  confirmed_via?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  delivery_type?: InputMaybe<Order_By>;
  flag?: InputMaybe<Order_By>;
  has_refrigerated_drugs?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  invoice_outdated?: InputMaybe<Order_By>;
  invoice_paid_amount?: InputMaybe<Order_By>;
  invoice_status?: InputMaybe<Order_By>;
  invoice_total_rx_amount?: InputMaybe<Order_By>;
  invoice_type?: InputMaybe<Order_By>;
  is_phone_only?: InputMaybe<Order_By>;
  is_profile_complete?: InputMaybe<Order_By>;
  locker_id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  planned_arrival_date?: InputMaybe<Order_By>;
  renewed_at?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  shipment_id?: InputMaybe<Order_By>;
  shipment_is_lost?: InputMaybe<Order_By>;
  shipment_label_printed_at?: InputMaybe<Order_By>;
  shipment_status?: InputMaybe<Order_By>;
  shipping_date?: InputMaybe<Order_By>;
  shipping_delay?: InputMaybe<Order_By>;
  shipping_delay_type?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  verified_at?: InputMaybe<Order_By>;
};

/** select columns of table "orders_dashboard" */
export enum Orders_Dashboard_Select_Column {
  /** column name */
  CancelledAt = 'cancelled_at',
  /** column name */
  CancelledByAccountUid = 'cancelled_by_account_uid',
  /** column name */
  Carrier = 'carrier',
  /** column name */
  ConfirmedAt = 'confirmed_at',
  /** column name */
  ConfirmedVia = 'confirmed_via',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeliveryType = 'delivery_type',
  /** column name */
  Flag = 'flag',
  /** column name */
  HasRefrigeratedDrugs = 'has_refrigerated_drugs',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceAmount = 'invoice_amount',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  InvoiceOutdated = 'invoice_outdated',
  /** column name */
  InvoicePaidAmount = 'invoice_paid_amount',
  /** column name */
  InvoiceStatus = 'invoice_status',
  /** column name */
  InvoiceTotalRxAmount = 'invoice_total_rx_amount',
  /** column name */
  InvoiceType = 'invoice_type',
  /** column name */
  IsPhoneOnly = 'is_phone_only',
  /** column name */
  IsProfileComplete = 'is_profile_complete',
  /** column name */
  LockerId = 'locker_id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PlannedArrivalDate = 'planned_arrival_date',
  /** column name */
  RenewedAt = 'renewed_at',
  /** column name */
  Score = 'score',
  /** column name */
  ShipmentId = 'shipment_id',
  /** column name */
  ShipmentIsLost = 'shipment_is_lost',
  /** column name */
  ShipmentLabelPrintedAt = 'shipment_label_printed_at',
  /** column name */
  ShipmentStatus = 'shipment_status',
  /** column name */
  ShippingDate = 'shipping_date',
  /** column name */
  ShippingDelay = 'shipping_delay',
  /** column name */
  ShippingDelayType = 'shipping_delay_type',
  /** column name */
  Status = 'status',
  /** column name */
  VerifiedAt = 'verified_at'
}

/** Streaming cursor of the table "orders_dashboard" */
export type Orders_Dashboard_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Orders_Dashboard_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Orders_Dashboard_Stream_Cursor_Value_Input = {
  cancelled_at?: InputMaybe<Scalars['timestamp']>;
  cancelled_by_account_uid?: InputMaybe<Scalars['String']>;
  carrier?: InputMaybe<Scalars['String']>;
  confirmed_at?: InputMaybe<Scalars['timestamp']>;
  confirmed_via?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  delivery_type?: InputMaybe<Scalars['String']>;
  flag?: InputMaybe<Scalars['String']>;
  has_refrigerated_drugs?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_amount?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  invoice_outdated?: InputMaybe<Scalars['Boolean']>;
  invoice_paid_amount?: InputMaybe<Scalars['Int']>;
  invoice_status?: InputMaybe<Scalars['String']>;
  invoice_total_rx_amount?: InputMaybe<Scalars['Int']>;
  invoice_type?: InputMaybe<Scalars['String']>;
  is_phone_only?: InputMaybe<Scalars['Boolean']>;
  is_profile_complete?: InputMaybe<Scalars['Boolean']>;
  locker_id?: InputMaybe<Scalars['uuid']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  planned_arrival_date?: InputMaybe<Scalars['date']>;
  renewed_at?: InputMaybe<Scalars['timestamp']>;
  score?: InputMaybe<Scalars['float8']>;
  shipment_id?: InputMaybe<Scalars['Int']>;
  shipment_is_lost?: InputMaybe<Scalars['Boolean']>;
  shipment_label_printed_at?: InputMaybe<Scalars['timestamp']>;
  shipment_status?: InputMaybe<Scalars['String']>;
  shipping_date?: InputMaybe<Scalars['date']>;
  shipping_delay?: InputMaybe<Scalars['Int']>;
  shipping_delay_type?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  verified_at?: InputMaybe<Scalars['timestamp']>;
};

export type Otp_Request_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Otp_Request_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Otp_Request_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Otp_Request_Aggregate_Bool_Exp_Count>;
};

export type Otp_Request_Aggregate_Bool_Exp_Bool_And = {
  arguments: Otp_Request_Select_Column_Otp_Request_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Otp_Request_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Otp_Request_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Otp_Request_Select_Column_Otp_Request_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Otp_Request_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Otp_Request_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Otp_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Otp_Request_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "otp_request" */
export type Otp_Request_Aggregate_Order_By = {
  avg?: InputMaybe<Otp_Request_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Otp_Request_Max_Order_By>;
  min?: InputMaybe<Otp_Request_Min_Order_By>;
  stddev?: InputMaybe<Otp_Request_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Otp_Request_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Otp_Request_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Otp_Request_Sum_Order_By>;
  var_pop?: InputMaybe<Otp_Request_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Otp_Request_Var_Samp_Order_By>;
  variance?: InputMaybe<Otp_Request_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "otp_request" */
export type Otp_Request_Arr_Rel_Insert_Input = {
  data: Array<Otp_Request_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Otp_Request_On_Conflict>;
};

/** order by avg() on columns of table "otp_request" */
export type Otp_Request_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  tries_left?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "otp_request". All fields are combined with a logical 'AND'. */
export type Otp_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Otp_Request_Bool_Exp>>;
  _not?: InputMaybe<Otp_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Otp_Request_Bool_Exp>>;
  account_uid?: InputMaybe<String_Comparison_Exp>;
  contact?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamp_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_valid?: InputMaybe<Boolean_Comparison_Exp>;
  tries_left?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<Otp_Request_Type_Enum_Comparison_Exp>;
  verified_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "otp_request" */
export enum Otp_Request_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_46517aa87968f18c82f077064c2 = 'PK_46517aa87968f18c82f077064c2'
}

/** input type for incrementing numeric columns in table "otp_request" */
export type Otp_Request_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  tries_left?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "otp_request" */
export type Otp_Request_Insert_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  tries_left?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['otp_request_type_enum']>;
  verified_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "otp_request" */
export type Otp_Request_Max_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  contact?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tries_left?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  verified_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "otp_request" */
export type Otp_Request_Min_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  contact?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tries_left?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  verified_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "otp_request" */
export type Otp_Request_On_Conflict = {
  constraint: Otp_Request_Constraint;
  update_columns?: Array<Otp_Request_Update_Column>;
  where?: InputMaybe<Otp_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "otp_request". */
export type Otp_Request_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  contact?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_valid?: InputMaybe<Order_By>;
  tries_left?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  verified_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: otp_request */
export type Otp_Request_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "otp_request" */
export enum Otp_Request_Select_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  Contact = 'contact',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  IsValid = 'is_valid',
  /** column name */
  TriesLeft = 'tries_left',
  /** column name */
  Type = 'type',
  /** column name */
  VerifiedAt = 'verified_at'
}

/** select "otp_request_aggregate_bool_exp_bool_and_arguments_columns" columns of table "otp_request" */
export enum Otp_Request_Select_Column_Otp_Request_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsValid = 'is_valid'
}

/** select "otp_request_aggregate_bool_exp_bool_or_arguments_columns" columns of table "otp_request" */
export enum Otp_Request_Select_Column_Otp_Request_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsValid = 'is_valid'
}

/** input type for updating data in table "otp_request" */
export type Otp_Request_Set_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  tries_left?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['otp_request_type_enum']>;
  verified_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "otp_request" */
export type Otp_Request_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  tries_left?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "otp_request" */
export type Otp_Request_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tries_left?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "otp_request" */
export type Otp_Request_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tries_left?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "otp_request" */
export type Otp_Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Otp_Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Otp_Request_Stream_Cursor_Value_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_valid?: InputMaybe<Scalars['Boolean']>;
  tries_left?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['otp_request_type_enum']>;
  verified_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "otp_request" */
export type Otp_Request_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  tries_left?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "otp_request_type_enum". All fields are combined with logical 'AND'. */
export type Otp_Request_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['otp_request_type_enum']>;
  _gt?: InputMaybe<Scalars['otp_request_type_enum']>;
  _gte?: InputMaybe<Scalars['otp_request_type_enum']>;
  _in?: InputMaybe<Array<Scalars['otp_request_type_enum']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['otp_request_type_enum']>;
  _lte?: InputMaybe<Scalars['otp_request_type_enum']>;
  _neq?: InputMaybe<Scalars['otp_request_type_enum']>;
  _nin?: InputMaybe<Array<Scalars['otp_request_type_enum']>>;
};

/** update columns of table "otp_request" */
export enum Otp_Request_Update_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  Contact = 'contact',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  IsValid = 'is_valid',
  /** column name */
  TriesLeft = 'tries_left',
  /** column name */
  Type = 'type',
  /** column name */
  VerifiedAt = 'verified_at'
}

export type Otp_Request_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Otp_Request_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Otp_Request_Set_Input>;
  /** filter the rows which have to be updated */
  where: Otp_Request_Bool_Exp;
};

/** order by var_pop() on columns of table "otp_request" */
export type Otp_Request_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tries_left?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "otp_request" */
export type Otp_Request_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tries_left?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "otp_request" */
export type Otp_Request_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  tries_left?: InputMaybe<Order_By>;
};

export type Patient_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Patient_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Patient_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Patient_Aggregate_Bool_Exp_Count>;
};

export type Patient_Aggregate_Bool_Exp_Bool_And = {
  arguments: Patient_Select_Column_Patient_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Patient_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Patient_Select_Column_Patient_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Patient_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patient_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "patient" */
export type Patient_Aggregate_Order_By = {
  avg?: InputMaybe<Patient_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patient_Max_Order_By>;
  min?: InputMaybe<Patient_Min_Order_By>;
  stddev?: InputMaybe<Patient_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Patient_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Patient_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Patient_Sum_Order_By>;
  var_pop?: InputMaybe<Patient_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Patient_Var_Samp_Order_By>;
  variance?: InputMaybe<Patient_Variance_Order_By>;
};

export type Patient_Allergies_Aggregate_Bool_Exp = {
  count?: InputMaybe<Patient_Allergies_Aggregate_Bool_Exp_Count>;
};

export type Patient_Allergies_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patient_Allergies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Allergies_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "patient_allergies" */
export type Patient_Allergies_Aggregate_Order_By = {
  avg?: InputMaybe<Patient_Allergies_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patient_Allergies_Max_Order_By>;
  min?: InputMaybe<Patient_Allergies_Min_Order_By>;
  stddev?: InputMaybe<Patient_Allergies_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Patient_Allergies_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Patient_Allergies_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Patient_Allergies_Sum_Order_By>;
  var_pop?: InputMaybe<Patient_Allergies_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Patient_Allergies_Var_Samp_Order_By>;
  variance?: InputMaybe<Patient_Allergies_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "patient_allergies" */
export type Patient_Allergies_Arr_Rel_Insert_Input = {
  data: Array<Patient_Allergies_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Allergies_On_Conflict>;
};

/** order by avg() on columns of table "patient_allergies" */
export type Patient_Allergies_Avg_Order_By = {
  allergy_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patient_allergies". All fields are combined with a logical 'AND'. */
export type Patient_Allergies_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Allergies_Bool_Exp>>;
  _not?: InputMaybe<Patient_Allergies_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Allergies_Bool_Exp>>;
  allergy?: InputMaybe<Allergy_Bool_Exp>;
  allergy_id?: InputMaybe<Int_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_allergies" */
export enum Patient_Allergies_Constraint {
  /** unique or primary key constraint on columns "patient_uid", "allergy_id" */
  Pk_137db30862fdff141938cc6adb0 = 'PK_137db30862fdff141938cc6adb0'
}

/** input type for incrementing numeric columns in table "patient_allergies" */
export type Patient_Allergies_Inc_Input = {
  allergy_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "patient_allergies" */
export type Patient_Allergies_Insert_Input = {
  allergy?: InputMaybe<Allergy_Obj_Rel_Insert_Input>;
  allergy_id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "patient_allergies" */
export type Patient_Allergies_Max_Order_By = {
  allergy_id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "patient_allergies" */
export type Patient_Allergies_Min_Order_By = {
  allergy_id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "patient_allergies" */
export type Patient_Allergies_On_Conflict = {
  constraint: Patient_Allergies_Constraint;
  update_columns?: Array<Patient_Allergies_Update_Column>;
  where?: InputMaybe<Patient_Allergies_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_allergies". */
export type Patient_Allergies_Order_By = {
  allergy?: InputMaybe<Allergy_Order_By>;
  allergy_id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_allergies */
export type Patient_Allergies_Pk_Columns_Input = {
  allergy_id: Scalars['Int'];
  patient_uid: Scalars['String'];
};

/** select columns of table "patient_allergies" */
export enum Patient_Allergies_Select_Column {
  /** column name */
  AllergyId = 'allergy_id',
  /** column name */
  PatientUid = 'patient_uid'
}

/** input type for updating data in table "patient_allergies" */
export type Patient_Allergies_Set_Input = {
  allergy_id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "patient_allergies" */
export type Patient_Allergies_Stddev_Order_By = {
  allergy_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "patient_allergies" */
export type Patient_Allergies_Stddev_Pop_Order_By = {
  allergy_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "patient_allergies" */
export type Patient_Allergies_Stddev_Samp_Order_By = {
  allergy_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "patient_allergies" */
export type Patient_Allergies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Allergies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Allergies_Stream_Cursor_Value_Input = {
  allergy_id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "patient_allergies" */
export type Patient_Allergies_Sum_Order_By = {
  allergy_id?: InputMaybe<Order_By>;
};

/** update columns of table "patient_allergies" */
export enum Patient_Allergies_Update_Column {
  /** column name */
  AllergyId = 'allergy_id',
  /** column name */
  PatientUid = 'patient_uid'
}

export type Patient_Allergies_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patient_Allergies_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Allergies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Allergies_Bool_Exp;
};

/** order by var_pop() on columns of table "patient_allergies" */
export type Patient_Allergies_Var_Pop_Order_By = {
  allergy_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "patient_allergies" */
export type Patient_Allergies_Var_Samp_Order_By = {
  allergy_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "patient_allergies" */
export type Patient_Allergies_Variance_Order_By = {
  allergy_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patient_and_acquired_patient". All fields are combined with a logical 'AND'. */
export type Patient_And_Acquired_Patient_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_And_Acquired_Patient_Bool_Exp>>;
  _not?: InputMaybe<Patient_And_Acquired_Patient_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_And_Acquired_Patient_Bool_Exp>>;
  birthday?: InputMaybe<String_Comparison_Exp>;
  channel?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  has_credentials?: InputMaybe<Boolean_Comparison_Exp>;
  health_insurance_number?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  intro_call_note?: InputMaybe<String_Comparison_Exp>;
  introduction_call_completed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  lead_uid?: InputMaybe<String_Comparison_Exp>;
  origin?: InputMaybe<String_Comparison_Exp>;
  patient_group_dependencies?: InputMaybe<Patient_Group_Dependent_Bool_Exp>;
  patient_group_dependencies_aggregate?: InputMaybe<Patient_Group_Dependent_Aggregate_Bool_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  pms_external_id?: InputMaybe<String_Comparison_Exp>;
  program?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "patient_and_acquired_patient". */
export type Patient_And_Acquired_Patient_Order_By = {
  birthday?: InputMaybe<Order_By>;
  channel?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  has_credentials?: InputMaybe<Order_By>;
  health_insurance_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intro_call_note?: InputMaybe<Order_By>;
  introduction_call_completed_at?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  lead_uid?: InputMaybe<Order_By>;
  origin?: InputMaybe<Order_By>;
  patient_group_dependencies_aggregate?: InputMaybe<Patient_Group_Dependent_Aggregate_Order_By>;
  payload?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  pms_external_id?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
};

/** select columns of table "patient_and_acquired_patient" */
export enum Patient_And_Acquired_Patient_Select_Column {
  /** column name */
  Birthday = 'birthday',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HasCredentials = 'has_credentials',
  /** column name */
  HealthInsuranceNumber = 'health_insurance_number',
  /** column name */
  Id = 'id',
  /** column name */
  IntroCallNote = 'intro_call_note',
  /** column name */
  IntroductionCallCompletedAt = 'introduction_call_completed_at',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LeadUid = 'lead_uid',
  /** column name */
  Origin = 'origin',
  /** column name */
  Payload = 'payload',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PmsExternalId = 'pms_external_id',
  /** column name */
  Program = 'program',
  /** column name */
  Status = 'status',
  /** column name */
  Uid = 'uid'
}

/** Streaming cursor of the table "patient_and_acquired_patient" */
export type Patient_And_Acquired_Patient_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_And_Acquired_Patient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_And_Acquired_Patient_Stream_Cursor_Value_Input = {
  birthday?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  has_credentials?: InputMaybe<Scalars['Boolean']>;
  health_insurance_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  intro_call_note?: InputMaybe<Scalars['String']>;
  introduction_call_completed_at?: InputMaybe<Scalars['timestamp']>;
  last_name?: InputMaybe<Scalars['String']>;
  lead_uid?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  phone_number?: InputMaybe<Scalars['String']>;
  pms_external_id?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Patient_Append_Input = {
  marketing_data?: InputMaybe<Scalars['jsonb']>;
  referral_sources?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "patient" */
export type Patient_Arr_Rel_Insert_Input = {
  data: Array<Patient_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_On_Conflict>;
};

/** order by avg() on columns of table "patient" */
export type Patient_Avg_Order_By = {
  default_address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patient". All fields are combined with a logical 'AND'. */
export type Patient_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Bool_Exp>>;
  _not?: InputMaybe<Patient_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  acquired_patient?: InputMaybe<Acquired_Patient_Bool_Exp>;
  addresses?: InputMaybe<Address_Bool_Exp>;
  addresses_aggregate?: InputMaybe<Address_Aggregate_Bool_Exp>;
  allergies?: InputMaybe<Allergy_Bool_Exp>;
  allergies_aggregate?: InputMaybe<Allergy_Aggregate_Bool_Exp>;
  auth_tokens_invalidated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  auto_renew_start_date?: InputMaybe<Date_Comparison_Exp>;
  automatic_renewal?: InputMaybe<Boolean_Comparison_Exp>;
  birthday?: InputMaybe<Date_Comparison_Exp>;
  calendly_id?: InputMaybe<String_Comparison_Exp>;
  clinic?: InputMaybe<Clinic_Bool_Exp>;
  clinic_id?: InputMaybe<String_Comparison_Exp>;
  clinic_name?: InputMaybe<String_Comparison_Exp>;
  close_person_first_name?: InputMaybe<String_Comparison_Exp>;
  close_person_last_name?: InputMaybe<String_Comparison_Exp>;
  close_person_relationship_type?: InputMaybe<String_Comparison_Exp>;
  communication_preferences?: InputMaybe<Json_Comparison_Exp>;
  company?: InputMaybe<String_Comparison_Exp>;
  consents?: InputMaybe<Consent_Bool_Exp>;
  consents_aggregate?: InputMaybe<Consent_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  default_address?: InputMaybe<Address_Bool_Exp>;
  default_address_id?: InputMaybe<Int_Comparison_Exp>;
  default_delivery_type?: InputMaybe<Config_Delivery_Type_Enum_Comparison_Exp>;
  default_locker_id?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  doctor_name?: InputMaybe<String_Comparison_Exp>;
  dropped?: InputMaybe<Boolean_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_call_booked?: InputMaybe<Boolean_Comparison_Exp>;
  first_date_medication_needed?: InputMaybe<Date_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  follow_up_programs?: InputMaybe<Patient_Follow_Up_Program_Bool_Exp>;
  follow_up_programs_aggregate?: InputMaybe<Patient_Follow_Up_Program_Aggregate_Bool_Exp>;
  has_accepted_marketing_communications?: InputMaybe<Boolean_Comparison_Exp>;
  has_accepted_terms_and_conditions?: InputMaybe<Boolean_Comparison_Exp>;
  has_active_prescriptions_available_for_transfer?: InputMaybe<Boolean_Comparison_Exp>;
  has_allergies?: InputMaybe<Boolean_Comparison_Exp>;
  has_consented_to_automatic_payments?: InputMaybe<Boolean_Comparison_Exp>;
  has_consented_to_communicate_with_dsq?: InputMaybe<Boolean_Comparison_Exp>;
  has_consented_to_pharmacy_transfer?: InputMaybe<Boolean_Comparison_Exp>;
  has_doctor?: InputMaybe<Boolean_Comparison_Exp>;
  has_health_conditions?: InputMaybe<Boolean_Comparison_Exp>;
  has_insurance?: InputMaybe<Boolean_Comparison_Exp>;
  has_no_prescription_yet?: InputMaybe<Boolean_Comparison_Exp>;
  has_parental_consent?: InputMaybe<Boolean_Comparison_Exp>;
  has_private_insurance?: InputMaybe<Boolean_Comparison_Exp>;
  has_public_insurance?: InputMaybe<Boolean_Comparison_Exp>;
  has_rush_request_on_signup?: InputMaybe<Boolean_Comparison_Exp>;
  health_conditions?: InputMaybe<Health_Condition_Bool_Exp>;
  health_conditions_aggregate?: InputMaybe<Health_Condition_Aggregate_Bool_Exp>;
  health_insurance_number?: InputMaybe<String_Comparison_Exp>;
  heard_about?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identity_verifications?: InputMaybe<Identity_Verification_Bool_Exp>;
  identity_verifications_aggregate?: InputMaybe<Identity_Verification_Aggregate_Bool_Exp>;
  in_app_notification_dismissals?: InputMaybe<In_App_Notification_Dismissal_Bool_Exp>;
  in_app_notification_dismissals_aggregate?: InputMaybe<In_App_Notification_Dismissal_Aggregate_Bool_Exp>;
  incoming_changes?: InputMaybe<Bridge_Incoming_Change_Bool_Exp>;
  incoming_changes_aggregate?: InputMaybe<Bridge_Incoming_Change_Aggregate_Bool_Exp>;
  intro_call_note?: InputMaybe<String_Comparison_Exp>;
  introduction_call_completed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  introduction_call_completed_by?: InputMaybe<Admin_Bool_Exp>;
  introduction_call_completed_by_uid?: InputMaybe<String_Comparison_Exp>;
  introduction_call_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  introduction_call_updated_by?: InputMaybe<Admin_Bool_Exp>;
  introduction_call_updated_by_uid?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  marketing_data?: InputMaybe<Jsonb_Comparison_Exp>;
  medication?: InputMaybe<Medication_Bool_Exp>;
  medication_aggregate?: InputMaybe<Medication_Aggregate_Bool_Exp>;
  natal_sex?: InputMaybe<Config_Sex_Enum_Comparison_Exp>;
  next_renewal_date?: InputMaybe<Date_Comparison_Exp>;
  no_upcoming_order_reason?: InputMaybe<Config_No_Upcoming_Order_Reasons_Enum_Comparison_Exp>;
  onboarded?: InputMaybe<Boolean_Comparison_Exp>;
  orders?: InputMaybe<Order_Bool_Exp>;
  orders_aggregate?: InputMaybe<Order_Aggregate_Bool_Exp>;
  origin?: InputMaybe<String_Comparison_Exp>;
  outgoing_changes?: InputMaybe<Bridge_Outgoing_Change_Bool_Exp>;
  outgoing_changes_aggregate?: InputMaybe<Bridge_Outgoing_Change_Aggregate_Bool_Exp>;
  owned_patient_groups?: InputMaybe<Patient_Group_Bool_Exp>;
  owned_patient_groups_aggregate?: InputMaybe<Patient_Group_Aggregate_Bool_Exp>;
  patient_allergies?: InputMaybe<Patient_Allergies_Bool_Exp>;
  patient_allergies_aggregate?: InputMaybe<Patient_Allergies_Aggregate_Bool_Exp>;
  patient_group_dependencies?: InputMaybe<Patient_Group_Dependent_Bool_Exp>;
  patient_group_dependencies_aggregate?: InputMaybe<Patient_Group_Dependent_Aggregate_Bool_Exp>;
  patient_health_conditions?: InputMaybe<Patient_Health_Conditions_Bool_Exp>;
  patient_health_conditions_aggregate?: InputMaybe<Patient_Health_Conditions_Aggregate_Bool_Exp>;
  patient_status?: InputMaybe<Config_Patient_Status_Bool_Exp>;
  paysafe_profile_id?: InputMaybe<String_Comparison_Exp>;
  pharmacy_transfer_not_applicable?: InputMaybe<Boolean_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  phone_type?: InputMaybe<String_Comparison_Exp>;
  pms_external_id?: InputMaybe<String_Comparison_Exp>;
  preferred_language?: InputMaybe<String_Comparison_Exp>;
  prescriptions?: InputMaybe<Prescription_Bool_Exp>;
  prescriptions_aggregate?: InputMaybe<Prescription_Aggregate_Bool_Exp>;
  private_insurance_company_name?: InputMaybe<String_Comparison_Exp>;
  private_insurances?: InputMaybe<Patient_Private_Insurance_Bool_Exp>;
  private_insurances_aggregate?: InputMaybe<Patient_Private_Insurance_Aggregate_Bool_Exp>;
  pronouns?: InputMaybe<String_Comparison_Exp>;
  public_insurances?: InputMaybe<Public_Insurance_Bool_Exp>;
  public_insurances_aggregate?: InputMaybe<Public_Insurance_Aggregate_Bool_Exp>;
  referral_sources?: InputMaybe<Jsonb_Comparison_Exp>;
  renewal_schedule?: InputMaybe<String_Comparison_Exp>;
  service_requests?: InputMaybe<Service_Request_Bool_Exp>;
  service_requests_aggregate?: InputMaybe<Service_Request_Aggregate_Bool_Exp>;
  signed_up?: InputMaybe<Boolean_Comparison_Exp>;
  status?: InputMaybe<Config_Patient_Status_Enum_Comparison_Exp>;
  status_mode?: InputMaybe<Patient_Status_Mode_Enum_Comparison_Exp>;
  takes_medication?: InputMaybe<Boolean_Comparison_Exp>;
  transfer_requests?: InputMaybe<Transfer_Request_Bool_Exp>;
  transfer_requests_aggregate?: InputMaybe<Transfer_Request_Aggregate_Bool_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  uploads?: InputMaybe<Upload_Bool_Exp>;
  uploads_aggregate?: InputMaybe<Upload_Aggregate_Bool_Exp>;
  zendesk_id?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "patient_care". All fields are combined with a logical 'AND'. */
export type Patient_Care_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Care_Bool_Exp>>;
  _not?: InputMaybe<Patient_Care_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Care_Bool_Exp>>;
  consultation_status?: InputMaybe<Config_Consultation_Status_Enum_Comparison_Exp>;
  follow_up?: InputMaybe<Config_Follow_Up_Status_Enum_Comparison_Exp>;
  form_consultation_id?: InputMaybe<Uuid_Comparison_Exp>;
  gap_status?: InputMaybe<Config_Gap_Status_Enum_Comparison_Exp>;
  invoiced_act?: InputMaybe<Boolean_Comparison_Exp>;
  lab_status?: InputMaybe<Config_Lab_Status_Enum_Comparison_Exp>;
  order_status?: InputMaybe<Config_Order_Status_Enum_Comparison_Exp>;
  patient_care_pathologies?: InputMaybe<Patient_Care_Pathology_Bool_Exp>;
  patient_care_pathologies_aggregate?: InputMaybe<Patient_Care_Pathology_Aggregate_Bool_Exp>;
  prescription_status?: InputMaybe<Config_Form_Consultation_Prescription_Status_Enum_Comparison_Exp>;
  transfer_status?: InputMaybe<Config_Transfer_Request_Status_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_care" */
export enum Patient_Care_Constraint {
  /** unique or primary key constraint on columns "form_consultation_id" */
  PatientCarePkey = 'patient_care_pkey'
}

/** input type for inserting data into table "patient_care" */
export type Patient_Care_Insert_Input = {
  consultation_status?: InputMaybe<Config_Consultation_Status_Enum>;
  follow_up?: InputMaybe<Config_Follow_Up_Status_Enum>;
  form_consultation_id?: InputMaybe<Scalars['uuid']>;
  gap_status?: InputMaybe<Config_Gap_Status_Enum>;
  invoiced_act?: InputMaybe<Scalars['Boolean']>;
  lab_status?: InputMaybe<Config_Lab_Status_Enum>;
  order_status?: InputMaybe<Config_Order_Status_Enum>;
  patient_care_pathologies?: InputMaybe<Patient_Care_Pathology_Arr_Rel_Insert_Input>;
  prescription_status?: InputMaybe<Config_Form_Consultation_Prescription_Status_Enum>;
  transfer_status?: InputMaybe<Config_Transfer_Request_Status_Enum>;
};

/** input type for inserting object relation for remote table "patient_care" */
export type Patient_Care_Obj_Rel_Insert_Input = {
  data: Patient_Care_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Care_On_Conflict>;
};

/** on_conflict condition type for table "patient_care" */
export type Patient_Care_On_Conflict = {
  constraint: Patient_Care_Constraint;
  update_columns?: Array<Patient_Care_Update_Column>;
  where?: InputMaybe<Patient_Care_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_care". */
export type Patient_Care_Order_By = {
  consultation_status?: InputMaybe<Order_By>;
  follow_up?: InputMaybe<Order_By>;
  form_consultation_id?: InputMaybe<Order_By>;
  gap_status?: InputMaybe<Order_By>;
  invoiced_act?: InputMaybe<Order_By>;
  lab_status?: InputMaybe<Order_By>;
  order_status?: InputMaybe<Order_By>;
  patient_care_pathologies_aggregate?: InputMaybe<Patient_Care_Pathology_Aggregate_Order_By>;
  prescription_status?: InputMaybe<Order_By>;
  transfer_status?: InputMaybe<Order_By>;
};

export type Patient_Care_Pathology_Aggregate_Bool_Exp = {
  count?: InputMaybe<Patient_Care_Pathology_Aggregate_Bool_Exp_Count>;
};

export type Patient_Care_Pathology_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patient_Care_Pathology_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Care_Pathology_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "patient_care_pathology" */
export type Patient_Care_Pathology_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patient_Care_Pathology_Max_Order_By>;
  min?: InputMaybe<Patient_Care_Pathology_Min_Order_By>;
};

/** input type for inserting array relation for remote table "patient_care_pathology" */
export type Patient_Care_Pathology_Arr_Rel_Insert_Input = {
  data: Array<Patient_Care_Pathology_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Care_Pathology_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patient_care_pathology". All fields are combined with a logical 'AND'. */
export type Patient_Care_Pathology_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Care_Pathology_Bool_Exp>>;
  _not?: InputMaybe<Patient_Care_Pathology_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Care_Pathology_Bool_Exp>>;
  pathology?: InputMaybe<Config_Pathology_Enum_Comparison_Exp>;
  patient_care_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_care_pathology" */
export enum Patient_Care_Pathology_Constraint {
  /** unique or primary key constraint on columns "patient_care_id", "pathology" */
  PatientCarePathologyPkey = 'patient_care_pathology_pkey'
}

/** input type for inserting data into table "patient_care_pathology" */
export type Patient_Care_Pathology_Insert_Input = {
  pathology?: InputMaybe<Config_Pathology_Enum>;
  patient_care_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "patient_care_pathology" */
export type Patient_Care_Pathology_Max_Order_By = {
  patient_care_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "patient_care_pathology" */
export type Patient_Care_Pathology_Min_Order_By = {
  patient_care_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "patient_care_pathology" */
export type Patient_Care_Pathology_On_Conflict = {
  constraint: Patient_Care_Pathology_Constraint;
  update_columns?: Array<Patient_Care_Pathology_Update_Column>;
  where?: InputMaybe<Patient_Care_Pathology_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_care_pathology". */
export type Patient_Care_Pathology_Order_By = {
  pathology?: InputMaybe<Order_By>;
  patient_care_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_care_pathology */
export type Patient_Care_Pathology_Pk_Columns_Input = {
  pathology: Config_Pathology_Enum;
  patient_care_id: Scalars['uuid'];
};

/** select columns of table "patient_care_pathology" */
export enum Patient_Care_Pathology_Select_Column {
  /** column name */
  Pathology = 'pathology',
  /** column name */
  PatientCareId = 'patient_care_id'
}

/** input type for updating data in table "patient_care_pathology" */
export type Patient_Care_Pathology_Set_Input = {
  pathology?: InputMaybe<Config_Pathology_Enum>;
  patient_care_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "patient_care_pathology" */
export type Patient_Care_Pathology_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Care_Pathology_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Care_Pathology_Stream_Cursor_Value_Input = {
  pathology?: InputMaybe<Config_Pathology_Enum>;
  patient_care_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "patient_care_pathology" */
export enum Patient_Care_Pathology_Update_Column {
  /** column name */
  Pathology = 'pathology',
  /** column name */
  PatientCareId = 'patient_care_id'
}

export type Patient_Care_Pathology_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Care_Pathology_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Care_Pathology_Bool_Exp;
};

/** primary key columns input for table: patient_care */
export type Patient_Care_Pk_Columns_Input = {
  form_consultation_id: Scalars['uuid'];
};

/** select columns of table "patient_care" */
export enum Patient_Care_Select_Column {
  /** column name */
  ConsultationStatus = 'consultation_status',
  /** column name */
  FollowUp = 'follow_up',
  /** column name */
  FormConsultationId = 'form_consultation_id',
  /** column name */
  GapStatus = 'gap_status',
  /** column name */
  InvoicedAct = 'invoiced_act',
  /** column name */
  LabStatus = 'lab_status',
  /** column name */
  OrderStatus = 'order_status',
  /** column name */
  PrescriptionStatus = 'prescription_status',
  /** column name */
  TransferStatus = 'transfer_status'
}

/** input type for updating data in table "patient_care" */
export type Patient_Care_Set_Input = {
  consultation_status?: InputMaybe<Config_Consultation_Status_Enum>;
  follow_up?: InputMaybe<Config_Follow_Up_Status_Enum>;
  form_consultation_id?: InputMaybe<Scalars['uuid']>;
  gap_status?: InputMaybe<Config_Gap_Status_Enum>;
  invoiced_act?: InputMaybe<Scalars['Boolean']>;
  lab_status?: InputMaybe<Config_Lab_Status_Enum>;
  order_status?: InputMaybe<Config_Order_Status_Enum>;
  prescription_status?: InputMaybe<Config_Form_Consultation_Prescription_Status_Enum>;
  transfer_status?: InputMaybe<Config_Transfer_Request_Status_Enum>;
};

/** Streaming cursor of the table "patient_care" */
export type Patient_Care_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Care_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Care_Stream_Cursor_Value_Input = {
  consultation_status?: InputMaybe<Config_Consultation_Status_Enum>;
  follow_up?: InputMaybe<Config_Follow_Up_Status_Enum>;
  form_consultation_id?: InputMaybe<Scalars['uuid']>;
  gap_status?: InputMaybe<Config_Gap_Status_Enum>;
  invoiced_act?: InputMaybe<Scalars['Boolean']>;
  lab_status?: InputMaybe<Config_Lab_Status_Enum>;
  order_status?: InputMaybe<Config_Order_Status_Enum>;
  prescription_status?: InputMaybe<Config_Form_Consultation_Prescription_Status_Enum>;
  transfer_status?: InputMaybe<Config_Transfer_Request_Status_Enum>;
};

/** update columns of table "patient_care" */
export enum Patient_Care_Update_Column {
  /** column name */
  ConsultationStatus = 'consultation_status',
  /** column name */
  FollowUp = 'follow_up',
  /** column name */
  FormConsultationId = 'form_consultation_id',
  /** column name */
  GapStatus = 'gap_status',
  /** column name */
  InvoicedAct = 'invoiced_act',
  /** column name */
  LabStatus = 'lab_status',
  /** column name */
  OrderStatus = 'order_status',
  /** column name */
  PrescriptionStatus = 'prescription_status',
  /** column name */
  TransferStatus = 'transfer_status'
}

export type Patient_Care_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Care_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Care_Bool_Exp;
};

/** unique or primary key constraints on table "patient" */
export enum Patient_Constraint {
  /** unique or primary key constraint on columns "calendly_id" */
  IdxC2843ecce10f662d9fad810df6 = 'IDX_c2843ecce10f662d9fad810df6',
  /** unique or primary key constraint on columns "uid" */
  Pk_16daec71a0b9b27c7dc2b819fbe = 'PK_16daec71a0b9b27c7dc2b819fbe',
  /** unique or primary key constraint on columns "id" */
  Uq_8dfa510bb29ad31ab2139fbfb99 = 'UQ_8dfa510bb29ad31ab2139fbfb99',
  /** unique or primary key constraint on columns "email" */
  PatientEmailKey = 'patient_email_key',
  /** unique or primary key constraint on columns "pms_external_id" */
  PatientPmsExternalIdKey = 'patient_pms_external_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Patient_Delete_At_Path_Input = {
  marketing_data?: InputMaybe<Array<Scalars['String']>>;
  referral_sources?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Patient_Delete_Elem_Input = {
  marketing_data?: InputMaybe<Scalars['Int']>;
  referral_sources?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Patient_Delete_Key_Input = {
  marketing_data?: InputMaybe<Scalars['String']>;
  referral_sources?: InputMaybe<Scalars['String']>;
};

export type Patient_Follow_Up_Program_Aggregate_Bool_Exp = {
  count?: InputMaybe<Patient_Follow_Up_Program_Aggregate_Bool_Exp_Count>;
};

export type Patient_Follow_Up_Program_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patient_Follow_Up_Program_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Follow_Up_Program_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "patient_follow_up_program" */
export type Patient_Follow_Up_Program_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patient_Follow_Up_Program_Max_Order_By>;
  min?: InputMaybe<Patient_Follow_Up_Program_Min_Order_By>;
};

/** input type for inserting array relation for remote table "patient_follow_up_program" */
export type Patient_Follow_Up_Program_Arr_Rel_Insert_Input = {
  data: Array<Patient_Follow_Up_Program_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Follow_Up_Program_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patient_follow_up_program". All fields are combined with a logical 'AND'. */
export type Patient_Follow_Up_Program_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Follow_Up_Program_Bool_Exp>>;
  _not?: InputMaybe<Patient_Follow_Up_Program_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Follow_Up_Program_Bool_Exp>>;
  follow_up_program?: InputMaybe<Config_Follow_Up_Program_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  started_by?: InputMaybe<String_Comparison_Exp>;
  started_by_admin?: InputMaybe<Admin_Bool_Exp>;
  stopped_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  stopped_by?: InputMaybe<String_Comparison_Exp>;
  stopped_by_admin?: InputMaybe<Admin_Bool_Exp>;
};

/** unique or primary key constraints on table "patient_follow_up_program" */
export enum Patient_Follow_Up_Program_Constraint {
  /** unique or primary key constraint on columns "patient_uid", "follow_up_program" */
  PatientFollowUpProgramPatientUidFollowUpProgramKey = 'patient_follow_up_program_patient_uid_follow_up_program_key',
  /** unique or primary key constraint on columns "id" */
  PatientFollowUpProgramPkey = 'patient_follow_up_program_pkey'
}

/** input type for inserting data into table "patient_follow_up_program" */
export type Patient_Follow_Up_Program_Insert_Input = {
  follow_up_program?: InputMaybe<Config_Follow_Up_Program_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  started_by?: InputMaybe<Scalars['String']>;
  started_by_admin?: InputMaybe<Admin_Obj_Rel_Insert_Input>;
  stopped_at?: InputMaybe<Scalars['timestamptz']>;
  stopped_by?: InputMaybe<Scalars['String']>;
  stopped_by_admin?: InputMaybe<Admin_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "patient_follow_up_program" */
export type Patient_Follow_Up_Program_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  started_by?: InputMaybe<Order_By>;
  stopped_at?: InputMaybe<Order_By>;
  stopped_by?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "patient_follow_up_program" */
export type Patient_Follow_Up_Program_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  started_by?: InputMaybe<Order_By>;
  stopped_at?: InputMaybe<Order_By>;
  stopped_by?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "patient_follow_up_program" */
export type Patient_Follow_Up_Program_On_Conflict = {
  constraint: Patient_Follow_Up_Program_Constraint;
  update_columns?: Array<Patient_Follow_Up_Program_Update_Column>;
  where?: InputMaybe<Patient_Follow_Up_Program_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_follow_up_program". */
export type Patient_Follow_Up_Program_Order_By = {
  follow_up_program?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  started_by?: InputMaybe<Order_By>;
  started_by_admin?: InputMaybe<Admin_Order_By>;
  stopped_at?: InputMaybe<Order_By>;
  stopped_by?: InputMaybe<Order_By>;
  stopped_by_admin?: InputMaybe<Admin_Order_By>;
};

/** primary key columns input for table: patient_follow_up_program */
export type Patient_Follow_Up_Program_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patient_follow_up_program" */
export enum Patient_Follow_Up_Program_Select_Column {
  /** column name */
  FollowUpProgram = 'follow_up_program',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  StartedBy = 'started_by',
  /** column name */
  StoppedAt = 'stopped_at',
  /** column name */
  StoppedBy = 'stopped_by'
}

/** input type for updating data in table "patient_follow_up_program" */
export type Patient_Follow_Up_Program_Set_Input = {
  follow_up_program?: InputMaybe<Config_Follow_Up_Program_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  started_by?: InputMaybe<Scalars['String']>;
  stopped_at?: InputMaybe<Scalars['timestamptz']>;
  stopped_by?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "patient_follow_up_program" */
export type Patient_Follow_Up_Program_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Follow_Up_Program_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Follow_Up_Program_Stream_Cursor_Value_Input = {
  follow_up_program?: InputMaybe<Config_Follow_Up_Program_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  started_by?: InputMaybe<Scalars['String']>;
  stopped_at?: InputMaybe<Scalars['timestamptz']>;
  stopped_by?: InputMaybe<Scalars['String']>;
};

/** update columns of table "patient_follow_up_program" */
export enum Patient_Follow_Up_Program_Update_Column {
  /** column name */
  FollowUpProgram = 'follow_up_program',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  StartedBy = 'started_by',
  /** column name */
  StoppedAt = 'stopped_at',
  /** column name */
  StoppedBy = 'stopped_by'
}

export type Patient_Follow_Up_Program_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Follow_Up_Program_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Follow_Up_Program_Bool_Exp;
};

export type Patient_Group_Aggregate_Bool_Exp = {
  count?: InputMaybe<Patient_Group_Aggregate_Bool_Exp_Count>;
};

export type Patient_Group_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patient_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Group_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "patient_group" */
export type Patient_Group_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patient_Group_Max_Order_By>;
  min?: InputMaybe<Patient_Group_Min_Order_By>;
};

/** input type for inserting array relation for remote table "patient_group" */
export type Patient_Group_Arr_Rel_Insert_Input = {
  data: Array<Patient_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Group_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patient_group". All fields are combined with a logical 'AND'. */
export type Patient_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Group_Bool_Exp>>;
  _not?: InputMaybe<Patient_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Group_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  owner?: InputMaybe<Patient_Bool_Exp>;
  owner_uid?: InputMaybe<String_Comparison_Exp>;
  patient_group_dependents?: InputMaybe<Patient_Group_Dependent_Bool_Exp>;
  patient_group_dependents_aggregate?: InputMaybe<Patient_Group_Dependent_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "patient_group" */
export enum Patient_Group_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_1f13e325125f70d896cd15f28ff = 'PK_1f13e325125f70d896cd15f28ff',
  /** unique or primary key constraint on columns "external_id" */
  PatientGroupExternalIdKey = 'patient_group_external_id_key',
  /** unique or primary key constraint on columns "owner_uid" */
  PatientGroupOwnerUidKey = 'patient_group_owner_uid_key'
}

export type Patient_Group_Dependent_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Patient_Group_Dependent_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Patient_Group_Dependent_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Patient_Group_Dependent_Aggregate_Bool_Exp_Count>;
};

export type Patient_Group_Dependent_Aggregate_Bool_Exp_Bool_And = {
  arguments: Patient_Group_Dependent_Select_Column_Patient_Group_Dependent_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Group_Dependent_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Patient_Group_Dependent_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Patient_Group_Dependent_Select_Column_Patient_Group_Dependent_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Group_Dependent_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Patient_Group_Dependent_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patient_Group_Dependent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Group_Dependent_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "patient_group_dependent" */
export type Patient_Group_Dependent_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patient_Group_Dependent_Max_Order_By>;
  min?: InputMaybe<Patient_Group_Dependent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "patient_group_dependent" */
export type Patient_Group_Dependent_Arr_Rel_Insert_Input = {
  data: Array<Patient_Group_Dependent_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Group_Dependent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patient_group_dependent". All fields are combined with a logical 'AND'. */
export type Patient_Group_Dependent_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Group_Dependent_Bool_Exp>>;
  _not?: InputMaybe<Patient_Group_Dependent_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Group_Dependent_Bool_Exp>>;
  dependent_uid?: InputMaybe<String_Comparison_Exp>;
  entered_at?: InputMaybe<Timestamp_Comparison_Exp>;
  has_given_access_to_group_owner?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_group?: InputMaybe<Patient_Group_Bool_Exp>;
  patient_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  relationship?: InputMaybe<Config_Relationship_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_group_dependent" */
export enum Patient_Group_Dependent_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkF836104960282528ea8d33074fb = 'PK_f836104960282528ea8d33074fb',
  /** unique or primary key constraint on columns "dependent_uid", "patient_group_id" */
  PatientGroupDependentPatientGroupIdDependentUidKey = 'patient_group_dependent_patient_group_id_dependent_uid_key'
}

/** input type for inserting data into table "patient_group_dependent" */
export type Patient_Group_Dependent_Insert_Input = {
  dependent_uid?: InputMaybe<Scalars['String']>;
  entered_at?: InputMaybe<Scalars['timestamp']>;
  has_given_access_to_group_owner?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_group?: InputMaybe<Patient_Group_Obj_Rel_Insert_Input>;
  patient_group_id?: InputMaybe<Scalars['uuid']>;
  relationship?: InputMaybe<Config_Relationship_Type_Enum>;
};

/** order by max() on columns of table "patient_group_dependent" */
export type Patient_Group_Dependent_Max_Order_By = {
  dependent_uid?: InputMaybe<Order_By>;
  entered_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_group_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "patient_group_dependent" */
export type Patient_Group_Dependent_Min_Order_By = {
  dependent_uid?: InputMaybe<Order_By>;
  entered_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_group_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "patient_group_dependent" */
export type Patient_Group_Dependent_On_Conflict = {
  constraint: Patient_Group_Dependent_Constraint;
  update_columns?: Array<Patient_Group_Dependent_Update_Column>;
  where?: InputMaybe<Patient_Group_Dependent_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_group_dependent". */
export type Patient_Group_Dependent_Order_By = {
  dependent_uid?: InputMaybe<Order_By>;
  entered_at?: InputMaybe<Order_By>;
  has_given_access_to_group_owner?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_group?: InputMaybe<Patient_Group_Order_By>;
  patient_group_id?: InputMaybe<Order_By>;
  relationship?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_group_dependent */
export type Patient_Group_Dependent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patient_group_dependent" */
export enum Patient_Group_Dependent_Select_Column {
  /** column name */
  DependentUid = 'dependent_uid',
  /** column name */
  EnteredAt = 'entered_at',
  /** column name */
  HasGivenAccessToGroupOwner = 'has_given_access_to_group_owner',
  /** column name */
  Id = 'id',
  /** column name */
  PatientGroupId = 'patient_group_id',
  /** column name */
  Relationship = 'relationship'
}

/** select "patient_group_dependent_aggregate_bool_exp_bool_and_arguments_columns" columns of table "patient_group_dependent" */
export enum Patient_Group_Dependent_Select_Column_Patient_Group_Dependent_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasGivenAccessToGroupOwner = 'has_given_access_to_group_owner'
}

/** select "patient_group_dependent_aggregate_bool_exp_bool_or_arguments_columns" columns of table "patient_group_dependent" */
export enum Patient_Group_Dependent_Select_Column_Patient_Group_Dependent_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasGivenAccessToGroupOwner = 'has_given_access_to_group_owner'
}

/** input type for updating data in table "patient_group_dependent" */
export type Patient_Group_Dependent_Set_Input = {
  dependent_uid?: InputMaybe<Scalars['String']>;
  entered_at?: InputMaybe<Scalars['timestamp']>;
  has_given_access_to_group_owner?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_group_id?: InputMaybe<Scalars['uuid']>;
  relationship?: InputMaybe<Config_Relationship_Type_Enum>;
};

/** Streaming cursor of the table "patient_group_dependent" */
export type Patient_Group_Dependent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Group_Dependent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Group_Dependent_Stream_Cursor_Value_Input = {
  dependent_uid?: InputMaybe<Scalars['String']>;
  entered_at?: InputMaybe<Scalars['timestamp']>;
  has_given_access_to_group_owner?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_group_id?: InputMaybe<Scalars['uuid']>;
  relationship?: InputMaybe<Config_Relationship_Type_Enum>;
};

/** update columns of table "patient_group_dependent" */
export enum Patient_Group_Dependent_Update_Column {
  /** column name */
  DependentUid = 'dependent_uid',
  /** column name */
  EnteredAt = 'entered_at',
  /** column name */
  HasGivenAccessToGroupOwner = 'has_given_access_to_group_owner',
  /** column name */
  Id = 'id',
  /** column name */
  PatientGroupId = 'patient_group_id',
  /** column name */
  Relationship = 'relationship'
}

export type Patient_Group_Dependent_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Group_Dependent_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Group_Dependent_Bool_Exp;
};

/** input type for inserting data into table "patient_group" */
export type Patient_Group_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  owner_uid?: InputMaybe<Scalars['String']>;
  patient_group_dependents?: InputMaybe<Patient_Group_Dependent_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "patient_group" */
export type Patient_Group_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_uid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "patient_group" */
export type Patient_Group_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_uid?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "patient_group" */
export type Patient_Group_Obj_Rel_Insert_Input = {
  data: Patient_Group_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Group_On_Conflict>;
};

/** on_conflict condition type for table "patient_group" */
export type Patient_Group_On_Conflict = {
  constraint: Patient_Group_Constraint;
  update_columns?: Array<Patient_Group_Update_Column>;
  where?: InputMaybe<Patient_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_group". */
export type Patient_Group_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Patient_Order_By>;
  owner_uid?: InputMaybe<Order_By>;
  patient_group_dependents_aggregate?: InputMaybe<Patient_Group_Dependent_Aggregate_Order_By>;
};

/** primary key columns input for table: patient_group */
export type Patient_Group_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "patient_group" */
export enum Patient_Group_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerUid = 'owner_uid'
}

/** input type for updating data in table "patient_group" */
export type Patient_Group_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner_uid?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "patient_group" */
export type Patient_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Group_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner_uid?: InputMaybe<Scalars['String']>;
};

/** update columns of table "patient_group" */
export enum Patient_Group_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerUid = 'owner_uid'
}

export type Patient_Group_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Group_Bool_Exp;
};

export type Patient_Health_Conditions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Patient_Health_Conditions_Aggregate_Bool_Exp_Count>;
};

export type Patient_Health_Conditions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patient_Health_Conditions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Health_Conditions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "patient_health_conditions" */
export type Patient_Health_Conditions_Aggregate_Order_By = {
  avg?: InputMaybe<Patient_Health_Conditions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patient_Health_Conditions_Max_Order_By>;
  min?: InputMaybe<Patient_Health_Conditions_Min_Order_By>;
  stddev?: InputMaybe<Patient_Health_Conditions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Patient_Health_Conditions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Patient_Health_Conditions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Patient_Health_Conditions_Sum_Order_By>;
  var_pop?: InputMaybe<Patient_Health_Conditions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Patient_Health_Conditions_Var_Samp_Order_By>;
  variance?: InputMaybe<Patient_Health_Conditions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "patient_health_conditions" */
export type Patient_Health_Conditions_Arr_Rel_Insert_Input = {
  data: Array<Patient_Health_Conditions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Health_Conditions_On_Conflict>;
};

/** order by avg() on columns of table "patient_health_conditions" */
export type Patient_Health_Conditions_Avg_Order_By = {
  health_condition_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patient_health_conditions". All fields are combined with a logical 'AND'. */
export type Patient_Health_Conditions_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Health_Conditions_Bool_Exp>>;
  _not?: InputMaybe<Patient_Health_Conditions_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Health_Conditions_Bool_Exp>>;
  health_condition?: InputMaybe<Health_Condition_Bool_Exp>;
  health_condition_id?: InputMaybe<Int_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_health_conditions" */
export enum Patient_Health_Conditions_Constraint {
  /** unique or primary key constraint on columns "patient_uid", "health_condition_id" */
  Pk_724c07925278415be388291fd98 = 'PK_724c07925278415be388291fd98'
}

/** input type for incrementing numeric columns in table "patient_health_conditions" */
export type Patient_Health_Conditions_Inc_Input = {
  health_condition_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "patient_health_conditions" */
export type Patient_Health_Conditions_Insert_Input = {
  health_condition?: InputMaybe<Health_Condition_Obj_Rel_Insert_Input>;
  health_condition_id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "patient_health_conditions" */
export type Patient_Health_Conditions_Max_Order_By = {
  health_condition_id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "patient_health_conditions" */
export type Patient_Health_Conditions_Min_Order_By = {
  health_condition_id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "patient_health_conditions" */
export type Patient_Health_Conditions_On_Conflict = {
  constraint: Patient_Health_Conditions_Constraint;
  update_columns?: Array<Patient_Health_Conditions_Update_Column>;
  where?: InputMaybe<Patient_Health_Conditions_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_health_conditions". */
export type Patient_Health_Conditions_Order_By = {
  health_condition?: InputMaybe<Health_Condition_Order_By>;
  health_condition_id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_health_conditions */
export type Patient_Health_Conditions_Pk_Columns_Input = {
  health_condition_id: Scalars['Int'];
  patient_uid: Scalars['String'];
};

/** select columns of table "patient_health_conditions" */
export enum Patient_Health_Conditions_Select_Column {
  /** column name */
  HealthConditionId = 'health_condition_id',
  /** column name */
  PatientUid = 'patient_uid'
}

/** input type for updating data in table "patient_health_conditions" */
export type Patient_Health_Conditions_Set_Input = {
  health_condition_id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "patient_health_conditions" */
export type Patient_Health_Conditions_Stddev_Order_By = {
  health_condition_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "patient_health_conditions" */
export type Patient_Health_Conditions_Stddev_Pop_Order_By = {
  health_condition_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "patient_health_conditions" */
export type Patient_Health_Conditions_Stddev_Samp_Order_By = {
  health_condition_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "patient_health_conditions" */
export type Patient_Health_Conditions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Health_Conditions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Health_Conditions_Stream_Cursor_Value_Input = {
  health_condition_id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "patient_health_conditions" */
export type Patient_Health_Conditions_Sum_Order_By = {
  health_condition_id?: InputMaybe<Order_By>;
};

/** update columns of table "patient_health_conditions" */
export enum Patient_Health_Conditions_Update_Column {
  /** column name */
  HealthConditionId = 'health_condition_id',
  /** column name */
  PatientUid = 'patient_uid'
}

export type Patient_Health_Conditions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patient_Health_Conditions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Health_Conditions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Health_Conditions_Bool_Exp;
};

/** order by var_pop() on columns of table "patient_health_conditions" */
export type Patient_Health_Conditions_Var_Pop_Order_By = {
  health_condition_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "patient_health_conditions" */
export type Patient_Health_Conditions_Var_Samp_Order_By = {
  health_condition_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "patient_health_conditions" */
export type Patient_Health_Conditions_Variance_Order_By = {
  health_condition_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "patient" */
export type Patient_Inc_Input = {
  default_address_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "patient" */
export type Patient_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  acquired_patient?: InputMaybe<Acquired_Patient_Obj_Rel_Insert_Input>;
  addresses?: InputMaybe<Address_Arr_Rel_Insert_Input>;
  allergies?: InputMaybe<Allergy_Arr_Rel_Insert_Input>;
  auth_tokens_invalidated_at?: InputMaybe<Scalars['timestamp']>;
  auto_renew_start_date?: InputMaybe<Scalars['date']>;
  automatic_renewal?: InputMaybe<Scalars['Boolean']>;
  birthday?: InputMaybe<Scalars['date']>;
  calendly_id?: InputMaybe<Scalars['String']>;
  clinic?: InputMaybe<Clinic_Obj_Rel_Insert_Input>;
  clinic_id?: InputMaybe<Scalars['String']>;
  /** Deprecated, use clinic_id instead */
  clinic_name?: InputMaybe<Scalars['String']>;
  close_person_first_name?: InputMaybe<Scalars['String']>;
  close_person_last_name?: InputMaybe<Scalars['String']>;
  close_person_relationship_type?: InputMaybe<Scalars['String']>;
  communication_preferences?: InputMaybe<Scalars['json']>;
  company?: InputMaybe<Scalars['String']>;
  consents?: InputMaybe<Consent_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default_address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  default_address_id?: InputMaybe<Scalars['Int']>;
  default_delivery_type?: InputMaybe<Config_Delivery_Type_Enum>;
  default_locker_id?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  doctor_name?: InputMaybe<Scalars['String']>;
  dropped?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  first_call_booked?: InputMaybe<Scalars['Boolean']>;
  first_date_medication_needed?: InputMaybe<Scalars['date']>;
  first_name?: InputMaybe<Scalars['String']>;
  follow_up_programs?: InputMaybe<Patient_Follow_Up_Program_Arr_Rel_Insert_Input>;
  has_accepted_marketing_communications?: InputMaybe<Scalars['Boolean']>;
  has_accepted_terms_and_conditions?: InputMaybe<Scalars['Boolean']>;
  has_active_prescriptions_available_for_transfer?: InputMaybe<Scalars['Boolean']>;
  has_allergies?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_automatic_payments?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_communicate_with_dsq?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_pharmacy_transfer?: InputMaybe<Scalars['Boolean']>;
  has_doctor?: InputMaybe<Scalars['Boolean']>;
  has_health_conditions?: InputMaybe<Scalars['Boolean']>;
  has_insurance?: InputMaybe<Scalars['Boolean']>;
  has_no_prescription_yet?: InputMaybe<Scalars['Boolean']>;
  has_parental_consent?: InputMaybe<Scalars['Boolean']>;
  has_private_insurance?: InputMaybe<Scalars['Boolean']>;
  has_public_insurance?: InputMaybe<Scalars['Boolean']>;
  /** DEPRECATED */
  has_rush_request_on_signup?: InputMaybe<Scalars['Boolean']>;
  health_conditions?: InputMaybe<Health_Condition_Arr_Rel_Insert_Input>;
  health_insurance_number?: InputMaybe<Scalars['String']>;
  heard_about?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identity_verifications?: InputMaybe<Identity_Verification_Arr_Rel_Insert_Input>;
  in_app_notification_dismissals?: InputMaybe<In_App_Notification_Dismissal_Arr_Rel_Insert_Input>;
  incoming_changes?: InputMaybe<Bridge_Incoming_Change_Arr_Rel_Insert_Input>;
  intro_call_note?: InputMaybe<Scalars['String']>;
  introduction_call_completed_at?: InputMaybe<Scalars['timestamp']>;
  introduction_call_completed_by?: InputMaybe<Admin_Obj_Rel_Insert_Input>;
  introduction_call_completed_by_uid?: InputMaybe<Scalars['String']>;
  introduction_call_updated_at?: InputMaybe<Scalars['timestamp']>;
  introduction_call_updated_by?: InputMaybe<Admin_Obj_Rel_Insert_Input>;
  introduction_call_updated_by_uid?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  marketing_data?: InputMaybe<Scalars['jsonb']>;
  medication?: InputMaybe<Medication_Arr_Rel_Insert_Input>;
  natal_sex?: InputMaybe<Config_Sex_Enum>;
  no_upcoming_order_reason?: InputMaybe<Config_No_Upcoming_Order_Reasons_Enum>;
  onboarded?: InputMaybe<Scalars['Boolean']>;
  orders?: InputMaybe<Order_Arr_Rel_Insert_Input>;
  outgoing_changes?: InputMaybe<Bridge_Outgoing_Change_Arr_Rel_Insert_Input>;
  owned_patient_groups?: InputMaybe<Patient_Group_Arr_Rel_Insert_Input>;
  patient_allergies?: InputMaybe<Patient_Allergies_Arr_Rel_Insert_Input>;
  patient_group_dependencies?: InputMaybe<Patient_Group_Dependent_Arr_Rel_Insert_Input>;
  patient_health_conditions?: InputMaybe<Patient_Health_Conditions_Arr_Rel_Insert_Input>;
  patient_status?: InputMaybe<Config_Patient_Status_Obj_Rel_Insert_Input>;
  paysafe_profile_id?: InputMaybe<Scalars['String']>;
  pharmacy_transfer_not_applicable?: InputMaybe<Scalars['Boolean']>;
  phone_number?: InputMaybe<Scalars['String']>;
  phone_type?: InputMaybe<Scalars['String']>;
  pms_external_id?: InputMaybe<Scalars['String']>;
  preferred_language?: InputMaybe<Scalars['String']>;
  prescriptions?: InputMaybe<Prescription_Arr_Rel_Insert_Input>;
  private_insurance_company_name?: InputMaybe<Scalars['String']>;
  private_insurances?: InputMaybe<Patient_Private_Insurance_Arr_Rel_Insert_Input>;
  pronouns?: InputMaybe<Scalars['String']>;
  public_insurances?: InputMaybe<Public_Insurance_Arr_Rel_Insert_Input>;
  referral_sources?: InputMaybe<Scalars['jsonb']>;
  renewal_schedule?: InputMaybe<Scalars['String']>;
  service_requests?: InputMaybe<Service_Request_Arr_Rel_Insert_Input>;
  /** DEPRECATED (2022/06) */
  signed_up?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Config_Patient_Status_Enum>;
  /** DEPRECATED (2022/06) */
  status_mode?: InputMaybe<Scalars['patient_status_mode_enum']>;
  takes_medication?: InputMaybe<Scalars['Boolean']>;
  transfer_requests?: InputMaybe<Transfer_Request_Arr_Rel_Insert_Input>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  uploads?: InputMaybe<Upload_Arr_Rel_Insert_Input>;
  zendesk_id?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "patient" */
export type Patient_Max_Order_By = {
  auth_tokens_invalidated_at?: InputMaybe<Order_By>;
  auto_renew_start_date?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  calendly_id?: InputMaybe<Order_By>;
  clinic_id?: InputMaybe<Order_By>;
  /** Deprecated, use clinic_id instead */
  clinic_name?: InputMaybe<Order_By>;
  close_person_first_name?: InputMaybe<Order_By>;
  close_person_last_name?: InputMaybe<Order_By>;
  close_person_relationship_type?: InputMaybe<Order_By>;
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_address_id?: InputMaybe<Order_By>;
  default_locker_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  doctor_name?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_date_medication_needed?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  health_insurance_number?: InputMaybe<Order_By>;
  heard_about?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intro_call_note?: InputMaybe<Order_By>;
  introduction_call_completed_at?: InputMaybe<Order_By>;
  introduction_call_completed_by_uid?: InputMaybe<Order_By>;
  introduction_call_updated_at?: InputMaybe<Order_By>;
  introduction_call_updated_by_uid?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  paysafe_profile_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  phone_type?: InputMaybe<Order_By>;
  pms_external_id?: InputMaybe<Order_By>;
  preferred_language?: InputMaybe<Order_By>;
  private_insurance_company_name?: InputMaybe<Order_By>;
  pronouns?: InputMaybe<Order_By>;
  renewal_schedule?: InputMaybe<Order_By>;
  /** DEPRECATED (2022/06) */
  status_mode?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zendesk_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "patient" */
export type Patient_Min_Order_By = {
  auth_tokens_invalidated_at?: InputMaybe<Order_By>;
  auto_renew_start_date?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  calendly_id?: InputMaybe<Order_By>;
  clinic_id?: InputMaybe<Order_By>;
  /** Deprecated, use clinic_id instead */
  clinic_name?: InputMaybe<Order_By>;
  close_person_first_name?: InputMaybe<Order_By>;
  close_person_last_name?: InputMaybe<Order_By>;
  close_person_relationship_type?: InputMaybe<Order_By>;
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_address_id?: InputMaybe<Order_By>;
  default_locker_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  doctor_name?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_date_medication_needed?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  health_insurance_number?: InputMaybe<Order_By>;
  heard_about?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  intro_call_note?: InputMaybe<Order_By>;
  introduction_call_completed_at?: InputMaybe<Order_By>;
  introduction_call_completed_by_uid?: InputMaybe<Order_By>;
  introduction_call_updated_at?: InputMaybe<Order_By>;
  introduction_call_updated_by_uid?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  paysafe_profile_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  phone_type?: InputMaybe<Order_By>;
  pms_external_id?: InputMaybe<Order_By>;
  preferred_language?: InputMaybe<Order_By>;
  private_insurance_company_name?: InputMaybe<Order_By>;
  pronouns?: InputMaybe<Order_By>;
  renewal_schedule?: InputMaybe<Order_By>;
  /** DEPRECATED (2022/06) */
  status_mode?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zendesk_id?: InputMaybe<Order_By>;
};

export type Patient_Note_Aggregate_Bool_Exp = {
  count?: InputMaybe<Patient_Note_Aggregate_Bool_Exp_Count>;
};

export type Patient_Note_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patient_Note_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Note_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "patient_note" */
export type Patient_Note_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patient_Note_Max_Order_By>;
  min?: InputMaybe<Patient_Note_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Patient_Note_Append_Input = {
  detail?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "patient_note" */
export type Patient_Note_Arr_Rel_Insert_Input = {
  data: Array<Patient_Note_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Note_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patient_note". All fields are combined with a logical 'AND'. */
export type Patient_Note_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Note_Bool_Exp>>;
  _not?: InputMaybe<Patient_Note_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Note_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_uid?: InputMaybe<String_Comparison_Exp>;
  detail?: InputMaybe<Jsonb_Comparison_Exp>;
  note?: InputMaybe<Note_Bool_Exp>;
  note_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Config_Note_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_note" */
export enum Patient_Note_Constraint {
  /** unique or primary key constraint on columns "note_id", "account_uid" */
  Pk_96d0c172a4fba276b1bbed43058 = 'PK_96d0c172a4fba276b1bbed43058'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Patient_Note_Delete_At_Path_Input = {
  detail?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Patient_Note_Delete_Elem_Input = {
  detail?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Patient_Note_Delete_Key_Input = {
  detail?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "patient_note" */
export type Patient_Note_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_uid?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['jsonb']>;
  note?: InputMaybe<Note_Obj_Rel_Insert_Input>;
  note_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Config_Note_Type_Enum>;
};

/** order by max() on columns of table "patient_note" */
export type Patient_Note_Max_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  note_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "patient_note" */
export type Patient_Note_Min_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  note_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "patient_note" */
export type Patient_Note_On_Conflict = {
  constraint: Patient_Note_Constraint;
  update_columns?: Array<Patient_Note_Update_Column>;
  where?: InputMaybe<Patient_Note_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_note". */
export type Patient_Note_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_uid?: InputMaybe<Order_By>;
  detail?: InputMaybe<Order_By>;
  note?: InputMaybe<Note_Order_By>;
  note_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_note */
export type Patient_Note_Pk_Columns_Input = {
  account_uid: Scalars['String'];
  note_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Patient_Note_Prepend_Input = {
  detail?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "patient_note" */
export enum Patient_Note_Select_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  Detail = 'detail',
  /** column name */
  NoteId = 'note_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "patient_note" */
export type Patient_Note_Set_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['jsonb']>;
  note_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Config_Note_Type_Enum>;
};

/** Streaming cursor of the table "patient_note" */
export type Patient_Note_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Note_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Note_Stream_Cursor_Value_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['jsonb']>;
  note_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Config_Note_Type_Enum>;
};

/** update columns of table "patient_note" */
export enum Patient_Note_Update_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  Detail = 'detail',
  /** column name */
  NoteId = 'note_id',
  /** column name */
  Type = 'type'
}

export type Patient_Note_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Patient_Note_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Patient_Note_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Patient_Note_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Patient_Note_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Patient_Note_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Note_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Note_Bool_Exp;
};

export type Patient_Notification_Aggregate_Bool_Exp = {
  count?: InputMaybe<Patient_Notification_Aggregate_Bool_Exp_Count>;
};

export type Patient_Notification_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patient_Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Notification_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "patient_notification" */
export type Patient_Notification_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patient_Notification_Max_Order_By>;
  min?: InputMaybe<Patient_Notification_Min_Order_By>;
};

/** input type for inserting array relation for remote table "patient_notification" */
export type Patient_Notification_Arr_Rel_Insert_Input = {
  data: Array<Patient_Notification_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_Notification_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patient_notification". All fields are combined with a logical 'AND'. */
export type Patient_Notification_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Notification_Bool_Exp>>;
  _not?: InputMaybe<Patient_Notification_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Notification_Bool_Exp>>;
  dismissed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  notification_id?: InputMaybe<Uuid_Comparison_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_notification" */
export enum Patient_Notification_Constraint {
  /** unique or primary key constraint on columns "patient_uid", "notification_id" */
  PatientNotificationPkey = 'patient_notification_pkey'
}

/** input type for inserting data into table "patient_notification" */
export type Patient_Notification_Insert_Input = {
  dismissed_at?: InputMaybe<Scalars['timestamptz']>;
  notification_id?: InputMaybe<Scalars['uuid']>;
  patient_uid?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "patient_notification" */
export type Patient_Notification_Max_Order_By = {
  dismissed_at?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "patient_notification" */
export type Patient_Notification_Min_Order_By = {
  dismissed_at?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "patient_notification" */
export type Patient_Notification_On_Conflict = {
  constraint: Patient_Notification_Constraint;
  update_columns?: Array<Patient_Notification_Update_Column>;
  where?: InputMaybe<Patient_Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "patient_notification". */
export type Patient_Notification_Order_By = {
  dismissed_at?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient_notification */
export type Patient_Notification_Pk_Columns_Input = {
  notification_id: Scalars['uuid'];
  patient_uid: Scalars['String'];
};

/** select columns of table "patient_notification" */
export enum Patient_Notification_Select_Column {
  /** column name */
  DismissedAt = 'dismissed_at',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  PatientUid = 'patient_uid'
}

/** input type for updating data in table "patient_notification" */
export type Patient_Notification_Set_Input = {
  dismissed_at?: InputMaybe<Scalars['timestamptz']>;
  notification_id?: InputMaybe<Scalars['uuid']>;
  patient_uid?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "patient_notification" */
export type Patient_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Notification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Notification_Stream_Cursor_Value_Input = {
  dismissed_at?: InputMaybe<Scalars['timestamptz']>;
  notification_id?: InputMaybe<Scalars['uuid']>;
  patient_uid?: InputMaybe<Scalars['String']>;
};

/** update columns of table "patient_notification" */
export enum Patient_Notification_Update_Column {
  /** column name */
  DismissedAt = 'dismissed_at',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  PatientUid = 'patient_uid'
}

export type Patient_Notification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Notification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Notification_Bool_Exp;
};

/** input type for inserting object relation for remote table "patient" */
export type Patient_Obj_Rel_Insert_Input = {
  data: Patient_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patient_On_Conflict>;
};

/** on_conflict condition type for table "patient" */
export type Patient_On_Conflict = {
  constraint: Patient_Constraint;
  update_columns?: Array<Patient_Update_Column>;
  where?: InputMaybe<Patient_Bool_Exp>;
};

/** Ordering options when selecting data from "patient". */
export type Patient_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  acquired_patient?: InputMaybe<Acquired_Patient_Order_By>;
  addresses_aggregate?: InputMaybe<Address_Aggregate_Order_By>;
  allergies_aggregate?: InputMaybe<Allergy_Aggregate_Order_By>;
  auth_tokens_invalidated_at?: InputMaybe<Order_By>;
  auto_renew_start_date?: InputMaybe<Order_By>;
  automatic_renewal?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  calendly_id?: InputMaybe<Order_By>;
  clinic?: InputMaybe<Clinic_Order_By>;
  clinic_id?: InputMaybe<Order_By>;
  clinic_name?: InputMaybe<Order_By>;
  close_person_first_name?: InputMaybe<Order_By>;
  close_person_last_name?: InputMaybe<Order_By>;
  close_person_relationship_type?: InputMaybe<Order_By>;
  communication_preferences?: InputMaybe<Order_By>;
  company?: InputMaybe<Order_By>;
  consents_aggregate?: InputMaybe<Consent_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_address?: InputMaybe<Address_Order_By>;
  default_address_id?: InputMaybe<Order_By>;
  default_delivery_type?: InputMaybe<Order_By>;
  default_locker_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  doctor_name?: InputMaybe<Order_By>;
  dropped?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_call_booked?: InputMaybe<Order_By>;
  first_date_medication_needed?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  follow_up_programs_aggregate?: InputMaybe<Patient_Follow_Up_Program_Aggregate_Order_By>;
  has_accepted_marketing_communications?: InputMaybe<Order_By>;
  has_accepted_terms_and_conditions?: InputMaybe<Order_By>;
  has_active_prescriptions_available_for_transfer?: InputMaybe<Order_By>;
  has_allergies?: InputMaybe<Order_By>;
  has_consented_to_automatic_payments?: InputMaybe<Order_By>;
  has_consented_to_communicate_with_dsq?: InputMaybe<Order_By>;
  has_consented_to_pharmacy_transfer?: InputMaybe<Order_By>;
  has_doctor?: InputMaybe<Order_By>;
  has_health_conditions?: InputMaybe<Order_By>;
  has_insurance?: InputMaybe<Order_By>;
  has_no_prescription_yet?: InputMaybe<Order_By>;
  has_parental_consent?: InputMaybe<Order_By>;
  has_private_insurance?: InputMaybe<Order_By>;
  has_public_insurance?: InputMaybe<Order_By>;
  has_rush_request_on_signup?: InputMaybe<Order_By>;
  health_conditions_aggregate?: InputMaybe<Health_Condition_Aggregate_Order_By>;
  health_insurance_number?: InputMaybe<Order_By>;
  heard_about?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identity_verifications_aggregate?: InputMaybe<Identity_Verification_Aggregate_Order_By>;
  in_app_notification_dismissals_aggregate?: InputMaybe<In_App_Notification_Dismissal_Aggregate_Order_By>;
  incoming_changes_aggregate?: InputMaybe<Bridge_Incoming_Change_Aggregate_Order_By>;
  intro_call_note?: InputMaybe<Order_By>;
  introduction_call_completed_at?: InputMaybe<Order_By>;
  introduction_call_completed_by?: InputMaybe<Admin_Order_By>;
  introduction_call_completed_by_uid?: InputMaybe<Order_By>;
  introduction_call_updated_at?: InputMaybe<Order_By>;
  introduction_call_updated_by?: InputMaybe<Admin_Order_By>;
  introduction_call_updated_by_uid?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  marketing_data?: InputMaybe<Order_By>;
  medication_aggregate?: InputMaybe<Medication_Aggregate_Order_By>;
  natal_sex?: InputMaybe<Order_By>;
  next_renewal_date?: InputMaybe<Order_By>;
  no_upcoming_order_reason?: InputMaybe<Order_By>;
  onboarded?: InputMaybe<Order_By>;
  orders_aggregate?: InputMaybe<Order_Aggregate_Order_By>;
  origin?: InputMaybe<Order_By>;
  outgoing_changes_aggregate?: InputMaybe<Bridge_Outgoing_Change_Aggregate_Order_By>;
  owned_patient_groups_aggregate?: InputMaybe<Patient_Group_Aggregate_Order_By>;
  patient_allergies_aggregate?: InputMaybe<Patient_Allergies_Aggregate_Order_By>;
  patient_group_dependencies_aggregate?: InputMaybe<Patient_Group_Dependent_Aggregate_Order_By>;
  patient_health_conditions_aggregate?: InputMaybe<Patient_Health_Conditions_Aggregate_Order_By>;
  patient_status?: InputMaybe<Config_Patient_Status_Order_By>;
  paysafe_profile_id?: InputMaybe<Order_By>;
  pharmacy_transfer_not_applicable?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  phone_type?: InputMaybe<Order_By>;
  pms_external_id?: InputMaybe<Order_By>;
  preferred_language?: InputMaybe<Order_By>;
  prescriptions_aggregate?: InputMaybe<Prescription_Aggregate_Order_By>;
  private_insurance_company_name?: InputMaybe<Order_By>;
  private_insurances_aggregate?: InputMaybe<Patient_Private_Insurance_Aggregate_Order_By>;
  pronouns?: InputMaybe<Order_By>;
  public_insurances_aggregate?: InputMaybe<Public_Insurance_Aggregate_Order_By>;
  referral_sources?: InputMaybe<Order_By>;
  renewal_schedule?: InputMaybe<Order_By>;
  service_requests_aggregate?: InputMaybe<Service_Request_Aggregate_Order_By>;
  signed_up?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_mode?: InputMaybe<Order_By>;
  takes_medication?: InputMaybe<Order_By>;
  transfer_requests_aggregate?: InputMaybe<Transfer_Request_Aggregate_Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploads_aggregate?: InputMaybe<Upload_Aggregate_Order_By>;
  zendesk_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patient */
export type Patient_Pk_Columns_Input = {
  uid: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Patient_Prepend_Input = {
  marketing_data?: InputMaybe<Scalars['jsonb']>;
  referral_sources?: InputMaybe<Scalars['jsonb']>;
};

export type Patient_Private_Insurance_Aggregate_Bool_Exp = {
  count?: InputMaybe<Patient_Private_Insurance_Aggregate_Bool_Exp_Count>;
};

export type Patient_Private_Insurance_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patient_Private_Insurance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patient_Private_Insurance_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "patient_private_insurance" */
export type Patient_Private_Insurance_Aggregate_Order_By = {
  avg?: InputMaybe<Patient_Private_Insurance_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patient_Private_Insurance_Max_Order_By>;
  min?: InputMaybe<Patient_Private_Insurance_Min_Order_By>;
  stddev?: InputMaybe<Patient_Private_Insurance_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Patient_Private_Insurance_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Patient_Private_Insurance_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Patient_Private_Insurance_Sum_Order_By>;
  var_pop?: InputMaybe<Patient_Private_Insurance_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Patient_Private_Insurance_Var_Samp_Order_By>;
  variance?: InputMaybe<Patient_Private_Insurance_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "patient_private_insurance" */
export type Patient_Private_Insurance_Arr_Rel_Insert_Input = {
  data: Array<Patient_Private_Insurance_Insert_Input>;
};

/** order by avg() on columns of table "patient_private_insurance" */
export type Patient_Private_Insurance_Avg_Order_By = {
  private_insurance_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patient_private_insurance". All fields are combined with a logical 'AND'. */
export type Patient_Private_Insurance_Bool_Exp = {
  _and?: InputMaybe<Array<Patient_Private_Insurance_Bool_Exp>>;
  _not?: InputMaybe<Patient_Private_Insurance_Bool_Exp>;
  _or?: InputMaybe<Array<Patient_Private_Insurance_Bool_Exp>>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  private_insurance?: InputMaybe<Private_Insurance_Bool_Exp>;
  private_insurance_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Config_Insurance_Status_Enum_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "patient_private_insurance" */
export type Patient_Private_Insurance_Inc_Input = {
  private_insurance_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "patient_private_insurance" */
export type Patient_Private_Insurance_Insert_Input = {
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  private_insurance?: InputMaybe<Private_Insurance_Obj_Rel_Insert_Input>;
  private_insurance_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Config_Insurance_Status_Enum>;
};

/** order by max() on columns of table "patient_private_insurance" */
export type Patient_Private_Insurance_Max_Order_By = {
  deleted_at?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  private_insurance_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "patient_private_insurance" */
export type Patient_Private_Insurance_Min_Order_By = {
  deleted_at?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  private_insurance_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "patient_private_insurance". */
export type Patient_Private_Insurance_Order_By = {
  deleted_at?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  private_insurance?: InputMaybe<Private_Insurance_Order_By>;
  private_insurance_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** select columns of table "patient_private_insurance" */
export enum Patient_Private_Insurance_Select_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PrivateInsuranceId = 'private_insurance_id',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "patient_private_insurance" */
export type Patient_Private_Insurance_Set_Input = {
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  private_insurance_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Config_Insurance_Status_Enum>;
};

/** order by stddev() on columns of table "patient_private_insurance" */
export type Patient_Private_Insurance_Stddev_Order_By = {
  private_insurance_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "patient_private_insurance" */
export type Patient_Private_Insurance_Stddev_Pop_Order_By = {
  private_insurance_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "patient_private_insurance" */
export type Patient_Private_Insurance_Stddev_Samp_Order_By = {
  private_insurance_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "patient_private_insurance" */
export type Patient_Private_Insurance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Private_Insurance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Private_Insurance_Stream_Cursor_Value_Input = {
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  private_insurance_id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Config_Insurance_Status_Enum>;
};

/** order by sum() on columns of table "patient_private_insurance" */
export type Patient_Private_Insurance_Sum_Order_By = {
  private_insurance_id?: InputMaybe<Order_By>;
};

export type Patient_Private_Insurance_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patient_Private_Insurance_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Private_Insurance_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Private_Insurance_Bool_Exp;
};

/** order by var_pop() on columns of table "patient_private_insurance" */
export type Patient_Private_Insurance_Var_Pop_Order_By = {
  private_insurance_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "patient_private_insurance" */
export type Patient_Private_Insurance_Var_Samp_Order_By = {
  private_insurance_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "patient_private_insurance" */
export type Patient_Private_Insurance_Variance_Order_By = {
  private_insurance_id?: InputMaybe<Order_By>;
};

/** select columns of table "patient" */
export enum Patient_Select_Column {
  /** column name */
  AuthTokensInvalidatedAt = 'auth_tokens_invalidated_at',
  /** column name */
  AutoRenewStartDate = 'auto_renew_start_date',
  /** column name */
  AutomaticRenewal = 'automatic_renewal',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CalendlyId = 'calendly_id',
  /** column name */
  ClinicId = 'clinic_id',
  /** column name */
  ClinicName = 'clinic_name',
  /** column name */
  ClosePersonFirstName = 'close_person_first_name',
  /** column name */
  ClosePersonLastName = 'close_person_last_name',
  /** column name */
  ClosePersonRelationshipType = 'close_person_relationship_type',
  /** column name */
  CommunicationPreferences = 'communication_preferences',
  /** column name */
  Company = 'company',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultAddressId = 'default_address_id',
  /** column name */
  DefaultDeliveryType = 'default_delivery_type',
  /** column name */
  DefaultLockerId = 'default_locker_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DoctorName = 'doctor_name',
  /** column name */
  Dropped = 'dropped',
  /** column name */
  Email = 'email',
  /** column name */
  FirstCallBooked = 'first_call_booked',
  /** column name */
  FirstDateMedicationNeeded = 'first_date_medication_needed',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HasAcceptedMarketingCommunications = 'has_accepted_marketing_communications',
  /** column name */
  HasAcceptedTermsAndConditions = 'has_accepted_terms_and_conditions',
  /** column name */
  HasActivePrescriptionsAvailableForTransfer = 'has_active_prescriptions_available_for_transfer',
  /** column name */
  HasAllergies = 'has_allergies',
  /** column name */
  HasConsentedToAutomaticPayments = 'has_consented_to_automatic_payments',
  /** column name */
  HasConsentedToCommunicateWithDsq = 'has_consented_to_communicate_with_dsq',
  /** column name */
  HasConsentedToPharmacyTransfer = 'has_consented_to_pharmacy_transfer',
  /** column name */
  HasDoctor = 'has_doctor',
  /** column name */
  HasHealthConditions = 'has_health_conditions',
  /** column name */
  HasInsurance = 'has_insurance',
  /** column name */
  HasNoPrescriptionYet = 'has_no_prescription_yet',
  /** column name */
  HasParentalConsent = 'has_parental_consent',
  /** column name */
  HasPrivateInsurance = 'has_private_insurance',
  /** column name */
  HasPublicInsurance = 'has_public_insurance',
  /** column name */
  HasRushRequestOnSignup = 'has_rush_request_on_signup',
  /** column name */
  HealthInsuranceNumber = 'health_insurance_number',
  /** column name */
  HeardAbout = 'heard_about',
  /** column name */
  Id = 'id',
  /** column name */
  IntroCallNote = 'intro_call_note',
  /** column name */
  IntroductionCallCompletedAt = 'introduction_call_completed_at',
  /** column name */
  IntroductionCallCompletedByUid = 'introduction_call_completed_by_uid',
  /** column name */
  IntroductionCallUpdatedAt = 'introduction_call_updated_at',
  /** column name */
  IntroductionCallUpdatedByUid = 'introduction_call_updated_by_uid',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MarketingData = 'marketing_data',
  /** column name */
  NatalSex = 'natal_sex',
  /** column name */
  NoUpcomingOrderReason = 'no_upcoming_order_reason',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  PaysafeProfileId = 'paysafe_profile_id',
  /** column name */
  PharmacyTransferNotApplicable = 'pharmacy_transfer_not_applicable',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PhoneType = 'phone_type',
  /** column name */
  PmsExternalId = 'pms_external_id',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  PrivateInsuranceCompanyName = 'private_insurance_company_name',
  /** column name */
  Pronouns = 'pronouns',
  /** column name */
  ReferralSources = 'referral_sources',
  /** column name */
  RenewalSchedule = 'renewal_schedule',
  /** column name */
  SignedUp = 'signed_up',
  /** column name */
  Status = 'status',
  /** column name */
  StatusMode = 'status_mode',
  /** column name */
  TakesMedication = 'takes_medication',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZendeskId = 'zendesk_id'
}

/** select "patient_aggregate_bool_exp_bool_and_arguments_columns" columns of table "patient" */
export enum Patient_Select_Column_Patient_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AutomaticRenewal = 'automatic_renewal',
  /** column name */
  Dropped = 'dropped',
  /** column name */
  FirstCallBooked = 'first_call_booked',
  /** column name */
  HasAcceptedMarketingCommunications = 'has_accepted_marketing_communications',
  /** column name */
  HasAcceptedTermsAndConditions = 'has_accepted_terms_and_conditions',
  /** column name */
  HasActivePrescriptionsAvailableForTransfer = 'has_active_prescriptions_available_for_transfer',
  /** column name */
  HasAllergies = 'has_allergies',
  /** column name */
  HasConsentedToAutomaticPayments = 'has_consented_to_automatic_payments',
  /** column name */
  HasConsentedToCommunicateWithDsq = 'has_consented_to_communicate_with_dsq',
  /** column name */
  HasConsentedToPharmacyTransfer = 'has_consented_to_pharmacy_transfer',
  /** column name */
  HasDoctor = 'has_doctor',
  /** column name */
  HasHealthConditions = 'has_health_conditions',
  /** column name */
  HasInsurance = 'has_insurance',
  /** column name */
  HasNoPrescriptionYet = 'has_no_prescription_yet',
  /** column name */
  HasParentalConsent = 'has_parental_consent',
  /** column name */
  HasPrivateInsurance = 'has_private_insurance',
  /** column name */
  HasPublicInsurance = 'has_public_insurance',
  /** column name */
  HasRushRequestOnSignup = 'has_rush_request_on_signup',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  PharmacyTransferNotApplicable = 'pharmacy_transfer_not_applicable',
  /** column name */
  SignedUp = 'signed_up',
  /** column name */
  TakesMedication = 'takes_medication'
}

/** select "patient_aggregate_bool_exp_bool_or_arguments_columns" columns of table "patient" */
export enum Patient_Select_Column_Patient_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AutomaticRenewal = 'automatic_renewal',
  /** column name */
  Dropped = 'dropped',
  /** column name */
  FirstCallBooked = 'first_call_booked',
  /** column name */
  HasAcceptedMarketingCommunications = 'has_accepted_marketing_communications',
  /** column name */
  HasAcceptedTermsAndConditions = 'has_accepted_terms_and_conditions',
  /** column name */
  HasActivePrescriptionsAvailableForTransfer = 'has_active_prescriptions_available_for_transfer',
  /** column name */
  HasAllergies = 'has_allergies',
  /** column name */
  HasConsentedToAutomaticPayments = 'has_consented_to_automatic_payments',
  /** column name */
  HasConsentedToCommunicateWithDsq = 'has_consented_to_communicate_with_dsq',
  /** column name */
  HasConsentedToPharmacyTransfer = 'has_consented_to_pharmacy_transfer',
  /** column name */
  HasDoctor = 'has_doctor',
  /** column name */
  HasHealthConditions = 'has_health_conditions',
  /** column name */
  HasInsurance = 'has_insurance',
  /** column name */
  HasNoPrescriptionYet = 'has_no_prescription_yet',
  /** column name */
  HasParentalConsent = 'has_parental_consent',
  /** column name */
  HasPrivateInsurance = 'has_private_insurance',
  /** column name */
  HasPublicInsurance = 'has_public_insurance',
  /** column name */
  HasRushRequestOnSignup = 'has_rush_request_on_signup',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  PharmacyTransferNotApplicable = 'pharmacy_transfer_not_applicable',
  /** column name */
  SignedUp = 'signed_up',
  /** column name */
  TakesMedication = 'takes_medication'
}

/** input type for updating data in table "patient" */
export type Patient_Set_Input = {
  auth_tokens_invalidated_at?: InputMaybe<Scalars['timestamp']>;
  auto_renew_start_date?: InputMaybe<Scalars['date']>;
  automatic_renewal?: InputMaybe<Scalars['Boolean']>;
  birthday?: InputMaybe<Scalars['date']>;
  calendly_id?: InputMaybe<Scalars['String']>;
  clinic_id?: InputMaybe<Scalars['String']>;
  /** Deprecated, use clinic_id instead */
  clinic_name?: InputMaybe<Scalars['String']>;
  close_person_first_name?: InputMaybe<Scalars['String']>;
  close_person_last_name?: InputMaybe<Scalars['String']>;
  close_person_relationship_type?: InputMaybe<Scalars['String']>;
  communication_preferences?: InputMaybe<Scalars['json']>;
  company?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default_address_id?: InputMaybe<Scalars['Int']>;
  default_delivery_type?: InputMaybe<Config_Delivery_Type_Enum>;
  default_locker_id?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  doctor_name?: InputMaybe<Scalars['String']>;
  dropped?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  first_call_booked?: InputMaybe<Scalars['Boolean']>;
  first_date_medication_needed?: InputMaybe<Scalars['date']>;
  first_name?: InputMaybe<Scalars['String']>;
  has_accepted_marketing_communications?: InputMaybe<Scalars['Boolean']>;
  has_accepted_terms_and_conditions?: InputMaybe<Scalars['Boolean']>;
  has_active_prescriptions_available_for_transfer?: InputMaybe<Scalars['Boolean']>;
  has_allergies?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_automatic_payments?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_communicate_with_dsq?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_pharmacy_transfer?: InputMaybe<Scalars['Boolean']>;
  has_doctor?: InputMaybe<Scalars['Boolean']>;
  has_health_conditions?: InputMaybe<Scalars['Boolean']>;
  has_insurance?: InputMaybe<Scalars['Boolean']>;
  has_no_prescription_yet?: InputMaybe<Scalars['Boolean']>;
  has_parental_consent?: InputMaybe<Scalars['Boolean']>;
  has_private_insurance?: InputMaybe<Scalars['Boolean']>;
  has_public_insurance?: InputMaybe<Scalars['Boolean']>;
  /** DEPRECATED */
  has_rush_request_on_signup?: InputMaybe<Scalars['Boolean']>;
  health_insurance_number?: InputMaybe<Scalars['String']>;
  heard_about?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  intro_call_note?: InputMaybe<Scalars['String']>;
  introduction_call_completed_at?: InputMaybe<Scalars['timestamp']>;
  introduction_call_completed_by_uid?: InputMaybe<Scalars['String']>;
  introduction_call_updated_at?: InputMaybe<Scalars['timestamp']>;
  introduction_call_updated_by_uid?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  marketing_data?: InputMaybe<Scalars['jsonb']>;
  natal_sex?: InputMaybe<Config_Sex_Enum>;
  no_upcoming_order_reason?: InputMaybe<Config_No_Upcoming_Order_Reasons_Enum>;
  onboarded?: InputMaybe<Scalars['Boolean']>;
  paysafe_profile_id?: InputMaybe<Scalars['String']>;
  pharmacy_transfer_not_applicable?: InputMaybe<Scalars['Boolean']>;
  phone_number?: InputMaybe<Scalars['String']>;
  phone_type?: InputMaybe<Scalars['String']>;
  pms_external_id?: InputMaybe<Scalars['String']>;
  preferred_language?: InputMaybe<Scalars['String']>;
  private_insurance_company_name?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  referral_sources?: InputMaybe<Scalars['jsonb']>;
  renewal_schedule?: InputMaybe<Scalars['String']>;
  /** DEPRECATED (2022/06) */
  signed_up?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Config_Patient_Status_Enum>;
  /** DEPRECATED (2022/06) */
  status_mode?: InputMaybe<Scalars['patient_status_mode_enum']>;
  takes_medication?: InputMaybe<Scalars['Boolean']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  zendesk_id?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "patient_status_mode_enum". All fields are combined with logical 'AND'. */
export type Patient_Status_Mode_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['patient_status_mode_enum']>;
  _gt?: InputMaybe<Scalars['patient_status_mode_enum']>;
  _gte?: InputMaybe<Scalars['patient_status_mode_enum']>;
  _in?: InputMaybe<Array<Scalars['patient_status_mode_enum']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['patient_status_mode_enum']>;
  _lte?: InputMaybe<Scalars['patient_status_mode_enum']>;
  _neq?: InputMaybe<Scalars['patient_status_mode_enum']>;
  _nin?: InputMaybe<Array<Scalars['patient_status_mode_enum']>>;
};

/** order by stddev() on columns of table "patient" */
export type Patient_Stddev_Order_By = {
  default_address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "patient" */
export type Patient_Stddev_Pop_Order_By = {
  default_address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "patient" */
export type Patient_Stddev_Samp_Order_By = {
  default_address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "patient" */
export type Patient_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patient_Stream_Cursor_Value_Input = {
  auth_tokens_invalidated_at?: InputMaybe<Scalars['timestamp']>;
  auto_renew_start_date?: InputMaybe<Scalars['date']>;
  automatic_renewal?: InputMaybe<Scalars['Boolean']>;
  birthday?: InputMaybe<Scalars['date']>;
  calendly_id?: InputMaybe<Scalars['String']>;
  clinic_id?: InputMaybe<Scalars['String']>;
  /** Deprecated, use clinic_id instead */
  clinic_name?: InputMaybe<Scalars['String']>;
  close_person_first_name?: InputMaybe<Scalars['String']>;
  close_person_last_name?: InputMaybe<Scalars['String']>;
  close_person_relationship_type?: InputMaybe<Scalars['String']>;
  communication_preferences?: InputMaybe<Scalars['json']>;
  company?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default_address_id?: InputMaybe<Scalars['Int']>;
  default_delivery_type?: InputMaybe<Config_Delivery_Type_Enum>;
  default_locker_id?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  doctor_name?: InputMaybe<Scalars['String']>;
  dropped?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  first_call_booked?: InputMaybe<Scalars['Boolean']>;
  first_date_medication_needed?: InputMaybe<Scalars['date']>;
  first_name?: InputMaybe<Scalars['String']>;
  has_accepted_marketing_communications?: InputMaybe<Scalars['Boolean']>;
  has_accepted_terms_and_conditions?: InputMaybe<Scalars['Boolean']>;
  has_active_prescriptions_available_for_transfer?: InputMaybe<Scalars['Boolean']>;
  has_allergies?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_automatic_payments?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_communicate_with_dsq?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_pharmacy_transfer?: InputMaybe<Scalars['Boolean']>;
  has_doctor?: InputMaybe<Scalars['Boolean']>;
  has_health_conditions?: InputMaybe<Scalars['Boolean']>;
  has_insurance?: InputMaybe<Scalars['Boolean']>;
  has_no_prescription_yet?: InputMaybe<Scalars['Boolean']>;
  has_parental_consent?: InputMaybe<Scalars['Boolean']>;
  has_private_insurance?: InputMaybe<Scalars['Boolean']>;
  has_public_insurance?: InputMaybe<Scalars['Boolean']>;
  /** DEPRECATED */
  has_rush_request_on_signup?: InputMaybe<Scalars['Boolean']>;
  health_insurance_number?: InputMaybe<Scalars['String']>;
  heard_about?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  intro_call_note?: InputMaybe<Scalars['String']>;
  introduction_call_completed_at?: InputMaybe<Scalars['timestamp']>;
  introduction_call_completed_by_uid?: InputMaybe<Scalars['String']>;
  introduction_call_updated_at?: InputMaybe<Scalars['timestamp']>;
  introduction_call_updated_by_uid?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  marketing_data?: InputMaybe<Scalars['jsonb']>;
  natal_sex?: InputMaybe<Config_Sex_Enum>;
  no_upcoming_order_reason?: InputMaybe<Config_No_Upcoming_Order_Reasons_Enum>;
  onboarded?: InputMaybe<Scalars['Boolean']>;
  paysafe_profile_id?: InputMaybe<Scalars['String']>;
  pharmacy_transfer_not_applicable?: InputMaybe<Scalars['Boolean']>;
  phone_number?: InputMaybe<Scalars['String']>;
  phone_type?: InputMaybe<Scalars['String']>;
  pms_external_id?: InputMaybe<Scalars['String']>;
  preferred_language?: InputMaybe<Scalars['String']>;
  private_insurance_company_name?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  referral_sources?: InputMaybe<Scalars['jsonb']>;
  renewal_schedule?: InputMaybe<Scalars['String']>;
  /** DEPRECATED (2022/06) */
  signed_up?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Config_Patient_Status_Enum>;
  /** DEPRECATED (2022/06) */
  status_mode?: InputMaybe<Scalars['patient_status_mode_enum']>;
  takes_medication?: InputMaybe<Scalars['Boolean']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  zendesk_id?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "patient" */
export type Patient_Sum_Order_By = {
  default_address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "patient" */
export enum Patient_Update_Column {
  /** column name */
  AuthTokensInvalidatedAt = 'auth_tokens_invalidated_at',
  /** column name */
  AutoRenewStartDate = 'auto_renew_start_date',
  /** column name */
  AutomaticRenewal = 'automatic_renewal',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CalendlyId = 'calendly_id',
  /** column name */
  ClinicId = 'clinic_id',
  /** column name */
  ClinicName = 'clinic_name',
  /** column name */
  ClosePersonFirstName = 'close_person_first_name',
  /** column name */
  ClosePersonLastName = 'close_person_last_name',
  /** column name */
  ClosePersonRelationshipType = 'close_person_relationship_type',
  /** column name */
  CommunicationPreferences = 'communication_preferences',
  /** column name */
  Company = 'company',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultAddressId = 'default_address_id',
  /** column name */
  DefaultDeliveryType = 'default_delivery_type',
  /** column name */
  DefaultLockerId = 'default_locker_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DoctorName = 'doctor_name',
  /** column name */
  Dropped = 'dropped',
  /** column name */
  Email = 'email',
  /** column name */
  FirstCallBooked = 'first_call_booked',
  /** column name */
  FirstDateMedicationNeeded = 'first_date_medication_needed',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HasAcceptedMarketingCommunications = 'has_accepted_marketing_communications',
  /** column name */
  HasAcceptedTermsAndConditions = 'has_accepted_terms_and_conditions',
  /** column name */
  HasActivePrescriptionsAvailableForTransfer = 'has_active_prescriptions_available_for_transfer',
  /** column name */
  HasAllergies = 'has_allergies',
  /** column name */
  HasConsentedToAutomaticPayments = 'has_consented_to_automatic_payments',
  /** column name */
  HasConsentedToCommunicateWithDsq = 'has_consented_to_communicate_with_dsq',
  /** column name */
  HasConsentedToPharmacyTransfer = 'has_consented_to_pharmacy_transfer',
  /** column name */
  HasDoctor = 'has_doctor',
  /** column name */
  HasHealthConditions = 'has_health_conditions',
  /** column name */
  HasInsurance = 'has_insurance',
  /** column name */
  HasNoPrescriptionYet = 'has_no_prescription_yet',
  /** column name */
  HasParentalConsent = 'has_parental_consent',
  /** column name */
  HasPrivateInsurance = 'has_private_insurance',
  /** column name */
  HasPublicInsurance = 'has_public_insurance',
  /** column name */
  HasRushRequestOnSignup = 'has_rush_request_on_signup',
  /** column name */
  HealthInsuranceNumber = 'health_insurance_number',
  /** column name */
  HeardAbout = 'heard_about',
  /** column name */
  Id = 'id',
  /** column name */
  IntroCallNote = 'intro_call_note',
  /** column name */
  IntroductionCallCompletedAt = 'introduction_call_completed_at',
  /** column name */
  IntroductionCallCompletedByUid = 'introduction_call_completed_by_uid',
  /** column name */
  IntroductionCallUpdatedAt = 'introduction_call_updated_at',
  /** column name */
  IntroductionCallUpdatedByUid = 'introduction_call_updated_by_uid',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MarketingData = 'marketing_data',
  /** column name */
  NatalSex = 'natal_sex',
  /** column name */
  NoUpcomingOrderReason = 'no_upcoming_order_reason',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  PaysafeProfileId = 'paysafe_profile_id',
  /** column name */
  PharmacyTransferNotApplicable = 'pharmacy_transfer_not_applicable',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PhoneType = 'phone_type',
  /** column name */
  PmsExternalId = 'pms_external_id',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  PrivateInsuranceCompanyName = 'private_insurance_company_name',
  /** column name */
  Pronouns = 'pronouns',
  /** column name */
  ReferralSources = 'referral_sources',
  /** column name */
  RenewalSchedule = 'renewal_schedule',
  /** column name */
  SignedUp = 'signed_up',
  /** column name */
  Status = 'status',
  /** column name */
  StatusMode = 'status_mode',
  /** column name */
  TakesMedication = 'takes_medication',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZendeskId = 'zendesk_id'
}

export type Patient_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Patient_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Patient_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Patient_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Patient_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patient_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Patient_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patient_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patient_Bool_Exp;
};

/** order by var_pop() on columns of table "patient" */
export type Patient_Var_Pop_Order_By = {
  default_address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "patient" */
export type Patient_Var_Samp_Order_By = {
  default_address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "patient" */
export type Patient_Variance_Order_By = {
  default_address_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export enum Paysafe_Card_Category {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Prepaid = 'PREPAID',
  Unknown = 'UNKNOWN'
}

export type Paysafe_Card_Expiry_Input = {
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type Paysafe_Card_Insert_Input = {
  cardToken: Scalars['String'];
  defaultCardIndicator: Scalars['Boolean'];
  patientUid: Scalars['String'];
  paysafeProfileId?: InputMaybe<Scalars['String']>;
  preferredLanguage: Scalars['String'];
};

export type Paysafe_Card_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

export type Paysafe_Card_Set_Input = {
  billingAddressId?: InputMaybe<Scalars['String']>;
  cardExpiry?: InputMaybe<Paysafe_Card_Expiry_Input>;
  /** This indicates whether this is the default card for the profile. */
  defaultCardIndicator?: InputMaybe<Scalars['Boolean']>;
  holderName?: InputMaybe<Scalars['String']>;
  /** This is an alias defined by the customer for this card. */
  nickName?: InputMaybe<Scalars['String']>;
};

export enum Paysafe_Card_Status {
  Active = 'ACTIVE'
}

export enum Paysafe_Card_Type {
  Am = 'AM',
  Dc = 'DC',
  Di = 'DI',
  Mc = 'MC',
  Vi = 'VI'
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pharmacy_Append_Input = {
  google_place_data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "pharmacy". All fields are combined with a logical 'AND'. */
export type Pharmacy_Bool_Exp = {
  _and?: InputMaybe<Array<Pharmacy_Bool_Exp>>;
  _not?: InputMaybe<Pharmacy_Bool_Exp>;
  _or?: InputMaybe<Array<Pharmacy_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  google_place_data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  transfer_requests?: InputMaybe<Transfer_Request_Bool_Exp>;
  transfer_requests_aggregate?: InputMaybe<Transfer_Request_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "pharmacy" */
export enum Pharmacy_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkF6712b909f461cf4fe9926e0c5d = 'PK_f6712b909f461cf4fe9926e0c5d'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pharmacy_Delete_At_Path_Input = {
  google_place_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pharmacy_Delete_Elem_Input = {
  google_place_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pharmacy_Delete_Key_Input = {
  google_place_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "pharmacy" */
export type Pharmacy_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  google_place_data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  transfer_requests?: InputMaybe<Transfer_Request_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** input type for inserting object relation for remote table "pharmacy" */
export type Pharmacy_Obj_Rel_Insert_Input = {
  data: Pharmacy_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Pharmacy_On_Conflict>;
};

/** on_conflict condition type for table "pharmacy" */
export type Pharmacy_On_Conflict = {
  constraint: Pharmacy_Constraint;
  update_columns?: Array<Pharmacy_Update_Column>;
  where?: InputMaybe<Pharmacy_Bool_Exp>;
};

/** Ordering options when selecting data from "pharmacy". */
export type Pharmacy_Order_By = {
  address?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  google_place_data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  transfer_requests_aggregate?: InputMaybe<Transfer_Request_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pharmacy */
export type Pharmacy_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pharmacy_Prepend_Input = {
  google_place_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "pharmacy" */
export enum Pharmacy_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GooglePlaceData = 'google_place_data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "pharmacy" */
export type Pharmacy_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  google_place_data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "pharmacy" */
export type Pharmacy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pharmacy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pharmacy_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  google_place_data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "pharmacy" */
export enum Pharmacy_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  GooglePlaceData = 'google_place_data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Pharmacy_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Pharmacy_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Pharmacy_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Pharmacy_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Pharmacy_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Pharmacy_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pharmacy_Set_Input>;
  /** filter the rows which have to be updated */
  where: Pharmacy_Bool_Exp;
};

/** Boolean expression to compare columns of type "point". All fields are combined with logical 'AND'. */
export type Point_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['point']>;
  _gt?: InputMaybe<Scalars['point']>;
  _gte?: InputMaybe<Scalars['point']>;
  _in?: InputMaybe<Array<Scalars['point']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['point']>;
  _lte?: InputMaybe<Scalars['point']>;
  _neq?: InputMaybe<Scalars['point']>;
  _nin?: InputMaybe<Array<Scalars['point']>>;
};

/** Boolean expression to filter rows from the table "prescriber". All fields are combined with a logical 'AND'. */
export type Prescriber_Bool_Exp = {
  _and?: InputMaybe<Array<Prescriber_Bool_Exp>>;
  _not?: InputMaybe<Prescriber_Bool_Exp>;
  _or?: InputMaybe<Array<Prescriber_Bool_Exp>>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "prescriber" */
export enum Prescriber_Constraint {
  /** unique or primary key constraint on columns "external_id" */
  PrescriberExternalIdKey = 'prescriber_external_id_key',
  /** unique or primary key constraint on columns "id" */
  PrescriberPkey = 'prescriber_pkey'
}

/** input type for inserting data into table "prescriber" */
export type Prescriber_Insert_Input = {
  external_id?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "prescriber" */
export type Prescriber_Obj_Rel_Insert_Input = {
  data: Prescriber_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Prescriber_On_Conflict>;
};

/** on_conflict condition type for table "prescriber" */
export type Prescriber_On_Conflict = {
  constraint: Prescriber_Constraint;
  update_columns?: Array<Prescriber_Update_Column>;
  where?: InputMaybe<Prescriber_Bool_Exp>;
};

/** Ordering options when selecting data from "prescriber". */
export type Prescriber_Order_By = {
  external_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: prescriber */
export type Prescriber_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "prescriber" */
export enum Prescriber_Select_Column {
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name'
}

/** input type for updating data in table "prescriber" */
export type Prescriber_Set_Input = {
  external_id?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "prescriber" */
export type Prescriber_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prescriber_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prescriber_Stream_Cursor_Value_Input = {
  external_id?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "prescriber" */
export enum Prescriber_Update_Column {
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name'
}

export type Prescriber_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prescriber_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prescriber_Bool_Exp;
};

export type Prescription_Aggregate_Bool_Exp = {
  count?: InputMaybe<Prescription_Aggregate_Bool_Exp_Count>;
};

export type Prescription_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Prescription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Prescription_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "prescription" */
export type Prescription_Aggregate_Order_By = {
  avg?: InputMaybe<Prescription_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Prescription_Max_Order_By>;
  min?: InputMaybe<Prescription_Min_Order_By>;
  stddev?: InputMaybe<Prescription_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Prescription_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Prescription_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Prescription_Sum_Order_By>;
  var_pop?: InputMaybe<Prescription_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Prescription_Var_Samp_Order_By>;
  variance?: InputMaybe<Prescription_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "prescription" */
export type Prescription_Arr_Rel_Insert_Input = {
  data: Array<Prescription_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Prescription_On_Conflict>;
};

/** order by avg() on columns of table "prescription" */
export type Prescription_Avg_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  prescribed_quantity?: InputMaybe<Order_By>;
  renewals?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "prescription". All fields are combined with a logical 'AND'. */
export type Prescription_Bool_Exp = {
  _and?: InputMaybe<Array<Prescription_Bool_Exp>>;
  _not?: InputMaybe<Prescription_Bool_Exp>;
  _or?: InputMaybe<Array<Prescription_Bool_Exp>>;
  ceased_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  dosage?: InputMaybe<String_Comparison_Exp>;
  expiration_date?: InputMaybe<Date_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  incoming_changes?: InputMaybe<Bridge_Incoming_Change_Bool_Exp>;
  incoming_changes_aggregate?: InputMaybe<Bridge_Incoming_Change_Aggregate_Bool_Exp>;
  medication?: InputMaybe<Medication_Bool_Exp>;
  medication_id?: InputMaybe<Int_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  prescribed_date?: InputMaybe<Date_Comparison_Exp>;
  prescribed_quantity?: InputMaybe<Float_Comparison_Exp>;
  prescriber?: InputMaybe<String_Comparison_Exp>;
  prescriber_details?: InputMaybe<Prescriber_Bool_Exp>;
  prescriber_id?: InputMaybe<Uuid_Comparison_Exp>;
  renewals?: InputMaybe<Int_Comparison_Exp>;
  services?: InputMaybe<Service_Bool_Exp>;
  services_aggregate?: InputMaybe<Service_Aggregate_Bool_Exp>;
  transferred_at?: InputMaybe<Timestamp_Comparison_Exp>;
  treatment_type?: InputMaybe<Config_Treatment_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  verified_at?: InputMaybe<Timestamp_Comparison_Exp>;
  verified_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "prescription" */
export enum Prescription_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkEaba5e4414e5382781e08467b51 = 'PK_eaba5e4414e5382781e08467b51',
  /** unique or primary key constraint on columns "external_id" */
  Uq_44db6f646adf15b31112f30a766 = 'UQ_44db6f646adf15b31112f30a766'
}

/** input type for incrementing numeric columns in table "prescription" */
export type Prescription_Inc_Input = {
  medication_id?: InputMaybe<Scalars['Int']>;
  prescribed_quantity?: InputMaybe<Scalars['Float']>;
  renewals?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "prescription" */
export type Prescription_Insert_Input = {
  ceased_at?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  dosage?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  incoming_changes?: InputMaybe<Bridge_Incoming_Change_Arr_Rel_Insert_Input>;
  medication?: InputMaybe<Medication_Obj_Rel_Insert_Input>;
  medication_id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  prescribed_date?: InputMaybe<Scalars['date']>;
  prescribed_quantity?: InputMaybe<Scalars['Float']>;
  prescriber?: InputMaybe<Scalars['String']>;
  prescriber_details?: InputMaybe<Prescriber_Obj_Rel_Insert_Input>;
  prescriber_id?: InputMaybe<Scalars['uuid']>;
  renewals?: InputMaybe<Scalars['Int']>;
  services?: InputMaybe<Service_Arr_Rel_Insert_Input>;
  transferred_at?: InputMaybe<Scalars['timestamp']>;
  treatment_type?: InputMaybe<Config_Treatment_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  verified_at?: InputMaybe<Scalars['timestamp']>;
  verified_by?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "prescription" */
export type Prescription_Max_Order_By = {
  ceased_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  dosage?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  prescribed_date?: InputMaybe<Order_By>;
  prescribed_quantity?: InputMaybe<Order_By>;
  prescriber?: InputMaybe<Order_By>;
  prescriber_id?: InputMaybe<Order_By>;
  renewals?: InputMaybe<Order_By>;
  transferred_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  verified_at?: InputMaybe<Order_By>;
  verified_by?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "prescription" */
export type Prescription_Min_Order_By = {
  ceased_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  dosage?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  prescribed_date?: InputMaybe<Order_By>;
  prescribed_quantity?: InputMaybe<Order_By>;
  prescriber?: InputMaybe<Order_By>;
  prescriber_id?: InputMaybe<Order_By>;
  renewals?: InputMaybe<Order_By>;
  transferred_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  verified_at?: InputMaybe<Order_By>;
  verified_by?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "prescription" */
export type Prescription_Obj_Rel_Insert_Input = {
  data: Prescription_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Prescription_On_Conflict>;
};

/** on_conflict condition type for table "prescription" */
export type Prescription_On_Conflict = {
  constraint: Prescription_Constraint;
  update_columns?: Array<Prescription_Update_Column>;
  where?: InputMaybe<Prescription_Bool_Exp>;
};

/** Ordering options when selecting data from "prescription". */
export type Prescription_Order_By = {
  ceased_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  dosage?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incoming_changes_aggregate?: InputMaybe<Bridge_Incoming_Change_Aggregate_Order_By>;
  medication?: InputMaybe<Medication_Order_By>;
  medication_id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  prescribed_date?: InputMaybe<Order_By>;
  prescribed_quantity?: InputMaybe<Order_By>;
  prescriber?: InputMaybe<Order_By>;
  prescriber_details?: InputMaybe<Prescriber_Order_By>;
  prescriber_id?: InputMaybe<Order_By>;
  renewals?: InputMaybe<Order_By>;
  services_aggregate?: InputMaybe<Service_Aggregate_Order_By>;
  transferred_at?: InputMaybe<Order_By>;
  treatment_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  verified_at?: InputMaybe<Order_By>;
  verified_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: prescription */
export type Prescription_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "prescription_renewal_request". All fields are combined with a logical 'AND'. */
export type Prescription_Renewal_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Prescription_Renewal_Request_Bool_Exp>>;
  _not?: InputMaybe<Prescription_Renewal_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Prescription_Renewal_Request_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  faxes?: InputMaybe<Fax_Bool_Exp>;
  faxes_aggregate?: InputMaybe<Fax_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  not_completed?: InputMaybe<Boolean_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  prescription_renewal_request_items?: InputMaybe<Prescription_Renewal_Request_Item_Bool_Exp>;
  prescription_renewal_request_items_aggregate?: InputMaybe<Prescription_Renewal_Request_Item_Aggregate_Bool_Exp>;
  seq_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "prescription_renewal_request" */
export enum Prescription_Renewal_Request_Constraint {
  /** unique or primary key constraint on columns "id" */
  PrescriptionRenewalRequestPkey = 'prescription_renewal_request_pkey',
  /** unique or primary key constraint on columns "seq_id" */
  PrescriptionRenewalRequestSeqIdKey = 'prescription_renewal_request_seq_id_key'
}

/** input type for incrementing numeric columns in table "prescription_renewal_request" */
export type Prescription_Renewal_Request_Inc_Input = {
  seq_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "prescription_renewal_request" */
export type Prescription_Renewal_Request_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  faxes?: InputMaybe<Fax_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  prescription_renewal_request_items?: InputMaybe<Prescription_Renewal_Request_Item_Arr_Rel_Insert_Input>;
  seq_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

export type Prescription_Renewal_Request_Item_Aggregate_Bool_Exp = {
  count?: InputMaybe<Prescription_Renewal_Request_Item_Aggregate_Bool_Exp_Count>;
};

export type Prescription_Renewal_Request_Item_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Prescription_Renewal_Request_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Prescription_Renewal_Request_Item_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Prescription_Renewal_Request_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Prescription_Renewal_Request_Item_Max_Order_By>;
  min?: InputMaybe<Prescription_Renewal_Request_Item_Min_Order_By>;
  stddev?: InputMaybe<Prescription_Renewal_Request_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Prescription_Renewal_Request_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Prescription_Renewal_Request_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Prescription_Renewal_Request_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Prescription_Renewal_Request_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Prescription_Renewal_Request_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Prescription_Renewal_Request_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Arr_Rel_Insert_Input = {
  data: Array<Prescription_Renewal_Request_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Prescription_Renewal_Request_Item_On_Conflict>;
};

/** order by avg() on columns of table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Avg_Order_By = {
  medication_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "prescription_renewal_request_item". All fields are combined with a logical 'AND'. */
export type Prescription_Renewal_Request_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Prescription_Renewal_Request_Item_Bool_Exp>>;
  _not?: InputMaybe<Prescription_Renewal_Request_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Prescription_Renewal_Request_Item_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  medication?: InputMaybe<Medication_Bool_Exp>;
  medication_id?: InputMaybe<Int_Comparison_Exp>;
  prescription_renewal_request?: InputMaybe<Prescription_Renewal_Request_Bool_Exp>;
  prescription_renewal_request_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Config_Prescription_Renewal_Request_Item_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "prescription_renewal_request_item" */
export enum Prescription_Renewal_Request_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  PrescriptionRenewalRequestItemPkey = 'prescription_renewal_request_item_pkey'
}

/** input type for incrementing numeric columns in table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Inc_Input = {
  medication_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  medication?: InputMaybe<Medication_Obj_Rel_Insert_Input>;
  medication_id?: InputMaybe<Scalars['Int']>;
  prescription_renewal_request?: InputMaybe<Prescription_Renewal_Request_Obj_Rel_Insert_Input>;
  prescription_renewal_request_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Config_Prescription_Renewal_Request_Item_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
  prescription_renewal_request_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
  prescription_renewal_request_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_On_Conflict = {
  constraint: Prescription_Renewal_Request_Item_Constraint;
  update_columns?: Array<Prescription_Renewal_Request_Item_Update_Column>;
  where?: InputMaybe<Prescription_Renewal_Request_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "prescription_renewal_request_item". */
export type Prescription_Renewal_Request_Item_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication?: InputMaybe<Medication_Order_By>;
  medication_id?: InputMaybe<Order_By>;
  prescription_renewal_request?: InputMaybe<Prescription_Renewal_Request_Order_By>;
  prescription_renewal_request_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: prescription_renewal_request_item */
export type Prescription_Renewal_Request_Item_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "prescription_renewal_request_item" */
export enum Prescription_Renewal_Request_Item_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  PrescriptionRenewalRequestId = 'prescription_renewal_request_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  prescription_renewal_request_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Config_Prescription_Renewal_Request_Item_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Stddev_Order_By = {
  medication_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Stddev_Pop_Order_By = {
  medication_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Stddev_Samp_Order_By = {
  medication_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prescription_Renewal_Request_Item_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prescription_Renewal_Request_Item_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  prescription_renewal_request_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Config_Prescription_Renewal_Request_Item_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Sum_Order_By = {
  medication_id?: InputMaybe<Order_By>;
};

/** update columns of table "prescription_renewal_request_item" */
export enum Prescription_Renewal_Request_Item_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  PrescriptionRenewalRequestId = 'prescription_renewal_request_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Prescription_Renewal_Request_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Prescription_Renewal_Request_Item_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prescription_Renewal_Request_Item_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prescription_Renewal_Request_Item_Bool_Exp;
};

/** order by var_pop() on columns of table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Var_Pop_Order_By = {
  medication_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Var_Samp_Order_By = {
  medication_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "prescription_renewal_request_item" */
export type Prescription_Renewal_Request_Item_Variance_Order_By = {
  medication_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "prescription_renewal_request_note". All fields are combined with a logical 'AND'. */
export type Prescription_Renewal_Request_Note_Bool_Exp = {
  _and?: InputMaybe<Array<Prescription_Renewal_Request_Note_Bool_Exp>>;
  _not?: InputMaybe<Prescription_Renewal_Request_Note_Bool_Exp>;
  _or?: InputMaybe<Array<Prescription_Renewal_Request_Note_Bool_Exp>>;
  note?: InputMaybe<Note_Bool_Exp>;
  note_id?: InputMaybe<Uuid_Comparison_Exp>;
  prescription_renewal_request?: InputMaybe<Prescription_Renewal_Request_Bool_Exp>;
  prescription_renewal_request_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "prescription_renewal_request_note" */
export enum Prescription_Renewal_Request_Note_Constraint {
  /** unique or primary key constraint on columns "note_id" */
  PrescriptionRenewalRequestNoteNoteIdKey = 'prescription_renewal_request_note_note_id_key',
  /** unique or primary key constraint on columns "note_id", "prescription_renewal_request_id" */
  PrescriptionRenewalRequestNotePkey = 'prescription_renewal_request_note_pkey'
}

/** input type for inserting data into table "prescription_renewal_request_note" */
export type Prescription_Renewal_Request_Note_Insert_Input = {
  note?: InputMaybe<Note_Obj_Rel_Insert_Input>;
  note_id?: InputMaybe<Scalars['uuid']>;
  prescription_renewal_request?: InputMaybe<Prescription_Renewal_Request_Obj_Rel_Insert_Input>;
  prescription_renewal_request_id?: InputMaybe<Scalars['uuid']>;
};

/** on_conflict condition type for table "prescription_renewal_request_note" */
export type Prescription_Renewal_Request_Note_On_Conflict = {
  constraint: Prescription_Renewal_Request_Note_Constraint;
  update_columns?: Array<Prescription_Renewal_Request_Note_Update_Column>;
  where?: InputMaybe<Prescription_Renewal_Request_Note_Bool_Exp>;
};

/** Ordering options when selecting data from "prescription_renewal_request_note". */
export type Prescription_Renewal_Request_Note_Order_By = {
  note?: InputMaybe<Note_Order_By>;
  note_id?: InputMaybe<Order_By>;
  prescription_renewal_request?: InputMaybe<Prescription_Renewal_Request_Order_By>;
  prescription_renewal_request_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: prescription_renewal_request_note */
export type Prescription_Renewal_Request_Note_Pk_Columns_Input = {
  note_id: Scalars['uuid'];
  prescription_renewal_request_id: Scalars['uuid'];
};

/** select columns of table "prescription_renewal_request_note" */
export enum Prescription_Renewal_Request_Note_Select_Column {
  /** column name */
  NoteId = 'note_id',
  /** column name */
  PrescriptionRenewalRequestId = 'prescription_renewal_request_id'
}

/** input type for updating data in table "prescription_renewal_request_note" */
export type Prescription_Renewal_Request_Note_Set_Input = {
  note_id?: InputMaybe<Scalars['uuid']>;
  prescription_renewal_request_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "prescription_renewal_request_note" */
export type Prescription_Renewal_Request_Note_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prescription_Renewal_Request_Note_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prescription_Renewal_Request_Note_Stream_Cursor_Value_Input = {
  note_id?: InputMaybe<Scalars['uuid']>;
  prescription_renewal_request_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "prescription_renewal_request_note" */
export enum Prescription_Renewal_Request_Note_Update_Column {
  /** column name */
  NoteId = 'note_id',
  /** column name */
  PrescriptionRenewalRequestId = 'prescription_renewal_request_id'
}

export type Prescription_Renewal_Request_Note_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prescription_Renewal_Request_Note_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prescription_Renewal_Request_Note_Bool_Exp;
};

/** input type for inserting object relation for remote table "prescription_renewal_request" */
export type Prescription_Renewal_Request_Obj_Rel_Insert_Input = {
  data: Prescription_Renewal_Request_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Prescription_Renewal_Request_On_Conflict>;
};

/** on_conflict condition type for table "prescription_renewal_request" */
export type Prescription_Renewal_Request_On_Conflict = {
  constraint: Prescription_Renewal_Request_Constraint;
  update_columns?: Array<Prescription_Renewal_Request_Update_Column>;
  where?: InputMaybe<Prescription_Renewal_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "prescription_renewal_request". */
export type Prescription_Renewal_Request_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  faxes_aggregate?: InputMaybe<Fax_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  not_completed?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  prescription_renewal_request_items_aggregate?: InputMaybe<Prescription_Renewal_Request_Item_Aggregate_Order_By>;
  seq_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: prescription_renewal_request */
export type Prescription_Renewal_Request_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "prescription_renewal_request" */
export enum Prescription_Renewal_Request_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  SeqId = 'seq_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "prescription_renewal_request" */
export type Prescription_Renewal_Request_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  seq_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "prescription_renewal_request" */
export type Prescription_Renewal_Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prescription_Renewal_Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prescription_Renewal_Request_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  seq_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "prescription_renewal_request" */
export enum Prescription_Renewal_Request_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  SeqId = 'seq_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Prescription_Renewal_Request_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Prescription_Renewal_Request_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prescription_Renewal_Request_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prescription_Renewal_Request_Bool_Exp;
};

/** select columns of table "prescription" */
export enum Prescription_Select_Column {
  /** column name */
  CeasedAt = 'ceased_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Dosage = 'dosage',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PrescribedDate = 'prescribed_date',
  /** column name */
  PrescribedQuantity = 'prescribed_quantity',
  /** column name */
  Prescriber = 'prescriber',
  /** column name */
  PrescriberId = 'prescriber_id',
  /** column name */
  Renewals = 'renewals',
  /** column name */
  TransferredAt = 'transferred_at',
  /** column name */
  TreatmentType = 'treatment_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerifiedAt = 'verified_at',
  /** column name */
  VerifiedBy = 'verified_by'
}

/** input type for updating data in table "prescription" */
export type Prescription_Set_Input = {
  ceased_at?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  dosage?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  prescribed_date?: InputMaybe<Scalars['date']>;
  prescribed_quantity?: InputMaybe<Scalars['Float']>;
  prescriber?: InputMaybe<Scalars['String']>;
  prescriber_id?: InputMaybe<Scalars['uuid']>;
  renewals?: InputMaybe<Scalars['Int']>;
  transferred_at?: InputMaybe<Scalars['timestamp']>;
  treatment_type?: InputMaybe<Config_Treatment_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  verified_at?: InputMaybe<Scalars['timestamp']>;
  verified_by?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "prescription" */
export type Prescription_Stddev_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  prescribed_quantity?: InputMaybe<Order_By>;
  renewals?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "prescription" */
export type Prescription_Stddev_Pop_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  prescribed_quantity?: InputMaybe<Order_By>;
  renewals?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "prescription" */
export type Prescription_Stddev_Samp_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  prescribed_quantity?: InputMaybe<Order_By>;
  renewals?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "prescription" */
export type Prescription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prescription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prescription_Stream_Cursor_Value_Input = {
  ceased_at?: InputMaybe<Scalars['timestamp']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  dosage?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  prescribed_date?: InputMaybe<Scalars['date']>;
  prescribed_quantity?: InputMaybe<Scalars['Float']>;
  prescriber?: InputMaybe<Scalars['String']>;
  prescriber_id?: InputMaybe<Scalars['uuid']>;
  renewals?: InputMaybe<Scalars['Int']>;
  transferred_at?: InputMaybe<Scalars['timestamp']>;
  treatment_type?: InputMaybe<Config_Treatment_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  verified_at?: InputMaybe<Scalars['timestamp']>;
  verified_by?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "prescription" */
export type Prescription_Sum_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  prescribed_quantity?: InputMaybe<Order_By>;
  renewals?: InputMaybe<Order_By>;
};

/** update columns of table "prescription" */
export enum Prescription_Update_Column {
  /** column name */
  CeasedAt = 'ceased_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Dosage = 'dosage',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PrescribedDate = 'prescribed_date',
  /** column name */
  PrescribedQuantity = 'prescribed_quantity',
  /** column name */
  Prescriber = 'prescriber',
  /** column name */
  PrescriberId = 'prescriber_id',
  /** column name */
  Renewals = 'renewals',
  /** column name */
  TransferredAt = 'transferred_at',
  /** column name */
  TreatmentType = 'treatment_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerifiedAt = 'verified_at',
  /** column name */
  VerifiedBy = 'verified_by'
}

export type Prescription_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Prescription_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prescription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prescription_Bool_Exp;
};

export type Prescription_Upload_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Prescription_Upload_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Prescription_Upload_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Prescription_Upload_Aggregate_Bool_Exp_Count>;
};

export type Prescription_Upload_Aggregate_Bool_Exp_Bool_And = {
  arguments: Prescription_Upload_Select_Column_Prescription_Upload_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Prescription_Upload_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Prescription_Upload_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Prescription_Upload_Select_Column_Prescription_Upload_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Prescription_Upload_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Prescription_Upload_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Prescription_Upload_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Prescription_Upload_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "prescription_upload" */
export type Prescription_Upload_Aggregate_Order_By = {
  avg?: InputMaybe<Prescription_Upload_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Prescription_Upload_Max_Order_By>;
  min?: InputMaybe<Prescription_Upload_Min_Order_By>;
  stddev?: InputMaybe<Prescription_Upload_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Prescription_Upload_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Prescription_Upload_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Prescription_Upload_Sum_Order_By>;
  var_pop?: InputMaybe<Prescription_Upload_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Prescription_Upload_Var_Samp_Order_By>;
  variance?: InputMaybe<Prescription_Upload_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "prescription_upload" */
export type Prescription_Upload_Arr_Rel_Insert_Input = {
  data: Array<Prescription_Upload_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Prescription_Upload_On_Conflict>;
};

/** order by avg() on columns of table "prescription_upload" */
export type Prescription_Upload_Avg_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "prescription_upload". All fields are combined with a logical 'AND'. */
export type Prescription_Upload_Bool_Exp = {
  _and?: InputMaybe<Array<Prescription_Upload_Bool_Exp>>;
  _not?: InputMaybe<Prescription_Upload_Bool_Exp>;
  _or?: InputMaybe<Array<Prescription_Upload_Bool_Exp>>;
  booking_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  contact_status?: InputMaybe<Config_Contact_Status_Enum_Comparison_Exp>;
  contacted?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  done?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  physical_prescription_received?: InputMaybe<Boolean_Comparison_Exp>;
  physical_prescription_return_strategy?: InputMaybe<Config_Physical_Prescription_Return_Strategy_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  upload?: InputMaybe<Upload_Bool_Exp>;
  upload_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "prescription_upload" */
export enum Prescription_Upload_Constraint {
  /** unique or primary key constraint on columns "booking_event_id" */
  PrescriptionUploadBookingEventIdKey = 'prescription_upload_booking_event_id_key',
  /** unique or primary key constraint on columns "id" */
  PrescriptionUploadPkey = 'prescription_upload_pkey'
}

/** input type for incrementing numeric columns in table "prescription_upload" */
export type Prescription_Upload_Inc_Input = {
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "prescription_upload" */
export type Prescription_Upload_Insert_Input = {
  booking_event_id?: InputMaybe<Scalars['uuid']>;
  contact_status?: InputMaybe<Config_Contact_Status_Enum>;
  contacted?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  done?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  physical_prescription_received?: InputMaybe<Scalars['Boolean']>;
  physical_prescription_return_strategy?: InputMaybe<Config_Physical_Prescription_Return_Strategy_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  upload?: InputMaybe<Upload_Obj_Rel_Insert_Input>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "prescription_upload" */
export type Prescription_Upload_Max_Order_By = {
  booking_event_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "prescription_upload" */
export type Prescription_Upload_Min_Order_By = {
  booking_event_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "prescription_upload" */
export type Prescription_Upload_On_Conflict = {
  constraint: Prescription_Upload_Constraint;
  update_columns?: Array<Prescription_Upload_Update_Column>;
  where?: InputMaybe<Prescription_Upload_Bool_Exp>;
};

/** Ordering options when selecting data from "prescription_upload". */
export type Prescription_Upload_Order_By = {
  booking_event_id?: InputMaybe<Order_By>;
  contact_status?: InputMaybe<Order_By>;
  contacted?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  done?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  physical_prescription_received?: InputMaybe<Order_By>;
  physical_prescription_return_strategy?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload?: InputMaybe<Upload_Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: prescription_upload */
export type Prescription_Upload_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "prescription_upload" */
export enum Prescription_Upload_Select_Column {
  /** column name */
  BookingEventId = 'booking_event_id',
  /** column name */
  ContactStatus = 'contact_status',
  /** column name */
  Contacted = 'contacted',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Done = 'done',
  /** column name */
  Id = 'id',
  /** column name */
  PhysicalPrescriptionReceived = 'physical_prescription_received',
  /** column name */
  PhysicalPrescriptionReturnStrategy = 'physical_prescription_return_strategy',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadId = 'upload_id'
}

/** select "prescription_upload_aggregate_bool_exp_bool_and_arguments_columns" columns of table "prescription_upload" */
export enum Prescription_Upload_Select_Column_Prescription_Upload_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Contacted = 'contacted',
  /** column name */
  Done = 'done',
  /** column name */
  PhysicalPrescriptionReceived = 'physical_prescription_received'
}

/** select "prescription_upload_aggregate_bool_exp_bool_or_arguments_columns" columns of table "prescription_upload" */
export enum Prescription_Upload_Select_Column_Prescription_Upload_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Contacted = 'contacted',
  /** column name */
  Done = 'done',
  /** column name */
  PhysicalPrescriptionReceived = 'physical_prescription_received'
}

/** input type for updating data in table "prescription_upload" */
export type Prescription_Upload_Set_Input = {
  booking_event_id?: InputMaybe<Scalars['uuid']>;
  contact_status?: InputMaybe<Config_Contact_Status_Enum>;
  contacted?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  done?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  physical_prescription_received?: InputMaybe<Scalars['Boolean']>;
  physical_prescription_return_strategy?: InputMaybe<Config_Physical_Prescription_Return_Strategy_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "prescription_upload" */
export type Prescription_Upload_Stddev_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "prescription_upload" */
export type Prescription_Upload_Stddev_Pop_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "prescription_upload" */
export type Prescription_Upload_Stddev_Samp_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "prescription_upload" */
export type Prescription_Upload_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prescription_Upload_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prescription_Upload_Stream_Cursor_Value_Input = {
  booking_event_id?: InputMaybe<Scalars['uuid']>;
  contact_status?: InputMaybe<Config_Contact_Status_Enum>;
  contacted?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  done?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  physical_prescription_received?: InputMaybe<Scalars['Boolean']>;
  physical_prescription_return_strategy?: InputMaybe<Config_Physical_Prescription_Return_Strategy_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "prescription_upload" */
export type Prescription_Upload_Sum_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** update columns of table "prescription_upload" */
export enum Prescription_Upload_Update_Column {
  /** column name */
  BookingEventId = 'booking_event_id',
  /** column name */
  ContactStatus = 'contact_status',
  /** column name */
  Contacted = 'contacted',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Done = 'done',
  /** column name */
  Id = 'id',
  /** column name */
  PhysicalPrescriptionReceived = 'physical_prescription_received',
  /** column name */
  PhysicalPrescriptionReturnStrategy = 'physical_prescription_return_strategy',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadId = 'upload_id'
}

export type Prescription_Upload_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Prescription_Upload_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prescription_Upload_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prescription_Upload_Bool_Exp;
};

/** order by var_pop() on columns of table "prescription_upload" */
export type Prescription_Upload_Var_Pop_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "prescription_upload" */
export type Prescription_Upload_Var_Samp_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "prescription_upload" */
export type Prescription_Upload_Variance_Order_By = {
  upload_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "prescription" */
export type Prescription_Var_Pop_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  prescribed_quantity?: InputMaybe<Order_By>;
  renewals?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "prescription" */
export type Prescription_Var_Samp_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  prescribed_quantity?: InputMaybe<Order_By>;
  renewals?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "prescription" */
export type Prescription_Variance_Order_By = {
  medication_id?: InputMaybe<Order_By>;
  prescribed_quantity?: InputMaybe<Order_By>;
  renewals?: InputMaybe<Order_By>;
};

export type Price_For_Din_Args = {
  din?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Price_Quote_Append_Input = {
  quote_items?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "price_quote". All fields are combined with a logical 'AND'. */
export type Price_Quote_Bool_Exp = {
  _and?: InputMaybe<Array<Price_Quote_Bool_Exp>>;
  _not?: InputMaybe<Price_Quote_Bool_Exp>;
  _or?: InputMaybe<Array<Price_Quote_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  has_accepted_terms_and_conditions?: InputMaybe<Boolean_Comparison_Exp>;
  has_private_insurance?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  line_items?: InputMaybe<Price_Quote_Line_Item_Bool_Exp>;
  line_items_aggregate?: InputMaybe<Price_Quote_Line_Item_Aggregate_Bool_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  price_quote_status?: InputMaybe<Config_Price_Quote_Status_Bool_Exp>;
  quote_items?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<Config_Price_Quote_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "price_quote" */
export enum Price_Quote_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_7704a311f09b4410b76e214f3ba = 'PK_7704a311f09b4410b76e214f3ba'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Price_Quote_Delete_At_Path_Input = {
  quote_items?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Price_Quote_Delete_Elem_Input = {
  quote_items?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Price_Quote_Delete_Key_Input = {
  quote_items?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "price_quote" */
export type Price_Quote_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "price_quote" */
export type Price_Quote_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  has_accepted_terms_and_conditions?: InputMaybe<Scalars['Boolean']>;
  has_private_insurance?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Scalars['String']>;
  line_items?: InputMaybe<Price_Quote_Line_Item_Arr_Rel_Insert_Input>;
  note?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  price_quote_status?: InputMaybe<Config_Price_Quote_Status_Obj_Rel_Insert_Input>;
  quote_items?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Config_Price_Quote_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

export type Price_Quote_Line_Item_Aggregate_Bool_Exp = {
  count?: InputMaybe<Price_Quote_Line_Item_Aggregate_Bool_Exp_Count>;
};

export type Price_Quote_Line_Item_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Price_Quote_Line_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Price_Quote_Line_Item_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "price_quote_line_item" */
export type Price_Quote_Line_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Price_Quote_Line_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Price_Quote_Line_Item_Max_Order_By>;
  min?: InputMaybe<Price_Quote_Line_Item_Min_Order_By>;
  stddev?: InputMaybe<Price_Quote_Line_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Price_Quote_Line_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Price_Quote_Line_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Price_Quote_Line_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Price_Quote_Line_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Price_Quote_Line_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Price_Quote_Line_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "price_quote_line_item" */
export type Price_Quote_Line_Item_Arr_Rel_Insert_Input = {
  data: Array<Price_Quote_Line_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Price_Quote_Line_Item_On_Conflict>;
};

/** order by avg() on columns of table "price_quote_line_item" */
export type Price_Quote_Line_Item_Avg_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  duration_in_months?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_generic_of_id?: InputMaybe<Order_By>;
  price_quote_id?: InputMaybe<Order_By>;
  /** Total quantity - NOT quantity per month */
  quantity?: InputMaybe<Order_By>;
  requested_quantity_per_month?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "price_quote_line_item". All fields are combined with a logical 'AND'. */
export type Price_Quote_Line_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Price_Quote_Line_Item_Bool_Exp>>;
  _not?: InputMaybe<Price_Quote_Line_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Price_Quote_Line_Item_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  din?: InputMaybe<String_Comparison_Exp>;
  drug?: InputMaybe<Drug_Bool_Exp>;
  drug_id?: InputMaybe<Int_Comparison_Exp>;
  duration_in_months?: InputMaybe<Int_Comparison_Exp>;
  form?: InputMaybe<String_Comparison_Exp>;
  generic_of?: InputMaybe<Price_Quote_Line_Item_Bool_Exp>;
  generics?: InputMaybe<Price_Quote_Line_Item_Bool_Exp>;
  generics_aggregate?: InputMaybe<Price_Quote_Line_Item_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ingredient?: InputMaybe<String_Comparison_Exp>;
  is_generic_of_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price_quote?: InputMaybe<Price_Quote_Bool_Exp>;
  price_quote_id?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  requested_quantity_per_month?: InputMaybe<Int_Comparison_Exp>;
  strength?: InputMaybe<String_Comparison_Exp>;
  total_amount?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "price_quote_line_item" */
export enum Price_Quote_Line_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkBd194ef4d6ff34823101ec14c86 = 'PK_bd194ef4d6ff34823101ec14c86'
}

/** input type for incrementing numeric columns in table "price_quote_line_item" */
export type Price_Quote_Line_Item_Inc_Input = {
  drug_id?: InputMaybe<Scalars['Int']>;
  duration_in_months?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  is_generic_of_id?: InputMaybe<Scalars['Int']>;
  price_quote_id?: InputMaybe<Scalars['Int']>;
  /** Total quantity - NOT quantity per month */
  quantity?: InputMaybe<Scalars['Int']>;
  requested_quantity_per_month?: InputMaybe<Scalars['Int']>;
  total_amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "price_quote_line_item" */
export type Price_Quote_Line_Item_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  din?: InputMaybe<Scalars['String']>;
  drug?: InputMaybe<Drug_Obj_Rel_Insert_Input>;
  drug_id?: InputMaybe<Scalars['Int']>;
  duration_in_months?: InputMaybe<Scalars['Int']>;
  form?: InputMaybe<Scalars['String']>;
  generic_of?: InputMaybe<Price_Quote_Line_Item_Obj_Rel_Insert_Input>;
  generics?: InputMaybe<Price_Quote_Line_Item_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  ingredient?: InputMaybe<Scalars['String']>;
  is_generic_of_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  price_quote?: InputMaybe<Price_Quote_Obj_Rel_Insert_Input>;
  price_quote_id?: InputMaybe<Scalars['Int']>;
  /** Total quantity - NOT quantity per month */
  quantity?: InputMaybe<Scalars['Int']>;
  requested_quantity_per_month?: InputMaybe<Scalars['Int']>;
  strength?: InputMaybe<Scalars['String']>;
  total_amount?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "price_quote_line_item" */
export type Price_Quote_Line_Item_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  din?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration_in_months?: InputMaybe<Order_By>;
  form?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ingredient?: InputMaybe<Order_By>;
  is_generic_of_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price_quote_id?: InputMaybe<Order_By>;
  /** Total quantity - NOT quantity per month */
  quantity?: InputMaybe<Order_By>;
  requested_quantity_per_month?: InputMaybe<Order_By>;
  strength?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "price_quote_line_item" */
export type Price_Quote_Line_Item_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  din?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration_in_months?: InputMaybe<Order_By>;
  form?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ingredient?: InputMaybe<Order_By>;
  is_generic_of_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price_quote_id?: InputMaybe<Order_By>;
  /** Total quantity - NOT quantity per month */
  quantity?: InputMaybe<Order_By>;
  requested_quantity_per_month?: InputMaybe<Order_By>;
  strength?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "price_quote_line_item" */
export type Price_Quote_Line_Item_Obj_Rel_Insert_Input = {
  data: Price_Quote_Line_Item_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Price_Quote_Line_Item_On_Conflict>;
};

/** on_conflict condition type for table "price_quote_line_item" */
export type Price_Quote_Line_Item_On_Conflict = {
  constraint: Price_Quote_Line_Item_Constraint;
  update_columns?: Array<Price_Quote_Line_Item_Update_Column>;
  where?: InputMaybe<Price_Quote_Line_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "price_quote_line_item". */
export type Price_Quote_Line_Item_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  din?: InputMaybe<Order_By>;
  drug?: InputMaybe<Drug_Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration_in_months?: InputMaybe<Order_By>;
  form?: InputMaybe<Order_By>;
  generic_of?: InputMaybe<Price_Quote_Line_Item_Order_By>;
  generics_aggregate?: InputMaybe<Price_Quote_Line_Item_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  ingredient?: InputMaybe<Order_By>;
  is_generic_of_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price_quote?: InputMaybe<Price_Quote_Order_By>;
  price_quote_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  requested_quantity_per_month?: InputMaybe<Order_By>;
  strength?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: price_quote_line_item */
export type Price_Quote_Line_Item_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "price_quote_line_item" */
export enum Price_Quote_Line_Item_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Din = 'din',
  /** column name */
  DrugId = 'drug_id',
  /** column name */
  DurationInMonths = 'duration_in_months',
  /** column name */
  Form = 'form',
  /** column name */
  Id = 'id',
  /** column name */
  Ingredient = 'ingredient',
  /** column name */
  IsGenericOfId = 'is_generic_of_id',
  /** column name */
  Name = 'name',
  /** column name */
  PriceQuoteId = 'price_quote_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RequestedQuantityPerMonth = 'requested_quantity_per_month',
  /** column name */
  Strength = 'strength',
  /** column name */
  TotalAmount = 'total_amount',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "price_quote_line_item" */
export type Price_Quote_Line_Item_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  din?: InputMaybe<Scalars['String']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  duration_in_months?: InputMaybe<Scalars['Int']>;
  form?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ingredient?: InputMaybe<Scalars['String']>;
  is_generic_of_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  price_quote_id?: InputMaybe<Scalars['Int']>;
  /** Total quantity - NOT quantity per month */
  quantity?: InputMaybe<Scalars['Int']>;
  requested_quantity_per_month?: InputMaybe<Scalars['Int']>;
  strength?: InputMaybe<Scalars['String']>;
  total_amount?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "price_quote_line_item" */
export type Price_Quote_Line_Item_Stddev_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  duration_in_months?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_generic_of_id?: InputMaybe<Order_By>;
  price_quote_id?: InputMaybe<Order_By>;
  /** Total quantity - NOT quantity per month */
  quantity?: InputMaybe<Order_By>;
  requested_quantity_per_month?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "price_quote_line_item" */
export type Price_Quote_Line_Item_Stddev_Pop_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  duration_in_months?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_generic_of_id?: InputMaybe<Order_By>;
  price_quote_id?: InputMaybe<Order_By>;
  /** Total quantity - NOT quantity per month */
  quantity?: InputMaybe<Order_By>;
  requested_quantity_per_month?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "price_quote_line_item" */
export type Price_Quote_Line_Item_Stddev_Samp_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  duration_in_months?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_generic_of_id?: InputMaybe<Order_By>;
  price_quote_id?: InputMaybe<Order_By>;
  /** Total quantity - NOT quantity per month */
  quantity?: InputMaybe<Order_By>;
  requested_quantity_per_month?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "price_quote_line_item" */
export type Price_Quote_Line_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Price_Quote_Line_Item_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Price_Quote_Line_Item_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  din?: InputMaybe<Scalars['String']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  duration_in_months?: InputMaybe<Scalars['Int']>;
  form?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ingredient?: InputMaybe<Scalars['String']>;
  is_generic_of_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  price_quote_id?: InputMaybe<Scalars['Int']>;
  /** Total quantity - NOT quantity per month */
  quantity?: InputMaybe<Scalars['Int']>;
  requested_quantity_per_month?: InputMaybe<Scalars['Int']>;
  strength?: InputMaybe<Scalars['String']>;
  total_amount?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "price_quote_line_item" */
export type Price_Quote_Line_Item_Sum_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  duration_in_months?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_generic_of_id?: InputMaybe<Order_By>;
  price_quote_id?: InputMaybe<Order_By>;
  /** Total quantity - NOT quantity per month */
  quantity?: InputMaybe<Order_By>;
  requested_quantity_per_month?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** update columns of table "price_quote_line_item" */
export enum Price_Quote_Line_Item_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Din = 'din',
  /** column name */
  DrugId = 'drug_id',
  /** column name */
  DurationInMonths = 'duration_in_months',
  /** column name */
  Form = 'form',
  /** column name */
  Id = 'id',
  /** column name */
  Ingredient = 'ingredient',
  /** column name */
  IsGenericOfId = 'is_generic_of_id',
  /** column name */
  Name = 'name',
  /** column name */
  PriceQuoteId = 'price_quote_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RequestedQuantityPerMonth = 'requested_quantity_per_month',
  /** column name */
  Strength = 'strength',
  /** column name */
  TotalAmount = 'total_amount',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Price_Quote_Line_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Price_Quote_Line_Item_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Price_Quote_Line_Item_Set_Input>;
  /** filter the rows which have to be updated */
  where: Price_Quote_Line_Item_Bool_Exp;
};

/** order by var_pop() on columns of table "price_quote_line_item" */
export type Price_Quote_Line_Item_Var_Pop_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  duration_in_months?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_generic_of_id?: InputMaybe<Order_By>;
  price_quote_id?: InputMaybe<Order_By>;
  /** Total quantity - NOT quantity per month */
  quantity?: InputMaybe<Order_By>;
  requested_quantity_per_month?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "price_quote_line_item" */
export type Price_Quote_Line_Item_Var_Samp_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  duration_in_months?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_generic_of_id?: InputMaybe<Order_By>;
  price_quote_id?: InputMaybe<Order_By>;
  /** Total quantity - NOT quantity per month */
  quantity?: InputMaybe<Order_By>;
  requested_quantity_per_month?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "price_quote_line_item" */
export type Price_Quote_Line_Item_Variance_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  duration_in_months?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_generic_of_id?: InputMaybe<Order_By>;
  price_quote_id?: InputMaybe<Order_By>;
  /** Total quantity - NOT quantity per month */
  quantity?: InputMaybe<Order_By>;
  requested_quantity_per_month?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "price_quote" */
export type Price_Quote_Obj_Rel_Insert_Input = {
  data: Price_Quote_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Price_Quote_On_Conflict>;
};

/** on_conflict condition type for table "price_quote" */
export type Price_Quote_On_Conflict = {
  constraint: Price_Quote_Constraint;
  update_columns?: Array<Price_Quote_Update_Column>;
  where?: InputMaybe<Price_Quote_Bool_Exp>;
};

/** Ordering options when selecting data from "price_quote". */
export type Price_Quote_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  has_accepted_terms_and_conditions?: InputMaybe<Order_By>;
  has_private_insurance?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  line_items_aggregate?: InputMaybe<Price_Quote_Line_Item_Aggregate_Order_By>;
  note?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  price_quote_status?: InputMaybe<Config_Price_Quote_Status_Order_By>;
  quote_items?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: price_quote */
export type Price_Quote_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Price_Quote_Prepend_Input = {
  quote_items?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "price_quote" */
export enum Price_Quote_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  HasAcceptedTermsAndConditions = 'has_accepted_terms_and_conditions',
  /** column name */
  HasPrivateInsurance = 'has_private_insurance',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Note = 'note',
  /** column name */
  Path = 'path',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  QuoteItems = 'quote_items',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "price_quote" */
export type Price_Quote_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  has_accepted_terms_and_conditions?: InputMaybe<Scalars['Boolean']>;
  has_private_insurance?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  quote_items?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Config_Price_Quote_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "price_quote" */
export type Price_Quote_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Price_Quote_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Price_Quote_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  has_accepted_terms_and_conditions?: InputMaybe<Scalars['Boolean']>;
  has_private_insurance?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  quote_items?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Config_Price_Quote_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "price_quote" */
export enum Price_Quote_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  HasAcceptedTermsAndConditions = 'has_accepted_terms_and_conditions',
  /** column name */
  HasPrivateInsurance = 'has_private_insurance',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Note = 'note',
  /** column name */
  Path = 'path',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  QuoteItems = 'quote_items',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Price_Quote_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Price_Quote_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Price_Quote_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Price_Quote_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Price_Quote_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Price_Quote_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Price_Quote_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Price_Quote_Set_Input>;
  /** filter the rows which have to be updated */
  where: Price_Quote_Bool_Exp;
};

/** Boolean expression to filter rows from the table "private_insurance". All fields are combined with a logical 'AND'. */
export type Private_Insurance_Bool_Exp = {
  _and?: InputMaybe<Array<Private_Insurance_Bool_Exp>>;
  _not?: InputMaybe<Private_Insurance_Bool_Exp>;
  _or?: InputMaybe<Array<Private_Insurance_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  patient_private_insurances?: InputMaybe<Patient_Private_Insurance_Bool_Exp>;
  patient_private_insurances_aggregate?: InputMaybe<Patient_Private_Insurance_Aggregate_Bool_Exp>;
  policy_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  upload?: InputMaybe<Upload_Bool_Exp>;
  upload_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "private_insurance" */
export enum Private_Insurance_Constraint {
  /** unique or primary key constraint on columns "id" */
  PrivateInsurancePkey = 'private_insurance_pkey'
}

/** input type for incrementing numeric columns in table "private_insurance" */
export type Private_Insurance_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "private_insurance" */
export type Private_Insurance_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  patient_private_insurances?: InputMaybe<Patient_Private_Insurance_Arr_Rel_Insert_Input>;
  policy_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  upload?: InputMaybe<Upload_Obj_Rel_Insert_Input>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting object relation for remote table "private_insurance" */
export type Private_Insurance_Obj_Rel_Insert_Input = {
  data: Private_Insurance_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Private_Insurance_On_Conflict>;
};

/** on_conflict condition type for table "private_insurance" */
export type Private_Insurance_On_Conflict = {
  constraint: Private_Insurance_Constraint;
  update_columns?: Array<Private_Insurance_Update_Column>;
  where?: InputMaybe<Private_Insurance_Bool_Exp>;
};

/** Ordering options when selecting data from "private_insurance". */
export type Private_Insurance_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_private_insurances_aggregate?: InputMaybe<Patient_Private_Insurance_Aggregate_Order_By>;
  policy_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload?: InputMaybe<Upload_Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: private_insurance */
export type Private_Insurance_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "private_insurance" */
export enum Private_Insurance_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyName = 'policy_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadId = 'upload_id'
}

/** input type for updating data in table "private_insurance" */
export type Private_Insurance_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  policy_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** Streaming cursor of the table "private_insurance" */
export type Private_Insurance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Private_Insurance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Private_Insurance_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  policy_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** update columns of table "private_insurance" */
export enum Private_Insurance_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyName = 'policy_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadId = 'upload_id'
}

export type Private_Insurance_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Private_Insurance_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Private_Insurance_Set_Input>;
  /** filter the rows which have to be updated */
  where: Private_Insurance_Bool_Exp;
};

export type Program_Enrollment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Program_Enrollment_Aggregate_Bool_Exp_Count>;
};

export type Program_Enrollment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Program_Enrollment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Program_Enrollment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "program_enrollment" */
export type Program_Enrollment_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Program_Enrollment_Max_Order_By>;
  min?: InputMaybe<Program_Enrollment_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Program_Enrollment_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "program_enrollment" */
export type Program_Enrollment_Arr_Rel_Insert_Input = {
  data: Array<Program_Enrollment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Program_Enrollment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "program_enrollment". All fields are combined with a logical 'AND'. */
export type Program_Enrollment_Bool_Exp = {
  _and?: InputMaybe<Array<Program_Enrollment_Bool_Exp>>;
  _not?: InputMaybe<Program_Enrollment_Bool_Exp>;
  _or?: InputMaybe<Array<Program_Enrollment_Bool_Exp>>;
  account_uid?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  program?: InputMaybe<Config_Program_Enum_Comparison_Exp>;
  source?: InputMaybe<Config_Source_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "program_enrollment" */
export enum Program_Enrollment_Constraint {
  /** unique or primary key constraint on columns "program", "source", "account_uid" */
  ProgramEnrollmentAccountUidProgramSourceKey = 'program_enrollment_account_uid_program_source_key',
  /** unique or primary key constraint on columns "id" */
  ProgramEnrollmentPkey = 'program_enrollment_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Program_Enrollment_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Program_Enrollment_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Program_Enrollment_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "program_enrollment" */
export type Program_Enrollment_Insert_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  program?: InputMaybe<Config_Program_Enum>;
  source?: InputMaybe<Config_Source_Enum>;
};

/** order by max() on columns of table "program_enrollment" */
export type Program_Enrollment_Max_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "program_enrollment" */
export type Program_Enrollment_Min_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "program_enrollment" */
export type Program_Enrollment_On_Conflict = {
  constraint: Program_Enrollment_Constraint;
  update_columns?: Array<Program_Enrollment_Update_Column>;
  where?: InputMaybe<Program_Enrollment_Bool_Exp>;
};

/** Ordering options when selecting data from "program_enrollment". */
export type Program_Enrollment_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
};

/** primary key columns input for table: program_enrollment */
export type Program_Enrollment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Program_Enrollment_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "program_enrollment" */
export enum Program_Enrollment_Select_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Program = 'program',
  /** column name */
  Source = 'source'
}

/** input type for updating data in table "program_enrollment" */
export type Program_Enrollment_Set_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  program?: InputMaybe<Config_Program_Enum>;
  source?: InputMaybe<Config_Source_Enum>;
};

/** Streaming cursor of the table "program_enrollment" */
export type Program_Enrollment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Program_Enrollment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Program_Enrollment_Stream_Cursor_Value_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  data?: InputMaybe<Scalars['jsonb']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  program?: InputMaybe<Config_Program_Enum>;
  source?: InputMaybe<Config_Source_Enum>;
};

/** update columns of table "program_enrollment" */
export enum Program_Enrollment_Update_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Program = 'program',
  /** column name */
  Source = 'source'
}

export type Program_Enrollment_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Program_Enrollment_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Program_Enrollment_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Program_Enrollment_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Program_Enrollment_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Program_Enrollment_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Program_Enrollment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Program_Enrollment_Bool_Exp;
};

export type Public_Insurance_Aggregate_Bool_Exp = {
  count?: InputMaybe<Public_Insurance_Aggregate_Bool_Exp_Count>;
};

export type Public_Insurance_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Public_Insurance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Public_Insurance_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "public_insurance" */
export type Public_Insurance_Aggregate_Order_By = {
  avg?: InputMaybe<Public_Insurance_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Public_Insurance_Max_Order_By>;
  min?: InputMaybe<Public_Insurance_Min_Order_By>;
  stddev?: InputMaybe<Public_Insurance_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Public_Insurance_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Public_Insurance_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Public_Insurance_Sum_Order_By>;
  var_pop?: InputMaybe<Public_Insurance_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Public_Insurance_Var_Samp_Order_By>;
  variance?: InputMaybe<Public_Insurance_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Public_Insurance_Append_Input = {
  ai_extraction?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "public_insurance" */
export type Public_Insurance_Arr_Rel_Insert_Input = {
  data: Array<Public_Insurance_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Public_Insurance_On_Conflict>;
};

/** order by avg() on columns of table "public_insurance" */
export type Public_Insurance_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "public_insurance". All fields are combined with a logical 'AND'. */
export type Public_Insurance_Bool_Exp = {
  _and?: InputMaybe<Array<Public_Insurance_Bool_Exp>>;
  _not?: InputMaybe<Public_Insurance_Bool_Exp>;
  _or?: InputMaybe<Array<Public_Insurance_Bool_Exp>>;
  ai_extraction?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  expiration_date?: InputMaybe<Date_Comparison_Exp>;
  health_insurance_number?: InputMaybe<String_Comparison_Exp>;
  health_insurance_number_inconsistent?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Config_Insurance_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  upload?: InputMaybe<Upload_Bool_Exp>;
  upload_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "public_insurance" */
export enum Public_Insurance_Constraint {
  /** unique or primary key constraint on columns "id" */
  PublicInsurancePkey = 'public_insurance_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Public_Insurance_Delete_At_Path_Input = {
  ai_extraction?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Public_Insurance_Delete_Elem_Input = {
  ai_extraction?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Public_Insurance_Delete_Key_Input = {
  ai_extraction?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "public_insurance" */
export type Public_Insurance_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "public_insurance" */
export type Public_Insurance_Insert_Input = {
  ai_extraction?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  health_insurance_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Insurance_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  upload?: InputMaybe<Upload_Obj_Rel_Insert_Input>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "public_insurance" */
export type Public_Insurance_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  health_insurance_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "public_insurance" */
export type Public_Insurance_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  health_insurance_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "public_insurance" */
export type Public_Insurance_On_Conflict = {
  constraint: Public_Insurance_Constraint;
  update_columns?: Array<Public_Insurance_Update_Column>;
  where?: InputMaybe<Public_Insurance_Bool_Exp>;
};

/** Ordering options when selecting data from "public_insurance". */
export type Public_Insurance_Order_By = {
  ai_extraction?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  health_insurance_number?: InputMaybe<Order_By>;
  health_insurance_number_inconsistent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload?: InputMaybe<Upload_Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: public_insurance */
export type Public_Insurance_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Public_Insurance_Prepend_Input = {
  ai_extraction?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "public_insurance" */
export enum Public_Insurance_Select_Column {
  /** column name */
  AiExtraction = 'ai_extraction',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  HealthInsuranceNumber = 'health_insurance_number',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadId = 'upload_id'
}

/** input type for updating data in table "public_insurance" */
export type Public_Insurance_Set_Input = {
  ai_extraction?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  health_insurance_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Insurance_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "public_insurance" */
export type Public_Insurance_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "public_insurance" */
export type Public_Insurance_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "public_insurance" */
export type Public_Insurance_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "public_insurance" */
export type Public_Insurance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Public_Insurance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Public_Insurance_Stream_Cursor_Value_Input = {
  ai_extraction?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  health_insurance_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Insurance_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  upload_id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "public_insurance" */
export type Public_Insurance_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** update columns of table "public_insurance" */
export enum Public_Insurance_Update_Column {
  /** column name */
  AiExtraction = 'ai_extraction',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  HealthInsuranceNumber = 'health_insurance_number',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadId = 'upload_id'
}

export type Public_Insurance_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Public_Insurance_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Public_Insurance_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Public_Insurance_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Public_Insurance_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Public_Insurance_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Public_Insurance_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Public_Insurance_Set_Input>;
  /** filter the rows which have to be updated */
  where: Public_Insurance_Bool_Exp;
};

/** order by var_pop() on columns of table "public_insurance" */
export type Public_Insurance_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "public_insurance" */
export type Public_Insurance_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "public_insurance" */
export type Public_Insurance_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

export type Refund_Aggregate_Bool_Exp = {
  count?: InputMaybe<Refund_Aggregate_Bool_Exp_Count>;
};

export type Refund_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Refund_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Refund_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "refund" */
export type Refund_Aggregate_Order_By = {
  avg?: InputMaybe<Refund_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Refund_Max_Order_By>;
  min?: InputMaybe<Refund_Min_Order_By>;
  stddev?: InputMaybe<Refund_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Refund_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Refund_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Refund_Sum_Order_By>;
  var_pop?: InputMaybe<Refund_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Refund_Var_Samp_Order_By>;
  variance?: InputMaybe<Refund_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "refund" */
export type Refund_Arr_Rel_Insert_Input = {
  data: Array<Refund_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Refund_On_Conflict>;
};

/** order by avg() on columns of table "refund" */
export type Refund_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "refund". All fields are combined with a logical 'AND'. */
export type Refund_Bool_Exp = {
  _and?: InputMaybe<Array<Refund_Bool_Exp>>;
  _not?: InputMaybe<Refund_Bool_Exp>;
  _or?: InputMaybe<Array<Refund_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  transaction_time?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "refund" */
export enum Refund_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefundPkey = 'refund_pkey'
}

/** input type for incrementing numeric columns in table "refund" */
export type Refund_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "refund" */
export type Refund_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  transaction_time?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "refund" */
export type Refund_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  transaction_time?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "refund" */
export type Refund_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  transaction_time?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "refund" */
export type Refund_On_Conflict = {
  constraint: Refund_Constraint;
  update_columns?: Array<Refund_Update_Column>;
  where?: InputMaybe<Refund_Bool_Exp>;
};

/** Ordering options when selecting data from "refund". */
export type Refund_Order_By = {
  amount?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  transaction_time?: InputMaybe<Order_By>;
};

/** primary key columns input for table: refund */
export type Refund_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "refund" */
export enum Refund_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TransactionTime = 'transaction_time'
}

/** input type for updating data in table "refund" */
export type Refund_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  transaction_time?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "refund" */
export type Refund_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "refund" */
export type Refund_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "refund" */
export type Refund_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "refund" */
export type Refund_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Refund_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Refund_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  transaction_time?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "refund" */
export type Refund_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "refund" */
export enum Refund_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TransactionTime = 'transaction_time'
}

export type Refund_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Refund_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Refund_Set_Input>;
  /** filter the rows which have to be updated */
  where: Refund_Bool_Exp;
};

/** order by var_pop() on columns of table "refund" */
export type Refund_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "refund" */
export type Refund_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "refund" */
export type Refund_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reporting.acquisition_metrics_daily". All fields are combined with a logical 'AND'. */
export type Reporting_Acquisition_Metrics_Daily_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Acquisition_Metrics_Daily_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Acquisition_Metrics_Daily_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Acquisition_Metrics_Daily_Bool_Exp>>;
  accounts_call_booked?: InputMaybe<Bigint_Comparison_Exp>;
  accounts_completed?: InputMaybe<Bigint_Comparison_Exp>;
  accounts_created?: InputMaybe<Bigint_Comparison_Exp>;
  active_clients?: InputMaybe<Bigint_Comparison_Exp>;
  contacts?: InputMaybe<Bigint_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  dependents?: InputMaybe<Bigint_Comparison_Exp>;
  drop?: InputMaybe<Bigint_Comparison_Exp>;
};

/** Ordering options when selecting data from "reporting.acquisition_metrics_daily". */
export type Reporting_Acquisition_Metrics_Daily_Order_By = {
  accounts_call_booked?: InputMaybe<Order_By>;
  accounts_completed?: InputMaybe<Order_By>;
  accounts_created?: InputMaybe<Order_By>;
  active_clients?: InputMaybe<Order_By>;
  contacts?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  dependents?: InputMaybe<Order_By>;
  drop?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.acquisition_metrics_daily" */
export enum Reporting_Acquisition_Metrics_Daily_Select_Column {
  /** column name */
  AccountsCallBooked = 'accounts_call_booked',
  /** column name */
  AccountsCompleted = 'accounts_completed',
  /** column name */
  AccountsCreated = 'accounts_created',
  /** column name */
  ActiveClients = 'active_clients',
  /** column name */
  Contacts = 'contacts',
  /** column name */
  Date = 'date',
  /** column name */
  Dependents = 'dependents',
  /** column name */
  Drop = 'drop'
}

/** Streaming cursor of the table "reporting_acquisition_metrics_daily" */
export type Reporting_Acquisition_Metrics_Daily_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Acquisition_Metrics_Daily_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Acquisition_Metrics_Daily_Stream_Cursor_Value_Input = {
  accounts_call_booked?: InputMaybe<Scalars['bigint']>;
  accounts_completed?: InputMaybe<Scalars['bigint']>;
  accounts_created?: InputMaybe<Scalars['bigint']>;
  active_clients?: InputMaybe<Scalars['bigint']>;
  contacts?: InputMaybe<Scalars['bigint']>;
  date?: InputMaybe<Scalars['date']>;
  dependents?: InputMaybe<Scalars['bigint']>;
  drop?: InputMaybe<Scalars['bigint']>;
};

/** Boolean expression to filter rows from the table "reporting.attribution". All fields are combined with a logical 'AND'. */
export type Reporting_Attribution_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Attribution_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Attribution_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Attribution_Bool_Exp>>;
  account_uid?: InputMaybe<String_Comparison_Exp>;
  channel?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Date_Comparison_Exp>;
  program?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  utm_campaign?: InputMaybe<String_Comparison_Exp>;
  utm_content?: InputMaybe<String_Comparison_Exp>;
  utm_medium?: InputMaybe<String_Comparison_Exp>;
  utm_source?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "reporting.attribution" */
export type Reporting_Attribution_Insert_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['date']>;
  program?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
};

/** Ordering options when selecting data from "reporting.attribution". */
export type Reporting_Attribution_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  channel?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  utm_campaign?: InputMaybe<Order_By>;
  utm_content?: InputMaybe<Order_By>;
  utm_medium?: InputMaybe<Order_By>;
  utm_source?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.attribution" */
export enum Reporting_Attribution_Select_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Program = 'program',
  /** column name */
  Source = 'source',
  /** column name */
  UtmCampaign = 'utm_campaign',
  /** column name */
  UtmContent = 'utm_content',
  /** column name */
  UtmMedium = 'utm_medium',
  /** column name */
  UtmSource = 'utm_source'
}

/** input type for updating data in table "reporting.attribution" */
export type Reporting_Attribution_Set_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['date']>;
  program?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "reporting_attribution" */
export type Reporting_Attribution_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Attribution_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Attribution_Stream_Cursor_Value_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['date']>;
  program?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
};

export type Reporting_Attribution_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reporting_Attribution_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reporting_Attribution_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Reporting_Consultation_Append_Input = {
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  row_data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "reporting.consultation". All fields are combined with a logical 'AND'. */
export type Reporting_Consultation_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Consultation_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Consultation_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Consultation_Bool_Exp>>;
  changed_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  event_id?: InputMaybe<Bigint_Comparison_Exp>;
  hasura_user?: InputMaybe<Jsonb_Comparison_Exp>;
  row_data?: InputMaybe<Jsonb_Comparison_Exp>;
  transaction_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Reporting_Consultation_Delete_At_Path_Input = {
  changed_fields?: InputMaybe<Array<Scalars['String']>>;
  hasura_user?: InputMaybe<Array<Scalars['String']>>;
  row_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Reporting_Consultation_Delete_Elem_Input = {
  changed_fields?: InputMaybe<Scalars['Int']>;
  hasura_user?: InputMaybe<Scalars['Int']>;
  row_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Reporting_Consultation_Delete_Key_Input = {
  changed_fields?: InputMaybe<Scalars['String']>;
  hasura_user?: InputMaybe<Scalars['String']>;
  row_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "reporting.consultation" */
export type Reporting_Consultation_Inc_Input = {
  event_id?: InputMaybe<Scalars['bigint']>;
  transaction_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "reporting.consultation" */
export type Reporting_Consultation_Insert_Input = {
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  date?: InputMaybe<Scalars['date']>;
  event_id?: InputMaybe<Scalars['bigint']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  row_data?: InputMaybe<Scalars['jsonb']>;
  transaction_id?: InputMaybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "reporting.consultation". */
export type Reporting_Consultation_Order_By = {
  changed_fields?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  hasura_user?: InputMaybe<Order_By>;
  row_data?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Reporting_Consultation_Prepend_Input = {
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  row_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "reporting.consultation" */
export enum Reporting_Consultation_Select_Column {
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  Date = 'date',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  RowData = 'row_data',
  /** column name */
  TransactionId = 'transaction_id'
}

/** input type for updating data in table "reporting.consultation" */
export type Reporting_Consultation_Set_Input = {
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  date?: InputMaybe<Scalars['date']>;
  event_id?: InputMaybe<Scalars['bigint']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  row_data?: InputMaybe<Scalars['jsonb']>;
  transaction_id?: InputMaybe<Scalars['bigint']>;
};

/** Streaming cursor of the table "reporting_consultation" */
export type Reporting_Consultation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Consultation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Consultation_Stream_Cursor_Value_Input = {
  changed_fields?: InputMaybe<Scalars['jsonb']>;
  date?: InputMaybe<Scalars['date']>;
  event_id?: InputMaybe<Scalars['bigint']>;
  hasura_user?: InputMaybe<Scalars['jsonb']>;
  row_data?: InputMaybe<Scalars['jsonb']>;
  transaction_id?: InputMaybe<Scalars['bigint']>;
};

export type Reporting_Consultation_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Reporting_Consultation_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Reporting_Consultation_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Reporting_Consultation_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Reporting_Consultation_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reporting_Consultation_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Reporting_Consultation_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reporting_Consultation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reporting_Consultation_Bool_Exp;
};

/** Boolean expression to filter rows from the table "reporting.drug". All fields are combined with a logical 'AND'. */
export type Reporting_Drug_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Drug_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Drug_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Drug_Bool_Exp>>;
  company?: InputMaybe<String_Comparison_Exp>;
  conservation_in_days?: InputMaybe<Numeric_Comparison_Exp>;
  conservation_in_days_2?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  din?: InputMaybe<String_Comparison_Exp>;
  drug_equivalence_id?: InputMaybe<Uuid_Comparison_Exp>;
  form?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ingredient?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  strength?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  was_ceased?: InputMaybe<Boolean_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "reporting.drug" */
export type Reporting_Drug_Inc_Input = {
  conservation_in_days?: InputMaybe<Scalars['numeric']>;
  conservation_in_days_2?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "reporting.drug" */
export type Reporting_Drug_Insert_Input = {
  company?: InputMaybe<Scalars['String']>;
  conservation_in_days?: InputMaybe<Scalars['numeric']>;
  conservation_in_days_2?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  din?: InputMaybe<Scalars['String']>;
  drug_equivalence_id?: InputMaybe<Scalars['uuid']>;
  form?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ingredient?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  was_ceased?: InputMaybe<Scalars['Boolean']>;
};

/** Ordering options when selecting data from "reporting.drug". */
export type Reporting_Drug_Order_By = {
  company?: InputMaybe<Order_By>;
  conservation_in_days?: InputMaybe<Order_By>;
  conservation_in_days_2?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  din?: InputMaybe<Order_By>;
  drug_equivalence_id?: InputMaybe<Order_By>;
  form?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ingredient?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  strength?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  was_ceased?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.drug" */
export enum Reporting_Drug_Select_Column {
  /** column name */
  Company = 'company',
  /** column name */
  ConservationInDays = 'conservation_in_days',
  /** column name */
  ConservationInDays_2 = 'conservation_in_days_2',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Din = 'din',
  /** column name */
  DrugEquivalenceId = 'drug_equivalence_id',
  /** column name */
  Form = 'form',
  /** column name */
  Id = 'id',
  /** column name */
  Ingredient = 'ingredient',
  /** column name */
  Name = 'name',
  /** column name */
  Strength = 'strength',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WasCeased = 'was_ceased'
}

/** input type for updating data in table "reporting.drug" */
export type Reporting_Drug_Set_Input = {
  company?: InputMaybe<Scalars['String']>;
  conservation_in_days?: InputMaybe<Scalars['numeric']>;
  conservation_in_days_2?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  din?: InputMaybe<Scalars['String']>;
  drug_equivalence_id?: InputMaybe<Scalars['uuid']>;
  form?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ingredient?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  was_ceased?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "reporting_drug" */
export type Reporting_Drug_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Drug_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Drug_Stream_Cursor_Value_Input = {
  company?: InputMaybe<Scalars['String']>;
  conservation_in_days?: InputMaybe<Scalars['numeric']>;
  conservation_in_days_2?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  din?: InputMaybe<Scalars['String']>;
  drug_equivalence_id?: InputMaybe<Scalars['uuid']>;
  form?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ingredient?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  was_ceased?: InputMaybe<Scalars['Boolean']>;
};

export type Reporting_Drug_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reporting_Drug_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reporting_Drug_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reporting_Drug_Bool_Exp;
};

/** Boolean expression to filter rows from the table "reporting.fax_ocr". All fields are combined with a logical 'AND'. */
export type Reporting_Fax_Ocr_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Fax_Ocr_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Fax_Ocr_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Fax_Ocr_Bool_Exp>>;
  account_uid?: InputMaybe<String_Comparison_Exp>;
  birthday?: InputMaybe<Date_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  filename?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  health_insurance_number?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "reporting.fax_ocr". */
export type Reporting_Fax_Ocr_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  health_insurance_number?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.fax_ocr" */
export enum Reporting_Fax_Ocr_Select_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Filename = 'filename',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HealthInsuranceNumber = 'health_insurance_number',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Type = 'type'
}

/** Streaming cursor of the table "reporting_fax_ocr" */
export type Reporting_Fax_Ocr_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Fax_Ocr_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Fax_Ocr_Stream_Cursor_Value_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  filename?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  health_insurance_number?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "reporting.invoice". All fields are combined with a logical 'AND'. */
export type Reporting_Invoice_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Invoice_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Invoice_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Invoice_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Date_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  details?: InputMaybe<Json_Comparison_Exp>;
  document_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  refund_reason?: InputMaybe<String_Comparison_Exp>;
  refunded_amount?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  total_rx_amount?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Date_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "reporting.invoice" */
export type Reporting_Invoice_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  refunded_amount?: InputMaybe<Scalars['Int']>;
  total_rx_amount?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "reporting.invoice" */
export type Reporting_Invoice_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  details?: InputMaybe<Scalars['json']>;
  document_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  refund_reason?: InputMaybe<Scalars['String']>;
  refunded_amount?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  total_rx_amount?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['date']>;
};

/** Boolean expression to filter rows from the table "reporting.invoice_line_item". All fields are combined with a logical 'AND'. */
export type Reporting_Invoice_Line_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Invoice_Line_Item_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Invoice_Line_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Invoice_Line_Item_Bool_Exp>>;
  created_at?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_id?: InputMaybe<Int_Comparison_Exp>;
  order_line_item_id?: InputMaybe<Uuid_Comparison_Exp>;
  service_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Date_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "reporting.invoice_line_item" */
export type Reporting_Invoice_Line_Item_Inc_Input = {
  invoice_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "reporting.invoice_line_item" */
export type Reporting_Invoice_Line_Item_Insert_Input = {
  created_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  order_line_item_id?: InputMaybe<Scalars['uuid']>;
  service_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['date']>;
};

/** Ordering options when selecting data from "reporting.invoice_line_item". */
export type Reporting_Invoice_Line_Item_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  order_line_item_id?: InputMaybe<Order_By>;
  service_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.invoice_line_item" */
export enum Reporting_Invoice_Line_Item_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  OrderLineItemId = 'order_line_item_id',
  /** column name */
  ServiceId = 'service_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "reporting.invoice_line_item" */
export type Reporting_Invoice_Line_Item_Set_Input = {
  created_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  order_line_item_id?: InputMaybe<Scalars['uuid']>;
  service_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['date']>;
};

/** Streaming cursor of the table "reporting_invoice_line_item" */
export type Reporting_Invoice_Line_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Invoice_Line_Item_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Invoice_Line_Item_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  order_line_item_id?: InputMaybe<Scalars['uuid']>;
  service_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['date']>;
};

export type Reporting_Invoice_Line_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reporting_Invoice_Line_Item_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reporting_Invoice_Line_Item_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reporting_Invoice_Line_Item_Bool_Exp;
};

/** Ordering options when selecting data from "reporting.invoice". */
export type Reporting_Invoice_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  refund_reason?: InputMaybe<Order_By>;
  refunded_amount?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total_rx_amount?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.invoice" */
export enum Reporting_Invoice_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Details = 'details',
  /** column name */
  DocumentUrl = 'document_url',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  RefundReason = 'refund_reason',
  /** column name */
  RefundedAmount = 'refunded_amount',
  /** column name */
  Status = 'status',
  /** column name */
  TotalRxAmount = 'total_rx_amount',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "reporting.invoice" */
export type Reporting_Invoice_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  details?: InputMaybe<Scalars['json']>;
  document_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  refund_reason?: InputMaybe<Scalars['String']>;
  refunded_amount?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  total_rx_amount?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['date']>;
};

/** Streaming cursor of the table "reporting_invoice" */
export type Reporting_Invoice_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Invoice_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Invoice_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  details?: InputMaybe<Scalars['json']>;
  document_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  refund_reason?: InputMaybe<Scalars['String']>;
  refunded_amount?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  total_rx_amount?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['date']>;
};

export type Reporting_Invoice_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reporting_Invoice_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reporting_Invoice_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reporting_Invoice_Bool_Exp;
};

/** Boolean expression to filter rows from the table "reporting.medication". All fields are combined with a logical 'AND'. */
export type Reporting_Medication_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Medication_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Medication_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Medication_Bool_Exp>>;
  auto_renew?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Date_Comparison_Exp>;
  drug_equivalence_id?: InputMaybe<Uuid_Comparison_Exp>;
  drug_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  packaging?: InputMaybe<String_Comparison_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  specifications?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "reporting.medication" */
export type Reporting_Medication_Inc_Input = {
  drug_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "reporting.medication" */
export type Reporting_Medication_Insert_Input = {
  auto_renew?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['date']>;
  drug_equivalence_id?: InputMaybe<Scalars['uuid']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  packaging?: InputMaybe<Scalars['String']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  specifications?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** Ordering options when selecting data from "reporting.medication". */
export type Reporting_Medication_Order_By = {
  auto_renew?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  drug_equivalence_id?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  packaging?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  specifications?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.medication" */
export enum Reporting_Medication_Select_Column {
  /** column name */
  AutoRenew = 'auto_renew',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DrugEquivalenceId = 'drug_equivalence_id',
  /** column name */
  DrugId = 'drug_id',
  /** column name */
  Id = 'id',
  /** column name */
  Packaging = 'packaging',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  Specifications = 'specifications',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "reporting.medication" */
export type Reporting_Medication_Set_Input = {
  auto_renew?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['date']>;
  drug_equivalence_id?: InputMaybe<Scalars['uuid']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  packaging?: InputMaybe<Scalars['String']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  specifications?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "reporting_medication" */
export type Reporting_Medication_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Medication_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Medication_Stream_Cursor_Value_Input = {
  auto_renew?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['date']>;
  drug_equivalence_id?: InputMaybe<Scalars['uuid']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  packaging?: InputMaybe<Scalars['String']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  specifications?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type Reporting_Medication_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reporting_Medication_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reporting_Medication_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reporting_Medication_Bool_Exp;
};

/** Boolean expression to filter rows from the table "reporting.order". All fields are combined with a logical 'AND'. */
export type Reporting_Order_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Order_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Order_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Order_Bool_Exp>>;
  address_id?: InputMaybe<Int_Comparison_Exp>;
  contacted_for_confirmation?: InputMaybe<Boolean_Comparison_Exp>;
  contacted_for_payment?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Date_Comparison_Exp>;
  deleted_at?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  patient_id?: InputMaybe<Int_Comparison_Exp>;
  patient_note?: InputMaybe<String_Comparison_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  paysafe_card_payment_token?: InputMaybe<String_Comparison_Exp>;
  planned_arrival_date?: InputMaybe<Date_Comparison_Exp>;
  planned_shipment_provider?: InputMaybe<String_Comparison_Exp>;
  planned_shipment_rate?: InputMaybe<Int_Comparison_Exp>;
  renewed?: InputMaybe<Boolean_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Date_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "reporting.order_item". All fields are combined with a logical 'AND'. */
export type Reporting_Order_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Order_Item_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Order_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Order_Item_Bool_Exp>>;
  company?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  drug_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  drug_deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  drug_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  form?: InputMaybe<String_Comparison_Exp>;
  ingredient?: InputMaybe<String_Comparison_Exp>;
  medication_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  number?: InputMaybe<String_Comparison_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  packaging?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  quantity_served?: InputMaybe<Numeric_Comparison_Exp>;
  requested_quantity?: InputMaybe<String_Comparison_Exp>;
  strength?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** Ordering options when selecting data from "reporting.order_item". */
export type Reporting_Order_Item_Order_By = {
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  drug_created_at?: InputMaybe<Order_By>;
  drug_deleted_at?: InputMaybe<Order_By>;
  drug_updated_at?: InputMaybe<Order_By>;
  form?: InputMaybe<Order_By>;
  ingredient?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  packaging?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_served?: InputMaybe<Order_By>;
  requested_quantity?: InputMaybe<Order_By>;
  strength?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.order_item" */
export enum Reporting_Order_Item_Select_Column {
  /** column name */
  Company = 'company',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DrugCreatedAt = 'drug_created_at',
  /** column name */
  DrugDeletedAt = 'drug_deleted_at',
  /** column name */
  DrugUpdatedAt = 'drug_updated_at',
  /** column name */
  Form = 'form',
  /** column name */
  Ingredient = 'ingredient',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  Name = 'name',
  /** column name */
  Number = 'number',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Packaging = 'packaging',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  QuantityServed = 'quantity_served',
  /** column name */
  RequestedQuantity = 'requested_quantity',
  /** column name */
  Strength = 'strength',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "reporting_order_item" */
export type Reporting_Order_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Order_Item_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Order_Item_Stream_Cursor_Value_Input = {
  company?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  drug_created_at?: InputMaybe<Scalars['timestamp']>;
  drug_deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug_updated_at?: InputMaybe<Scalars['timestamp']>;
  form?: InputMaybe<Scalars['String']>;
  ingredient?: InputMaybe<Scalars['String']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['Int']>;
  packaging?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['Int']>;
  quantity_served?: InputMaybe<Scalars['numeric']>;
  requested_quantity?: InputMaybe<Scalars['String']>;
  strength?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "reporting.order". */
export type Reporting_Order_Order_By = {
  address_id?: InputMaybe<Order_By>;
  contacted_for_confirmation?: InputMaybe<Order_By>;
  contacted_for_payment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  patient_note?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  paysafe_card_payment_token?: InputMaybe<Order_By>;
  planned_arrival_date?: InputMaybe<Order_By>;
  planned_shipment_provider?: InputMaybe<Order_By>;
  planned_shipment_rate?: InputMaybe<Order_By>;
  renewed?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.order" */
export enum Reporting_Order_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  ContactedForConfirmation = 'contacted_for_confirmation',
  /** column name */
  ContactedForPayment = 'contacted_for_payment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PatientNote = 'patient_note',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PaysafeCardPaymentToken = 'paysafe_card_payment_token',
  /** column name */
  PlannedArrivalDate = 'planned_arrival_date',
  /** column name */
  PlannedShipmentProvider = 'planned_shipment_provider',
  /** column name */
  PlannedShipmentRate = 'planned_shipment_rate',
  /** column name */
  Renewed = 'renewed',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "reporting_order" */
export type Reporting_Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Order_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['Int']>;
  contacted_for_confirmation?: InputMaybe<Scalars['Boolean']>;
  contacted_for_payment?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  patient_id?: InputMaybe<Scalars['Int']>;
  patient_note?: InputMaybe<Scalars['String']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  paysafe_card_payment_token?: InputMaybe<Scalars['String']>;
  planned_arrival_date?: InputMaybe<Scalars['date']>;
  planned_shipment_provider?: InputMaybe<Scalars['String']>;
  planned_shipment_rate?: InputMaybe<Scalars['Int']>;
  renewed?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['date']>;
};

/** Boolean expression to filter rows from the table "reporting.patient_and_acquired_patient". All fields are combined with a logical 'AND'. */
export type Reporting_Patient_And_Acquired_Patient_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Patient_And_Acquired_Patient_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Patient_And_Acquired_Patient_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Patient_And_Acquired_Patient_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  origin?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  program?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "reporting.patient_and_acquired_patient". */
export type Reporting_Patient_And_Acquired_Patient_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  origin?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.patient_and_acquired_patient" */
export enum Reporting_Patient_And_Acquired_Patient_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Origin = 'origin',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Program = 'program',
  /** column name */
  Status = 'status',
  /** column name */
  Uid = 'uid'
}

/** Streaming cursor of the table "reporting_patient_and_acquired_patient" */
export type Reporting_Patient_And_Acquired_Patient_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Patient_And_Acquired_Patient_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Patient_And_Acquired_Patient_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  last_name?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "reporting.patient_info". All fields are combined with a logical 'AND'. */
export type Reporting_Patient_Info_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Patient_Info_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Patient_Info_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Patient_Info_Bool_Exp>>;
  acquired_external_id?: InputMaybe<String_Comparison_Exp>;
  acquired_patient_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  acquired_patient_id?: InputMaybe<Int_Comparison_Exp>;
  automatic_renewal?: InputMaybe<Boolean_Comparison_Exp>;
  birthday?: InputMaybe<Date_Comparison_Exp>;
  clinic?: InputMaybe<String_Comparison_Exp>;
  company?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  default_address_id?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  doctor_name?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  has_allergies?: InputMaybe<Boolean_Comparison_Exp>;
  has_consented_to_automatic_payments?: InputMaybe<Boolean_Comparison_Exp>;
  has_doctor?: InputMaybe<Boolean_Comparison_Exp>;
  has_health_conditions?: InputMaybe<Boolean_Comparison_Exp>;
  has_insurance?: InputMaybe<Boolean_Comparison_Exp>;
  has_private_insurance?: InputMaybe<Boolean_Comparison_Exp>;
  has_public_insurance?: InputMaybe<Boolean_Comparison_Exp>;
  heard_about?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  introduction_call_completed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  last_service_date?: InputMaybe<Date_Comparison_Exp>;
  last_service_duration?: InputMaybe<Int_Comparison_Exp>;
  last_touched_utm?: InputMaybe<String_Comparison_Exp>;
  lead_uid?: InputMaybe<String_Comparison_Exp>;
  note_date?: InputMaybe<Timestamp_Comparison_Exp>;
  note_detail?: InputMaybe<Jsonb_Comparison_Exp>;
  note_id?: InputMaybe<Uuid_Comparison_Exp>;
  note_message?: InputMaybe<String_Comparison_Exp>;
  origin?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  pms_external_id?: InputMaybe<String_Comparison_Exp>;
  preferred_language?: InputMaybe<String_Comparison_Exp>;
  private_insurance_company_name?: InputMaybe<String_Comparison_Exp>;
  program?: InputMaybe<String_Comparison_Exp>;
  referral_sources?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  status_mode?: InputMaybe<Patient_Status_Mode_Enum_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** Ordering options when selecting data from "reporting.patient_info". */
export type Reporting_Patient_Info_Order_By = {
  acquired_external_id?: InputMaybe<Order_By>;
  acquired_patient_created_at?: InputMaybe<Order_By>;
  acquired_patient_id?: InputMaybe<Order_By>;
  automatic_renewal?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  clinic?: InputMaybe<Order_By>;
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  default_address_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  doctor_name?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  has_allergies?: InputMaybe<Order_By>;
  has_consented_to_automatic_payments?: InputMaybe<Order_By>;
  has_doctor?: InputMaybe<Order_By>;
  has_health_conditions?: InputMaybe<Order_By>;
  has_insurance?: InputMaybe<Order_By>;
  has_private_insurance?: InputMaybe<Order_By>;
  has_public_insurance?: InputMaybe<Order_By>;
  heard_about?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  introduction_call_completed_at?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  last_service_date?: InputMaybe<Order_By>;
  last_service_duration?: InputMaybe<Order_By>;
  last_touched_utm?: InputMaybe<Order_By>;
  lead_uid?: InputMaybe<Order_By>;
  note_date?: InputMaybe<Order_By>;
  note_detail?: InputMaybe<Order_By>;
  note_id?: InputMaybe<Order_By>;
  note_message?: InputMaybe<Order_By>;
  origin?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  pms_external_id?: InputMaybe<Order_By>;
  preferred_language?: InputMaybe<Order_By>;
  private_insurance_company_name?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  referral_sources?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_mode?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.patient_info" */
export enum Reporting_Patient_Info_Select_Column {
  /** column name */
  AcquiredExternalId = 'acquired_external_id',
  /** column name */
  AcquiredPatientCreatedAt = 'acquired_patient_created_at',
  /** column name */
  AcquiredPatientId = 'acquired_patient_id',
  /** column name */
  AutomaticRenewal = 'automatic_renewal',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  Clinic = 'clinic',
  /** column name */
  Company = 'company',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultAddressId = 'default_address_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DoctorName = 'doctor_name',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  HasAllergies = 'has_allergies',
  /** column name */
  HasConsentedToAutomaticPayments = 'has_consented_to_automatic_payments',
  /** column name */
  HasDoctor = 'has_doctor',
  /** column name */
  HasHealthConditions = 'has_health_conditions',
  /** column name */
  HasInsurance = 'has_insurance',
  /** column name */
  HasPrivateInsurance = 'has_private_insurance',
  /** column name */
  HasPublicInsurance = 'has_public_insurance',
  /** column name */
  HeardAbout = 'heard_about',
  /** column name */
  Id = 'id',
  /** column name */
  IntroductionCallCompletedAt = 'introduction_call_completed_at',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastServiceDate = 'last_service_date',
  /** column name */
  LastServiceDuration = 'last_service_duration',
  /** column name */
  LastTouchedUtm = 'last_touched_utm',
  /** column name */
  LeadUid = 'lead_uid',
  /** column name */
  NoteDate = 'note_date',
  /** column name */
  NoteDetail = 'note_detail',
  /** column name */
  NoteId = 'note_id',
  /** column name */
  NoteMessage = 'note_message',
  /** column name */
  Origin = 'origin',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PmsExternalId = 'pms_external_id',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  PrivateInsuranceCompanyName = 'private_insurance_company_name',
  /** column name */
  Program = 'program',
  /** column name */
  ReferralSources = 'referral_sources',
  /** column name */
  Status = 'status',
  /** column name */
  StatusMode = 'status_mode',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "reporting_patient_info" */
export type Reporting_Patient_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Patient_Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Patient_Info_Stream_Cursor_Value_Input = {
  acquired_external_id?: InputMaybe<Scalars['String']>;
  acquired_patient_created_at?: InputMaybe<Scalars['timestamp']>;
  acquired_patient_id?: InputMaybe<Scalars['Int']>;
  automatic_renewal?: InputMaybe<Scalars['Boolean']>;
  birthday?: InputMaybe<Scalars['date']>;
  clinic?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  default_address_id?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  doctor_name?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  has_allergies?: InputMaybe<Scalars['Boolean']>;
  has_consented_to_automatic_payments?: InputMaybe<Scalars['Boolean']>;
  has_doctor?: InputMaybe<Scalars['Boolean']>;
  has_health_conditions?: InputMaybe<Scalars['Boolean']>;
  has_insurance?: InputMaybe<Scalars['Boolean']>;
  has_private_insurance?: InputMaybe<Scalars['Boolean']>;
  has_public_insurance?: InputMaybe<Scalars['Boolean']>;
  heard_about?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  introduction_call_completed_at?: InputMaybe<Scalars['timestamp']>;
  last_name?: InputMaybe<Scalars['String']>;
  last_service_date?: InputMaybe<Scalars['date']>;
  last_service_duration?: InputMaybe<Scalars['Int']>;
  last_touched_utm?: InputMaybe<Scalars['String']>;
  lead_uid?: InputMaybe<Scalars['String']>;
  note_date?: InputMaybe<Scalars['timestamp']>;
  note_detail?: InputMaybe<Scalars['jsonb']>;
  note_id?: InputMaybe<Scalars['uuid']>;
  note_message?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  pms_external_id?: InputMaybe<Scalars['String']>;
  preferred_language?: InputMaybe<Scalars['String']>;
  private_insurance_company_name?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  referral_sources?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  status_mode?: InputMaybe<Scalars['patient_status_mode_enum']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Boolean expression to filter rows from the table "reporting.patient_referral". All fields are combined with a logical 'AND'. */
export type Reporting_Patient_Referral_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Patient_Referral_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Patient_Referral_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Patient_Referral_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  origin?: InputMaybe<String_Comparison_Exp>;
  rejection_reason?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "reporting.patient_referral" */
export type Reporting_Patient_Referral_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  rejection_reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** Ordering options when selecting data from "reporting.patient_referral". */
export type Reporting_Patient_Referral_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  origin?: InputMaybe<Order_By>;
  rejection_reason?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.patient_referral" */
export enum Reporting_Patient_Referral_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Origin = 'origin',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "reporting.patient_referral" */
export type Reporting_Patient_Referral_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  rejection_reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "reporting_patient_referral" */
export type Reporting_Patient_Referral_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Patient_Referral_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Patient_Referral_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  origin?: InputMaybe<Scalars['String']>;
  rejection_reason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type Reporting_Patient_Referral_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reporting_Patient_Referral_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reporting_Patient_Referral_Bool_Exp;
};

/** Boolean expression to filter rows from the table "reporting.prescription". All fields are combined with a logical 'AND'. */
export type Reporting_Prescription_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Prescription_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Prescription_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Prescription_Bool_Exp>>;
  created_at?: InputMaybe<Date_Comparison_Exp>;
  deleted_at?: InputMaybe<Date_Comparison_Exp>;
  dosage?: InputMaybe<String_Comparison_Exp>;
  expiration_date?: InputMaybe<Date_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  medication_id?: InputMaybe<Int_Comparison_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  prescribed_date?: InputMaybe<Date_Comparison_Exp>;
  prescribed_quantity?: InputMaybe<Float_Comparison_Exp>;
  prescriber?: InputMaybe<String_Comparison_Exp>;
  prescriber_id?: InputMaybe<Uuid_Comparison_Exp>;
  renewals?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Date_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "reporting.prescription" */
export type Reporting_Prescription_Inc_Input = {
  medication_id?: InputMaybe<Scalars['Int']>;
  prescribed_quantity?: InputMaybe<Scalars['Float']>;
  renewals?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "reporting.prescription" */
export type Reporting_Prescription_Insert_Input = {
  created_at?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['date']>;
  dosage?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  prescribed_date?: InputMaybe<Scalars['date']>;
  prescribed_quantity?: InputMaybe<Scalars['Float']>;
  prescriber?: InputMaybe<Scalars['String']>;
  prescriber_id?: InputMaybe<Scalars['uuid']>;
  renewals?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['date']>;
};

/** Ordering options when selecting data from "reporting.prescription". */
export type Reporting_Prescription_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  dosage?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  prescribed_date?: InputMaybe<Order_By>;
  prescribed_quantity?: InputMaybe<Order_By>;
  prescriber?: InputMaybe<Order_By>;
  prescriber_id?: InputMaybe<Order_By>;
  renewals?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.prescription" */
export enum Reporting_Prescription_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Dosage = 'dosage',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PrescribedDate = 'prescribed_date',
  /** column name */
  PrescribedQuantity = 'prescribed_quantity',
  /** column name */
  Prescriber = 'prescriber',
  /** column name */
  PrescriberId = 'prescriber_id',
  /** column name */
  Renewals = 'renewals',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "reporting.prescription" */
export type Reporting_Prescription_Set_Input = {
  created_at?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['date']>;
  dosage?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  prescribed_date?: InputMaybe<Scalars['date']>;
  prescribed_quantity?: InputMaybe<Scalars['Float']>;
  prescriber?: InputMaybe<Scalars['String']>;
  prescriber_id?: InputMaybe<Scalars['uuid']>;
  renewals?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['date']>;
};

/** Streaming cursor of the table "reporting_prescription" */
export type Reporting_Prescription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Prescription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Prescription_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['date']>;
  dosage?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  prescribed_date?: InputMaybe<Scalars['date']>;
  prescribed_quantity?: InputMaybe<Scalars['Float']>;
  prescriber?: InputMaybe<Scalars['String']>;
  prescriber_id?: InputMaybe<Scalars['uuid']>;
  renewals?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['date']>;
};

export type Reporting_Prescription_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reporting_Prescription_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reporting_Prescription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reporting_Prescription_Bool_Exp;
};

/** Boolean expression to filter rows from the table "reporting.program_enrollment". All fields are combined with a logical 'AND'. */
export type Reporting_Program_Enrollment_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Program_Enrollment_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Program_Enrollment_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Program_Enrollment_Bool_Exp>>;
  account_uid?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Date_Comparison_Exp>;
  deleted_at?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  program?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  utm_campaign?: InputMaybe<String_Comparison_Exp>;
  utm_content?: InputMaybe<String_Comparison_Exp>;
  utm_medium?: InputMaybe<String_Comparison_Exp>;
  utm_source?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "reporting.program_enrollment" */
export type Reporting_Program_Enrollment_Insert_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  program?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
};

/** Ordering options when selecting data from "reporting.program_enrollment". */
export type Reporting_Program_Enrollment_Order_By = {
  account_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  utm_campaign?: InputMaybe<Order_By>;
  utm_content?: InputMaybe<Order_By>;
  utm_medium?: InputMaybe<Order_By>;
  utm_source?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.program_enrollment" */
export enum Reporting_Program_Enrollment_Select_Column {
  /** column name */
  AccountUid = 'account_uid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Program = 'program',
  /** column name */
  Source = 'source',
  /** column name */
  UtmCampaign = 'utm_campaign',
  /** column name */
  UtmContent = 'utm_content',
  /** column name */
  UtmMedium = 'utm_medium',
  /** column name */
  UtmSource = 'utm_source'
}

/** input type for updating data in table "reporting.program_enrollment" */
export type Reporting_Program_Enrollment_Set_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  program?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "reporting_program_enrollment" */
export type Reporting_Program_Enrollment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Program_Enrollment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Program_Enrollment_Stream_Cursor_Value_Input = {
  account_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['date']>;
  deleted_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  program?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  utm_campaign?: InputMaybe<Scalars['String']>;
  utm_content?: InputMaybe<Scalars['String']>;
  utm_medium?: InputMaybe<Scalars['String']>;
  utm_source?: InputMaybe<Scalars['String']>;
};

export type Reporting_Program_Enrollment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reporting_Program_Enrollment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reporting_Program_Enrollment_Bool_Exp;
};

/** Boolean expression to filter rows from the table "reporting.services". All fields are combined with a logical 'AND'. */
export type Reporting_Services_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Services_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Services_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Services_Bool_Exp>>;
  adjudication_code?: InputMaybe<String_Comparison_Exp>;
  adjudication_message?: InputMaybe<String_Comparison_Exp>;
  adjudication_status?: InputMaybe<String_Comparison_Exp>;
  charged?: InputMaybe<Int_Comparison_Exp>;
  copay?: InputMaybe<Int_Comparison_Exp>;
  cost?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  cumulative?: InputMaybe<Float_Comparison_Exp>;
  deductible?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  drug_id?: InputMaybe<Int_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  fee?: InputMaybe<Int_Comparison_Exp>;
  gross_margin?: InputMaybe<Int_Comparison_Exp>;
  gst?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  insurance_company?: InputMaybe<String_Comparison_Exp>;
  pharmacist?: InputMaybe<String_Comparison_Exp>;
  prescription_id?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  product_expiration_date?: InputMaybe<Date_Comparison_Exp>;
  qst?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Float_Comparison_Exp>;
  rgam_price?: InputMaybe<Int_Comparison_Exp>;
  service_date?: InputMaybe<Date_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** Boolean expression to filter rows from the table "reporting.services_flat". All fields are combined with a logical 'AND'. */
export type Reporting_Services_Flat_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Services_Flat_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Services_Flat_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Services_Flat_Bool_Exp>>;
  charged?: InputMaybe<Int_Comparison_Exp>;
  copay?: InputMaybe<Int_Comparison_Exp>;
  cost?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  cumulative?: InputMaybe<Float_Comparison_Exp>;
  deductible?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  din?: InputMaybe<String_Comparison_Exp>;
  drug_id?: InputMaybe<Int_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  fee?: InputMaybe<Int_Comparison_Exp>;
  gross_margin?: InputMaybe<Int_Comparison_Exp>;
  gst?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  insurance_company?: InputMaybe<String_Comparison_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  patient_id?: InputMaybe<Int_Comparison_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  pharmacist?: InputMaybe<String_Comparison_Exp>;
  planned_arrival_date?: InputMaybe<Date_Comparison_Exp>;
  prescription_id?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  product_expiration_date?: InputMaybe<Date_Comparison_Exp>;
  qst?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Float_Comparison_Exp>;
  rgam_price?: InputMaybe<Int_Comparison_Exp>;
  service_date?: InputMaybe<Date_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** Ordering options when selecting data from "reporting.services_flat". */
export type Reporting_Services_Flat_Order_By = {
  charged?: InputMaybe<Order_By>;
  copay?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cumulative?: InputMaybe<Order_By>;
  deductible?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  din?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  gross_margin?: InputMaybe<Order_By>;
  gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurance_company?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  pharmacist?: InputMaybe<Order_By>;
  planned_arrival_date?: InputMaybe<Order_By>;
  prescription_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_expiration_date?: InputMaybe<Order_By>;
  qst?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  rgam_price?: InputMaybe<Order_By>;
  service_date?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.services_flat" */
export enum Reporting_Services_Flat_Select_Column {
  /** column name */
  Charged = 'charged',
  /** column name */
  Copay = 'copay',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cumulative = 'cumulative',
  /** column name */
  Deductible = 'deductible',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Din = 'din',
  /** column name */
  DrugId = 'drug_id',
  /** column name */
  Duration = 'duration',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Fee = 'fee',
  /** column name */
  GrossMargin = 'gross_margin',
  /** column name */
  Gst = 'gst',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceCompany = 'insurance_company',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  Pharmacist = 'pharmacist',
  /** column name */
  PlannedArrivalDate = 'planned_arrival_date',
  /** column name */
  PrescriptionId = 'prescription_id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductExpirationDate = 'product_expiration_date',
  /** column name */
  Qst = 'qst',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RgamPrice = 'rgam_price',
  /** column name */
  ServiceDate = 'service_date',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Streaming cursor of the table "reporting_services_flat" */
export type Reporting_Services_Flat_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Services_Flat_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Services_Flat_Stream_Cursor_Value_Input = {
  charged?: InputMaybe<Scalars['Int']>;
  copay?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  cumulative?: InputMaybe<Scalars['Float']>;
  deductible?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  din?: InputMaybe<Scalars['String']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<Scalars['Int']>;
  gross_margin?: InputMaybe<Scalars['Int']>;
  gst?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  insurance_company?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['Int']>;
  patient_id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  pharmacist?: InputMaybe<Scalars['String']>;
  planned_arrival_date?: InputMaybe<Scalars['date']>;
  prescription_id?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  product_expiration_date?: InputMaybe<Scalars['date']>;
  qst?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Float']>;
  rgam_price?: InputMaybe<Scalars['Int']>;
  service_date?: InputMaybe<Scalars['date']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** input type for incrementing numeric columns in table "reporting.services" */
export type Reporting_Services_Inc_Input = {
  charged?: InputMaybe<Scalars['Int']>;
  copay?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['Int']>;
  cumulative?: InputMaybe<Scalars['Float']>;
  deductible?: InputMaybe<Scalars['Int']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  fee?: InputMaybe<Scalars['Int']>;
  gross_margin?: InputMaybe<Scalars['Int']>;
  gst?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  qst?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Float']>;
  rgam_price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "reporting.services" */
export type Reporting_Services_Insert_Input = {
  adjudication_code?: InputMaybe<Scalars['String']>;
  adjudication_message?: InputMaybe<Scalars['String']>;
  adjudication_status?: InputMaybe<Scalars['String']>;
  charged?: InputMaybe<Scalars['Int']>;
  copay?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  cumulative?: InputMaybe<Scalars['Float']>;
  deductible?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<Scalars['Int']>;
  gross_margin?: InputMaybe<Scalars['Int']>;
  gst?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  insurance_company?: InputMaybe<Scalars['String']>;
  pharmacist?: InputMaybe<Scalars['String']>;
  prescription_id?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  product_expiration_date?: InputMaybe<Scalars['date']>;
  qst?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Float']>;
  rgam_price?: InputMaybe<Scalars['Int']>;
  service_date?: InputMaybe<Scalars['date']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "reporting.services". */
export type Reporting_Services_Order_By = {
  adjudication_code?: InputMaybe<Order_By>;
  adjudication_message?: InputMaybe<Order_By>;
  adjudication_status?: InputMaybe<Order_By>;
  charged?: InputMaybe<Order_By>;
  copay?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cumulative?: InputMaybe<Order_By>;
  deductible?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  gross_margin?: InputMaybe<Order_By>;
  gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurance_company?: InputMaybe<Order_By>;
  pharmacist?: InputMaybe<Order_By>;
  prescription_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_expiration_date?: InputMaybe<Order_By>;
  qst?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  rgam_price?: InputMaybe<Order_By>;
  service_date?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.services" */
export enum Reporting_Services_Select_Column {
  /** column name */
  AdjudicationCode = 'adjudication_code',
  /** column name */
  AdjudicationMessage = 'adjudication_message',
  /** column name */
  AdjudicationStatus = 'adjudication_status',
  /** column name */
  Charged = 'charged',
  /** column name */
  Copay = 'copay',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cumulative = 'cumulative',
  /** column name */
  Deductible = 'deductible',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DrugId = 'drug_id',
  /** column name */
  Duration = 'duration',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Fee = 'fee',
  /** column name */
  GrossMargin = 'gross_margin',
  /** column name */
  Gst = 'gst',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceCompany = 'insurance_company',
  /** column name */
  Pharmacist = 'pharmacist',
  /** column name */
  PrescriptionId = 'prescription_id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductExpirationDate = 'product_expiration_date',
  /** column name */
  Qst = 'qst',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RgamPrice = 'rgam_price',
  /** column name */
  ServiceDate = 'service_date',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "reporting.services" */
export type Reporting_Services_Set_Input = {
  adjudication_code?: InputMaybe<Scalars['String']>;
  adjudication_message?: InputMaybe<Scalars['String']>;
  adjudication_status?: InputMaybe<Scalars['String']>;
  charged?: InputMaybe<Scalars['Int']>;
  copay?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  cumulative?: InputMaybe<Scalars['Float']>;
  deductible?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<Scalars['Int']>;
  gross_margin?: InputMaybe<Scalars['Int']>;
  gst?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  insurance_company?: InputMaybe<Scalars['String']>;
  pharmacist?: InputMaybe<Scalars['String']>;
  prescription_id?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  product_expiration_date?: InputMaybe<Scalars['date']>;
  qst?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Float']>;
  rgam_price?: InputMaybe<Scalars['Int']>;
  service_date?: InputMaybe<Scalars['date']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "reporting_services" */
export type Reporting_Services_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Services_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Services_Stream_Cursor_Value_Input = {
  adjudication_code?: InputMaybe<Scalars['String']>;
  adjudication_message?: InputMaybe<Scalars['String']>;
  adjudication_status?: InputMaybe<Scalars['String']>;
  charged?: InputMaybe<Scalars['Int']>;
  copay?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  cumulative?: InputMaybe<Scalars['Float']>;
  deductible?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  fee?: InputMaybe<Scalars['Int']>;
  gross_margin?: InputMaybe<Scalars['Int']>;
  gst?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  insurance_company?: InputMaybe<Scalars['String']>;
  pharmacist?: InputMaybe<Scalars['String']>;
  prescription_id?: InputMaybe<Scalars['uuid']>;
  price?: InputMaybe<Scalars['Int']>;
  product_expiration_date?: InputMaybe<Scalars['date']>;
  qst?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Float']>;
  rgam_price?: InputMaybe<Scalars['Int']>;
  service_date?: InputMaybe<Scalars['date']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

export type Reporting_Services_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reporting_Services_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reporting_Services_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reporting_Services_Bool_Exp;
};

/** Boolean expression to filter rows from the table "reporting.shipment_rate". All fields are combined with a logical 'AND'. */
export type Reporting_Shipment_Rate_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Shipment_Rate_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Shipment_Rate_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Shipment_Rate_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  priority?: InputMaybe<Float_Comparison_Exp>;
  rate?: InputMaybe<String_Comparison_Exp>;
  region_code?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "reporting.shipment_rate" */
export type Reporting_Shipment_Rate_Inc_Input = {
  priority?: InputMaybe<Scalars['Float']>;
};

/** input type for inserting data into table "reporting.shipment_rate" */
export type Reporting_Shipment_Rate_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Float']>;
  rate?: InputMaybe<Scalars['String']>;
  region_code?: InputMaybe<Scalars['String']>;
};

/** Ordering options when selecting data from "reporting.shipment_rate". */
export type Reporting_Shipment_Rate_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  region_code?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.shipment_rate" */
export enum Reporting_Shipment_Rate_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Rate = 'rate',
  /** column name */
  RegionCode = 'region_code'
}

/** input type for updating data in table "reporting.shipment_rate" */
export type Reporting_Shipment_Rate_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Float']>;
  rate?: InputMaybe<Scalars['String']>;
  region_code?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "reporting_shipment_rate" */
export type Reporting_Shipment_Rate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Shipment_Rate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Shipment_Rate_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<Scalars['Float']>;
  rate?: InputMaybe<Scalars['String']>;
  region_code?: InputMaybe<Scalars['String']>;
};

export type Reporting_Shipment_Rate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reporting_Shipment_Rate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reporting_Shipment_Rate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reporting_Shipment_Rate_Bool_Exp;
};

/** Boolean expression to filter rows from the table "reporting.shipments". All fields are combined with a logical 'AND'. */
export type Reporting_Shipments_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Shipments_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Shipments_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Shipments_Bool_Exp>>;
  address_id?: InputMaybe<Int_Comparison_Exp>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  height_in_cm?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  length_in_cm?: InputMaybe<Numeric_Comparison_Exp>;
  lost?: InputMaybe<Boolean_Comparison_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  postcode?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<String_Comparison_Exp>;
  rate?: InputMaybe<Int_Comparison_Exp>;
  shipping_date?: InputMaybe<Date_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  weight_in_g?: InputMaybe<Numeric_Comparison_Exp>;
  width_in_cm?: InputMaybe<Numeric_Comparison_Exp>;
};

/** Ordering options when selecting data from "reporting.shipments". */
export type Reporting_Shipments_Order_By = {
  address_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  height_in_cm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  length_in_cm?: InputMaybe<Order_By>;
  lost?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  shipping_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weight_in_g?: InputMaybe<Order_By>;
  width_in_cm?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.shipments" */
export enum Reporting_Shipments_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  HeightInCm = 'height_in_cm',
  /** column name */
  Id = 'id',
  /** column name */
  LengthInCm = 'length_in_cm',
  /** column name */
  Lost = 'lost',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  Price = 'price',
  /** column name */
  Provider = 'provider',
  /** column name */
  Rate = 'rate',
  /** column name */
  ShippingDate = 'shipping_date',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightInG = 'weight_in_g',
  /** column name */
  WidthInCm = 'width_in_cm'
}

/** Streaming cursor of the table "reporting_shipments" */
export type Reporting_Shipments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Shipments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Shipments_Stream_Cursor_Value_Input = {
  address_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  height_in_cm?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  length_in_cm?: InputMaybe<Scalars['numeric']>;
  lost?: InputMaybe<Scalars['Boolean']>;
  order_id?: InputMaybe<Scalars['Int']>;
  postcode?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Int']>;
  shipping_date?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  weight_in_g?: InputMaybe<Scalars['numeric']>;
  width_in_cm?: InputMaybe<Scalars['numeric']>;
};

/** Boolean expression to filter rows from the table "reporting.shipping_delays". All fields are combined with a logical 'AND'. */
export type Reporting_Shipping_Delays_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Shipping_Delays_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Shipping_Delays_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Shipping_Delays_Bool_Exp>>;
  actual_arrival_date?: InputMaybe<Date_Comparison_Exp>;
  carrier?: InputMaybe<String_Comparison_Exp>;
  expected_arrival_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lost?: InputMaybe<Boolean_Comparison_Exp>;
  planned_arrival_date?: InputMaybe<Date_Comparison_Exp>;
  postal_code?: InputMaybe<String_Comparison_Exp>;
  rate?: InputMaybe<String_Comparison_Exp>;
  shipping_date?: InputMaybe<Date_Comparison_Exp>;
};

/** Ordering options when selecting data from "reporting.shipping_delays". */
export type Reporting_Shipping_Delays_Order_By = {
  actual_arrival_date?: InputMaybe<Order_By>;
  carrier?: InputMaybe<Order_By>;
  expected_arrival_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lost?: InputMaybe<Order_By>;
  planned_arrival_date?: InputMaybe<Order_By>;
  postal_code?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  shipping_date?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.shipping_delays" */
export enum Reporting_Shipping_Delays_Select_Column {
  /** column name */
  ActualArrivalDate = 'actual_arrival_date',
  /** column name */
  Carrier = 'carrier',
  /** column name */
  ExpectedArrivalDate = 'expected_arrival_date',
  /** column name */
  Id = 'id',
  /** column name */
  Lost = 'lost',
  /** column name */
  PlannedArrivalDate = 'planned_arrival_date',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  Rate = 'rate',
  /** column name */
  ShippingDate = 'shipping_date'
}

/** Streaming cursor of the table "reporting_shipping_delays" */
export type Reporting_Shipping_Delays_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Shipping_Delays_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Shipping_Delays_Stream_Cursor_Value_Input = {
  actual_arrival_date?: InputMaybe<Scalars['date']>;
  carrier?: InputMaybe<Scalars['String']>;
  expected_arrival_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  lost?: InputMaybe<Scalars['Boolean']>;
  planned_arrival_date?: InputMaybe<Scalars['date']>;
  postal_code?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['String']>;
  shipping_date?: InputMaybe<Scalars['date']>;
};

/** Boolean expression to filter rows from the table "reporting.transaction". All fields are combined with a logical 'AND'. */
export type Reporting_Transaction_Bool_Exp = {
  _and?: InputMaybe<Array<Reporting_Transaction_Bool_Exp>>;
  _not?: InputMaybe<Reporting_Transaction_Bool_Exp>;
  _or?: InputMaybe<Array<Reporting_Transaction_Bool_Exp>>;
  address_city?: InputMaybe<String_Comparison_Exp>;
  address_id?: InputMaybe<Int_Comparison_Exp>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  birthday?: InputMaybe<Date_Comparison_Exp>;
  card_brand?: InputMaybe<String_Comparison_Exp>;
  company?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  error_code?: InputMaybe<String_Comparison_Exp>;
  error_message?: InputMaybe<String_Comparison_Exp>;
  has_insurance?: InputMaybe<Boolean_Comparison_Exp>;
  has_private_insurance?: InputMaybe<Boolean_Comparison_Exp>;
  has_public_insurance?: InputMaybe<Boolean_Comparison_Exp>;
  heard_about?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invoice_amount?: InputMaybe<Int_Comparison_Exp>;
  invoice_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  invoice_deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  invoice_id?: InputMaybe<Int_Comparison_Exp>;
  invoice_status?: InputMaybe<String_Comparison_Exp>;
  invoice_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  last_four_digits?: InputMaybe<String_Comparison_Exp>;
  order_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  order_deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  order_status?: InputMaybe<String_Comparison_Exp>;
  order_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  patient_created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  patient_deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  patient_id?: InputMaybe<Int_Comparison_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  patient_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  payment_method?: InputMaybe<String_Comparison_Exp>;
  paysafe_profile_id?: InputMaybe<String_Comparison_Exp>;
  planned_arrival_date?: InputMaybe<Date_Comparison_Exp>;
  planned_shipment_provider?: InputMaybe<String_Comparison_Exp>;
  postcode?: InputMaybe<String_Comparison_Exp>;
  preferred_language?: InputMaybe<String_Comparison_Exp>;
  refund_reason?: InputMaybe<String_Comparison_Exp>;
  refunded_amount?: InputMaybe<Int_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  save_instrument?: InputMaybe<Boolean_Comparison_Exp>;
  set_instrument_as_default?: InputMaybe<Boolean_Comparison_Exp>;
  total_rx_amount?: InputMaybe<Int_Comparison_Exp>;
  transaction_id?: InputMaybe<String_Comparison_Exp>;
  transaction_status?: InputMaybe<String_Comparison_Exp>;
  transaction_time?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** Ordering options when selecting data from "reporting.transaction". */
export type Reporting_Transaction_Order_By = {
  address_city?: InputMaybe<Order_By>;
  address_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  birthday?: InputMaybe<Order_By>;
  card_brand?: InputMaybe<Order_By>;
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  error_code?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  has_insurance?: InputMaybe<Order_By>;
  has_private_insurance?: InputMaybe<Order_By>;
  has_public_insurance?: InputMaybe<Order_By>;
  heard_about?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_amount?: InputMaybe<Order_By>;
  invoice_created_at?: InputMaybe<Order_By>;
  invoice_deleted_at?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  invoice_status?: InputMaybe<Order_By>;
  invoice_updated_at?: InputMaybe<Order_By>;
  last_four_digits?: InputMaybe<Order_By>;
  order_created_at?: InputMaybe<Order_By>;
  order_deleted_at?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_status?: InputMaybe<Order_By>;
  order_updated_at?: InputMaybe<Order_By>;
  patient_created_at?: InputMaybe<Order_By>;
  patient_deleted_at?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  patient_updated_at?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Order_By>;
  paysafe_profile_id?: InputMaybe<Order_By>;
  planned_arrival_date?: InputMaybe<Order_By>;
  planned_shipment_provider?: InputMaybe<Order_By>;
  postcode?: InputMaybe<Order_By>;
  preferred_language?: InputMaybe<Order_By>;
  refund_reason?: InputMaybe<Order_By>;
  refunded_amount?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  save_instrument?: InputMaybe<Order_By>;
  set_instrument_as_default?: InputMaybe<Order_By>;
  total_rx_amount?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  transaction_status?: InputMaybe<Order_By>;
  transaction_time?: InputMaybe<Order_By>;
};

/** select columns of table "reporting.transaction" */
export enum Reporting_Transaction_Select_Column {
  /** column name */
  AddressCity = 'address_city',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CardBrand = 'card_brand',
  /** column name */
  Company = 'company',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErrorCode = 'error_code',
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  HasInsurance = 'has_insurance',
  /** column name */
  HasPrivateInsurance = 'has_private_insurance',
  /** column name */
  HasPublicInsurance = 'has_public_insurance',
  /** column name */
  HeardAbout = 'heard_about',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceAmount = 'invoice_amount',
  /** column name */
  InvoiceCreatedAt = 'invoice_created_at',
  /** column name */
  InvoiceDeletedAt = 'invoice_deleted_at',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  InvoiceStatus = 'invoice_status',
  /** column name */
  InvoiceUpdatedAt = 'invoice_updated_at',
  /** column name */
  LastFourDigits = 'last_four_digits',
  /** column name */
  OrderCreatedAt = 'order_created_at',
  /** column name */
  OrderDeletedAt = 'order_deleted_at',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderStatus = 'order_status',
  /** column name */
  OrderUpdatedAt = 'order_updated_at',
  /** column name */
  PatientCreatedAt = 'patient_created_at',
  /** column name */
  PatientDeletedAt = 'patient_deleted_at',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PatientUpdatedAt = 'patient_updated_at',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PaysafeProfileId = 'paysafe_profile_id',
  /** column name */
  PlannedArrivalDate = 'planned_arrival_date',
  /** column name */
  PlannedShipmentProvider = 'planned_shipment_provider',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  RefundReason = 'refund_reason',
  /** column name */
  RefundedAmount = 'refunded_amount',
  /** column name */
  Region = 'region',
  /** column name */
  SaveInstrument = 'save_instrument',
  /** column name */
  SetInstrumentAsDefault = 'set_instrument_as_default',
  /** column name */
  TotalRxAmount = 'total_rx_amount',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TransactionStatus = 'transaction_status',
  /** column name */
  TransactionTime = 'transaction_time'
}

/** Streaming cursor of the table "reporting_transaction" */
export type Reporting_Transaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reporting_Transaction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reporting_Transaction_Stream_Cursor_Value_Input = {
  address_city?: InputMaybe<Scalars['String']>;
  address_id?: InputMaybe<Scalars['Int']>;
  amount?: InputMaybe<Scalars['Int']>;
  birthday?: InputMaybe<Scalars['date']>;
  card_brand?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  error_code?: InputMaybe<Scalars['String']>;
  error_message?: InputMaybe<Scalars['String']>;
  has_insurance?: InputMaybe<Scalars['Boolean']>;
  has_private_insurance?: InputMaybe<Scalars['Boolean']>;
  has_public_insurance?: InputMaybe<Scalars['Boolean']>;
  heard_about?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  invoice_amount?: InputMaybe<Scalars['Int']>;
  invoice_created_at?: InputMaybe<Scalars['timestamp']>;
  invoice_deleted_at?: InputMaybe<Scalars['timestamp']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  invoice_status?: InputMaybe<Scalars['String']>;
  invoice_updated_at?: InputMaybe<Scalars['timestamp']>;
  last_four_digits?: InputMaybe<Scalars['String']>;
  order_created_at?: InputMaybe<Scalars['timestamp']>;
  order_deleted_at?: InputMaybe<Scalars['timestamp']>;
  order_id?: InputMaybe<Scalars['Int']>;
  order_status?: InputMaybe<Scalars['String']>;
  order_updated_at?: InputMaybe<Scalars['timestamp']>;
  patient_created_at?: InputMaybe<Scalars['timestamp']>;
  patient_deleted_at?: InputMaybe<Scalars['timestamp']>;
  patient_id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  patient_updated_at?: InputMaybe<Scalars['timestamp']>;
  payment_method?: InputMaybe<Scalars['String']>;
  paysafe_profile_id?: InputMaybe<Scalars['String']>;
  planned_arrival_date?: InputMaybe<Scalars['date']>;
  planned_shipment_provider?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  preferred_language?: InputMaybe<Scalars['String']>;
  refund_reason?: InputMaybe<Scalars['String']>;
  refunded_amount?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  save_instrument?: InputMaybe<Scalars['Boolean']>;
  set_instrument_as_default?: InputMaybe<Scalars['Boolean']>;
  total_rx_amount?: InputMaybe<Scalars['Int']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  transaction_status?: InputMaybe<Scalars['String']>;
  transaction_time?: InputMaybe<Scalars['timestamp']>;
};

export type Search_Acquired_Patient_Args = {
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Drug_Args = {
  prefilter_limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Drug_Equivalence_Args = {
  prefilter_limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Fax_Args = {
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Form_Consultation_And_Follow_Up_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Form_Consultation_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Order_Args = {
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Patient_Args = {
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Price_Quote_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Service_Aggregate_Bool_Exp = {
  count?: InputMaybe<Service_Aggregate_Bool_Exp_Count>;
};

export type Service_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Service_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "service" */
export type Service_Aggregate_Order_By = {
  avg?: InputMaybe<Service_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Service_Max_Order_By>;
  min?: InputMaybe<Service_Min_Order_By>;
  stddev?: InputMaybe<Service_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Service_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Service_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Service_Sum_Order_By>;
  var_pop?: InputMaybe<Service_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Service_Var_Samp_Order_By>;
  variance?: InputMaybe<Service_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service" */
export type Service_Arr_Rel_Insert_Input = {
  data: Array<Service_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Service_On_Conflict>;
};

/** order by avg() on columns of table "service" */
export type Service_Avg_Order_By = {
  /** (cents) */
  charged?: InputMaybe<Order_By>;
  /** (cents) */
  copay?: InputMaybe<Order_By>;
  /** (cents) */
  cost?: InputMaybe<Order_By>;
  cumulative?: InputMaybe<Order_By>;
  /** (cents) */
  deductible?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  /** (cents) */
  fee?: InputMaybe<Order_By>;
  /** (cents) */
  gross_margin?: InputMaybe<Order_By>;
  /** (cents) */
  gst?: InputMaybe<Order_By>;
  /** (cents) */
  price?: InputMaybe<Order_By>;
  /** (cents) */
  qst?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** (cents) */
  rgam_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service". All fields are combined with a logical 'AND'. */
export type Service_Bool_Exp = {
  _and?: InputMaybe<Array<Service_Bool_Exp>>;
  _not?: InputMaybe<Service_Bool_Exp>;
  _or?: InputMaybe<Array<Service_Bool_Exp>>;
  adjudication_code?: InputMaybe<String_Comparison_Exp>;
  adjudication_message?: InputMaybe<String_Comparison_Exp>;
  adjudication_status?: InputMaybe<String_Comparison_Exp>;
  charged?: InputMaybe<Int_Comparison_Exp>;
  copay?: InputMaybe<Int_Comparison_Exp>;
  cost?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  cumulative?: InputMaybe<Float_Comparison_Exp>;
  deductible?: InputMaybe<Int_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  drug?: InputMaybe<Drug_Bool_Exp>;
  drug_id?: InputMaybe<Int_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  fee?: InputMaybe<Int_Comparison_Exp>;
  gross_margin?: InputMaybe<Int_Comparison_Exp>;
  gst?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  incoming_changes?: InputMaybe<Bridge_Incoming_Change_Bool_Exp>;
  incoming_changes_aggregate?: InputMaybe<Bridge_Incoming_Change_Aggregate_Bool_Exp>;
  insurance_company?: InputMaybe<String_Comparison_Exp>;
  invoice_line_items?: InputMaybe<Invoice_Line_Item_Bool_Exp>;
  invoice_line_items_aggregate?: InputMaybe<Invoice_Line_Item_Aggregate_Bool_Exp>;
  pharmacist?: InputMaybe<String_Comparison_Exp>;
  prescription?: InputMaybe<Prescription_Bool_Exp>;
  prescription_id?: InputMaybe<Uuid_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  product_expiration_date?: InputMaybe<Date_Comparison_Exp>;
  qst?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Float_Comparison_Exp>;
  rgam_price?: InputMaybe<Int_Comparison_Exp>;
  service_date?: InputMaybe<Date_Comparison_Exp>;
  service_type?: InputMaybe<Config_Service_Type_Bool_Exp>;
  type?: InputMaybe<Config_Service_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  verified_at?: InputMaybe<Timestamp_Comparison_Exp>;
  verified_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "service" */
export enum Service_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_85a21558c006647cd76fdce044b = 'PK_85a21558c006647cd76fdce044b',
  /** unique or primary key constraint on columns "external_id" */
  Uq_791d87e2bb57fc25190aca54930 = 'UQ_791d87e2bb57fc25190aca54930'
}

/** input type for incrementing numeric columns in table "service" */
export type Service_Inc_Input = {
  /** (cents) */
  charged?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  copay?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  cost?: InputMaybe<Scalars['Int']>;
  cumulative?: InputMaybe<Scalars['Float']>;
  /** (cents) */
  deductible?: InputMaybe<Scalars['Int']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  fee?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  gross_margin?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  gst?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  price?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  qst?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Float']>;
  /** (cents) */
  rgam_price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "service" */
export type Service_Insert_Input = {
  adjudication_code?: InputMaybe<Scalars['String']>;
  adjudication_message?: InputMaybe<Scalars['String']>;
  adjudication_status?: InputMaybe<Scalars['String']>;
  /** (cents) */
  charged?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  copay?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  cost?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  cumulative?: InputMaybe<Scalars['Float']>;
  /** (cents) */
  deductible?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug?: InputMaybe<Drug_Obj_Rel_Insert_Input>;
  drug_id?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  /** (cents) */
  fee?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  gross_margin?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  gst?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  incoming_changes?: InputMaybe<Bridge_Incoming_Change_Arr_Rel_Insert_Input>;
  insurance_company?: InputMaybe<Scalars['String']>;
  invoice_line_items?: InputMaybe<Invoice_Line_Item_Arr_Rel_Insert_Input>;
  pharmacist?: InputMaybe<Scalars['String']>;
  prescription?: InputMaybe<Prescription_Obj_Rel_Insert_Input>;
  prescription_id?: InputMaybe<Scalars['uuid']>;
  /** (cents) */
  price?: InputMaybe<Scalars['Int']>;
  product_expiration_date?: InputMaybe<Scalars['date']>;
  /** (cents) */
  qst?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Float']>;
  /** (cents) */
  rgam_price?: InputMaybe<Scalars['Int']>;
  service_date?: InputMaybe<Scalars['date']>;
  service_type?: InputMaybe<Config_Service_Type_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Config_Service_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  verified_at?: InputMaybe<Scalars['timestamp']>;
  verified_by?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "service" */
export type Service_Max_Order_By = {
  adjudication_code?: InputMaybe<Order_By>;
  adjudication_message?: InputMaybe<Order_By>;
  adjudication_status?: InputMaybe<Order_By>;
  /** (cents) */
  charged?: InputMaybe<Order_By>;
  /** (cents) */
  copay?: InputMaybe<Order_By>;
  /** (cents) */
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cumulative?: InputMaybe<Order_By>;
  /** (cents) */
  deductible?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  /** (cents) */
  fee?: InputMaybe<Order_By>;
  /** (cents) */
  gross_margin?: InputMaybe<Order_By>;
  /** (cents) */
  gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurance_company?: InputMaybe<Order_By>;
  pharmacist?: InputMaybe<Order_By>;
  prescription_id?: InputMaybe<Order_By>;
  /** (cents) */
  price?: InputMaybe<Order_By>;
  product_expiration_date?: InputMaybe<Order_By>;
  /** (cents) */
  qst?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** (cents) */
  rgam_price?: InputMaybe<Order_By>;
  service_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  verified_at?: InputMaybe<Order_By>;
  verified_by?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "service" */
export type Service_Min_Order_By = {
  adjudication_code?: InputMaybe<Order_By>;
  adjudication_message?: InputMaybe<Order_By>;
  adjudication_status?: InputMaybe<Order_By>;
  /** (cents) */
  charged?: InputMaybe<Order_By>;
  /** (cents) */
  copay?: InputMaybe<Order_By>;
  /** (cents) */
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cumulative?: InputMaybe<Order_By>;
  /** (cents) */
  deductible?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  /** (cents) */
  fee?: InputMaybe<Order_By>;
  /** (cents) */
  gross_margin?: InputMaybe<Order_By>;
  /** (cents) */
  gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insurance_company?: InputMaybe<Order_By>;
  pharmacist?: InputMaybe<Order_By>;
  prescription_id?: InputMaybe<Order_By>;
  /** (cents) */
  price?: InputMaybe<Order_By>;
  product_expiration_date?: InputMaybe<Order_By>;
  /** (cents) */
  qst?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** (cents) */
  rgam_price?: InputMaybe<Order_By>;
  service_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  verified_at?: InputMaybe<Order_By>;
  verified_by?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "service" */
export type Service_Obj_Rel_Insert_Input = {
  data: Service_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Service_On_Conflict>;
};

/** on_conflict condition type for table "service" */
export type Service_On_Conflict = {
  constraint: Service_Constraint;
  update_columns?: Array<Service_Update_Column>;
  where?: InputMaybe<Service_Bool_Exp>;
};

/** Ordering options when selecting data from "service". */
export type Service_Order_By = {
  adjudication_code?: InputMaybe<Order_By>;
  adjudication_message?: InputMaybe<Order_By>;
  adjudication_status?: InputMaybe<Order_By>;
  charged?: InputMaybe<Order_By>;
  copay?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cumulative?: InputMaybe<Order_By>;
  deductible?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drug?: InputMaybe<Drug_Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  fee?: InputMaybe<Order_By>;
  gross_margin?: InputMaybe<Order_By>;
  gst?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incoming_changes_aggregate?: InputMaybe<Bridge_Incoming_Change_Aggregate_Order_By>;
  insurance_company?: InputMaybe<Order_By>;
  invoice_line_items_aggregate?: InputMaybe<Invoice_Line_Item_Aggregate_Order_By>;
  pharmacist?: InputMaybe<Order_By>;
  prescription?: InputMaybe<Prescription_Order_By>;
  prescription_id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_expiration_date?: InputMaybe<Order_By>;
  qst?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  rgam_price?: InputMaybe<Order_By>;
  service_date?: InputMaybe<Order_By>;
  service_type?: InputMaybe<Config_Service_Type_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  verified_at?: InputMaybe<Order_By>;
  verified_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service */
export type Service_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

export type Service_Request_Aggregate_Bool_Exp = {
  count?: InputMaybe<Service_Request_Aggregate_Bool_Exp_Count>;
};

export type Service_Request_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Service_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Request_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "service_request" */
export type Service_Request_Aggregate_Order_By = {
  avg?: InputMaybe<Service_Request_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Service_Request_Max_Order_By>;
  min?: InputMaybe<Service_Request_Min_Order_By>;
  stddev?: InputMaybe<Service_Request_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Service_Request_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Service_Request_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Service_Request_Sum_Order_By>;
  var_pop?: InputMaybe<Service_Request_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Service_Request_Var_Samp_Order_By>;
  variance?: InputMaybe<Service_Request_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_request" */
export type Service_Request_Arr_Rel_Insert_Input = {
  data: Array<Service_Request_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Service_Request_On_Conflict>;
};

/** order by avg() on columns of table "service_request" */
export type Service_Request_Avg_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_request". All fields are combined with a logical 'AND'. */
export type Service_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Service_Request_Bool_Exp>>;
  _not?: InputMaybe<Service_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Service_Request_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  drug?: InputMaybe<Drug_Bool_Exp>;
  drug_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  medication?: InputMaybe<Medication_Bool_Exp>;
  medication_id?: InputMaybe<Int_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  service_request_status?: InputMaybe<Config_Service_Request_Status_Bool_Exp>;
  status?: InputMaybe<Config_Service_Request_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_request" */
export enum Service_Request_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_08446fa58294cb2dd0b6ff9e5a7 = 'PK_08446fa58294cb2dd0b6ff9e5a7'
}

/** input type for incrementing numeric columns in table "service_request" */
export type Service_Request_Inc_Input = {
  drug_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  medication_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "service_request" */
export type Service_Request_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug?: InputMaybe<Drug_Obj_Rel_Insert_Input>;
  drug_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  medication?: InputMaybe<Medication_Obj_Rel_Insert_Input>;
  medication_id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  service_request_status?: InputMaybe<Config_Service_Request_Status_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Config_Service_Request_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "service_request" */
export type Service_Request_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "service_request" */
export type Service_Request_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "service_request" */
export type Service_Request_On_Conflict = {
  constraint: Service_Request_Constraint;
  update_columns?: Array<Service_Request_Update_Column>;
  where?: InputMaybe<Service_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "service_request". */
export type Service_Request_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  drug?: InputMaybe<Drug_Order_By>;
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication?: InputMaybe<Medication_Order_By>;
  medication_id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  service_request_status?: InputMaybe<Config_Service_Request_Status_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_request */
export type Service_Request_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "service_request" */
export enum Service_Request_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DrugId = 'drug_id',
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_request" */
export type Service_Request_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Service_Request_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "service_request" */
export type Service_Request_Stddev_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "service_request" */
export type Service_Request_Stddev_Pop_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "service_request" */
export type Service_Request_Stddev_Samp_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "service_request" */
export type Service_Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Request_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  medication_id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Service_Request_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "service_request" */
export type Service_Request_Sum_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
};

/** update columns of table "service_request" */
export enum Service_Request_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DrugId = 'drug_id',
  /** column name */
  Id = 'id',
  /** column name */
  MedicationId = 'medication_id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Service_Request_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Service_Request_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Service_Request_Set_Input>;
  /** filter the rows which have to be updated */
  where: Service_Request_Bool_Exp;
};

/** order by var_pop() on columns of table "service_request" */
export type Service_Request_Var_Pop_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "service_request" */
export type Service_Request_Var_Samp_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "service_request" */
export type Service_Request_Variance_Order_By = {
  drug_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  medication_id?: InputMaybe<Order_By>;
};

/** select columns of table "service" */
export enum Service_Select_Column {
  /** column name */
  AdjudicationCode = 'adjudication_code',
  /** column name */
  AdjudicationMessage = 'adjudication_message',
  /** column name */
  AdjudicationStatus = 'adjudication_status',
  /** column name */
  Charged = 'charged',
  /** column name */
  Copay = 'copay',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cumulative = 'cumulative',
  /** column name */
  Deductible = 'deductible',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DrugId = 'drug_id',
  /** column name */
  Duration = 'duration',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Fee = 'fee',
  /** column name */
  GrossMargin = 'gross_margin',
  /** column name */
  Gst = 'gst',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceCompany = 'insurance_company',
  /** column name */
  Pharmacist = 'pharmacist',
  /** column name */
  PrescriptionId = 'prescription_id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductExpirationDate = 'product_expiration_date',
  /** column name */
  Qst = 'qst',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RgamPrice = 'rgam_price',
  /** column name */
  ServiceDate = 'service_date',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerifiedAt = 'verified_at',
  /** column name */
  VerifiedBy = 'verified_by'
}

/** input type for updating data in table "service" */
export type Service_Set_Input = {
  adjudication_code?: InputMaybe<Scalars['String']>;
  adjudication_message?: InputMaybe<Scalars['String']>;
  adjudication_status?: InputMaybe<Scalars['String']>;
  /** (cents) */
  charged?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  copay?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  cost?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  cumulative?: InputMaybe<Scalars['Float']>;
  /** (cents) */
  deductible?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  /** (cents) */
  fee?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  gross_margin?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  gst?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  insurance_company?: InputMaybe<Scalars['String']>;
  pharmacist?: InputMaybe<Scalars['String']>;
  prescription_id?: InputMaybe<Scalars['uuid']>;
  /** (cents) */
  price?: InputMaybe<Scalars['Int']>;
  product_expiration_date?: InputMaybe<Scalars['date']>;
  /** (cents) */
  qst?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Float']>;
  /** (cents) */
  rgam_price?: InputMaybe<Scalars['Int']>;
  service_date?: InputMaybe<Scalars['date']>;
  type?: InputMaybe<Config_Service_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  verified_at?: InputMaybe<Scalars['timestamp']>;
  verified_by?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "service" */
export type Service_Stddev_Order_By = {
  /** (cents) */
  charged?: InputMaybe<Order_By>;
  /** (cents) */
  copay?: InputMaybe<Order_By>;
  /** (cents) */
  cost?: InputMaybe<Order_By>;
  cumulative?: InputMaybe<Order_By>;
  /** (cents) */
  deductible?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  /** (cents) */
  fee?: InputMaybe<Order_By>;
  /** (cents) */
  gross_margin?: InputMaybe<Order_By>;
  /** (cents) */
  gst?: InputMaybe<Order_By>;
  /** (cents) */
  price?: InputMaybe<Order_By>;
  /** (cents) */
  qst?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** (cents) */
  rgam_price?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "service" */
export type Service_Stddev_Pop_Order_By = {
  /** (cents) */
  charged?: InputMaybe<Order_By>;
  /** (cents) */
  copay?: InputMaybe<Order_By>;
  /** (cents) */
  cost?: InputMaybe<Order_By>;
  cumulative?: InputMaybe<Order_By>;
  /** (cents) */
  deductible?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  /** (cents) */
  fee?: InputMaybe<Order_By>;
  /** (cents) */
  gross_margin?: InputMaybe<Order_By>;
  /** (cents) */
  gst?: InputMaybe<Order_By>;
  /** (cents) */
  price?: InputMaybe<Order_By>;
  /** (cents) */
  qst?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** (cents) */
  rgam_price?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "service" */
export type Service_Stddev_Samp_Order_By = {
  /** (cents) */
  charged?: InputMaybe<Order_By>;
  /** (cents) */
  copay?: InputMaybe<Order_By>;
  /** (cents) */
  cost?: InputMaybe<Order_By>;
  cumulative?: InputMaybe<Order_By>;
  /** (cents) */
  deductible?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  /** (cents) */
  fee?: InputMaybe<Order_By>;
  /** (cents) */
  gross_margin?: InputMaybe<Order_By>;
  /** (cents) */
  gst?: InputMaybe<Order_By>;
  /** (cents) */
  price?: InputMaybe<Order_By>;
  /** (cents) */
  qst?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** (cents) */
  rgam_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "service" */
export type Service_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Stream_Cursor_Value_Input = {
  adjudication_code?: InputMaybe<Scalars['String']>;
  adjudication_message?: InputMaybe<Scalars['String']>;
  adjudication_status?: InputMaybe<Scalars['String']>;
  /** (cents) */
  charged?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  copay?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  cost?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  cumulative?: InputMaybe<Scalars['Float']>;
  /** (cents) */
  deductible?: InputMaybe<Scalars['Int']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  drug_id?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  /** (cents) */
  fee?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  gross_margin?: InputMaybe<Scalars['Int']>;
  /** (cents) */
  gst?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  insurance_company?: InputMaybe<Scalars['String']>;
  pharmacist?: InputMaybe<Scalars['String']>;
  prescription_id?: InputMaybe<Scalars['uuid']>;
  /** (cents) */
  price?: InputMaybe<Scalars['Int']>;
  product_expiration_date?: InputMaybe<Scalars['date']>;
  /** (cents) */
  qst?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Float']>;
  /** (cents) */
  rgam_price?: InputMaybe<Scalars['Int']>;
  service_date?: InputMaybe<Scalars['date']>;
  type?: InputMaybe<Config_Service_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  verified_at?: InputMaybe<Scalars['timestamp']>;
  verified_by?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "service" */
export type Service_Sum_Order_By = {
  /** (cents) */
  charged?: InputMaybe<Order_By>;
  /** (cents) */
  copay?: InputMaybe<Order_By>;
  /** (cents) */
  cost?: InputMaybe<Order_By>;
  cumulative?: InputMaybe<Order_By>;
  /** (cents) */
  deductible?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  /** (cents) */
  fee?: InputMaybe<Order_By>;
  /** (cents) */
  gross_margin?: InputMaybe<Order_By>;
  /** (cents) */
  gst?: InputMaybe<Order_By>;
  /** (cents) */
  price?: InputMaybe<Order_By>;
  /** (cents) */
  qst?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** (cents) */
  rgam_price?: InputMaybe<Order_By>;
};

/** update columns of table "service" */
export enum Service_Update_Column {
  /** column name */
  AdjudicationCode = 'adjudication_code',
  /** column name */
  AdjudicationMessage = 'adjudication_message',
  /** column name */
  AdjudicationStatus = 'adjudication_status',
  /** column name */
  Charged = 'charged',
  /** column name */
  Copay = 'copay',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Cumulative = 'cumulative',
  /** column name */
  Deductible = 'deductible',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DrugId = 'drug_id',
  /** column name */
  Duration = 'duration',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Fee = 'fee',
  /** column name */
  GrossMargin = 'gross_margin',
  /** column name */
  Gst = 'gst',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceCompany = 'insurance_company',
  /** column name */
  Pharmacist = 'pharmacist',
  /** column name */
  PrescriptionId = 'prescription_id',
  /** column name */
  Price = 'price',
  /** column name */
  ProductExpirationDate = 'product_expiration_date',
  /** column name */
  Qst = 'qst',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RgamPrice = 'rgam_price',
  /** column name */
  ServiceDate = 'service_date',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerifiedAt = 'verified_at',
  /** column name */
  VerifiedBy = 'verified_by'
}

export type Service_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Service_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Service_Set_Input>;
  /** filter the rows which have to be updated */
  where: Service_Bool_Exp;
};

/** order by var_pop() on columns of table "service" */
export type Service_Var_Pop_Order_By = {
  /** (cents) */
  charged?: InputMaybe<Order_By>;
  /** (cents) */
  copay?: InputMaybe<Order_By>;
  /** (cents) */
  cost?: InputMaybe<Order_By>;
  cumulative?: InputMaybe<Order_By>;
  /** (cents) */
  deductible?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  /** (cents) */
  fee?: InputMaybe<Order_By>;
  /** (cents) */
  gross_margin?: InputMaybe<Order_By>;
  /** (cents) */
  gst?: InputMaybe<Order_By>;
  /** (cents) */
  price?: InputMaybe<Order_By>;
  /** (cents) */
  qst?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** (cents) */
  rgam_price?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "service" */
export type Service_Var_Samp_Order_By = {
  /** (cents) */
  charged?: InputMaybe<Order_By>;
  /** (cents) */
  copay?: InputMaybe<Order_By>;
  /** (cents) */
  cost?: InputMaybe<Order_By>;
  cumulative?: InputMaybe<Order_By>;
  /** (cents) */
  deductible?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  /** (cents) */
  fee?: InputMaybe<Order_By>;
  /** (cents) */
  gross_margin?: InputMaybe<Order_By>;
  /** (cents) */
  gst?: InputMaybe<Order_By>;
  /** (cents) */
  price?: InputMaybe<Order_By>;
  /** (cents) */
  qst?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** (cents) */
  rgam_price?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "service" */
export type Service_Variance_Order_By = {
  /** (cents) */
  charged?: InputMaybe<Order_By>;
  /** (cents) */
  copay?: InputMaybe<Order_By>;
  /** (cents) */
  cost?: InputMaybe<Order_By>;
  cumulative?: InputMaybe<Order_By>;
  /** (cents) */
  deductible?: InputMaybe<Order_By>;
  drug_id?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  /** (cents) */
  fee?: InputMaybe<Order_By>;
  /** (cents) */
  gross_margin?: InputMaybe<Order_By>;
  /** (cents) */
  gst?: InputMaybe<Order_By>;
  /** (cents) */
  price?: InputMaybe<Order_By>;
  /** (cents) */
  qst?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  /** (cents) */
  rgam_price?: InputMaybe<Order_By>;
};

export type Shipment_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Shipment_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Shipment_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Shipment_Aggregate_Bool_Exp_Count>;
};

export type Shipment_Aggregate_Bool_Exp_Bool_And = {
  arguments: Shipment_Select_Column_Shipment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Shipment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Shipment_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Shipment_Select_Column_Shipment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Shipment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Shipment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Shipment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Shipment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "shipment" */
export type Shipment_Aggregate_Order_By = {
  avg?: InputMaybe<Shipment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Shipment_Max_Order_By>;
  min?: InputMaybe<Shipment_Min_Order_By>;
  stddev?: InputMaybe<Shipment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Shipment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Shipment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Shipment_Sum_Order_By>;
  var_pop?: InputMaybe<Shipment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Shipment_Var_Samp_Order_By>;
  variance?: InputMaybe<Shipment_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shipment_Append_Input = {
  provider_data?: InputMaybe<Scalars['jsonb']>;
  provider_status_updates?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "shipment" */
export type Shipment_Arr_Rel_Insert_Input = {
  data: Array<Shipment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Shipment_On_Conflict>;
};

/** order by avg() on columns of table "shipment" */
export type Shipment_Avg_Order_By = {
  height_in_cm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  length_in_cm?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  rate?: InputMaybe<Order_By>;
  weight_in_g?: InputMaybe<Order_By>;
  width_in_cm?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "shipment". All fields are combined with a logical 'AND'. */
export type Shipment_Bool_Exp = {
  _and?: InputMaybe<Array<Shipment_Bool_Exp>>;
  _not?: InputMaybe<Shipment_Bool_Exp>;
  _or?: InputMaybe<Array<Shipment_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  height_in_cm?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  label_printed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  label_printed_by?: InputMaybe<Admin_Bool_Exp>;
  label_printed_by_uid?: InputMaybe<String_Comparison_Exp>;
  length_in_cm?: InputMaybe<Numeric_Comparison_Exp>;
  locker_reservation?: InputMaybe<Locker_Reservation_Bool_Exp>;
  locker_reservation_id?: InputMaybe<Uuid_Comparison_Exp>;
  lost?: InputMaybe<Boolean_Comparison_Exp>;
  manifest_id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Order_Bool_Exp>;
  order_id?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<Config_Shipment_Provider_Enum_Comparison_Exp>;
  provider_data?: InputMaybe<Jsonb_Comparison_Exp>;
  provider_status_updates?: InputMaybe<Jsonb_Comparison_Exp>;
  rate?: InputMaybe<Int_Comparison_Exp>;
  rate_v2_id?: InputMaybe<Uuid_Comparison_Exp>;
  shipment_carrier_changes?: InputMaybe<Shipment_Carrier_Change_Bool_Exp>;
  shipment_carrier_changes_aggregate?: InputMaybe<Shipment_Carrier_Change_Aggregate_Bool_Exp>;
  shipment_provider?: InputMaybe<Config_Shipment_Provider_Bool_Exp>;
  shipment_rate?: InputMaybe<Shipment_Rate_Bool_Exp>;
  shipment_rate_code?: InputMaybe<String_Comparison_Exp>;
  shipment_rate_v2?: InputMaybe<Config_Region_Shipment_Rate_Bool_Exp>;
  shipment_rate_v3?: InputMaybe<Config_Shipment_Rate_Bool_Exp>;
  shipment_status?: InputMaybe<Config_Shipment_Status_Bool_Exp>;
  shipment_status_updates?: InputMaybe<Shipment_Status_Update_Bool_Exp>;
  shipment_status_updates_aggregate?: InputMaybe<Shipment_Status_Update_Aggregate_Bool_Exp>;
  shipping_date?: InputMaybe<Date_Comparison_Exp>;
  shipping_manifest?: InputMaybe<Shipping_Manifest_Bool_Exp>;
  status?: InputMaybe<Config_Shipment_Status_Enum_Comparison_Exp>;
  tracking_number?: InputMaybe<String_Comparison_Exp>;
  tracking_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  weight_in_g?: InputMaybe<Numeric_Comparison_Exp>;
  width_in_cm?: InputMaybe<Numeric_Comparison_Exp>;
};

export type Shipment_Carrier_Change_Aggregate_Bool_Exp = {
  count?: InputMaybe<Shipment_Carrier_Change_Aggregate_Bool_Exp_Count>;
};

export type Shipment_Carrier_Change_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Shipment_Carrier_Change_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Shipment_Carrier_Change_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Aggregate_Order_By = {
  avg?: InputMaybe<Shipment_Carrier_Change_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Shipment_Carrier_Change_Max_Order_By>;
  min?: InputMaybe<Shipment_Carrier_Change_Min_Order_By>;
  stddev?: InputMaybe<Shipment_Carrier_Change_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Shipment_Carrier_Change_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Shipment_Carrier_Change_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Shipment_Carrier_Change_Sum_Order_By>;
  var_pop?: InputMaybe<Shipment_Carrier_Change_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Shipment_Carrier_Change_Var_Samp_Order_By>;
  variance?: InputMaybe<Shipment_Carrier_Change_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Arr_Rel_Insert_Input = {
  data: Array<Shipment_Carrier_Change_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Shipment_Carrier_Change_On_Conflict>;
};

/** order by avg() on columns of table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Avg_Order_By = {
  shipment_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "shipment_carrier_change". All fields are combined with a logical 'AND'. */
export type Shipment_Carrier_Change_Bool_Exp = {
  _and?: InputMaybe<Array<Shipment_Carrier_Change_Bool_Exp>>;
  _not?: InputMaybe<Shipment_Carrier_Change_Bool_Exp>;
  _or?: InputMaybe<Array<Shipment_Carrier_Change_Bool_Exp>>;
  admin?: InputMaybe<Admin_Bool_Exp>;
  admin_uid?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  planned_arrival_date?: InputMaybe<Date_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  selected_rate?: InputMaybe<Config_Shipment_Rate_Bool_Exp>;
  selected_rate_code?: InputMaybe<String_Comparison_Exp>;
  selected_rate_id?: InputMaybe<Uuid_Comparison_Exp>;
  shipment?: InputMaybe<Shipment_Bool_Exp>;
  shipment_id?: InputMaybe<Int_Comparison_Exp>;
  suggested_rate?: InputMaybe<Config_Shipment_Rate_Bool_Exp>;
  suggested_rate_code?: InputMaybe<String_Comparison_Exp>;
  suggested_rate_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "shipment_carrier_change" */
export enum Shipment_Carrier_Change_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShipmentCarrierChangePkey = 'shipment_carrier_change_pkey'
}

/** input type for incrementing numeric columns in table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Inc_Input = {
  shipment_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Insert_Input = {
  admin?: InputMaybe<Admin_Obj_Rel_Insert_Input>;
  admin_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  planned_arrival_date?: InputMaybe<Scalars['date']>;
  reason?: InputMaybe<Scalars['String']>;
  selected_rate?: InputMaybe<Config_Shipment_Rate_Obj_Rel_Insert_Input>;
  selected_rate_code?: InputMaybe<Scalars['String']>;
  /** DEPRECATED (2023/10) */
  selected_rate_id?: InputMaybe<Scalars['uuid']>;
  shipment?: InputMaybe<Shipment_Obj_Rel_Insert_Input>;
  shipment_id?: InputMaybe<Scalars['Int']>;
  suggested_rate?: InputMaybe<Config_Shipment_Rate_Obj_Rel_Insert_Input>;
  suggested_rate_code?: InputMaybe<Scalars['String']>;
  /** DEPRECATED (2023/10) */
  suggested_rate_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Max_Order_By = {
  admin_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  planned_arrival_date?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  selected_rate_code?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  selected_rate_id?: InputMaybe<Order_By>;
  shipment_id?: InputMaybe<Order_By>;
  suggested_rate_code?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  suggested_rate_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Min_Order_By = {
  admin_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  planned_arrival_date?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  selected_rate_code?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  selected_rate_id?: InputMaybe<Order_By>;
  shipment_id?: InputMaybe<Order_By>;
  suggested_rate_code?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  suggested_rate_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "shipment_carrier_change" */
export type Shipment_Carrier_Change_On_Conflict = {
  constraint: Shipment_Carrier_Change_Constraint;
  update_columns?: Array<Shipment_Carrier_Change_Update_Column>;
  where?: InputMaybe<Shipment_Carrier_Change_Bool_Exp>;
};

/** Ordering options when selecting data from "shipment_carrier_change". */
export type Shipment_Carrier_Change_Order_By = {
  admin?: InputMaybe<Admin_Order_By>;
  admin_uid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  planned_arrival_date?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  selected_rate?: InputMaybe<Config_Shipment_Rate_Order_By>;
  selected_rate_code?: InputMaybe<Order_By>;
  selected_rate_id?: InputMaybe<Order_By>;
  shipment?: InputMaybe<Shipment_Order_By>;
  shipment_id?: InputMaybe<Order_By>;
  suggested_rate?: InputMaybe<Config_Shipment_Rate_Order_By>;
  suggested_rate_code?: InputMaybe<Order_By>;
  suggested_rate_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shipment_carrier_change */
export type Shipment_Carrier_Change_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "shipment_carrier_change" */
export enum Shipment_Carrier_Change_Select_Column {
  /** column name */
  AdminUid = 'admin_uid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PlannedArrivalDate = 'planned_arrival_date',
  /** column name */
  Reason = 'reason',
  /** column name */
  SelectedRateCode = 'selected_rate_code',
  /** column name */
  SelectedRateId = 'selected_rate_id',
  /** column name */
  ShipmentId = 'shipment_id',
  /** column name */
  SuggestedRateCode = 'suggested_rate_code',
  /** column name */
  SuggestedRateId = 'suggested_rate_id'
}

/** input type for updating data in table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Set_Input = {
  admin_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  planned_arrival_date?: InputMaybe<Scalars['date']>;
  reason?: InputMaybe<Scalars['String']>;
  selected_rate_code?: InputMaybe<Scalars['String']>;
  /** DEPRECATED (2023/10) */
  selected_rate_id?: InputMaybe<Scalars['uuid']>;
  shipment_id?: InputMaybe<Scalars['Int']>;
  suggested_rate_code?: InputMaybe<Scalars['String']>;
  /** DEPRECATED (2023/10) */
  suggested_rate_id?: InputMaybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Stddev_Order_By = {
  shipment_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Stddev_Pop_Order_By = {
  shipment_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Stddev_Samp_Order_By = {
  shipment_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shipment_Carrier_Change_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shipment_Carrier_Change_Stream_Cursor_Value_Input = {
  admin_uid?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  planned_arrival_date?: InputMaybe<Scalars['date']>;
  reason?: InputMaybe<Scalars['String']>;
  selected_rate_code?: InputMaybe<Scalars['String']>;
  /** DEPRECATED (2023/10) */
  selected_rate_id?: InputMaybe<Scalars['uuid']>;
  shipment_id?: InputMaybe<Scalars['Int']>;
  suggested_rate_code?: InputMaybe<Scalars['String']>;
  /** DEPRECATED (2023/10) */
  suggested_rate_id?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Sum_Order_By = {
  shipment_id?: InputMaybe<Order_By>;
};

/** update columns of table "shipment_carrier_change" */
export enum Shipment_Carrier_Change_Update_Column {
  /** column name */
  AdminUid = 'admin_uid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PlannedArrivalDate = 'planned_arrival_date',
  /** column name */
  Reason = 'reason',
  /** column name */
  SelectedRateCode = 'selected_rate_code',
  /** column name */
  SelectedRateId = 'selected_rate_id',
  /** column name */
  ShipmentId = 'shipment_id',
  /** column name */
  SuggestedRateCode = 'suggested_rate_code',
  /** column name */
  SuggestedRateId = 'suggested_rate_id'
}

export type Shipment_Carrier_Change_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Shipment_Carrier_Change_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shipment_Carrier_Change_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shipment_Carrier_Change_Bool_Exp;
};

/** order by var_pop() on columns of table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Var_Pop_Order_By = {
  shipment_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Var_Samp_Order_By = {
  shipment_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "shipment_carrier_change" */
export type Shipment_Carrier_Change_Variance_Order_By = {
  shipment_id?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "shipment" */
export enum Shipment_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkF51f635db95c534ca206bf7a0a4 = 'PK_f51f635db95c534ca206bf7a0a4'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shipment_Delete_At_Path_Input = {
  provider_data?: InputMaybe<Array<Scalars['String']>>;
  provider_status_updates?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shipment_Delete_Elem_Input = {
  provider_data?: InputMaybe<Scalars['Int']>;
  provider_status_updates?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shipment_Delete_Key_Input = {
  provider_data?: InputMaybe<Scalars['String']>;
  provider_status_updates?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "shipment" */
export type Shipment_Inc_Input = {
  height_in_cm?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  length_in_cm?: InputMaybe<Scalars['numeric']>;
  manifest_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  /** DEPRECATED (2023/10) */
  rate?: InputMaybe<Scalars['Int']>;
  weight_in_g?: InputMaybe<Scalars['numeric']>;
  width_in_cm?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "shipment" */
export type Shipment_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  height_in_cm?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  label_printed_at?: InputMaybe<Scalars['timestamp']>;
  label_printed_by?: InputMaybe<Admin_Obj_Rel_Insert_Input>;
  label_printed_by_uid?: InputMaybe<Scalars['String']>;
  length_in_cm?: InputMaybe<Scalars['numeric']>;
  locker_reservation?: InputMaybe<Locker_Reservation_Obj_Rel_Insert_Input>;
  locker_reservation_id?: InputMaybe<Scalars['uuid']>;
  lost?: InputMaybe<Scalars['Boolean']>;
  manifest_id?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Order_Obj_Rel_Insert_Input>;
  order_id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Config_Shipment_Provider_Enum>;
  provider_data?: InputMaybe<Scalars['jsonb']>;
  provider_status_updates?: InputMaybe<Scalars['jsonb']>;
  /** DEPRECATED (2023/10) */
  rate?: InputMaybe<Scalars['Int']>;
  /** DEPRECATED (2023/10) */
  rate_v2_id?: InputMaybe<Scalars['uuid']>;
  shipment_carrier_changes?: InputMaybe<Shipment_Carrier_Change_Arr_Rel_Insert_Input>;
  shipment_provider?: InputMaybe<Config_Shipment_Provider_Obj_Rel_Insert_Input>;
  shipment_rate?: InputMaybe<Shipment_Rate_Obj_Rel_Insert_Input>;
  shipment_rate_code?: InputMaybe<Scalars['String']>;
  shipment_rate_v2?: InputMaybe<Config_Region_Shipment_Rate_Obj_Rel_Insert_Input>;
  shipment_rate_v3?: InputMaybe<Config_Shipment_Rate_Obj_Rel_Insert_Input>;
  shipment_status?: InputMaybe<Config_Shipment_Status_Obj_Rel_Insert_Input>;
  shipment_status_updates?: InputMaybe<Shipment_Status_Update_Arr_Rel_Insert_Input>;
  shipping_date?: InputMaybe<Scalars['date']>;
  shipping_manifest?: InputMaybe<Shipping_Manifest_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Config_Shipment_Status_Enum>;
  tracking_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  weight_in_g?: InputMaybe<Scalars['numeric']>;
  width_in_cm?: InputMaybe<Scalars['numeric']>;
};

/** order by max() on columns of table "shipment" */
export type Shipment_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  height_in_cm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label_printed_at?: InputMaybe<Order_By>;
  label_printed_by_uid?: InputMaybe<Order_By>;
  length_in_cm?: InputMaybe<Order_By>;
  locker_reservation_id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  rate?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  rate_v2_id?: InputMaybe<Order_By>;
  shipment_rate_code?: InputMaybe<Order_By>;
  shipping_date?: InputMaybe<Order_By>;
  tracking_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weight_in_g?: InputMaybe<Order_By>;
  width_in_cm?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "shipment" */
export type Shipment_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  height_in_cm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label_printed_at?: InputMaybe<Order_By>;
  label_printed_by_uid?: InputMaybe<Order_By>;
  length_in_cm?: InputMaybe<Order_By>;
  locker_reservation_id?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  rate?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  rate_v2_id?: InputMaybe<Order_By>;
  shipment_rate_code?: InputMaybe<Order_By>;
  shipping_date?: InputMaybe<Order_By>;
  tracking_number?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weight_in_g?: InputMaybe<Order_By>;
  width_in_cm?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "shipment" */
export type Shipment_Obj_Rel_Insert_Input = {
  data: Shipment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Shipment_On_Conflict>;
};

/** on_conflict condition type for table "shipment" */
export type Shipment_On_Conflict = {
  constraint: Shipment_Constraint;
  update_columns?: Array<Shipment_Update_Column>;
  where?: InputMaybe<Shipment_Bool_Exp>;
};

/** Ordering options when selecting data from "shipment". */
export type Shipment_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  height_in_cm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  label_printed_at?: InputMaybe<Order_By>;
  label_printed_by?: InputMaybe<Admin_Order_By>;
  label_printed_by_uid?: InputMaybe<Order_By>;
  length_in_cm?: InputMaybe<Order_By>;
  locker_reservation?: InputMaybe<Locker_Reservation_Order_By>;
  locker_reservation_id?: InputMaybe<Order_By>;
  lost?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_Order_By>;
  order_id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  provider_data?: InputMaybe<Order_By>;
  provider_status_updates?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  rate_v2_id?: InputMaybe<Order_By>;
  shipment_carrier_changes_aggregate?: InputMaybe<Shipment_Carrier_Change_Aggregate_Order_By>;
  shipment_provider?: InputMaybe<Config_Shipment_Provider_Order_By>;
  shipment_rate?: InputMaybe<Shipment_Rate_Order_By>;
  shipment_rate_code?: InputMaybe<Order_By>;
  shipment_rate_v2?: InputMaybe<Config_Region_Shipment_Rate_Order_By>;
  shipment_rate_v3?: InputMaybe<Config_Shipment_Rate_Order_By>;
  shipment_status?: InputMaybe<Config_Shipment_Status_Order_By>;
  shipment_status_updates_aggregate?: InputMaybe<Shipment_Status_Update_Aggregate_Order_By>;
  shipping_date?: InputMaybe<Order_By>;
  shipping_manifest?: InputMaybe<Shipping_Manifest_Order_By>;
  status?: InputMaybe<Order_By>;
  tracking_number?: InputMaybe<Order_By>;
  tracking_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weight_in_g?: InputMaybe<Order_By>;
  width_in_cm?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shipment */
export type Shipment_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shipment_Prepend_Input = {
  provider_data?: InputMaybe<Scalars['jsonb']>;
  provider_status_updates?: InputMaybe<Scalars['jsonb']>;
};

export type Shipment_Rate_Aggregate_Bool_Exp = {
  count?: InputMaybe<Shipment_Rate_Aggregate_Bool_Exp_Count>;
};

export type Shipment_Rate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Shipment_Rate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Shipment_Rate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "shipment_rate" */
export type Shipment_Rate_Aggregate_Order_By = {
  avg?: InputMaybe<Shipment_Rate_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Shipment_Rate_Max_Order_By>;
  min?: InputMaybe<Shipment_Rate_Min_Order_By>;
  stddev?: InputMaybe<Shipment_Rate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Shipment_Rate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Shipment_Rate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Shipment_Rate_Sum_Order_By>;
  var_pop?: InputMaybe<Shipment_Rate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Shipment_Rate_Var_Samp_Order_By>;
  variance?: InputMaybe<Shipment_Rate_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shipment_Rate_Append_Input = {
  provider_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "shipment_rate" */
export type Shipment_Rate_Arr_Rel_Insert_Input = {
  data: Array<Shipment_Rate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Shipment_Rate_On_Conflict>;
};

/** order by avg() on columns of table "shipment_rate" */
export type Shipment_Rate_Avg_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  response_id?: InputMaybe<Order_By>;
  shipping_time_in_days?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "shipment_rate". All fields are combined with a logical 'AND'. */
export type Shipment_Rate_Bool_Exp = {
  _and?: InputMaybe<Array<Shipment_Rate_Bool_Exp>>;
  _not?: InputMaybe<Shipment_Rate_Bool_Exp>;
  _or?: InputMaybe<Array<Shipment_Rate_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  destination_address?: InputMaybe<Int_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  integration?: InputMaybe<Config_Shipment_Integration_Enum_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  orders?: InputMaybe<Order_Bool_Exp>;
  orders_aggregate?: InputMaybe<Order_Aggregate_Bool_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  priority?: InputMaybe<Int_Comparison_Exp>;
  provider?: InputMaybe<Config_Shipment_Provider_Enum_Comparison_Exp>;
  provider_data?: InputMaybe<Jsonb_Comparison_Exp>;
  response_id?: InputMaybe<Int_Comparison_Exp>;
  shipment_integration?: InputMaybe<Config_Shipment_Integration_Bool_Exp>;
  shipment_provider?: InputMaybe<Config_Shipment_Provider_Bool_Exp>;
  shipment_rate_response?: InputMaybe<Shipment_Rate_Response_Bool_Exp>;
  shipments?: InputMaybe<Shipment_Bool_Exp>;
  shipments_aggregate?: InputMaybe<Shipment_Aggregate_Bool_Exp>;
  shipping_time_in_days?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "shipment_rate" */
export enum Shipment_Rate_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_62f500949e01cb1f7b43e689f79 = 'PK_62f500949e01cb1f7b43e689f79'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shipment_Rate_Delete_At_Path_Input = {
  provider_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shipment_Rate_Delete_Elem_Input = {
  provider_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shipment_Rate_Delete_Key_Input = {
  provider_data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "shipment_rate" */
export type Shipment_Rate_Inc_Input = {
  destination_address?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  response_id?: InputMaybe<Scalars['Int']>;
  shipping_time_in_days?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shipment_rate" */
export type Shipment_Rate_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  destination_address?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  integration?: InputMaybe<Config_Shipment_Integration_Enum>;
  name?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<Order_Arr_Rel_Insert_Input>;
  price?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Config_Shipment_Provider_Enum>;
  provider_data?: InputMaybe<Scalars['jsonb']>;
  response_id?: InputMaybe<Scalars['Int']>;
  shipment_integration?: InputMaybe<Config_Shipment_Integration_Obj_Rel_Insert_Input>;
  shipment_provider?: InputMaybe<Config_Shipment_Provider_Obj_Rel_Insert_Input>;
  shipment_rate_response?: InputMaybe<Shipment_Rate_Response_Obj_Rel_Insert_Input>;
  shipments?: InputMaybe<Shipment_Arr_Rel_Insert_Input>;
  shipping_time_in_days?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "shipment_rate" */
export type Shipment_Rate_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  destination_address?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  response_id?: InputMaybe<Order_By>;
  shipping_time_in_days?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "shipment_rate" */
export type Shipment_Rate_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  destination_address?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  response_id?: InputMaybe<Order_By>;
  shipping_time_in_days?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "shipment_rate" */
export type Shipment_Rate_Obj_Rel_Insert_Input = {
  data: Shipment_Rate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Shipment_Rate_On_Conflict>;
};

/** on_conflict condition type for table "shipment_rate" */
export type Shipment_Rate_On_Conflict = {
  constraint: Shipment_Rate_Constraint;
  update_columns?: Array<Shipment_Rate_Update_Column>;
  where?: InputMaybe<Shipment_Rate_Bool_Exp>;
};

/** Ordering options when selecting data from "shipment_rate". */
export type Shipment_Rate_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  created_at?: InputMaybe<Order_By>;
  destination_address?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  orders_aggregate?: InputMaybe<Order_Aggregate_Order_By>;
  price?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  provider_data?: InputMaybe<Order_By>;
  response_id?: InputMaybe<Order_By>;
  shipment_integration?: InputMaybe<Config_Shipment_Integration_Order_By>;
  shipment_provider?: InputMaybe<Config_Shipment_Provider_Order_By>;
  shipment_rate_response?: InputMaybe<Shipment_Rate_Response_Order_By>;
  shipments_aggregate?: InputMaybe<Shipment_Aggregate_Order_By>;
  shipping_time_in_days?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shipment_rate */
export type Shipment_Rate_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shipment_Rate_Prepend_Input = {
  provider_data?: InputMaybe<Scalars['jsonb']>;
};

export type Shipment_Rate_Response_Aggregate_Bool_Exp = {
  count?: InputMaybe<Shipment_Rate_Response_Aggregate_Bool_Exp_Count>;
};

export type Shipment_Rate_Response_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Shipment_Rate_Response_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Shipment_Rate_Response_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "shipment_rate_response" */
export type Shipment_Rate_Response_Aggregate_Order_By = {
  avg?: InputMaybe<Shipment_Rate_Response_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Shipment_Rate_Response_Max_Order_By>;
  min?: InputMaybe<Shipment_Rate_Response_Min_Order_By>;
  stddev?: InputMaybe<Shipment_Rate_Response_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Shipment_Rate_Response_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Shipment_Rate_Response_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Shipment_Rate_Response_Sum_Order_By>;
  var_pop?: InputMaybe<Shipment_Rate_Response_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Shipment_Rate_Response_Var_Samp_Order_By>;
  variance?: InputMaybe<Shipment_Rate_Response_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shipment_Rate_Response_Append_Input = {
  details?: InputMaybe<Scalars['jsonb']>;
  errors?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "shipment_rate_response" */
export type Shipment_Rate_Response_Arr_Rel_Insert_Input = {
  data: Array<Shipment_Rate_Response_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Shipment_Rate_Response_On_Conflict>;
};

/** order by avg() on columns of table "shipment_rate_response" */
export type Shipment_Rate_Response_Avg_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "shipment_rate_response". All fields are combined with a logical 'AND'. */
export type Shipment_Rate_Response_Bool_Exp = {
  _and?: InputMaybe<Array<Shipment_Rate_Response_Bool_Exp>>;
  _not?: InputMaybe<Shipment_Rate_Response_Bool_Exp>;
  _or?: InputMaybe<Array<Shipment_Rate_Response_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  destination_address?: InputMaybe<Int_Comparison_Exp>;
  details?: InputMaybe<Jsonb_Comparison_Exp>;
  errors?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  shipment_rates?: InputMaybe<Shipment_Rate_Bool_Exp>;
  shipment_rates_aggregate?: InputMaybe<Shipment_Rate_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "shipment_rate_response" */
export enum Shipment_Rate_Response_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_5256bd7a50e7c34c6948c20c018 = 'PK_5256bd7a50e7c34c6948c20c018'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shipment_Rate_Response_Delete_At_Path_Input = {
  details?: InputMaybe<Array<Scalars['String']>>;
  errors?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shipment_Rate_Response_Delete_Elem_Input = {
  details?: InputMaybe<Scalars['Int']>;
  errors?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shipment_Rate_Response_Delete_Key_Input = {
  details?: InputMaybe<Scalars['String']>;
  errors?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "shipment_rate_response" */
export type Shipment_Rate_Response_Inc_Input = {
  destination_address?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shipment_rate_response" */
export type Shipment_Rate_Response_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  destination_address?: InputMaybe<Scalars['Int']>;
  details?: InputMaybe<Scalars['jsonb']>;
  errors?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  shipment_rates?: InputMaybe<Shipment_Rate_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "shipment_rate_response" */
export type Shipment_Rate_Response_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "shipment_rate_response" */
export type Shipment_Rate_Response_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "shipment_rate_response" */
export type Shipment_Rate_Response_Obj_Rel_Insert_Input = {
  data: Shipment_Rate_Response_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Shipment_Rate_Response_On_Conflict>;
};

/** on_conflict condition type for table "shipment_rate_response" */
export type Shipment_Rate_Response_On_Conflict = {
  constraint: Shipment_Rate_Response_Constraint;
  update_columns?: Array<Shipment_Rate_Response_Update_Column>;
  where?: InputMaybe<Shipment_Rate_Response_Bool_Exp>;
};

/** Ordering options when selecting data from "shipment_rate_response". */
export type Shipment_Rate_Response_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  created_at?: InputMaybe<Order_By>;
  destination_address?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  errors?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  shipment_rates_aggregate?: InputMaybe<Shipment_Rate_Aggregate_Order_By>;
};

/** primary key columns input for table: shipment_rate_response */
export type Shipment_Rate_Response_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shipment_Rate_Response_Prepend_Input = {
  details?: InputMaybe<Scalars['jsonb']>;
  errors?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "shipment_rate_response" */
export enum Shipment_Rate_Response_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DestinationAddress = 'destination_address',
  /** column name */
  Details = 'details',
  /** column name */
  Errors = 'errors',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "shipment_rate_response" */
export type Shipment_Rate_Response_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  destination_address?: InputMaybe<Scalars['Int']>;
  details?: InputMaybe<Scalars['jsonb']>;
  errors?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "shipment_rate_response" */
export type Shipment_Rate_Response_Stddev_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "shipment_rate_response" */
export type Shipment_Rate_Response_Stddev_Pop_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "shipment_rate_response" */
export type Shipment_Rate_Response_Stddev_Samp_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "shipment_rate_response" */
export type Shipment_Rate_Response_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shipment_Rate_Response_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shipment_Rate_Response_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  destination_address?: InputMaybe<Scalars['Int']>;
  details?: InputMaybe<Scalars['jsonb']>;
  errors?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "shipment_rate_response" */
export type Shipment_Rate_Response_Sum_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "shipment_rate_response" */
export enum Shipment_Rate_Response_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DestinationAddress = 'destination_address',
  /** column name */
  Details = 'details',
  /** column name */
  Errors = 'errors',
  /** column name */
  Id = 'id'
}

export type Shipment_Rate_Response_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Shipment_Rate_Response_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Shipment_Rate_Response_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Shipment_Rate_Response_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Shipment_Rate_Response_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Shipment_Rate_Response_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Shipment_Rate_Response_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shipment_Rate_Response_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shipment_Rate_Response_Bool_Exp;
};

/** order by var_pop() on columns of table "shipment_rate_response" */
export type Shipment_Rate_Response_Var_Pop_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "shipment_rate_response" */
export type Shipment_Rate_Response_Var_Samp_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "shipment_rate_response" */
export type Shipment_Rate_Response_Variance_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "shipment_rate" */
export enum Shipment_Rate_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DestinationAddress = 'destination_address',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Integration = 'integration',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Provider = 'provider',
  /** column name */
  ProviderData = 'provider_data',
  /** column name */
  ResponseId = 'response_id',
  /** column name */
  ShippingTimeInDays = 'shipping_time_in_days'
}

/** input type for updating data in table "shipment_rate" */
export type Shipment_Rate_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  destination_address?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  integration?: InputMaybe<Config_Shipment_Integration_Enum>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Config_Shipment_Provider_Enum>;
  provider_data?: InputMaybe<Scalars['jsonb']>;
  response_id?: InputMaybe<Scalars['Int']>;
  shipping_time_in_days?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "shipment_rate" */
export type Shipment_Rate_Stddev_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  response_id?: InputMaybe<Order_By>;
  shipping_time_in_days?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "shipment_rate" */
export type Shipment_Rate_Stddev_Pop_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  response_id?: InputMaybe<Order_By>;
  shipping_time_in_days?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "shipment_rate" */
export type Shipment_Rate_Stddev_Samp_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  response_id?: InputMaybe<Order_By>;
  shipping_time_in_days?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "shipment_rate" */
export type Shipment_Rate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shipment_Rate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shipment_Rate_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  destination_address?: InputMaybe<Scalars['Int']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  integration?: InputMaybe<Config_Shipment_Integration_Enum>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Config_Shipment_Provider_Enum>;
  provider_data?: InputMaybe<Scalars['jsonb']>;
  response_id?: InputMaybe<Scalars['Int']>;
  shipping_time_in_days?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "shipment_rate" */
export type Shipment_Rate_Sum_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  response_id?: InputMaybe<Order_By>;
  shipping_time_in_days?: InputMaybe<Order_By>;
};

/** update columns of table "shipment_rate" */
export enum Shipment_Rate_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DestinationAddress = 'destination_address',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Integration = 'integration',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Provider = 'provider',
  /** column name */
  ProviderData = 'provider_data',
  /** column name */
  ResponseId = 'response_id',
  /** column name */
  ShippingTimeInDays = 'shipping_time_in_days'
}

export type Shipment_Rate_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Shipment_Rate_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Shipment_Rate_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Shipment_Rate_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Shipment_Rate_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Shipment_Rate_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Shipment_Rate_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shipment_Rate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shipment_Rate_Bool_Exp;
};

/** order by var_pop() on columns of table "shipment_rate" */
export type Shipment_Rate_Var_Pop_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  response_id?: InputMaybe<Order_By>;
  shipping_time_in_days?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "shipment_rate" */
export type Shipment_Rate_Var_Samp_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  response_id?: InputMaybe<Order_By>;
  shipping_time_in_days?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "shipment_rate" */
export type Shipment_Rate_Variance_Order_By = {
  destination_address?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  response_id?: InputMaybe<Order_By>;
  shipping_time_in_days?: InputMaybe<Order_By>;
};

/** select columns of table "shipment" */
export enum Shipment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  HeightInCm = 'height_in_cm',
  /** column name */
  Id = 'id',
  /** column name */
  LabelPrintedAt = 'label_printed_at',
  /** column name */
  LabelPrintedByUid = 'label_printed_by_uid',
  /** column name */
  LengthInCm = 'length_in_cm',
  /** column name */
  LockerReservationId = 'locker_reservation_id',
  /** column name */
  Lost = 'lost',
  /** column name */
  ManifestId = 'manifest_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Provider = 'provider',
  /** column name */
  ProviderData = 'provider_data',
  /** column name */
  ProviderStatusUpdates = 'provider_status_updates',
  /** column name */
  Rate = 'rate',
  /** column name */
  RateV2Id = 'rate_v2_id',
  /** column name */
  ShipmentRateCode = 'shipment_rate_code',
  /** column name */
  ShippingDate = 'shipping_date',
  /** column name */
  Status = 'status',
  /** column name */
  TrackingNumber = 'tracking_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightInG = 'weight_in_g',
  /** column name */
  WidthInCm = 'width_in_cm'
}

/** select "shipment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "shipment" */
export enum Shipment_Select_Column_Shipment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Lost = 'lost'
}

/** select "shipment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "shipment" */
export enum Shipment_Select_Column_Shipment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Lost = 'lost'
}

/** input type for updating data in table "shipment" */
export type Shipment_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  height_in_cm?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  label_printed_at?: InputMaybe<Scalars['timestamp']>;
  label_printed_by_uid?: InputMaybe<Scalars['String']>;
  length_in_cm?: InputMaybe<Scalars['numeric']>;
  locker_reservation_id?: InputMaybe<Scalars['uuid']>;
  lost?: InputMaybe<Scalars['Boolean']>;
  manifest_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Config_Shipment_Provider_Enum>;
  provider_data?: InputMaybe<Scalars['jsonb']>;
  provider_status_updates?: InputMaybe<Scalars['jsonb']>;
  /** DEPRECATED (2023/10) */
  rate?: InputMaybe<Scalars['Int']>;
  /** DEPRECATED (2023/10) */
  rate_v2_id?: InputMaybe<Scalars['uuid']>;
  shipment_rate_code?: InputMaybe<Scalars['String']>;
  shipping_date?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Config_Shipment_Status_Enum>;
  tracking_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  weight_in_g?: InputMaybe<Scalars['numeric']>;
  width_in_cm?: InputMaybe<Scalars['numeric']>;
};

export type Shipment_Status_Update_Aggregate_Bool_Exp = {
  count?: InputMaybe<Shipment_Status_Update_Aggregate_Bool_Exp_Count>;
};

export type Shipment_Status_Update_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Shipment_Status_Update_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Shipment_Status_Update_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "shipment_status_update" */
export type Shipment_Status_Update_Aggregate_Order_By = {
  avg?: InputMaybe<Shipment_Status_Update_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Shipment_Status_Update_Max_Order_By>;
  min?: InputMaybe<Shipment_Status_Update_Min_Order_By>;
  stddev?: InputMaybe<Shipment_Status_Update_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Shipment_Status_Update_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Shipment_Status_Update_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Shipment_Status_Update_Sum_Order_By>;
  var_pop?: InputMaybe<Shipment_Status_Update_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Shipment_Status_Update_Var_Samp_Order_By>;
  variance?: InputMaybe<Shipment_Status_Update_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shipment_Status_Update_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "shipment_status_update" */
export type Shipment_Status_Update_Arr_Rel_Insert_Input = {
  data: Array<Shipment_Status_Update_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Shipment_Status_Update_On_Conflict>;
};

/** order by avg() on columns of table "shipment_status_update" */
export type Shipment_Status_Update_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  shipment_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "shipment_status_update". All fields are combined with a logical 'AND'. */
export type Shipment_Status_Update_Bool_Exp = {
  _and?: InputMaybe<Array<Shipment_Status_Update_Bool_Exp>>;
  _not?: InputMaybe<Shipment_Status_Update_Bool_Exp>;
  _or?: InputMaybe<Array<Shipment_Status_Update_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  occurred_at?: InputMaybe<Timestamp_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  shipment?: InputMaybe<Shipment_Bool_Exp>;
  shipment_id?: InputMaybe<Int_Comparison_Exp>;
  shipment_status?: InputMaybe<Config_Shipment_Status_Bool_Exp>;
  status?: InputMaybe<Config_Shipment_Status_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "shipment_status_update" */
export enum Shipment_Status_Update_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_5848258e89b6a4eca6dd287b7a2 = 'PK_5848258e89b6a4eca6dd287b7a2'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shipment_Status_Update_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shipment_Status_Update_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shipment_Status_Update_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "shipment_status_update" */
export type Shipment_Status_Update_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  shipment_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shipment_status_update" */
export type Shipment_Status_Update_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  occurred_at?: InputMaybe<Scalars['timestamp']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  shipment?: InputMaybe<Shipment_Obj_Rel_Insert_Input>;
  shipment_id?: InputMaybe<Scalars['Int']>;
  shipment_status?: InputMaybe<Config_Shipment_Status_Obj_Rel_Insert_Input>;
  /** NULL for events that should not occasion a change in status */
  status?: InputMaybe<Config_Shipment_Status_Enum>;
};

/** order by max() on columns of table "shipment_status_update" */
export type Shipment_Status_Update_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occurred_at?: InputMaybe<Order_By>;
  shipment_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "shipment_status_update" */
export type Shipment_Status_Update_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occurred_at?: InputMaybe<Order_By>;
  shipment_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "shipment_status_update" */
export type Shipment_Status_Update_On_Conflict = {
  constraint: Shipment_Status_Update_Constraint;
  update_columns?: Array<Shipment_Status_Update_Update_Column>;
  where?: InputMaybe<Shipment_Status_Update_Bool_Exp>;
};

/** Ordering options when selecting data from "shipment_status_update". */
export type Shipment_Status_Update_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  occurred_at?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  shipment?: InputMaybe<Shipment_Order_By>;
  shipment_id?: InputMaybe<Order_By>;
  shipment_status?: InputMaybe<Config_Shipment_Status_Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shipment_status_update */
export type Shipment_Status_Update_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shipment_Status_Update_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "shipment_status_update" */
export enum Shipment_Status_Update_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OccurredAt = 'occurred_at',
  /** column name */
  Payload = 'payload',
  /** column name */
  ShipmentId = 'shipment_id',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "shipment_status_update" */
export type Shipment_Status_Update_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  occurred_at?: InputMaybe<Scalars['timestamp']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  shipment_id?: InputMaybe<Scalars['Int']>;
  /** NULL for events that should not occasion a change in status */
  status?: InputMaybe<Config_Shipment_Status_Enum>;
};

/** order by stddev() on columns of table "shipment_status_update" */
export type Shipment_Status_Update_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  shipment_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "shipment_status_update" */
export type Shipment_Status_Update_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  shipment_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "shipment_status_update" */
export type Shipment_Status_Update_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  shipment_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "shipment_status_update" */
export type Shipment_Status_Update_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shipment_Status_Update_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shipment_Status_Update_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  occurred_at?: InputMaybe<Scalars['timestamp']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  shipment_id?: InputMaybe<Scalars['Int']>;
  /** NULL for events that should not occasion a change in status */
  status?: InputMaybe<Config_Shipment_Status_Enum>;
};

/** order by sum() on columns of table "shipment_status_update" */
export type Shipment_Status_Update_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  shipment_id?: InputMaybe<Order_By>;
};

/** update columns of table "shipment_status_update" */
export enum Shipment_Status_Update_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OccurredAt = 'occurred_at',
  /** column name */
  Payload = 'payload',
  /** column name */
  ShipmentId = 'shipment_id',
  /** column name */
  Status = 'status'
}

export type Shipment_Status_Update_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Shipment_Status_Update_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Shipment_Status_Update_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Shipment_Status_Update_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Shipment_Status_Update_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Shipment_Status_Update_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Shipment_Status_Update_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shipment_Status_Update_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shipment_Status_Update_Bool_Exp;
};

/** order by var_pop() on columns of table "shipment_status_update" */
export type Shipment_Status_Update_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  shipment_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "shipment_status_update" */
export type Shipment_Status_Update_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  shipment_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "shipment_status_update" */
export type Shipment_Status_Update_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  shipment_id?: InputMaybe<Order_By>;
};

/** order by stddev() on columns of table "shipment" */
export type Shipment_Stddev_Order_By = {
  height_in_cm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  length_in_cm?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  rate?: InputMaybe<Order_By>;
  weight_in_g?: InputMaybe<Order_By>;
  width_in_cm?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "shipment" */
export type Shipment_Stddev_Pop_Order_By = {
  height_in_cm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  length_in_cm?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  rate?: InputMaybe<Order_By>;
  weight_in_g?: InputMaybe<Order_By>;
  width_in_cm?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "shipment" */
export type Shipment_Stddev_Samp_Order_By = {
  height_in_cm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  length_in_cm?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  rate?: InputMaybe<Order_By>;
  weight_in_g?: InputMaybe<Order_By>;
  width_in_cm?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "shipment" */
export type Shipment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shipment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shipment_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  height_in_cm?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  label_printed_at?: InputMaybe<Scalars['timestamp']>;
  label_printed_by_uid?: InputMaybe<Scalars['String']>;
  length_in_cm?: InputMaybe<Scalars['numeric']>;
  locker_reservation_id?: InputMaybe<Scalars['uuid']>;
  lost?: InputMaybe<Scalars['Boolean']>;
  manifest_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Config_Shipment_Provider_Enum>;
  provider_data?: InputMaybe<Scalars['jsonb']>;
  provider_status_updates?: InputMaybe<Scalars['jsonb']>;
  /** DEPRECATED (2023/10) */
  rate?: InputMaybe<Scalars['Int']>;
  /** DEPRECATED (2023/10) */
  rate_v2_id?: InputMaybe<Scalars['uuid']>;
  shipment_rate_code?: InputMaybe<Scalars['String']>;
  shipping_date?: InputMaybe<Scalars['date']>;
  status?: InputMaybe<Config_Shipment_Status_Enum>;
  tracking_number?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  weight_in_g?: InputMaybe<Scalars['numeric']>;
  width_in_cm?: InputMaybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "shipment" */
export type Shipment_Sum_Order_By = {
  height_in_cm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  length_in_cm?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  rate?: InputMaybe<Order_By>;
  weight_in_g?: InputMaybe<Order_By>;
  width_in_cm?: InputMaybe<Order_By>;
};

/** update columns of table "shipment" */
export enum Shipment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  HeightInCm = 'height_in_cm',
  /** column name */
  Id = 'id',
  /** column name */
  LabelPrintedAt = 'label_printed_at',
  /** column name */
  LabelPrintedByUid = 'label_printed_by_uid',
  /** column name */
  LengthInCm = 'length_in_cm',
  /** column name */
  LockerReservationId = 'locker_reservation_id',
  /** column name */
  Lost = 'lost',
  /** column name */
  ManifestId = 'manifest_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  Provider = 'provider',
  /** column name */
  ProviderData = 'provider_data',
  /** column name */
  ProviderStatusUpdates = 'provider_status_updates',
  /** column name */
  Rate = 'rate',
  /** column name */
  RateV2Id = 'rate_v2_id',
  /** column name */
  ShipmentRateCode = 'shipment_rate_code',
  /** column name */
  ShippingDate = 'shipping_date',
  /** column name */
  Status = 'status',
  /** column name */
  TrackingNumber = 'tracking_number',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightInG = 'weight_in_g',
  /** column name */
  WidthInCm = 'width_in_cm'
}

export type Shipment_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Shipment_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Shipment_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Shipment_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Shipment_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Shipment_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Shipment_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shipment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shipment_Bool_Exp;
};

/** order by var_pop() on columns of table "shipment" */
export type Shipment_Var_Pop_Order_By = {
  height_in_cm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  length_in_cm?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  rate?: InputMaybe<Order_By>;
  weight_in_g?: InputMaybe<Order_By>;
  width_in_cm?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "shipment" */
export type Shipment_Var_Samp_Order_By = {
  height_in_cm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  length_in_cm?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  rate?: InputMaybe<Order_By>;
  weight_in_g?: InputMaybe<Order_By>;
  width_in_cm?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "shipment" */
export type Shipment_Variance_Order_By = {
  height_in_cm?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  length_in_cm?: InputMaybe<Order_By>;
  manifest_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  /** DEPRECATED (2023/10) */
  rate?: InputMaybe<Order_By>;
  weight_in_g?: InputMaybe<Order_By>;
  width_in_cm?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "shipping_manifest". All fields are combined with a logical 'AND'. */
export type Shipping_Manifest_Bool_Exp = {
  _and?: InputMaybe<Array<Shipping_Manifest_Bool_Exp>>;
  _not?: InputMaybe<Shipping_Manifest_Bool_Exp>;
  _or?: InputMaybe<Array<Shipping_Manifest_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  shipments?: InputMaybe<Shipment_Bool_Exp>;
  shipments_aggregate?: InputMaybe<Shipment_Aggregate_Bool_Exp>;
  status?: InputMaybe<Config_Shipping_Manifest_Status_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "shipping_manifest" */
export enum Shipping_Manifest_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkD400b2553b9e0923e89f56e9a90 = 'PK_d400b2553b9e0923e89f56e9a90'
}

/** input type for incrementing numeric columns in table "shipping_manifest" */
export type Shipping_Manifest_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "shipping_manifest" */
export type Shipping_Manifest_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  shipments?: InputMaybe<Shipment_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Config_Shipping_Manifest_Status_Enum>;
};

/** input type for inserting object relation for remote table "shipping_manifest" */
export type Shipping_Manifest_Obj_Rel_Insert_Input = {
  data: Shipping_Manifest_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Shipping_Manifest_On_Conflict>;
};

/** on_conflict condition type for table "shipping_manifest" */
export type Shipping_Manifest_On_Conflict = {
  constraint: Shipping_Manifest_Constraint;
  update_columns?: Array<Shipping_Manifest_Update_Column>;
  where?: InputMaybe<Shipping_Manifest_Bool_Exp>;
};

/** Ordering options when selecting data from "shipping_manifest". */
export type Shipping_Manifest_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  shipments_aggregate?: InputMaybe<Shipment_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: shipping_manifest */
export type Shipping_Manifest_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "shipping_manifest" */
export enum Shipping_Manifest_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "shipping_manifest" */
export type Shipping_Manifest_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Config_Shipping_Manifest_Status_Enum>;
};

/** Streaming cursor of the table "shipping_manifest" */
export type Shipping_Manifest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shipping_Manifest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shipping_Manifest_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Config_Shipping_Manifest_Status_Enum>;
};

/** update columns of table "shipping_manifest" */
export enum Shipping_Manifest_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status'
}

export type Shipping_Manifest_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Shipping_Manifest_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Shipping_Manifest_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shipping_Manifest_Bool_Exp;
};

/** Boolean expression to filter rows from the table "short_url". All fields are combined with a logical 'AND'. */
export type Short_Url_Bool_Exp = {
  _and?: InputMaybe<Array<Short_Url_Bool_Exp>>;
  _not?: InputMaybe<Short_Url_Bool_Exp>;
  _or?: InputMaybe<Array<Short_Url_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamp_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  long?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "short_url" */
export enum Short_Url_Constraint {
  /** unique or primary key constraint on columns "hash" */
  ShortUrlHashKey = 'short_url_hash_key',
  /** unique or primary key constraint on columns "id" */
  ShortUrlPkey = 'short_url_pkey'
}

/** input type for inserting data into table "short_url" */
export type Short_Url_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  long?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "short_url" */
export type Short_Url_On_Conflict = {
  constraint: Short_Url_Constraint;
  update_columns?: Array<Short_Url_Update_Column>;
  where?: InputMaybe<Short_Url_Bool_Exp>;
};

/** Ordering options when selecting data from "short_url". */
export type Short_Url_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  long?: InputMaybe<Order_By>;
};

/** primary key columns input for table: short_url */
export type Short_Url_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "short_url" */
export enum Short_Url_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Long = 'long'
}

/** input type for updating data in table "short_url" */
export type Short_Url_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  long?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "short_url" */
export type Short_Url_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Short_Url_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Short_Url_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  expires_at?: InputMaybe<Scalars['timestamp']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  long?: InputMaybe<Scalars['String']>;
};

/** update columns of table "short_url" */
export enum Short_Url_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Long = 'long'
}

export type Short_Url_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Short_Url_Set_Input>;
  /** filter the rows which have to be updated */
  where: Short_Url_Bool_Exp;
};

export type Survey_Monkey_Response_Details_Aggregate_Bool_Exp = {
  count?: InputMaybe<Survey_Monkey_Response_Details_Aggregate_Bool_Exp_Count>;
};

export type Survey_Monkey_Response_Details_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Survey_Monkey_Response_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Survey_Monkey_Response_Details_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Aggregate_Order_By = {
  avg?: InputMaybe<Survey_Monkey_Response_Details_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Survey_Monkey_Response_Details_Max_Order_By>;
  min?: InputMaybe<Survey_Monkey_Response_Details_Min_Order_By>;
  stddev?: InputMaybe<Survey_Monkey_Response_Details_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Survey_Monkey_Response_Details_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Survey_Monkey_Response_Details_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Survey_Monkey_Response_Details_Sum_Order_By>;
  var_pop?: InputMaybe<Survey_Monkey_Response_Details_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Survey_Monkey_Response_Details_Var_Samp_Order_By>;
  variance?: InputMaybe<Survey_Monkey_Response_Details_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Survey_Monkey_Response_Details_Append_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Arr_Rel_Insert_Input = {
  data: Array<Survey_Monkey_Response_Details_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_Monkey_Response_Details_On_Conflict>;
};

/** order by avg() on columns of table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Avg_Order_By = {
  survey_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "survey_monkey.response_details". All fields are combined with a logical 'AND'. */
export type Survey_Monkey_Response_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_Monkey_Response_Details_Bool_Exp>>;
  _not?: InputMaybe<Survey_Monkey_Response_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_Monkey_Response_Details_Bool_Exp>>;
  body?: InputMaybe<Jsonb_Comparison_Exp>;
  response_id?: InputMaybe<String_Comparison_Exp>;
  survey_details_snapshot?: InputMaybe<Survey_Monkey_Survey_Details_Snapshot_Bool_Exp>;
  survey_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_monkey.response_details" */
export enum Survey_Monkey_Response_Details_Constraint {
  /** unique or primary key constraint on columns "response_id" */
  Pk_096e86a92638e74025a9c264111 = 'PK_096e86a92638e74025a9c264111'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Survey_Monkey_Response_Details_Delete_At_Path_Input = {
  body?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Survey_Monkey_Response_Details_Delete_Elem_Input = {
  body?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Survey_Monkey_Response_Details_Delete_Key_Input = {
  body?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Inc_Input = {
  survey_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Insert_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
  response_id?: InputMaybe<Scalars['String']>;
  survey_details_snapshot?: InputMaybe<Survey_Monkey_Survey_Details_Snapshot_Obj_Rel_Insert_Input>;
  survey_id?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Max_Order_By = {
  response_id?: InputMaybe<Order_By>;
  survey_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Min_Order_By = {
  response_id?: InputMaybe<Order_By>;
  survey_id?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_On_Conflict = {
  constraint: Survey_Monkey_Response_Details_Constraint;
  update_columns?: Array<Survey_Monkey_Response_Details_Update_Column>;
  where?: InputMaybe<Survey_Monkey_Response_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_monkey.response_details". */
export type Survey_Monkey_Response_Details_Order_By = {
  body?: InputMaybe<Order_By>;
  response_id?: InputMaybe<Order_By>;
  survey_details_snapshot?: InputMaybe<Survey_Monkey_Survey_Details_Snapshot_Order_By>;
  survey_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_monkey.response_details */
export type Survey_Monkey_Response_Details_Pk_Columns_Input = {
  response_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Survey_Monkey_Response_Details_Prepend_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "survey_monkey.response_details" */
export enum Survey_Monkey_Response_Details_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  ResponseId = 'response_id',
  /** column name */
  SurveyId = 'survey_id'
}

/** input type for updating data in table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Set_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
  response_id?: InputMaybe<Scalars['String']>;
  survey_id?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Stddev_Order_By = {
  survey_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Stddev_Pop_Order_By = {
  survey_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Stddev_Samp_Order_By = {
  survey_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "survey_monkey_response_details" */
export type Survey_Monkey_Response_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_Monkey_Response_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_Monkey_Response_Details_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
  response_id?: InputMaybe<Scalars['String']>;
  survey_id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Sum_Order_By = {
  survey_id?: InputMaybe<Order_By>;
};

/** update columns of table "survey_monkey.response_details" */
export enum Survey_Monkey_Response_Details_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  ResponseId = 'response_id',
  /** column name */
  SurveyId = 'survey_id'
}

export type Survey_Monkey_Response_Details_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Survey_Monkey_Response_Details_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Survey_Monkey_Response_Details_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Survey_Monkey_Response_Details_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Survey_Monkey_Response_Details_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Survey_Monkey_Response_Details_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Survey_Monkey_Response_Details_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_Monkey_Response_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_Monkey_Response_Details_Bool_Exp;
};

/** order by var_pop() on columns of table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Var_Pop_Order_By = {
  survey_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Var_Samp_Order_By = {
  survey_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "survey_monkey.response_details" */
export type Survey_Monkey_Response_Details_Variance_Order_By = {
  survey_id?: InputMaybe<Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Survey_Monkey_Survey_Details_Snapshot_Append_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "survey_monkey.survey_details_snapshot". All fields are combined with a logical 'AND'. */
export type Survey_Monkey_Survey_Details_Snapshot_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_Monkey_Survey_Details_Snapshot_Bool_Exp>>;
  _not?: InputMaybe<Survey_Monkey_Survey_Details_Snapshot_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_Monkey_Survey_Details_Snapshot_Bool_Exp>>;
  body?: InputMaybe<Jsonb_Comparison_Exp>;
  date_modified?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  response_details?: InputMaybe<Survey_Monkey_Response_Details_Bool_Exp>;
  response_details_aggregate?: InputMaybe<Survey_Monkey_Response_Details_Aggregate_Bool_Exp>;
  survey_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_monkey.survey_details_snapshot" */
export enum Survey_Monkey_Survey_Details_Snapshot_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_101bcfb0bee1a87680c56b18988 = 'PK_101bcfb0bee1a87680c56b18988',
  /** unique or primary key constraint on columns "survey_id", "date_modified" */
  SurveySnapshot = 'survey_snapshot'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Survey_Monkey_Survey_Details_Snapshot_Delete_At_Path_Input = {
  body?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Survey_Monkey_Survey_Details_Snapshot_Delete_Elem_Input = {
  body?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Survey_Monkey_Survey_Details_Snapshot_Delete_Key_Input = {
  body?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "survey_monkey.survey_details_snapshot" */
export type Survey_Monkey_Survey_Details_Snapshot_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "survey_monkey.survey_details_snapshot" */
export type Survey_Monkey_Survey_Details_Snapshot_Insert_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
  date_modified?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  response_details?: InputMaybe<Survey_Monkey_Response_Details_Arr_Rel_Insert_Input>;
  survey_id?: InputMaybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "survey_monkey.survey_details_snapshot" */
export type Survey_Monkey_Survey_Details_Snapshot_Obj_Rel_Insert_Input = {
  data: Survey_Monkey_Survey_Details_Snapshot_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_Monkey_Survey_Details_Snapshot_On_Conflict>;
};

/** on_conflict condition type for table "survey_monkey.survey_details_snapshot" */
export type Survey_Monkey_Survey_Details_Snapshot_On_Conflict = {
  constraint: Survey_Monkey_Survey_Details_Snapshot_Constraint;
  update_columns?: Array<Survey_Monkey_Survey_Details_Snapshot_Update_Column>;
  where?: InputMaybe<Survey_Monkey_Survey_Details_Snapshot_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_monkey.survey_details_snapshot". */
export type Survey_Monkey_Survey_Details_Snapshot_Order_By = {
  body?: InputMaybe<Order_By>;
  date_modified?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  response_details_aggregate?: InputMaybe<Survey_Monkey_Response_Details_Aggregate_Order_By>;
  survey_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_monkey.survey_details_snapshot */
export type Survey_Monkey_Survey_Details_Snapshot_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Survey_Monkey_Survey_Details_Snapshot_Prepend_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "survey_monkey.survey_details_snapshot" */
export enum Survey_Monkey_Survey_Details_Snapshot_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  DateModified = 'date_modified',
  /** column name */
  Id = 'id',
  /** column name */
  SurveyId = 'survey_id'
}

/** input type for updating data in table "survey_monkey.survey_details_snapshot" */
export type Survey_Monkey_Survey_Details_Snapshot_Set_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
  date_modified?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  survey_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "survey_monkey_survey_details_snapshot" */
export type Survey_Monkey_Survey_Details_Snapshot_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_Monkey_Survey_Details_Snapshot_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_Monkey_Survey_Details_Snapshot_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
  date_modified?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  survey_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "survey_monkey.survey_details_snapshot" */
export enum Survey_Monkey_Survey_Details_Snapshot_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  DateModified = 'date_modified',
  /** column name */
  Id = 'id',
  /** column name */
  SurveyId = 'survey_id'
}

export type Survey_Monkey_Survey_Details_Snapshot_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Survey_Monkey_Survey_Details_Snapshot_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Survey_Monkey_Survey_Details_Snapshot_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Survey_Monkey_Survey_Details_Snapshot_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Survey_Monkey_Survey_Details_Snapshot_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Survey_Monkey_Survey_Details_Snapshot_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Survey_Monkey_Survey_Details_Snapshot_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_Monkey_Survey_Details_Snapshot_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_Monkey_Survey_Details_Snapshot_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Survey_Monkey_Webhook_Callback_Append_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "survey_monkey.webhook_callback". All fields are combined with a logical 'AND'. */
export type Survey_Monkey_Webhook_Callback_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_Monkey_Webhook_Callback_Bool_Exp>>;
  _not?: InputMaybe<Survey_Monkey_Webhook_Callback_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_Monkey_Webhook_Callback_Bool_Exp>>;
  body?: InputMaybe<Jsonb_Comparison_Exp>;
  event_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_monkey.webhook_callback" */
export enum Survey_Monkey_Webhook_Callback_Constraint {
  /** unique or primary key constraint on columns "event_id" */
  Pk_929469f0d1eacbf082c629e4dd1 = 'PK_929469f0d1eacbf082c629e4dd1'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Survey_Monkey_Webhook_Callback_Delete_At_Path_Input = {
  body?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Survey_Monkey_Webhook_Callback_Delete_Elem_Input = {
  body?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Survey_Monkey_Webhook_Callback_Delete_Key_Input = {
  body?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "survey_monkey.webhook_callback" */
export type Survey_Monkey_Webhook_Callback_Insert_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
  event_id?: InputMaybe<Scalars['String']>;
};

/** on_conflict condition type for table "survey_monkey.webhook_callback" */
export type Survey_Monkey_Webhook_Callback_On_Conflict = {
  constraint: Survey_Monkey_Webhook_Callback_Constraint;
  update_columns?: Array<Survey_Monkey_Webhook_Callback_Update_Column>;
  where?: InputMaybe<Survey_Monkey_Webhook_Callback_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_monkey.webhook_callback". */
export type Survey_Monkey_Webhook_Callback_Order_By = {
  body?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_monkey.webhook_callback */
export type Survey_Monkey_Webhook_Callback_Pk_Columns_Input = {
  event_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Survey_Monkey_Webhook_Callback_Prepend_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "survey_monkey.webhook_callback" */
export enum Survey_Monkey_Webhook_Callback_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  EventId = 'event_id'
}

/** input type for updating data in table "survey_monkey.webhook_callback" */
export type Survey_Monkey_Webhook_Callback_Set_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
  event_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "survey_monkey_webhook_callback" */
export type Survey_Monkey_Webhook_Callback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_Monkey_Webhook_Callback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_Monkey_Webhook_Callback_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['jsonb']>;
  event_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "survey_monkey.webhook_callback" */
export enum Survey_Monkey_Webhook_Callback_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  EventId = 'event_id'
}

export type Survey_Monkey_Webhook_Callback_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Survey_Monkey_Webhook_Callback_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Survey_Monkey_Webhook_Callback_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Survey_Monkey_Webhook_Callback_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Survey_Monkey_Webhook_Callback_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Survey_Monkey_Webhook_Callback_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_Monkey_Webhook_Callback_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_Monkey_Webhook_Callback_Bool_Exp;
};

/** order by aggregate values of table "survey_result" */
export type Survey_Result_Aggregate_Order_By = {
  avg?: InputMaybe<Survey_Result_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Survey_Result_Max_Order_By>;
  min?: InputMaybe<Survey_Result_Min_Order_By>;
  stddev?: InputMaybe<Survey_Result_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Survey_Result_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Survey_Result_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Survey_Result_Sum_Order_By>;
  var_pop?: InputMaybe<Survey_Result_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Survey_Result_Var_Samp_Order_By>;
  variance?: InputMaybe<Survey_Result_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Survey_Result_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** order by avg() on columns of table "survey_result" */
export type Survey_Result_Avg_Order_By = {
  external_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "survey_result". All fields are combined with a logical 'AND'. */
export type Survey_Result_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_Result_Bool_Exp>>;
  _not?: InputMaybe<Survey_Result_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_Result_Bool_Exp>>;
  consultation_survey_results?: InputMaybe<Consultation_Survey_Result_Bool_Exp>;
  consultation_survey_results_aggregate?: InputMaybe<Consultation_Survey_Result_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  external_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  survey_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_result" */
export enum Survey_Result_Constraint {
  /** unique or primary key constraint on columns "external_id" */
  SurveyResultExternalIdKey = 'survey_result_external_id_key',
  /** unique or primary key constraint on columns "id" */
  SurveyResultPkey = 'survey_result_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Survey_Result_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Survey_Result_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Survey_Result_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "survey_result" */
export type Survey_Result_Inc_Input = {
  external_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "survey_result" */
export type Survey_Result_Insert_Input = {
  consultation_survey_results?: InputMaybe<Consultation_Survey_Result_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  external_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  survey_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "survey_result" */
export type Survey_Result_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  survey_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "survey_result" */
export type Survey_Result_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  survey_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "survey_result" */
export type Survey_Result_Obj_Rel_Insert_Input = {
  data: Survey_Result_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_Result_On_Conflict>;
};

/** on_conflict condition type for table "survey_result" */
export type Survey_Result_On_Conflict = {
  constraint: Survey_Result_Constraint;
  update_columns?: Array<Survey_Result_Update_Column>;
  where?: InputMaybe<Survey_Result_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_result". */
export type Survey_Result_Order_By = {
  consultation_survey_results_aggregate?: InputMaybe<Consultation_Survey_Result_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  survey_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_result */
export type Survey_Result_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Survey_Result_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "survey_result" */
export enum Survey_Result_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  SurveyUrl = 'survey_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "survey_result" */
export type Survey_Result_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  external_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  survey_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "survey_result" */
export type Survey_Result_Stddev_Order_By = {
  external_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "survey_result" */
export type Survey_Result_Stddev_Pop_Order_By = {
  external_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "survey_result" */
export type Survey_Result_Stddev_Samp_Order_By = {
  external_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "survey_result" */
export type Survey_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_Result_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_Result_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  external_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  survey_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "survey_result" */
export type Survey_Result_Sum_Order_By = {
  external_id?: InputMaybe<Order_By>;
};

/** update columns of table "survey_result" */
export enum Survey_Result_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  SurveyUrl = 'survey_url',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Survey_Result_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Survey_Result_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Survey_Result_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Survey_Result_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Survey_Result_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Survey_Result_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Survey_Result_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_Result_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_Result_Bool_Exp;
};

/** order by var_pop() on columns of table "survey_result" */
export type Survey_Result_Var_Pop_Order_By = {
  external_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "survey_result" */
export type Survey_Result_Var_Samp_Order_By = {
  external_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "survey_result" */
export type Survey_Result_Variance_Order_By = {
  external_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['time']>;
  _gt?: InputMaybe<Scalars['time']>;
  _gte?: InputMaybe<Scalars['time']>;
  _in?: InputMaybe<Array<Scalars['time']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['time']>;
  _lte?: InputMaybe<Scalars['time']>;
  _neq?: InputMaybe<Scalars['time']>;
  _nin?: InputMaybe<Array<Scalars['time']>>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

export type Transaction_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Transaction_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Transaction_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Transaction_Aggregate_Bool_Exp_Count>;
};

export type Transaction_Aggregate_Bool_Exp_Bool_And = {
  arguments: Transaction_Select_Column_Transaction_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transaction_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Transaction_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Transaction_Select_Column_Transaction_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transaction_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Transaction_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Transaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transaction_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "transaction" */
export type Transaction_Aggregate_Order_By = {
  avg?: InputMaybe<Transaction_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Transaction_Max_Order_By>;
  min?: InputMaybe<Transaction_Min_Order_By>;
  stddev?: InputMaybe<Transaction_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Transaction_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Transaction_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Transaction_Sum_Order_By>;
  var_pop?: InputMaybe<Transaction_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Transaction_Var_Samp_Order_By>;
  variance?: InputMaybe<Transaction_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "transaction" */
export type Transaction_Arr_Rel_Insert_Input = {
  data: Array<Transaction_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Transaction_On_Conflict>;
};

/** order by avg() on columns of table "transaction" */
export type Transaction_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transaction". All fields are combined with a logical 'AND'. */
export type Transaction_Bool_Exp = {
  _and?: InputMaybe<Array<Transaction_Bool_Exp>>;
  _not?: InputMaybe<Transaction_Bool_Exp>;
  _or?: InputMaybe<Array<Transaction_Bool_Exp>>;
  amount?: InputMaybe<Int_Comparison_Exp>;
  card_brand?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  error_code?: InputMaybe<String_Comparison_Exp>;
  error_message?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  idempotency_key?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoice_id?: InputMaybe<Int_Comparison_Exp>;
  last_four_digits?: InputMaybe<String_Comparison_Exp>;
  payment_method?: InputMaybe<String_Comparison_Exp>;
  payment_token?: InputMaybe<String_Comparison_Exp>;
  refunded_amount?: InputMaybe<Int_Comparison_Exp>;
  refunds?: InputMaybe<Refund_Bool_Exp>;
  refunds_aggregate?: InputMaybe<Refund_Aggregate_Bool_Exp>;
  save_instrument?: InputMaybe<Boolean_Comparison_Exp>;
  seq_id?: InputMaybe<Int_Comparison_Exp>;
  set_instrument_as_default?: InputMaybe<Boolean_Comparison_Exp>;
  transaction_id?: InputMaybe<String_Comparison_Exp>;
  transaction_status?: InputMaybe<String_Comparison_Exp>;
  transaction_time?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "transaction" */
export enum Transaction_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_89eadb93a89810556e1cbcd6ab9 = 'PK_89eadb93a89810556e1cbcd6ab9',
  /** unique or primary key constraint on columns "id" */
  TransactionIdKey = 'transaction_id_key',
  /** unique or primary key constraint on columns "idempotency_key" */
  TransactionIdempotencyKeyKey = 'transaction_idempotency_key_key'
}

/** input type for incrementing numeric columns in table "transaction" */
export type Transaction_Inc_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  seq_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "transaction" */
export type Transaction_Insert_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  card_brand?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  error_code?: InputMaybe<Scalars['String']>;
  error_message?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  idempotency_key?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  last_four_digits?: InputMaybe<Scalars['String']>;
  payment_method?: InputMaybe<Scalars['String']>;
  payment_token?: InputMaybe<Scalars['String']>;
  refunds?: InputMaybe<Refund_Arr_Rel_Insert_Input>;
  save_instrument?: InputMaybe<Scalars['Boolean']>;
  seq_id?: InputMaybe<Scalars['Int']>;
  set_instrument_as_default?: InputMaybe<Scalars['Boolean']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  transaction_status?: InputMaybe<Scalars['String']>;
  transaction_time?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "transaction" */
export type Transaction_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  card_brand?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  error_code?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idempotency_key?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  last_four_digits?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Order_By>;
  payment_token?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  transaction_status?: InputMaybe<Order_By>;
  transaction_time?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "transaction" */
export type Transaction_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  card_brand?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  error_code?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idempotency_key?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  last_four_digits?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Order_By>;
  payment_token?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  transaction_status?: InputMaybe<Order_By>;
  transaction_time?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "transaction" */
export type Transaction_On_Conflict = {
  constraint: Transaction_Constraint;
  update_columns?: Array<Transaction_Update_Column>;
  where?: InputMaybe<Transaction_Bool_Exp>;
};

/** Ordering options when selecting data from "transaction". */
export type Transaction_Order_By = {
  amount?: InputMaybe<Order_By>;
  card_brand?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  error_code?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idempotency_key?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  last_four_digits?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Order_By>;
  payment_token?: InputMaybe<Order_By>;
  refunded_amount?: InputMaybe<Order_By>;
  refunds_aggregate?: InputMaybe<Refund_Aggregate_Order_By>;
  save_instrument?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
  set_instrument_as_default?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  transaction_status?: InputMaybe<Order_By>;
  transaction_time?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transaction */
export type Transaction_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "transaction" */
export enum Transaction_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CardBrand = 'card_brand',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErrorCode = 'error_code',
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  Id = 'id',
  /** column name */
  IdempotencyKey = 'idempotency_key',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  LastFourDigits = 'last_four_digits',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PaymentToken = 'payment_token',
  /** column name */
  SaveInstrument = 'save_instrument',
  /** column name */
  SeqId = 'seq_id',
  /** column name */
  SetInstrumentAsDefault = 'set_instrument_as_default',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TransactionStatus = 'transaction_status',
  /** column name */
  TransactionTime = 'transaction_time'
}

/** select "transaction_aggregate_bool_exp_bool_and_arguments_columns" columns of table "transaction" */
export enum Transaction_Select_Column_Transaction_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  SaveInstrument = 'save_instrument',
  /** column name */
  SetInstrumentAsDefault = 'set_instrument_as_default'
}

/** select "transaction_aggregate_bool_exp_bool_or_arguments_columns" columns of table "transaction" */
export enum Transaction_Select_Column_Transaction_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  SaveInstrument = 'save_instrument',
  /** column name */
  SetInstrumentAsDefault = 'set_instrument_as_default'
}

/** input type for updating data in table "transaction" */
export type Transaction_Set_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  card_brand?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  error_code?: InputMaybe<Scalars['String']>;
  error_message?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  idempotency_key?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  last_four_digits?: InputMaybe<Scalars['String']>;
  payment_method?: InputMaybe<Scalars['String']>;
  payment_token?: InputMaybe<Scalars['String']>;
  save_instrument?: InputMaybe<Scalars['Boolean']>;
  seq_id?: InputMaybe<Scalars['Int']>;
  set_instrument_as_default?: InputMaybe<Scalars['Boolean']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  transaction_status?: InputMaybe<Scalars['String']>;
  transaction_time?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "transaction" */
export type Transaction_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "transaction" */
export type Transaction_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "transaction" */
export type Transaction_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "transaction" */
export type Transaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transaction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transaction_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['Int']>;
  card_brand?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  error_code?: InputMaybe<Scalars['String']>;
  error_message?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  idempotency_key?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['Int']>;
  last_four_digits?: InputMaybe<Scalars['String']>;
  payment_method?: InputMaybe<Scalars['String']>;
  payment_token?: InputMaybe<Scalars['String']>;
  save_instrument?: InputMaybe<Scalars['Boolean']>;
  seq_id?: InputMaybe<Scalars['Int']>;
  set_instrument_as_default?: InputMaybe<Scalars['Boolean']>;
  transaction_id?: InputMaybe<Scalars['String']>;
  transaction_status?: InputMaybe<Scalars['String']>;
  transaction_time?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "transaction" */
export type Transaction_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
};

/** update columns of table "transaction" */
export enum Transaction_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CardBrand = 'card_brand',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErrorCode = 'error_code',
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  Id = 'id',
  /** column name */
  IdempotencyKey = 'idempotency_key',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  LastFourDigits = 'last_four_digits',
  /** column name */
  PaymentMethod = 'payment_method',
  /** column name */
  PaymentToken = 'payment_token',
  /** column name */
  SaveInstrument = 'save_instrument',
  /** column name */
  SeqId = 'seq_id',
  /** column name */
  SetInstrumentAsDefault = 'set_instrument_as_default',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TransactionStatus = 'transaction_status',
  /** column name */
  TransactionTime = 'transaction_time'
}

export type Transaction_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transaction_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transaction_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transaction_Bool_Exp;
};

/** order by var_pop() on columns of table "transaction" */
export type Transaction_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "transaction" */
export type Transaction_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "transaction" */
export type Transaction_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  seq_id?: InputMaybe<Order_By>;
};

export type Transfer_Request_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Transfer_Request_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Transfer_Request_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Transfer_Request_Aggregate_Bool_Exp_Count>;
};

export type Transfer_Request_Aggregate_Bool_Exp_Bool_And = {
  arguments: Transfer_Request_Select_Column_Transfer_Request_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transfer_Request_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Transfer_Request_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Transfer_Request_Select_Column_Transfer_Request_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transfer_Request_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Transfer_Request_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Transfer_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Transfer_Request_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "transfer_request" */
export type Transfer_Request_Aggregate_Order_By = {
  avg?: InputMaybe<Transfer_Request_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Transfer_Request_Max_Order_By>;
  min?: InputMaybe<Transfer_Request_Min_Order_By>;
  stddev?: InputMaybe<Transfer_Request_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Transfer_Request_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Transfer_Request_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Transfer_Request_Sum_Order_By>;
  var_pop?: InputMaybe<Transfer_Request_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Transfer_Request_Var_Samp_Order_By>;
  variance?: InputMaybe<Transfer_Request_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "transfer_request" */
export type Transfer_Request_Arr_Rel_Insert_Input = {
  data: Array<Transfer_Request_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Transfer_Request_On_Conflict>;
};

/** order by avg() on columns of table "transfer_request" */
export type Transfer_Request_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transfer_request". All fields are combined with a logical 'AND'. */
export type Transfer_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Transfer_Request_Bool_Exp>>;
  _not?: InputMaybe<Transfer_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Transfer_Request_Bool_Exp>>;
  booking_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  cancellation_reason?: InputMaybe<Config_Transfer_Request_Cancellation_Reasons_Enum_Comparison_Exp>;
  cancellation_reason_other?: InputMaybe<String_Comparison_Exp>;
  cancelled?: InputMaybe<Boolean_Comparison_Exp>;
  consent_type?: InputMaybe<Config_Transfer_Request_Consent_Type_Enum_Comparison_Exp>;
  contact_status?: InputMaybe<Config_Contact_Status_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  done?: InputMaybe<Boolean_Comparison_Exp>;
  first_order_outcome?: InputMaybe<Config_Transfer_Request_First_Order_Outcome_Enum_Comparison_Exp>;
  has_accepted_terms?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  pharmacy?: InputMaybe<Pharmacy_Bool_Exp>;
  pharmacy_id?: InputMaybe<String_Comparison_Exp>;
  planned_transfer_date?: InputMaybe<Date_Comparison_Exp>;
  processing_status?: InputMaybe<Config_Transfer_Request_Processing_Status_Enum_Comparison_Exp>;
  sent?: InputMaybe<Boolean_Comparison_Exp>;
  status?: InputMaybe<Config_Transfer_Request_Status_Enum_Comparison_Exp>;
  transfer_request_status?: InputMaybe<Config_Transfer_Request_Status_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "transfer_request" */
export enum Transfer_Request_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkDe9b8501ed3bf28cd7dbdcb1d60 = 'PK_de9b8501ed3bf28cd7dbdcb1d60',
  /** unique or primary key constraint on columns "booking_event_id" */
  TransferRequestBookingEventIdKey = 'transfer_request_booking_event_id_key'
}

/** input type for incrementing numeric columns in table "transfer_request" */
export type Transfer_Request_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "transfer_request" */
export type Transfer_Request_Insert_Input = {
  booking_event_id?: InputMaybe<Scalars['uuid']>;
  cancellation_reason?: InputMaybe<Config_Transfer_Request_Cancellation_Reasons_Enum>;
  cancellation_reason_other?: InputMaybe<Scalars['String']>;
  cancelled?: InputMaybe<Scalars['Boolean']>;
  consent_type?: InputMaybe<Config_Transfer_Request_Consent_Type_Enum>;
  contact_status?: InputMaybe<Config_Contact_Status_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  /** DEPRECATED: remove on or after 2022-05-01 */
  done?: InputMaybe<Scalars['Boolean']>;
  first_order_outcome?: InputMaybe<Config_Transfer_Request_First_Order_Outcome_Enum>;
  has_accepted_terms?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  /** DEPRECATED: remove on or after 2022-05-01 */
  notes?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  pharmacy?: InputMaybe<Pharmacy_Obj_Rel_Insert_Input>;
  pharmacy_id?: InputMaybe<Scalars['String']>;
  planned_transfer_date?: InputMaybe<Scalars['date']>;
  processing_status?: InputMaybe<Config_Transfer_Request_Processing_Status_Enum>;
  sent?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Config_Transfer_Request_Status_Enum>;
  transfer_request_status?: InputMaybe<Config_Transfer_Request_Status_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "transfer_request" */
export type Transfer_Request_Max_Order_By = {
  booking_event_id?: InputMaybe<Order_By>;
  cancellation_reason_other?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** DEPRECATED: remove on or after 2022-05-01 */
  notes?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  pharmacy_id?: InputMaybe<Order_By>;
  planned_transfer_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "transfer_request" */
export type Transfer_Request_Min_Order_By = {
  booking_event_id?: InputMaybe<Order_By>;
  cancellation_reason_other?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** DEPRECATED: remove on or after 2022-05-01 */
  notes?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  pharmacy_id?: InputMaybe<Order_By>;
  planned_transfer_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** on_conflict condition type for table "transfer_request" */
export type Transfer_Request_On_Conflict = {
  constraint: Transfer_Request_Constraint;
  update_columns?: Array<Transfer_Request_Update_Column>;
  where?: InputMaybe<Transfer_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "transfer_request". */
export type Transfer_Request_Order_By = {
  booking_event_id?: InputMaybe<Order_By>;
  cancellation_reason?: InputMaybe<Order_By>;
  cancellation_reason_other?: InputMaybe<Order_By>;
  cancelled?: InputMaybe<Order_By>;
  consent_type?: InputMaybe<Order_By>;
  contact_status?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  done?: InputMaybe<Order_By>;
  first_order_outcome?: InputMaybe<Order_By>;
  has_accepted_terms?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  pharmacy?: InputMaybe<Pharmacy_Order_By>;
  pharmacy_id?: InputMaybe<Order_By>;
  planned_transfer_date?: InputMaybe<Order_By>;
  processing_status?: InputMaybe<Order_By>;
  sent?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transfer_request_status?: InputMaybe<Config_Transfer_Request_Status_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transfer_request */
export type Transfer_Request_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "transfer_request" */
export enum Transfer_Request_Select_Column {
  /** column name */
  BookingEventId = 'booking_event_id',
  /** column name */
  CancellationReason = 'cancellation_reason',
  /** column name */
  CancellationReasonOther = 'cancellation_reason_other',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  ConsentType = 'consent_type',
  /** column name */
  ContactStatus = 'contact_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Done = 'done',
  /** column name */
  FirstOrderOutcome = 'first_order_outcome',
  /** column name */
  HasAcceptedTerms = 'has_accepted_terms',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PharmacyId = 'pharmacy_id',
  /** column name */
  PlannedTransferDate = 'planned_transfer_date',
  /** column name */
  ProcessingStatus = 'processing_status',
  /** column name */
  Sent = 'sent',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "transfer_request_aggregate_bool_exp_bool_and_arguments_columns" columns of table "transfer_request" */
export enum Transfer_Request_Select_Column_Transfer_Request_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  Done = 'done',
  /** column name */
  HasAcceptedTerms = 'has_accepted_terms',
  /** column name */
  Sent = 'sent'
}

/** select "transfer_request_aggregate_bool_exp_bool_or_arguments_columns" columns of table "transfer_request" */
export enum Transfer_Request_Select_Column_Transfer_Request_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  Done = 'done',
  /** column name */
  HasAcceptedTerms = 'has_accepted_terms',
  /** column name */
  Sent = 'sent'
}

/** input type for updating data in table "transfer_request" */
export type Transfer_Request_Set_Input = {
  booking_event_id?: InputMaybe<Scalars['uuid']>;
  cancellation_reason?: InputMaybe<Config_Transfer_Request_Cancellation_Reasons_Enum>;
  cancellation_reason_other?: InputMaybe<Scalars['String']>;
  cancelled?: InputMaybe<Scalars['Boolean']>;
  consent_type?: InputMaybe<Config_Transfer_Request_Consent_Type_Enum>;
  contact_status?: InputMaybe<Config_Contact_Status_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  /** DEPRECATED: remove on or after 2022-05-01 */
  done?: InputMaybe<Scalars['Boolean']>;
  first_order_outcome?: InputMaybe<Config_Transfer_Request_First_Order_Outcome_Enum>;
  has_accepted_terms?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  /** DEPRECATED: remove on or after 2022-05-01 */
  notes?: InputMaybe<Scalars['String']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  pharmacy_id?: InputMaybe<Scalars['String']>;
  planned_transfer_date?: InputMaybe<Scalars['date']>;
  processing_status?: InputMaybe<Config_Transfer_Request_Processing_Status_Enum>;
  sent?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Config_Transfer_Request_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "transfer_request" */
export type Transfer_Request_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "transfer_request" */
export type Transfer_Request_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "transfer_request" */
export type Transfer_Request_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "transfer_request" */
export type Transfer_Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transfer_Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transfer_Request_Stream_Cursor_Value_Input = {
  booking_event_id?: InputMaybe<Scalars['uuid']>;
  cancellation_reason?: InputMaybe<Config_Transfer_Request_Cancellation_Reasons_Enum>;
  cancellation_reason_other?: InputMaybe<Scalars['String']>;
  cancelled?: InputMaybe<Scalars['Boolean']>;
  consent_type?: InputMaybe<Config_Transfer_Request_Consent_Type_Enum>;
  contact_status?: InputMaybe<Config_Contact_Status_Enum>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamp']>;
  /** DEPRECATED: remove on or after 2022-05-01 */
  done?: InputMaybe<Scalars['Boolean']>;
  first_order_outcome?: InputMaybe<Config_Transfer_Request_First_Order_Outcome_Enum>;
  has_accepted_terms?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  /** DEPRECATED: remove on or after 2022-05-01 */
  notes?: InputMaybe<Scalars['String']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  pharmacy_id?: InputMaybe<Scalars['String']>;
  planned_transfer_date?: InputMaybe<Scalars['date']>;
  processing_status?: InputMaybe<Config_Transfer_Request_Processing_Status_Enum>;
  sent?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Config_Transfer_Request_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "transfer_request" */
export type Transfer_Request_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "transfer_request" */
export enum Transfer_Request_Update_Column {
  /** column name */
  BookingEventId = 'booking_event_id',
  /** column name */
  CancellationReason = 'cancellation_reason',
  /** column name */
  CancellationReasonOther = 'cancellation_reason_other',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  ConsentType = 'consent_type',
  /** column name */
  ContactStatus = 'contact_status',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Done = 'done',
  /** column name */
  FirstOrderOutcome = 'first_order_outcome',
  /** column name */
  HasAcceptedTerms = 'has_accepted_terms',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  PharmacyId = 'pharmacy_id',
  /** column name */
  PlannedTransferDate = 'planned_transfer_date',
  /** column name */
  ProcessingStatus = 'processing_status',
  /** column name */
  Sent = 'sent',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Transfer_Request_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transfer_Request_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transfer_Request_Set_Input>;
  /** filter the rows which have to be updated */
  where: Transfer_Request_Bool_Exp;
};

/** order by var_pop() on columns of table "transfer_request" */
export type Transfer_Request_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "transfer_request" */
export type Transfer_Request_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "transfer_request" */
export type Transfer_Request_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "typeorm_metadata". All fields are combined with a logical 'AND'. */
export type Typeorm_Metadata_Bool_Exp = {
  _and?: InputMaybe<Array<Typeorm_Metadata_Bool_Exp>>;
  _not?: InputMaybe<Typeorm_Metadata_Bool_Exp>;
  _or?: InputMaybe<Array<Typeorm_Metadata_Bool_Exp>>;
  database?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  schema?: InputMaybe<String_Comparison_Exp>;
  table?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "typeorm_metadata" */
export type Typeorm_Metadata_Insert_Input = {
  database?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schema?: InputMaybe<Scalars['String']>;
  table?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Ordering options when selecting data from "typeorm_metadata". */
export type Typeorm_Metadata_Order_By = {
  database?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  schema?: InputMaybe<Order_By>;
  table?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "typeorm_metadata" */
export enum Typeorm_Metadata_Select_Column {
  /** column name */
  Database = 'database',
  /** column name */
  Name = 'name',
  /** column name */
  Schema = 'schema',
  /** column name */
  Table = 'table',
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "typeorm_metadata" */
export type Typeorm_Metadata_Set_Input = {
  database?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schema?: InputMaybe<Scalars['String']>;
  table?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "typeorm_metadata" */
export type Typeorm_Metadata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Typeorm_Metadata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Typeorm_Metadata_Stream_Cursor_Value_Input = {
  database?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schema?: InputMaybe<Scalars['String']>;
  table?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Typeorm_Metadata_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Typeorm_Metadata_Set_Input>;
  /** filter the rows which have to be updated */
  where: Typeorm_Metadata_Bool_Exp;
};

export type Upload_Aggregate_Bool_Exp = {
  count?: InputMaybe<Upload_Aggregate_Bool_Exp_Count>;
};

export type Upload_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Upload_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Upload_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** order by aggregate values of table "upload" */
export type Upload_Aggregate_Order_By = {
  avg?: InputMaybe<Upload_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Upload_Max_Order_By>;
  min?: InputMaybe<Upload_Min_Order_By>;
  stddev?: InputMaybe<Upload_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Upload_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Upload_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Upload_Sum_Order_By>;
  var_pop?: InputMaybe<Upload_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Upload_Var_Samp_Order_By>;
  variance?: InputMaybe<Upload_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "upload" */
export type Upload_Arr_Rel_Insert_Input = {
  data: Array<Upload_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Upload_On_Conflict>;
};

/** order by avg() on columns of table "upload" */
export type Upload_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "upload". All fields are combined with a logical 'AND'. */
export type Upload_Bool_Exp = {
  _and?: InputMaybe<Array<Upload_Bool_Exp>>;
  _not?: InputMaybe<Upload_Bool_Exp>;
  _or?: InputMaybe<Array<Upload_Bool_Exp>>;
  back_image_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  front_image_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  patient?: InputMaybe<Patient_Bool_Exp>;
  patient_uid?: InputMaybe<String_Comparison_Exp>;
  prescription_uploads?: InputMaybe<Prescription_Upload_Bool_Exp>;
  prescription_uploads_aggregate?: InputMaybe<Prescription_Upload_Aggregate_Bool_Exp>;
  status?: InputMaybe<Config_Upload_Status_Enum_Comparison_Exp>;
  type?: InputMaybe<Upload_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  upload_status?: InputMaybe<Config_Upload_Status_Bool_Exp>;
};

/** unique or primary key constraints on table "upload" */
export enum Upload_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_1fe8db121b3de4ddfa677fc51f3 = 'PK_1fe8db121b3de4ddfa677fc51f3'
}

/** input type for incrementing numeric columns in table "upload" */
export type Upload_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "upload" */
export type Upload_Insert_Input = {
  back_image_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  front_image_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patient?: InputMaybe<Patient_Obj_Rel_Insert_Input>;
  patient_uid?: InputMaybe<Scalars['String']>;
  prescription_uploads?: InputMaybe<Prescription_Upload_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Config_Upload_Status_Enum>;
  /** Deprecated since June 2022 */
  type?: InputMaybe<Scalars['upload_type_enum']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  upload_status?: InputMaybe<Config_Upload_Status_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "upload" */
export type Upload_Max_Order_By = {
  back_image_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  front_image_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  /** Deprecated since June 2022 */
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "upload" */
export type Upload_Min_Order_By = {
  back_image_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  front_image_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  /** Deprecated since June 2022 */
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "upload" */
export type Upload_Obj_Rel_Insert_Input = {
  data: Upload_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Upload_On_Conflict>;
};

/** on_conflict condition type for table "upload" */
export type Upload_On_Conflict = {
  constraint: Upload_Constraint;
  update_columns?: Array<Upload_Update_Column>;
  where?: InputMaybe<Upload_Bool_Exp>;
};

/** Ordering options when selecting data from "upload". */
export type Upload_Order_By = {
  back_image_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  front_image_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patient_Order_By>;
  patient_uid?: InputMaybe<Order_By>;
  prescription_uploads_aggregate?: InputMaybe<Prescription_Upload_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload_status?: InputMaybe<Config_Upload_Status_Order_By>;
};

/** primary key columns input for table: upload */
export type Upload_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "upload" */
export enum Upload_Select_Column {
  /** column name */
  BackImageUrl = 'back_image_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FrontImageUrl = 'front_image_url',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "upload" */
export type Upload_Set_Input = {
  back_image_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  front_image_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Upload_Status_Enum>;
  /** Deprecated since June 2022 */
  type?: InputMaybe<Scalars['upload_type_enum']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by stddev() on columns of table "upload" */
export type Upload_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "upload" */
export type Upload_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "upload" */
export type Upload_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "upload" */
export type Upload_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Upload_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Upload_Stream_Cursor_Value_Input = {
  back_image_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  front_image_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patient_uid?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Upload_Status_Enum>;
  /** Deprecated since June 2022 */
  type?: InputMaybe<Scalars['upload_type_enum']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** order by sum() on columns of table "upload" */
export type Upload_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "upload_type_enum". All fields are combined with logical 'AND'. */
export type Upload_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['upload_type_enum']>;
  _gt?: InputMaybe<Scalars['upload_type_enum']>;
  _gte?: InputMaybe<Scalars['upload_type_enum']>;
  _in?: InputMaybe<Array<Scalars['upload_type_enum']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['upload_type_enum']>;
  _lte?: InputMaybe<Scalars['upload_type_enum']>;
  _neq?: InputMaybe<Scalars['upload_type_enum']>;
  _nin?: InputMaybe<Array<Scalars['upload_type_enum']>>;
};

/** update columns of table "upload" */
export enum Upload_Update_Column {
  /** column name */
  BackImageUrl = 'back_image_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FrontImageUrl = 'front_image_url',
  /** column name */
  Id = 'id',
  /** column name */
  PatientUid = 'patient_uid',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Upload_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Upload_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Upload_Set_Input>;
  /** filter the rows which have to be updated */
  where: Upload_Bool_Exp;
};

/** order by var_pop() on columns of table "upload" */
export type Upload_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "upload" */
export type Upload_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "upload" */
export type Upload_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};


