import React, {Component, ErrorInfo} from 'react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/react';
import {GraphQLError} from 'graphql';
import {useTranslation} from 'react-i18next';
import Alert from 'ui/@components/alert-v2';
import Alerts from 'ui/@components/alerts';

interface State {
  hasError: boolean;
}

const Container = styled.div`
  padding-top: env(safe-area-inset-top);
`;

const Reload = styled.button`
  text-decoration: underline;
  font-weight: 600;
  color: inherit;
`;

const ErrorMessage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <Alerts>
      <Alert type="danger" icon="x">
        {t('fatalError')} <Reload onClick={() => window.location.reload()}>{t('reload')}</Reload>
      </Alert>
    </Alerts>
  );
};

const isGraphQLError = (error: unknown): error is GraphQLError => {
  const maybeGraphQLError = error as GraphQLError;

  return 'message' in maybeGraphQLError && 'extensions' in maybeGraphQLError;
};

export class ErrorBoundary extends Component<{}, State> {
  static getDerivedStateFromError() {
    return {hasError: true};
  }

  state: State = {
    hasError: false,
  };

  componentDidCatch(error: unknown, errorInfo: ErrorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      if (isGraphQLError(error)) {
        scope.setExtra('extensions', error.extensions);
        Sentry.captureException(error);
        return;
      }

      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <ErrorMessage />
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
